import moment from 'moment';

/* Components */
import IconTeasers from './components/IconTeasers.vue';
import IconTeaser from './components/IconTeaser.vue';
import VitAccordionGroup from './components/base/VitAccordionGroup.vue';
import VitAccordion from './components/base/VitAccordion.vue';
import VitTabs from './components/base/VitTabs.vue';
import VitTab from './components/base/VitTab.vue';
import VitDropdown from './components/base/VitDropdown.vue';
import VitBreadcrumbs from './components/base/VitBreadcrumbs.vue';
import VitSlider from './components/base/VitSlider.vue';
import VitSlide from './components/base/VitSlide.vue';
import VitLeadership from './components/VitLeadership.vue';
import SharedMap from './components/SharedMap.vue';
import OfficesMap from './components/OfficesMap.vue';
import CookieBanner from './components/CookieBanner.vue';
import CookiePopup from './components/CookiePopup.vue';
import HorizontalScrollSlider from './components/HorizontalScrollSlider.vue';
import ShapeSlide from './components/atoms/ShapeSlide.vue';
import FilterableArticles from './components/FilterableArticles.vue';
import MediaFilter from './components/MediaFilter.vue';
import ReCaptchaSubmit from './components/ReCaptchaSubmit.vue';
import FileUpload from './components/atoms/FileUpload.vue';
import CountryPopup from './components/CountryPopup.vue';
import CountryDisclaimer from './components/CountryDisclaimer.vue';
import BidSheetsLinks from './components/BidSheetsLinks.vue';
import JobOverview from './components/JobOverview.vue';
import JobTeasers from './components/JobTeasers.vue';
import TabbedSlide from './components/TabbedSlide.vue';
import TabbedSlider from './components/TabbedSlider.vue';
import CustomHtml from './components/CustomHtml.vue';
import RedirectPopup from './components/RedirectPopup.vue';
import MlcConverter from './components/MlcConverter.vue';
import ReportSubscription from './components/ReportSubscription.vue';
import VitShare from './components/base/VitShare.vue';

import MainNavigation from './components/navigation/MainNavigation.vue';
import MainNavigationItem from './components/navigation/MainNavigationItem.vue';

import GavBanner from './components/gavilon/GavBanner.vue';
import GavCalculator from './components/gavilon/GavCalculator.vue';
import GavContracts from './components/gavilon/GavContracts.vue';
import GavLocations from './components/gavilon/GavLocations.vue';
import GavMobileLoadMore from './components/gavilon/GavMobileLoadMore.vue';
import GavStageMap from './components/gavilon/GavStageMap.vue';

import CashPricing from './components/segregation/CashPricing.vue';
import SegregationPlan from './components/segregation/SegregationPlan.vue';
import SegregationDetail from './components/segregation/SegregationDetail.vue';
import QualityStats from './components/segregation/QualityStats.vue';

/* Directives */
import openPopUp from './directives/open-pop-up';
import inputNumber from './directives/input-number';
import FadeIn from './directives/fade-in';
import NumberAnimation from './directives/number-animation';

export default {
    install(app) {
        /* eslint-disable dot-notation */
        // global properties
        app.config.globalProperties.$contextPath = window['contextPath'];
        app.config.globalProperties.$resourcePath = window['resourcePath'];
        app.config.globalProperties.$siteName = window['siteName'];
        app.config.globalProperties.$lang = window['lang'];
        app.config.globalProperties.$theme = window['theme'];

        // also provide them so we can use them in composition api components with `inject`
        app.provide('contextPath', window['contextPath']);
        app.provide('resourcePath', window['resourcePath']);
        app.provide('siteName', window['siteName']);
        app.provide('lang', window['lang']);
        app.provide('theme', window['theme']);
        /* eslint-enable dot-notation */

        /* Components */
        app.component('IconTeasers', IconTeasers);
        app.component('IconTeaser', IconTeaser);
        app.component('VitAccordionGroup', VitAccordionGroup);
        app.component('VitAccordion', VitAccordion);
        app.component('VitTabs', VitTabs);
        app.component('VitTab', VitTab);
        app.component('VitBreadcrumbs', VitBreadcrumbs);
        app.component('VitDropdown', VitDropdown);
        app.component('VitLeadership', VitLeadership);
        app.component('SharedMap', SharedMap);
        app.component('OfficesMap', OfficesMap);
        app.component('CookieBanner', CookieBanner);
        app.component('CookiePopup', CookiePopup);
        app.component('HorizontalScrollSlider', HorizontalScrollSlider);
        app.component('ShapeSlide', ShapeSlide);
        app.component('FilterableArticles', FilterableArticles);
        app.component('MediaFilter', MediaFilter);
        app.component('ReCaptchaSubmit', ReCaptchaSubmit);
        app.component('FileUpload', FileUpload);
        app.component('CountryPopup', CountryPopup);
        app.component('CountryDisclaimer', CountryDisclaimer);
        app.component('BidSheetsLinks', BidSheetsLinks);
        app.component('JobOverview', JobOverview);
        app.component('JobTeasers', JobTeasers);
        app.component('VitSlider', VitSlider);
        app.component('VitSlide', VitSlide);
        app.component('TabbedSlider', TabbedSlider);
        app.component('TabbedSlide', TabbedSlide);
        app.component('CustomHtml', CustomHtml);
        app.component('RedirectPopup', RedirectPopup);
        app.component('MlcConverter', MlcConverter);
        app.component('ReportSubscription', ReportSubscription);
        app.component('VitShare', VitShare);

        /* Navigation Components */
        app.component('MainNavigation', MainNavigation);
        app.component('MainNavigationItem', MainNavigationItem);

        /* Gavilon Components */
        app.component('GavBanner', GavBanner);
        app.component('GavCalculator', GavCalculator);
        app.component('GavContracts', GavContracts);
        app.component('GavLocations', GavLocations);
        app.component('GavMobileLoadMore', GavMobileLoadMore);
        app.component('GavStageMap', GavStageMap);

        /* Segregation Components */
        app.component('CashPricing', CashPricing);
        app.component('SegregationPlan', SegregationPlan);
        app.component('SegregationDetail', SegregationDetail);
        app.component('QualityStats', QualityStats);

        /* Directives */
        app.directive('pop-up', openPopUp);
        app.directive('input-number', inputNumber);
        app.directive('fade-in', FadeIn);
        app.directive('number-animation', NumberAnimation);

        // global truncate filter
        // truncates text to the given length optionally ignoring html tags
        app.config.globalProperties.$truncate = (val, len = 65, ignoreHTML = false) => {
            if (val && val.length > len) {
                let length = len;
                if (ignoreHTML) {
                    const tmp = document.createElement('DIV');
                    tmp.innerHTML = val;
                    const textOnlyLength = (tmp.textContent || tmp.innerText || val).length;
                    if (textOnlyLength <= len) {
                        return val;
                    }
                    length += (val.length - textOnlyLength);
                }
                return `${val.slice(0, length)}...`;
            }
            return val;
        };
        // date formatter ("2019-08-19T08:39:09.295+02:00" -> dateFormatPattern, e.g. "19/08/2019")
        // forces UTC offset 0 if fromJcr is true (default)
        app.config.globalProperties.$date = (date, fromJcr = true) => {
            // eslint-disable-next-line dot-notation
            if (window['dateFormatPattern']) {
                // eslint-disable-next-line dot-notation
                return fromJcr ? moment(date).utcOffset(0).format(window['dateFormatPattern']) : moment(date).format(window['dateFormatPattern']);
            }

            // Fallback in case dateFormatPattern is missing
            return new Date(date).toLocaleDateString('en-GB');
        };
        // capitalize string
        app.config.globalProperties.$capitalize = str => str.toLowerCase().replace(/\b[a-z](?=[a-z]{2})/g, letter => letter.toUpperCase());
        app.config.globalProperties.$stripBreaks = html => html.replace(/<br\s?\/?>/g, '');
        app.config.globalProperties.$formatNumber = (val: string) => val.replace(',', '.');
    }
};

<template>
    <div class="media-filter">
        <vit-dropdown-bar>
            <vit-dropdown :label="format.length ? $capitalize(format) : allFormatsLabel">
                <dropdown-item :label="allFormatsLabel" @click="selectFormat" :selected="format === ''" />
                <dropdown-item
                    v-for="f in formats"
                    :key="f.key"
                    :label="f.label"
                    :selected="f.key === format"
                    @click="selectFormat(f.key)" />
            </vit-dropdown>
            <vit-dropdown :label="country.length ? country : allCountriesLabel">
                <dropdown-item :label="allCountriesLabel" @click="selectCountry" :selected="country === ''" />
                <dropdown-item
                    v-for="c in countries"
                    :key="c"
                    :label="c"
                    :selected="c === country"
                    @click="selectCountry(c)" />
            </vit-dropdown>
            <vit-dropdown :label="commodity.length ? commodity : allCommoditiesLabel">
                <dropdown-item :label="allCommoditiesLabel" @click="selectCommodity" :selected="commodity === ''" />
                <dropdown-item
                    v-for="c in commodities"
                    :key="c"
                    :selected="c === commodity"
                    :label="c"
                    @click="selectCommodity(c)" />
            </vit-dropdown>
        </vit-dropdown-bar>
        <button
            v-if="filterQuery.includes('country') || filterQuery.includes('commodity') || filterQuery.includes('format')"
            @click="resetFilter"
            class="button button--borderless button--no-arrow media-filter__reset-filters">
            {{ resetFiltersLabel }}
        </button>
        <div class="media-filter__grid">
            <media-item
                v-for="item in items"
                :key="item.uuid"
                :item="item"
                :goto-video-label="gotoVideoLabel"
                :download-label="downloadLabel"
                class="media-filter__item" />
        </div>

        <pagination :num-pages="numPages" v-model="page" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
import { MEDIA_ENDPOINT } from '../Constants';
import MediaItem from './MediaItem.vue';
import VitDropdownBar from './base/VitDropdownBar.vue';
import DropdownItem from './base/VitDropdownItem.vue';
import Pagination from './atoms/Pagination.vue';
import VitDropdown from './base/VitDropdown.vue';

export default defineComponent({
    components: { VitDropdown, Pagination, VitDropdownBar, DropdownItem, MediaItem },
    props: {
        siteName: { type: String, required: true },
        allFormatsLabel: { type: String, required: true },
        allCountriesLabel: { type: String, required: true },
        allCommoditiesLabel: { type: String, required: true },
        imagesLabel: { type: String, required: true },
        videosLabel: { type: String, required: true },
        downloadLabel: { type: String, required: true },
        gotoVideoLabel: { type: String, required: true },
        resetFiltersLabel: { type: String, required: true }
    },
    data() {
        return {
            formats: [],
            countries: [],
            commodities: [],
            format: '',
            country: '',
            commodity: '',
            page: 1,
            total: 0,
            pageSize: 9,
            items: []
        };
    },

    created() {
        this.formats = [{ key: 'image', label: this.imagesLabel }, { key: 'video', label: this.videosLabel }];
        this.getCountries();
        this.getCommodities();
        this.getMedia();
    },

    methods: {
        async getCountries() {
            this.countries = (await axios.get(`${this.$contextPath}${MEDIA_ENDPOINT.countries(this.siteName)}`)).data;
        },

        async getCommodities() {
            this.commodities = (await axios.get(`${this.$contextPath}${MEDIA_ENDPOINT.commodities(this.siteName)}`)).data;
        },

        /**
         *  get data fom REST endpoint
         */
        async getMedia() {
            const {
                mediaItems,
                total,
                limit,
                page
            } = (await axios.get(`${this.$contextPath}${MEDIA_ENDPOINT.media(this.siteName)}${this.filterQuery}`)).data;
            this.items = mediaItems;
            this.total = total;
            this.pageSize = limit;
            this.page = page;
        },

        selectFormat(format: string = '') {
            this.page = 1;
            this.format = format.toLowerCase();
        },

        selectCountry(country: string = '') {
            this.page = 1;
            this.country = country;
        },

        selectCommodity(commodity: string = '') {
            this.page = 1;
            this.commodity = commodity;
        },

        resetFilter() {
            this.format = '';
            this.country = '';
            this.commodity = '';
            this.page = 1;
        },

        selectPage(page) {
            this.page = page;
        }
    },

    watch: {
        filterQuery() {
            this.getMedia();
        }
    },

    computed: {
        numPages() {
            return Math.ceil(this.total / this.pageSize);
        },

        // build filter query or empty string if no filter is selected
        filterQuery() {
            const query = {
                format: this.format,
                commodity: this.commodity,
                country: this.country,
                limit: `${this.pageSize}`,
                page: `${this.page}`
            };
            const queryStr = `${Object.keys(query).filter(key => query[key].length > 0).map(key => `${key}=${query[key]}`).join('&')}`;
            return queryStr.length ? `?${queryStr}` : '';
        }
    }
});

</script>

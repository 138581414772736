import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "target"]
const _hoisted_2 = { class: "icon-teaser__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.link,
    target: _ctx.determineTarget,
    class: _normalizeClass(["icon-teaser", `icon-teaser--${_ctx.category}`]),
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnter && _ctx.onMouseEnter(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
  }, [
    _renderSlot(_ctx.$slots, "icon"),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1)
  ], 42, _hoisted_1))
}
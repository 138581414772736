import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "filterable-articles" }
const _hoisted_2 = { class: "teasers teasers--grid3 teasers--grid3-article" }
const _hoisted_3 = {
  key: 0,
  class: "filterable-articles__cover-row"
}
const _hoisted_4 = {
  key: 2,
  class: "filterable-articles__error"
}
const _hoisted_5 = { class: "filterable-articles__error-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_item = _resolveComponent("dropdown-item")!
  const _component_vit_dropdown = _resolveComponent("vit-dropdown")!
  const _component_vit_dropdown_bar = _resolveComponent("vit-dropdown-bar")!
  const _component_article_teaser = _resolveComponent("article-teaser")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vit_dropdown_bar, { title: _ctx.filtersLabel }, {
      default: _withCtx(() => [
        (_ctx.tags.length)
          ? (_openBlock(), _createBlock(_component_vit_dropdown, {
              key: 0,
              label: _ctx.$capitalize(_ctx.tagsLabel),
              multiple: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
                  return (_openBlock(), _createBlock(_component_dropdown_item, {
                    key: tag.key,
                    label: tag.label,
                    selected: _ctx.selectedTags.includes(tag.key),
                    onClick: ($event: any) => (_ctx.toggleTag(tag))
                  }, null, 8, ["label", "selected", "onClick"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_vit_dropdown, {
          label: _ctx.year > 0 ? _ctx.year : _ctx.allYearsLabel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_dropdown_item, {
              label: _ctx.allYearsLabel,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectYear(-1)))
            }, null, 8, ["label"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.years, (y) => {
              return (_openBlock(), _createBlock(_component_dropdown_item, {
                key: y,
                selected: y === _ctx.year,
                label: `${y}`,
                onClick: ($event: any) => (_ctx.selectYear(`${y}`))
              }, null, 8, ["selected", "label", "onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }, 8, ["title"]),
    (_ctx.filterQuery.includes('tags') || _ctx.filterQuery.includes('year'))
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.resetFilter && _ctx.resetFilter(...args))),
          class: "button button--borderless button--no-arrow filterable-articles__reset-filters"
        }, _toDisplayString(_ctx.resetFiltersLabel), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(9, (i) => {
            return _createVNode(_component_article_teaser, {
              key: `loading#${i}`,
              class: "teasers__cell",
              loading: ""
            })
          }), 64))
        : (_ctx.articles.length)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.coverArticles.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coverArticles, (article, a) => {
                      return (_openBlock(), _createBlock(_component_article_teaser, {
                        key: article.uuid,
                        class: "teasers__cell",
                        date: article.date,
                        link: article.link,
                        large: _ctx.withCover && a === 0,
                        "show-image": _ctx.showImages,
                        "read-more-label": _ctx.readMoreLabel,
                        "image-md": article.mdImageUrl,
                        "image-lg": article.lgImageUrl,
                        description: article.title
                      }, null, 8, ["date", "link", "large", "show-image", "read-more-label", "image-md", "image-lg", "description"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articles.slice(_ctx.coverArticles.length), (article) => {
                return (_openBlock(), _createBlock(_component_article_teaser, {
                  key: article.uuid,
                  class: "teasers__cell",
                  date: article.date,
                  link: article.link,
                  "show-image": _ctx.showImages,
                  "read-more-label": _ctx.readMoreLabel,
                  "image-md": article.mdImageUrl,
                  "image-lg": article.lgImageUrl,
                  description: article.title
                }, null, 8, ["date", "link", "show-image", "read-more-label", "image-md", "image-lg", "description"]))
              }), 128))
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.noResultsLabel), 1),
              _createElementVNode("button", {
                class: "button",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.resetFilter && _ctx.resetFilter(...args)))
              }, _toDisplayString(_ctx.resetFiltersLabel), 1)
            ]))
    ]),
    _createVNode(_component_pagination, {
      "num-pages": _ctx.numPages,
      modelValue: _ctx.page,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.page) = $event))
    }, null, 8, ["num-pages", "modelValue"])
  ]))
}
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "slider__container" }
const _hoisted_2 = {
  class: "slider__slides",
  ref: "teaserSlider"
}
const _hoisted_3 = { class: "slider__controls-wrapper" }
const _hoisted_4 = { class: "slider__controls" }
const _hoisted_5 = { class: "slider__indicators" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["slider", { 'slider--highlight': _ctx.highlight }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ], 512)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_icon, {
          name: "icn-bracket",
          class: "slider__controls-bracket"
        }),
        _createElementVNode("div", {
          class: _normalizeClass(["button button--borderless button--reverse", { 'button--disabled': _ctx.activeIndex === 0 && !_ctx.loop, 'button--white': _ctx.highlight }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.prev && _ctx.prev(...args)))
        }, null, 2),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teasers, (teaser, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: teaser._uid,
              class: _normalizeClass(["slider__indicator", { 'slider__indicator--active': _ctx.activeIndex === i }]),
              onClick: ($event: any) => (_ctx.jumpTo(i))
            }, null, 10, _hoisted_6))
          }), 128))
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["button button--borderless slider__controls-button-next", { 'button--disabled': _ctx.activeIndex + 1 >= _ctx.teasers.length && !_ctx.loop, 'button--white': _ctx.highlight }]),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)))
        }, null, 2),
        _createVNode(_component_icon, {
          name: "icn-bracket",
          class: "slider__controls-bracket slider__controls-bracket-right"
        })
      ])
    ])
  ], 2))
}
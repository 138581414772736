import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form__privacy"
}
const _hoisted_2 = { class: "form__privacy-text" }
const _hoisted_3 = { class: "form__submit" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_recaptcha = _resolveComponent("vue-recaptcha")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showPrivacyPolicy)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.privacyPolicyAccepted) = $event))
          }, null, 512), [
            [_vModelCheckbox, _ctx.privacyPolicyAccepted]
          ]),
          _createElementVNode("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.isDev)
        ? (_openBlock(), _createBlock(_component_vue_recaptcha, {
            key: 0,
            ref: "captcha",
            sitekey: _ctx.siteKey,
            "load-recaptcha-script": "",
            onVerify: _ctx.onVerified
          }, null, 8, ["sitekey", "onVerify"]))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        type: "submit",
        disabled: !((_ctx.verified || _ctx.isDev) && _ctx.isFilled && (!_ctx.showPrivacyPolicy || _ctx.privacyPolicyAccepted)),
        class: "button form__submit-btn",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
      }, _toDisplayString(_ctx.label), 41, _hoisted_4)
    ])
  ]))
}
<template>
    <div class="share__flex">
        <div class="share__text">
            {{ title }}
        </div>
        <div class="share__share">
            <icon
                class="share__share__icon"
                :class="{'share__share__icon--active': isOpen}"
                name="icn-share"
                @click="isOpen = !isOpen" />
            <collapse-transition dimension="width">
                <ul class="share__share__list" v-if="isOpen" @click.stop>
                    <div @click="share('facebook')" class="share__share__list__icon">
                        <icon class="share__share__list__icon--icon" name="icn-facebook" />
                    </div>
                    <div @click="share('linkedin')" class="share__share__list__icon">
                        <icon class="share__share__list__icon--icon" name="icn-linkedin" />
                    </div>
                    <div @click="share('socialX')" class="share__share__list__icon">
                        <icon class="share__share__list__icon--icon" name="icn-social-x" />
                    </div>
                    <div @click="share('whatsapp')" class="share__share__list__icon">
                        <icon class="share__share__list__icon--icon" name="icn-whatsapp" />
                    </div>
                    <div @click="share('email')" class="share__share__list__icon">
                        <icon class="share__share__list__icon--icon" name="icn-email" />
                    </div>
                </ul>
            </collapse-transition>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import Icon from '../atoms/Icon.vue';

export default defineComponent({
    components: { CollapseTransition, Icon },
    props: {
        title: { type: String, default: '' }
    },
    data() {
        return {
            isOpen: false,
            shareUrl: window.location.href,
            shareUrls: {
                facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
                linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url=',
                socialX: 'https://twitter.com/intent/tweet?url=',
                whatsapp: 'https://api.whatsapp.com/send?text=',
                email: 'mailto:?subject=Check%20this%20out&body='
            }
        };
    },

    methods: {
        share(platform) {
            const url = this.shareUrls[platform] + encodeURIComponent(this.shareUrl);
            const target = platform === 'email' ? '_self' : '_blank';
            window.open(url, target);
        }
    }
});

</script>

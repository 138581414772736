import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, withCtx as _withCtx, Transition as _Transition, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "main-navigation__position" }
const _hoisted_2 = { class: "main-navigation__wrapper" }
const _hoisted_3 = {
  class: "navbar main-navigation__navbar navbar-light navbar-expand-lg",
  "aria-label": "main-nav"
}
const _hoisted_4 = { class: "main-navigation__wrapper" }
const _hoisted_5 = { class: "main-navigation__top" }
const _hoisted_6 = { class: "main-navigation__top-left" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "main-navigation__mobile-menu" }
const _hoisted_9 = {
  key: 0,
  class: "main-navigation__toggle-icon main-navigation__burger-icon"
}
const _hoisted_10 = {
  id: "nav-collapse",
  class: "main-navigation__main-items navbar-collapse collapse"
}
const _hoisted_11 = { class: "navbar-nav main-navigation__navbar-nav" }
const _hoisted_12 = ["aria-label"]
const _hoisted_13 = {
  key: 0,
  class: "main-navigation__country-identifier"
}
const _hoisted_14 = { class: "main-navigation__country-identifier-site" }
const _hoisted_15 = { class: "main-navigation__country-identifier-country" }
const _hoisted_16 = {
  key: 0,
  class: "search-bar__container search-bar__footer"
}
const _hoisted_17 = {
  key: 0,
  class: "pagination"
}
const _hoisted_18 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_meta_navigation = _resolveComponent("meta-navigation")!
  const _component_icon = _resolveComponent("icon")!
  const _component_main_navigation_submenu = _resolveComponent("main-navigation-submenu")!
  const _component_search_bar = _resolveComponent("search-bar")!
  const _component_overlay_navigation = _resolveComponent("overlay-navigation")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["main-navigation", { 'main-navigation--light': _ctx.light, 'main-navigation--fixed': _ctx.fixed || _ctx.mobileMenuOpen, 'main-navigation--fixed-out': _ctx.fading }]),
    onMouseleave: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
    onMouseenter: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("a", {
                  href: `${_ctx.logoLink}`,
                  class: "navbar-brand main-navigation__logo",
                  "aria-label": "Home"
                }, [
                  _createElementVNode("div", {
                    class: "main-navigation__logo-img",
                    style: _normalizeStyle(_ctx.logoStyle)
                  }, null, 4)
                ], 8, _hoisted_7)
              ]),
              _createVNode(_component_meta_navigation, {
                onOpenOverlayNav: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onOverlayTriggered(true))),
                items: _ctx.metaNav,
                "lang-items": _ctx.langNav,
                light: _ctx.light
              }, null, 8, ["items", "lang-items", "light"]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  type: "button",
                  "aria-label": "Toggle navigation",
                  class: "navbar-toggler main-navigation__toggle collapsed",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSearch && _ctx.toggleSearch(...args)))
                }, [
                  _createVNode(_component_icon, {
                    name: _ctx.searchVisible ? 'icn-cross' : 'icn-search',
                    class: "main-navigation__search-icon main-navigation__search-icon-green"
                  }, null, 8, ["name"])
                ]),
                _createElementVNode("button", {
                  type: "button",
                  "aria-label": "Toggle navigation",
                  class: "navbar-toggler main-navigation__toggle collapsed",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openMobileMenu && _ctx.openMobileMenu(...args)))
                }, [
                  (!_ctx.activeItem)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                    : (_openBlock(), _createBlock(_component_icon, {
                        key: 1,
                        name: "icn-cross",
                        class: "main-navigation__toggle-icon"
                      }))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("ul", _hoisted_11, [
                _renderSlot(_ctx.$slots, "default"),
                _createElementVNode("button", {
                  class: "main-navigation__search",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleSearch && _ctx.toggleSearch(...args))),
                  "aria-label": _ctx.searchLabel
                }, [
                  _createVNode(_component_icon, {
                    name: _ctx.searchVisible ? 'cross' : 'icn-search',
                    class: "main-navigation__search-icon"
                  }, null, 8, ["name"])
                ], 8, _hoisted_12)
              ])
            ]),
            (_ctx.welcomeTo && _ctx.country)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.welcomeTo), 1),
                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.country), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_Transition, { name: "main-navigation__slide-animation" }, {
          default: _withCtx(() => [
            (_ctx.activeItem)
              ? (_openBlock(), _createBlock(_component_main_navigation_submenu, {
                  key: 0,
                  "current-item": _ctx.activeItem,
                  onNav: _ctx.closeMobileMenu,
                  onSubmenu: _ctx.onHover
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_meta_navigation, {
                      items: _ctx.metaNav,
                      light: true,
                      class: "meta-navigation--mobile-only",
                      "lang-items": _ctx.langNav,
                      onOpenOverlayNav: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onOverlayTriggered(true)))
                    }, null, 8, ["items", "lang-items"])
                  ]),
                  _: 1
                }, 8, ["current-item", "onNav", "onSubmenu"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_search_bar, {
        visible: _ctx.searchVisible,
        results: _ctx.searchResults.map(x => ({ id: x.id, title: x.label })),
        container: "",
        placeholder: _ctx.searchPlaceholder,
        onResultClicked: _ctx.gotoResult,
        onSearch: _ctx.search
      }, {
        default: _withCtx(() => [
          ((_ctx.numPages > 1 && _ctx.searchPage > 0) || _ctx.showLoadMoreButton)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                (_ctx.numPages > 1 && _ctx.searchPage > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numPages, (i) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: `page#${i}`,
                          class: _normalizeClass(["pagination-item", { 'pagination-item-active' : i === _ctx.searchPage }]),
                          onClick: ($event: any) => (_ctx.selectPage(i))
                        }, _toDisplayString(i), 11, _hoisted_18))
                      }), 128))
                    ]))
                  : (_ctx.showLoadMoreButton)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: "button",
                        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.loadMore && _ctx.loadMore(...args)))
                      }, _toDisplayString(_ctx.loadMoreLabel), 1))
                    : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["visible", "results", "placeholder", "onResultClicked", "onSearch"]),
      (_ctx.overlayNavigationOpen)
        ? (_openBlock(), _createBlock(_component_overlay_navigation, {
            key: 0,
            onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onOverlayTriggered(false))),
            items: _ctx.overlayNavItems
          }, null, 8, ["items"]))
        : _createCommentVNode("", true)
    ])
  ], 34))
}
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "segregation-plan__filter" }
const _hoisted_2 = { class: "segregation-plan__filters" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "segregation-plan__filter-item segregation-plan__filter-item--search" }
const _hoisted_5 = {
  key: 4,
  class: "segregation-plan__select-region"
}
const _hoisted_6 = { key: 5 }
const _hoisted_7 = { class: "segregation-plan__filters--mobile" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "segregation-plan__filter-item segregation-plan__filter-item--search" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 4,
  class: "segregation-plan__select-region"
}
const _hoisted_13 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_item = _resolveComponent("dropdown-item")!
  const _component_vit_dropdown = _resolveComponent("vit-dropdown")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _component_icon = _resolveComponent("icon")!
  const _component_vit_accordion = _resolveComponent("vit-accordion")!
  const _component_vit_accordion_group = _resolveComponent("vit-accordion-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.allSites.length)
        ? (_openBlock(), _createBlock(_component_vit_dropdown, {
            key: 0,
            label: _ctx.translations.region,
            inline: "",
            light: "",
            "dark-menu": "",
            multiple: "",
            rounded: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_dropdown_item, { onClick: _ctx.selectAllRegions }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["segregation-plan__filter-item", { 'segregation-plan__filter-item--active': _ctx.allRegionsSelected }])
                  }, _toDisplayString(_ctx.translations.allRegions), 3)
                ]),
                _: 1
              }, 8, ["onClick"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.regions, (region) => {
                return (_openBlock(), _createBlock(_component_dropdown_item, {
                  key: 'filter-' + region,
                  onClick: ($event: any) => (_ctx.selectRegion(region))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["segregation-plan__filter-item", { 'segregation-plan__filter-item--active': _ctx.selectedRegions.find(x => x === region) }])
                    }, _toDisplayString(region) + " region ", 3)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["label"]))
        : (_ctx.status.sitesRegion)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.status.sitesRegion), 1))
          : (_openBlock(), _createBlock(_component_loading_spinner, { key: 2 })),
      (_ctx.allSites.length)
        ? (_openBlock(), _createBlock(_component_vit_dropdown, {
            key: 3,
            label: _ctx.translations.site,
            inline: "",
            light: "",
            "dark-menu": "",
            multiple: "",
            rounded: "",
            onClose: _ctx.onDropdownClose
          }, {
            default: _withCtx(() => [
              _createVNode(_component_dropdown_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_icon, {
                      name: "icn-search",
                      class: "icon"
                    }),
                    _createElementVNode("input", {
                      type: "text",
                      class: "segregation-plan__filter-search",
                      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSearchSites && _ctx.onSearchSites(...args)))
                    }, null, 32)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_dropdown_item, { onClick: _ctx.selectAllSites }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["segregation-plan__filter-item", { 'segregation-plan__filter-item--active': _ctx.allSitesSelected }])
                  }, _toDisplayString(_ctx.translations.allSites), 3)
                ]),
                _: 1
              }, 8, ["onClick"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSites, (site) => {
                return (_openBlock(), _createBlock(_component_dropdown_item, {
                  key: 'filter-' + site.sId,
                  onClick: ($event: any) => (_ctx.selectSite(site.sId))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["segregation-plan__filter-item", { 'segregation-plan__filter-item--active': _ctx.selectedSites.find(x => x.sId === site.sId) }])
                    }, _toDisplayString(site.sName), 3)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["label", "onClose"]))
        : (_ctx.selectedRegions.length === 0 && _ctx.regionSitesLoaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.translations.noRegion), 1))
          : (_ctx.status.sites)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.status.sites), 1))
            : (_openBlock(), _createBlock(_component_loading_spinner, { key: 6 }))
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_vit_accordion_group, { group: "segregationplan" }, {
        default: _withCtx(() => [
          (_ctx.regions.length)
            ? (_openBlock(), _createBlock(_component_vit_accordion, {
                key: 0,
                title: _ctx.translations.region,
                narrow: "",
                group: "segregationplan"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["segregation-plan__filter-item", { 'segregation-plan__filter-item--active': _ctx.allRegionsSelected }]),
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.selectAllRegions && _ctx.selectAllRegions(...args)))
                  }, _toDisplayString(_ctx.translations.allRegions), 3),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.regions, (region) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: 'filter-' + region,
                      class: _normalizeClass(["segregation-plan__filter-item", { 'segregation-plan__filter-item--active': _ctx.selectedRegions.find(x => x === region) }]),
                      onClick: ($event: any) => (_ctx.selectRegion(region))
                    }, _toDisplayString(region), 11, _hoisted_8))
                  }), 128))
                ]),
                _: 1
              }, 8, ["title"]))
            : (_ctx.status.sitesRegion)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.status.sitesRegion), 1))
              : (_openBlock(), _createBlock(_component_loading_spinner, { key: 2 })),
          (_ctx.allSites.length)
            ? (_openBlock(), _createBlock(_component_vit_accordion, {
                key: 3,
                title: _ctx.translations.site,
                narrow: "",
                group: "segregationplan"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_icon, {
                      name: "icn-search",
                      class: "icon"
                    }),
                    _createElementVNode("input", {
                      type: "text",
                      class: "segregation-plan__filter-search",
                      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSearchSites && _ctx.onSearchSites(...args)))
                    }, null, 32)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["segregation-plan__filter-item", { 'segregation-plan__filter-item--active': _ctx.allSitesSelected }]),
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.selectAllSites && _ctx.selectAllSites(...args)))
                  }, _toDisplayString(_ctx.translations.allSites), 3),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSites, (site) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: 'filter-' + site.sId,
                      class: _normalizeClass(["segregation-plan__filter-item", { 'segregation-plan__filter-item--active': _ctx.selectedSites.find(x => x.sId === site.sId) }]),
                      onClick: ($event: any) => (_ctx.selectSite(site.sId))
                    }, _toDisplayString(site.sName), 11, _hoisted_11))
                  }), 128))
                ]),
                _: 1
              }, 8, ["title"]))
            : (_ctx.selectedRegions.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.translations.noRegion), 1))
              : (_ctx.status.sites)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.status.sites), 1))
                : (_openBlock(), _createBlock(_component_loading_spinner, { key: 6 }))
        ]),
        _: 1
      })
    ])
  ]))
}
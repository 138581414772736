/* eslint-disable */
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { useMyStore } from './store';
import VueLoader from './VueLoader';
import bsBreakpoints from './lib/bs-breakpoints';

const pinia = createPinia();

const app = createApp({});

// Register Pinia plugin before anything else
app.use(pinia);

let resizeListenerAdded = false;
let scrollListenerAdded = false;

app.mixin({
    created() {
        // set initial breakpoint in store
        bsBreakpoints.init();
        const breakpoint = bsBreakpoints.detectBreakpoint();
        useMyStore().setBreakpoint(breakpoint);
        
        // listen for breakpoint changes and update the store
        bsBreakpoints.registerListener((breakpoint) => {
            useMyStore().setBreakpoint(breakpoint);
        });
    },
    mounted() {
        // resize images and svgs from 0 to 100%
        window.addEventListener('DOMContentLoaded', this.imageResize);
        // Handle anchor scrolling after all scripts are executed
        window.addEventListener('load', this.anchorScroll);
    },
    methods: {
        // resize images and svgs from 0 to 100%
        imageResize() {
            // Create a new style sheet if none available
            if (!document.styleSheets.length) {
                const style = document.createElement('style');
                document.head.appendChild(style);
            }

            // Add the new rule to the file
            document.styleSheets[0].insertRule(
                `.image__img { height: 100% !important; width: 100% !important; max-width: 100%; }`, 
                document.styleSheets[0].cssRules.length
            );
        },
        anchorScroll(){
            // Check if there's an anchor in the URL
            const hash = window.location.hash;
            if (hash) {
                // Find the element that matches the anchor
                const targetElement = document.querySelector(hash);
                if (targetElement) {
                    // Scroll to the anchor element
                    setTimeout(() => {
                        targetElement.scrollIntoView({ behavior: 'smooth' });
                    }, 500);
                }
            }
        }
    }
});

/* Plugins */
app.use(VueLoader);

// IMPORTANT FOR GREENBARS
app.config.compilerOptions.comments = true;

app.mount('#app');

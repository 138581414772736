<template>
    <div>
        <!-- form page -->
        <div v-if="!resultsPage">
            <gav-form
                :commodities="commodities"
                :deliveries-date="deliveriesDate"
                :price-headline="priceHeadline"
                :involved-client="involvedClient"
                :form-translations="formTranslations"
                :locations-link="locationsLink"
                :form-selection="formSelection"
                :required-test="formChecked"
                @formSelection="onFormSelection" />
            <!-- buttons -->
            <div class="contracts-box_buttons">
                <div
                    class="button"
                    @click="seeResults">
                    {{ buttonsTranslations.showContracts }}
                </div>
                <a
                    class="button button--borderless"
                    v-if="professionalLink && buttonsTranslations.speakProfessional"
                    :href="professionalLink"
                    target="_blank"
                    rel="noopener noreferrer">{{ buttonsTranslations.speakProfessional }}</a>
            </div>
        </div>
        <!-- results page -->
        <div v-else>
            <!-- return to form-->
            <div
                class="button button--borderless button--reverse contracts-backbutton"
                @click="backForm">
                {{ buttonsTranslations.backNeeds }}
            </div>
            <div class="contracts-box-mb-30">
                <div class="contracts-box_title">
                    {{ resultsTranslations.title }}
                </div>
                <!-- custom sentence with search inputs-->
                <div class="contracts-box_subtitle" v-html="resultsSentence()" v-if="!resultsShowAll" />
                <!-- contract results -->
                <gav-results
                    class="more-space"
                    :results-translations="resultsTranslations"
                    :results-labels="resultsLabels"
                    :show-all="resultsShowAll"
                    :contracts-cash="filterContractsCash"
                    :contracts-manage="filterContractsManage"
                    :contracts-structure="filterContractsStructure"
                    :contracts-link="contractsLink" />
            </div>
            <!-- buttons -->
            <div class="contracts-box_buttons">
                <div
                    class="button"
                    v-if="!resultsShowAll && buttonsTranslations.viewAllContracts"
                    @click="showAllContracts">
                    {{ buttonsTranslations.viewAllContracts }}
                </div>
                <a
                    class="button button--borderless"
                    v-if="professionalLink && buttonsTranslations.speakProfessional"
                    :href="professionalLink"
                    target="_blank"
                    rel="noopener noreferrer">{{ buttonsTranslations.speakProfessional }}</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GavForm from './GavContractsForm.vue';
import GavResults from './GavContractsResults.vue';

export default defineComponent({
    components: { GavForm, GavResults },
    props: {
        buttonsTranslations: { type: Object as () => { [key: string]: string } },
        formTranslations: { type: Object as () => { [key: string]: string } },
        resultsTranslations: { type: Object as () => { [key: string]: string } },
        resultsLabels: { type: Array as () => Array<Object> },
        commodities: { type: Array as () => Array<Object> },
        deliveriesDate: { type: Array as () => Array<Object> },
        priceHeadline: { type: Object as () => { [key: string]: string } },
        involvedClient: { type: Array as () => Array<Object> },
        contractsCash: { type: Array as () => Array<Object> },
        contractsManage: { type: Array as () => Array<Object> },
        contractsStructure: { type: Array as () => Array<Object> },
        locationsLink: { type: String },
        professionalLink: { type: String },
        contractsLink: { type: String }
    },
    data() {
        return {
            formSelection: {
                location: '',
                commodity: '',
                delivery: '',
                deliveryLabel: '',
                trading: '',
                tradingLabel: '',
                priceDirection: 'increasing'
            },
            resultsShowAll: false,
            resultsPage: false,
            formChecked: false
        };
    },

    methods: {
        // page buttons
        seeResults() {
            if (this.formSelection.location && this.formSelection.commodity && this.formSelection.delivery && this.formSelection.trading && this.formSelection.priceDirection) {
                this.resultsPage = true;
                this.$el.scrollIntoView({ behavior: 'smooth' });
            } else {
                this.formChecked = true;
            }
        },

        // return to form, disable all contracts results
        backForm() {
            this.resultsShowAll = false;
            this.resultsPage = false;
        },
        // update formSelection when form component emits
        onFormSelection(formSelection) {
            this.formSelection = formSelection;
        },

        // go to results page and show all contracts
        showAllContracts() {
            this.resultsShowAll = true;
            this.$el.scrollIntoView({ behavior: 'smooth' });
        },

        resultsSentence() {
            return this.resultsTranslations.subtitle
                .replace('SelectedCommodity', this.formSelection.commodity)
                .replace('SelectedPlace', this.formSelection.location)
                .replace('SelectedDelivery', this.formSelection.deliveryLabel.toLowerCase())
                .replace('SelectedManage', this.formSelection.tradingLabel);
        }
    },

    computed: {
        // if is to show all results, return all contracts
        // if not all results show, filtered contracts
        filterContractsCash() {
            return this.resultsShowAll ? this.contractsCash : this.contractsCash.filter(item => (item[this.formSelection.delivery] && item[this.formSelection.trading] && item[this.formSelection.priceDirection]));
        },
        filterContractsManage() {
            return this.resultsShowAll ? this.contractsManage : this.contractsManage.filter(item => (item[this.formSelection.delivery] && item[this.formSelection.trading] && item[this.formSelection.priceDirection]));
        },
        filterContractsStructure() {
            return this.resultsShowAll ? this.contractsStructure : this.contractsStructure.filter(item => (item[this.formSelection.delivery] && item[this.formSelection.trading] && item[this.formSelection.priceDirection]));
        }
    }
});

</script>

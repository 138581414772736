import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "job-teasers__fav" }
const _hoisted_2 = { class: "job-teasers__fav-label" }
const _hoisted_3 = ["id", "value"]
const _hoisted_4 = { class: "job-teasers__content" }
const _hoisted_5 = { class: "job-teasers__title" }
const _hoisted_6 = { class: "job-teasers__info" }
const _hoisted_7 = { class: "job-teasers__descr" }
const _hoisted_8 = { class: "job-teasers__descr__title" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_vit_slide = _resolveComponent("vit-slide")!
  const _component_vit_slider = _resolveComponent("vit-slider")!

  return (_openBlock(), _createBlock(_component_vit_slider, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobsList, (job, index) => {
        return (_openBlock(), _createBlock(_component_vit_slide, {
          key: index,
          class: "job-teaser-slide"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("label", _hoisted_2, [
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  id: job.jobId,
                  value: job.jobId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.favourites) = $event)),
                  class: "job-teasers__fav-input"
                }, null, 8, _hoisted_3), [
                  [_vModelCheckbox, _ctx.favourites]
                ]),
                _createVNode(_component_icon, {
                  name: "icn-star",
                  class: _normalizeClass(["icon job-teasers__fav-icon", [{'job-teasers__fav-icon-active': _ctx.favourites.includes(job.jobId)}]])
                }, null, 8, ["class"])
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(job.title), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", null, _toDisplayString(job.businessArea), 1),
                _createElementVNode("div", null, _toDisplayString(_ctx.getCountry(job)), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translations.jobDescription), 1),
                _createElementVNode("div", {
                  innerHTML: job.descriptionAbstract
                }, null, 8, _hoisted_9)
              ]),
              _createElementVNode("a", {
                class: "button job-teasers__button",
                href: _ctx.determineJobDetailsPageUrl(job.jobId)
              }, _toDisplayString(_ctx.translations.goToJob), 9, _hoisted_10)
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["dropdown__item dropdown__item-link button button--link", { 'dropdown__item--selected': _ctx.selected }])
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(_ctx.label), 1)
    ])
  ], 2))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 0,
  class: "meta-navigation__langs"
}
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["meta-navigation", { 'meta-navigation--light': _ctx.light }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items.filter(x => x.title), (item, i) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (item.navigationType === 'META')
          ? (_openBlock(), _createElementBlock("a", {
              key: `metanav#${i}`,
              href: item.link,
              class: "meta-navigation__item"
            }, _toDisplayString(item.title), 9, _hoisted_1))
          : _createCommentVNode("", true),
        (item.navigationType === 'GLOBAL')
          ? (_openBlock(), _createElementBlock("button", {
              key: `metanav#${i}`,
              onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.$emit('open-overlay-nav');}),
              class: "meta-navigation__item"
            }, _toDisplayString(item.title), 1))
          : _createCommentVNode("", true)
      ], 64))
    }), 256)),
    (_ctx.langItems.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.langItems, (lang) => {
            return (_openBlock(), _createElementBlock("a", {
              key: `lang#${lang.label}`,
              href: lang.link,
              class: _normalizeClass(["meta-navigation__lang", { 'meta-navigation__lang-active': lang.active }])
            }, _toDisplayString(_ctx.getLabel(lang)), 11, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
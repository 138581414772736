<template>
    <a
        :href="link"
        :target="determineTarget"
        class="icon-teaser"
        :class="`icon-teaser--${category}`"
        @mouseenter="onMouseEnter"
        @mouseleave="onBlur">
        <slot name="icon" />
        <div class="icon-teaser__title">{{ title }}</div>
    </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        title: String,
        text: String,
        icon: String,
        link: String,
        target: String
    },
    data() {
        return {
            svg: ''
        };
    },
    inject: ['register', 'onHover', 'onBlur'],
    mounted() {
        // register self on parent component
        this.register(this);
    },
    methods: {
        onMouseEnter() {
            this.onHover(this);
        }
    },
    computed: {
        category() {
            const categories = [
                'processing_refining',
                'storage_handling',
                'marketing',
                'port_terminals',
                'logistics'
            ];
            return `cat${categories.indexOf(this.icon) + 1}`;
        },
        determineTarget(): string {
            return this.target.toUpperCase() === 'SELF' ? '' : this.target;
        }
    }
});

</script>

<template>
    <div class="cash-pricing__filter">
        <div class="cash-pricing__filter-date">
            {{ translations.lastRefresh }}: {{ refreshDate }}
        </div>
        <icon
            :class="{ 'cash-pricing__refresh-loading': loading }"
            class="cash-pricing__refresh"
            name="icn-refresh"
            @click.native="fetchPrices" />
        <!-- Desktop -->
        <div class="cash-pricing__filters">
            <!-- sites -->
            <vit-dropdown
                v-if="sites.length"
                :label="translations.site"
                dark-menu
                inline
                light
                multiple
                rounded>
                <dropdown-item>
                    <div class="cash-pricing__filter-item cash-pricing__filter-item--search">
                        <icon class="icon" name="icn-search" />
                        <input class="cash-pricing__filter-search" type="text" @input="onSearchSites">
                    </div>
                </dropdown-item>
                <dropdown-item @click="selectAllSites">
                    <div
                        :class="{ 'cash-pricing__filter-item--active': allSitesSelected }"
                        class="cash-pricing__filter-item">
                        {{ translations.allSites }}
                    </div>
                </dropdown-item>
                <dropdown-item v-for="site in selectableSites" :key="'filter-' + site.sId" @click="selectSite(site.sId)">
                    <div
                        :class="{ 'cash-pricing__filter-item--active': selectedSites.find(x => x.sId === site.sId) }"
                        class="cash-pricing__filter-item">
                        {{ site.sName }}
                    </div>
                </dropdown-item>
            </vit-dropdown>
            <!-- errors display-->
            <div v-else-if="status.sites">
                {{ status.sites }}
            </div>
            <loading-spinner v-else-if="loading" />
            <!-- commodity or grade -->
            <vit-dropdown
                v-if="commodities.length"
                :label="translations.commodityGrade"
                dark-menu
                inline
                light
                multiple
                rounded>
                <dropdown-item>
                    <div class="cash-pricing__filter-item cash-pricing__filter-item--search">
                        <icon class="icon" name="icn-search" />
                        <input class="cash-pricing__filter-search" type="text" @input="onSearchCommodities">
                    </div>
                </dropdown-item>
                <dropdown-item @click="selectAllCommodities">
                    <div
                        :class="{ 'cash-pricing__filter-item--active': allCommoditiesSelected }"
                        class="cash-pricing__filter-item">
                        {{ translations.allCommodities }}
                    </div>
                </dropdown-item>
                <div v-for="commodity in selectableCommodities" :key="'filtersss-' + commodity.cId">
                    <dropdown-item v-if="commodity.grades.length" :key="'filter-' + commodity.cId" @click="selectCommodity(commodity.cId)">
                        <div
                            :class="{ 'cash-pricing__filter-item--active': isCommoditySelected(commodity.cId) }"
                            class="cash-pricing__filter-item">
                            {{ commodity.cName }}
                        </div>
                    </dropdown-item>
                    <dropdown-item v-for="grade in commodity.grades" :key="'filter-' + grade.gId" @click="selectGrade(grade.gId)">
                        <div
                            :class="{ 'cash-pricing__filter-item--active': selectedGrades.find(x => x.gId === grade.gId) }"
                            class="cash-pricing__filter-item cash-pricing__filter-item--sub">
                            {{ grade.gName }}
                        </div>
                    </dropdown-item>
                </div>
            </vit-dropdown>
            <div v-else-if="status.commodities">
                {{ status.commodities }}
            </div>
            <loading-spinner v-else-if="loading" />
            <!-- group by -->
            <vit-dropdown
                :disabled="!(selectedSites.length && selectedGrades.length)"
                :label="translations.group"
                dark-menu
                inline
                light
                rounded>
                <dropdown-item
                    v-for="groupOption in groupOptions"
                    :key="groupOption.key"
                    @click="groupBy(groupOption.key)">
                    <div
                        :class="{ 'cash-pricing__filter-item--active': selectedGroupOption === groupOption.key }"
                        class="cash-pricing__filter-item">
                        {{ groupOption.label }}
                    </div>
                </dropdown-item>
            </vit-dropdown>
        </div>
        <!-- Mobile -->
        <div class="cash-pricing__filters--mobile">
            <vit-accordion-group group="cashpricing">
                <!-- sites -->
                <vit-accordion
                    v-if="sites.length"
                    group="cashpricing"
                    :title="translations.site"
                    narrow>
                    <div class="cash-pricing__filter-item cash-pricing__filter-item--search">
                        <icon class="icon" name="icn-search" />
                        <input class="cash-pricing__filter-search" type="text" @input="onSearchSites">
                    </div>
                    <div
                        :class="{ 'cash-pricing__filter-item--active': allSitesSelected }"
                        class="cash-pricing__filter-item"
                        @click="selectAllSites">
                        {{ translations.allSites }}
                    </div>
                    <div
                        v-for="site in selectableSites"
                        :key="'filter-' + site.sId"
                        :class="{ 'cash-pricing__filter-item--active': selectedSites.find(x => x.sId === site.sId) }"
                        class="cash-pricing__filter-item"
                        @click="selectSite(site.sId)">
                        {{ site.sName }}
                    </div>
                </vit-accordion>
                <div v-else-if="status.sites">
                    {{ status.sites }}
                </div>
                <loading-spinner v-else-if="loading" />
                <!-- commodities and grades -->
                <vit-accordion
                    v-if="commodities.length"
                    group="cashpricing"
                    :title="translations.commodityGrade"
                    narrow>
                    <div class="cash-pricing__filter-item cash-pricing__filter-item--search">
                        <icon class="icon" name="icn-search" />
                        <input class="cash-pricing__filter-search" type="text" @input="onSearchCommodities">
                    </div>
                    <div
                        :class="{ 'cash-pricing__filter-item--active': allCommoditiesSelected }"
                        class="cash-pricing__filter-item"
                        @click="selectAllCommodities">
                        {{ translations.allCommodities }}
                    </div>
                    <div v-for="commodity in selectableCommodities" :key="'filterss-' + commodity.cId">
                        <div
                            :key="'filter-' + commodity.cId"
                            :class="{ 'cash-pricing__filter-item--active': isCommoditySelected(commodity.cId) }"
                            class="cash-pricing__filter-item"
                            @click="selectCommodity(commodity.cId)">
                            {{ commodity.cName }}
                        </div>
                        <div
                            v-for="grade in commodity.grades"
                            :key="'filter-' + grade.gId"
                            :class="{ 'cash-pricing__filter-item--active': selectedGrades.find(x => x.gId === grade.gId) }"
                            class="cash-pricing__filter-item cash-pricing__filter-item--sub"
                            @click="selectGrade(grade.gId)">
                            {{ grade.gName }}
                        </div>
                    </div>
                </vit-accordion>
                <div v-else-if="status.commodities">
                    {{ status.commodities }}
                </div>
                <loading-spinner v-else-if="loading" />
                <!-- group by -->
                <vit-accordion
                    group="cashpricing"
                    :disabled="!(selectedSites.length && selectedGrades.length)"
                    :title="translations.group"
                    narrow>
                    <div
                        v-for="groupOption in groupOptions"
                        :key="groupOption.key"
                        :class="{ 'cash-pricing__filter-item--active': selectedGroupOption === groupOption.key }"
                        class="cash-pricing__filter-item"
                        @click="groupBy(groupOption.key)">
                        {{ groupOption.label }}
                    </div>
                </vit-accordion>
            </vit-accordion-group>
        </div>
        <!-- other filters -->
        <div
            v-if="selectedSites.length && selectedGrades.length && filteredPrices.length"
            class="mt-4 justify-content-between d-flex">
            <div
                :class="{ 'cash-pricing__pool-filter--active': showPoolOptions }"
                class="cash-pricing__pool-filter"
                @click="showPoolOptions = !showPoolOptions">
                {{ translations.showPool }}
            </div>
            <button class="button d-none d-lg-flex align-self-start" @click="exportXlsx">
                {{ translations.export }}
            </button>
        </div>
        <div
            v-if="selectedSites.length && selectedGrades.length && filteredPrices.length"
            class="justify-content-between d-flex">
            <div
                :class="{ 'cash-pricing__pool-filter--active': showExtendedPaymentTerms }"
                class="cash-pricing__pool-filter"
                @click="showExtendedPaymentTerms = !showExtendedPaymentTerms">
                {{ translations.showExtendedPaymentTerms }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import flatten from 'lodash/flatten';
import debounce from 'lodash/debounce';
import Segregation, { SiteInfo, CommodityInfo, GradeInfo, PriceInfo, GroupOption } from '../../lib/Segregation';
import LoadingSpinner from '../atoms/LoadingSpinner.vue';
import Icon from '../atoms/Icon.vue';
import DropdownItem from '../base/VitDropdownItem.vue';
import Helper from '../../lib/Helper';
import VitDropdown from '../base/VitDropdown.vue';
import VitAccordionGroup from '../base/VitAccordionGroup.vue';
import VitAccordion from '../base/VitAccordion.vue';

// used for dev purposes to filter empty sites & commodities
const FILTER_EMPTY = false;

const ALL = 'ALL';

export default defineComponent({
    components: { VitAccordion, VitAccordionGroup, VitDropdown, LoadingSpinner, Icon, DropdownItem },
    props: {
        // get base API url
        translations: { required: true, type: Object as () => Record<string, string> },
        wsBaseUrl: { required: true, type: String }
    },
    data() {
        return {
            // all values/options
            sites: [] as SiteInfo[],
            commodities: [] as CommodityInfo[],
            groupOptions: [] as { key: GroupOption, label: string }[],
            showPoolOptions: false,
            showExtendedPaymentTerms: false,

            // filtered values
            selectedSites: [] as SiteInfo[],
            selectedGrades: [] as GradeInfo[],
            selectedGroupOption: GroupOption.SITE as GroupOption,
            filteredPrices: [] as PriceInfo[],

            // aux search
            loaded: false,
            loading: false,
            filterAttempt: false,
            sitesFilter: '',
            commoditiesFilter: '',
            refreshDate: '',
            status: {
                sites: '',
                commodities: '',
                grades: ''
            },
            onSearchSites: null,
            onSearchCommodities: null

        };
    },
    created() {
        this.onSearchSites = debounce(this.searchSitesInput, 350);
        this.onSearchCommodities = debounce(this.searchCommoditiesInput, 350);
        this.groupOptions = [
            { key: GroupOption.SITE, label: this.translations.groupSite },
            { key: GroupOption.COMMODITY, label: this.translations.groupCommodity },
            { key: GroupOption.NONE, label: this.translations.groupNone }
        ];
    },

    computed: {
        // if all sites are selected
        allSitesSelected(): boolean {
            return this.selectedSites.length === this.sites.length;
        },

        // if all commodities are selected
        allCommoditiesSelected(): boolean {
            return this.commodities.every(c => this.isCommoditySelected(c.cId));
        },

        // sites visible at the dropdown (with or without search input)
        selectableSites(): SiteInfo[] {
            return this.sites.filter(x => x.sName.toLowerCase().includes(this.sitesFilter));
        },

        // commodities visible at the dropdown (with or without search input)
        selectableCommodities(): CommodityInfo[] {
            return this.commodities.filter(x => {
                const matchesCommodity = x.cName.toLowerCase().includes(this.commoditiesFilter);
                if (matchesCommodity) {
                    return true;
                }
                const filteredGrades: GradeInfo[] = x.grades.filter(g => g.gName.toLowerCase().includes(this.commoditiesFilter));
                if (filteredGrades.length) {
                    x.grades = filteredGrades;
                    return true;
                }
                return false;
            });
        },

        // get hash
        filterQuery(): string {
            if (!this.loaded) {
                return '';
            }
            let sites = '';
            let commodities = '';
            let grades = '';
            // sites
            if (this.selectedSites.length) {
                if (this.allSitesSelected) {
                    sites = 'sites=ALL';
                } else {
                    sites = `sites=${this.selectedSites.map(x => x.sName).join(',')}`;
                }
            }
            // grades
            if (this.selectedGrades.length) {
                const allCommodities: CommodityInfo[] = this.commodities.filter((commodity: CommodityInfo) => this.isCommoditySelected(commodity.cId));
                if (allCommodities.length) {
                    commodities = `commodities=${allCommodities.map(c => c.cName.toUpperCase()).join(',')}`;
                }
                const singleGrades: GradeInfo[] = this.selectedGrades
                    .filter(grade => !allCommodities.some((commodity: CommodityInfo) => commodity.grades.some(g => g.gId === grade.gId)));
                if (singleGrades.length) {
                    grades = `grades=${singleGrades.map(x => x.gName).join(',')}`;
                }
            }
            // others
            const group = `groupBy=${this.selectedGroupOption}`;
            const poolPrices = `poolPrices=${this.showPoolOptions.toString()}`;
            // create
            const query = `${[sites, commodities, grades, group, poolPrices].filter(x => x.length).join('&')}`;
            return query.length ? `?${query}` : '';
        }
    },

    async mounted() {
        this.loading = true;
        // get all commodities and all sites
        try {
            // get all sites or errors
            const { sites, sitesStatus } = await Segregation.fetchSites(this.wsBaseUrl);
            this.sites = sites;
            this.status.sites = this.handleError(sitesStatus, 'sites');

            // get all commodities or errors
            const { commodities, commoditiesStatus, allGradeStatus } = await Segregation.getCommoditiesWithGrades(this.wsBaseUrl);
            this.commodities = commodities;
            this.$emit('commodities', commodities);
            this.status.commodities = this.handleError(commoditiesStatus, 'commodities');
            this.status.commodities = this.handleError(allGradeStatus, 'commodities');
        } catch (error) {
            this.status.sites = this.handleError(0, 'sites');
            this.status.commodities = this.handleError(0, 'commodities');
        }
        // filters
        if (!this.setFilters(window.location.search)) {
            this.loaded = true;
            this.checkCookie();
        } else {
            this.filterPrices();
        }
        this.$forceUpdate();
        await this.fetchPrices();
        this.loading = false;
        this.loaded = true;
        this.filterPrices();
    },

    methods: {
        // group by
        groupBy(option: GroupOption) {
            this.selectedGroupOption = option;
        },

        // SITES
        // select or deselect all sites
        selectAllSites() {
            if (this.allSitesSelected) {
                // deselect all
                this.selectedSites = [];
                this.checkFilters();
            } else {
                this.selectedSites = [...this.sites];
                this.groupBy(GroupOption.NONE);
            }
            this.filterPrices();
            this.$forceUpdate();
        },

        // select or deselect a site
        selectSite(siteId: string) {
            const index = this.selectedSites.findIndex(x => x.sId === siteId);
            if (index < 0) {
                this.selectedSites.push(this.sites.find(x => x.sId === siteId));
            } else {
                this.selectedSites.splice(index, 1);
                this.checkFilters();
            }
            this.filterPrices();
            this.$forceUpdate();
        },

        // search for a site input update
        searchSitesInput(event: InputEvent) {
            this.sitesFilter = (event.target as HTMLInputElement).value.toLowerCase();
        },

        // COMMODITIES
        // select or deselect all commodities
        selectAllCommodities() {
            if (this.allCommoditiesSelected) {
                this.selectedGrades = [];
                this.checkFilters();
            } else {
                this.selectedGrades = flatten(this.commodities.map(x => x.grades));
                this.groupBy(GroupOption.NONE);
            }
            this.filterPrices();
            this.$forceUpdate();
        },

        // select or deselect a commodity and all child grades
        selectCommodity(commodityId) {
            const commodity = this.commodities.find(x => x.cId === commodityId);
            // if not all grades of the commodity are selected we want to force the rest and deselect all otherwise
            const force = !this.isCommoditySelected(commodityId);
            commodity.grades.forEach(g => {
                this.selectGrade(g.gId, force);
            });
        },

        // select or deselect a grade and parent if needed
        selectGrade(gradeId: string, force = false) {
            const index = this.selectedGrades.findIndex(x => x.gId === gradeId);
            if (index < 0) {
                // noinspection TypeScriptUnresolvedFunction
                this.selectedGrades.push(flatten(this.commodities.map(x => x.grades)).find((x: GradeInfo) => x.gId === gradeId));
            } else if (!force) {
                this.selectedGrades.splice(index, 1);
                this.checkFilters();
            }
            this.filterPrices();
            this.$forceUpdate();
        },

        // check if all grades of a commodity are selected
        isCommoditySelected(commodityId): boolean {
            const commodity = this.commodities.find(x => x.cId === commodityId);
            if (commodity) {
                return commodity.grades.every(g => !!this.selectedGrades.find(x => x.gId === g.gId));
            }
            return false;
        },

        // search for a commodity input update
        searchCommoditiesInput(event: InputEvent) {
            this.commoditiesFilter = (event.target as HTMLInputElement).value.toLowerCase();
        },
        // FILTERS
        // check if have pre filters
        setFilters(query: string): boolean {
            let filterSet = false;
            // check for filter query in the url
            const urlParams = new URLSearchParams(query);
            if (urlParams.has('sites')) {
                if (urlParams.get('sites') === ALL) {
                    this.selectedSites = [...this.sites];
                } else {
                    const sites = urlParams.get('sites').split(',');
                    this.selectedSites = this.sites.filter(x => sites.some(s => s === x.sName));
                }
                filterSet = true;
            }
            if (urlParams.has('commodities')) {
                const commodityNames = urlParams.get('commodities').split(',');
                const commodities = this.commodities.filter(c => commodityNames.some(n => n === c.cName.toUpperCase()));
                this.selectedGrades = flatten(commodities.map(x => x.grades));
            }
            if (urlParams.has('grades')) {
                const grades = urlParams.get('grades').split(',');
                this.selectedGrades.push(...flatten(this.commodities.map(x => x.grades)).filter((x: GradeInfo) => grades.some(s => s === x.gName)));
                filterSet = true;
            }
            if (urlParams.has('groupBy')) {
                const group = urlParams.get('groupBy');
                switch (group) {
                    case GroupOption.SITE:
                        this.selectedGroupOption = GroupOption.SITE;
                        break;
                    case GroupOption.COMMODITY:
                        this.selectedGroupOption = GroupOption.COMMODITY;
                        break;
                    case GroupOption.NONE:
                        this.selectedGroupOption = GroupOption.NONE;
                        break;
                    default:
                        this.selectedGroupOption = GroupOption.SITE;
                }
                filterSet = true;
            }
            return filterSet;
        },

        // filter prices
        filterPrices(): PriceInfo[] {
            if (this.filteredPrices.length === 0) {
                this.filterAttempt = true;
                this.$emit('prices', []);
                return [];
            }
            if (this.selectedSites.length && this.selectedGrades.length) {
                let prices = this.filteredPrices.filter(p => {
                    if (p.grade && p.site) {
                        return this.selectedSites.some(s => s.sId === p.site.sId)
                            && this.selectedGrades.some(g => g.gId === p.grade.gId);
                    }
                    return false;
                });
                prices = prices.filter(p => (this.showPoolOptions ? true : !p.sellInfo.isPool));
                prices = prices.filter(p => (this.showExtendedPaymentTerms ? true : !p.sellInfo.isExtendedPaymentTerm));
                this.$emit('prices', prices);
                return prices;
            }
            return [];
        },

        async fetchPrices() {
            try {
                this.loading = true;
                this.filteredPrices = await Segregation.fetchSitesPricesInfo(this.wsBaseUrl, this.selectedSites);
                const date = new Date();
                this.refreshDate = `${this.$date(date, false)} ${date.toLocaleTimeString()} (${Intl.DateTimeFormat().resolvedOptions().timeZone})`;

                if (this.filterAttempt) {
                    // filter was triggered before prices were loaded
                    this.filterAttempt = false;
                    this.filterPrices();
                }

                if (FILTER_EMPTY) {
                    this.sites = this.sites.filter(s => this.filteredPrices.some(p => p.site.sId === s.sId));
                    this.commodities.forEach(c => {
                        c.grades = c.grades.filter(g => this.filteredPrices.some(p => p.grade.gId === g.gId));
                    });
                    this.commodities = this.commodities.filter(c => c.grades.length);
                }
            } catch (e) {
                // not logging this exception, as these normally mean the previous request was cancelled due to user action. The exception message is useless in that case.
            }
            this.filterPrices();
            this.loading = false;
        },

        // OTHERS
        // if no site and no grade is selected reset sorting and grouping
        checkFilters() {
            if (this.selectedGrades.length === 0 && this.selectedSites.length === 0) {
                this.selectedGroupOption = GroupOption.NONE;
            }
        },

        // check if cash pricing cookie is set and load filters from there
        checkCookie() {
            const cookie = Helper.getCookie('cpfq');
            if (cookie && cookie !== this.filterQuery) {
                this.setFilters(cookie);
                this.filterPrices();
            }
        },
        // error
        handleError(error, commodity): string {
            if (error === 200) {
                return '';
            }
            if (error === 504) {
                return `Timeout while fetching ${commodity}. Please try again.`;
            }
            return `Error while fetching ${commodity}. Please try again later.`;
        },

        // EXPORTS
        exportXlsx() {
            Segregation.exportPricesInfoToXlsx(this.translations, this.filterPrices(), 'Viterra_CashPricing');
        },
        onShowPool() {
            this.fetchPrices();
            this.filterPrices();
        }

    },

    watch: {
        selectedSites() {
            this.fetchPrices();
            this.$emit('sites', this.selectedSites);
        },
        selectedGrades() {
            this.fetchPrices();
            this.$emit('grades', this.selectedGrades);
        },
        selectedGroupOption() {
            this.fetchPrices();
            this.$emit('group', this.selectedGroupOption);
        },
        showPoolOptions() {
            this.onShowPool();
        },
        showExtendedPaymentTerms() {
            this.onShowPool();
        },
        filterQuery() {
            if (history.pushState) {
                const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${this.filterQuery}`;
                window.history.pushState({ path: newurl }, '', newurl);
            }
            Helper.setCookie('cpfq', this.filterQuery);
        }
    }
});

</script>

<template>
    <div class="tabbed-slider" :class="{ 'tabbed-slider--edit-mode': editMode }">
        <div class="tabbed-slider__carousel">
            <slot />
        </div>
        <div class="tabbed-slider__control-wrapper">
            <div class="tabbed-slider__control" ref="sliderControl">
                <div
                    class="tabbed-slider__control-item"
                    :class="{' tabbed-slider__control-item--active': index === i }"
                    :style="`--num-items: ${tabs.length}`"
                    v-for="(tab, i) in tabs"
                    @click="onItemClick(i)"
                    :key="tab._uid">
                    <div ref="textContainer" class="tabbed-slider__control-item-text-container">
                        <p class="tabbed-slider__control-item-text">
                            {{ tab.text }}
                        </p>
                    </div>
                    <a
                        :href="tab.link"
                        class="button button--borderless tabbed-slider__control-item-button"
                        :target="tab.linkTarget"
                        :aria-label="tab.linkLabel">
                        <span class="tabbed-slider__control-item-button-label">{{ tab.linkLabel }}</span>
                    </a>
                    <div v-if="index === i" class="tabbed-slider__progress-ring">
                        <circle-progress-ring :progress="progress" />
                    </div>
                </div>
            </div>
            <div class="tabbed-slider__control-dots">
                <div
                    v-for="(tab, t) in tabs"
                    :key="tab._uid + '_dot'"
                    @click="index = t"
                    class="tabbed-slider__control-dot"
                    :class="{ 'tabbed-slider__control-dot--active': t === index }" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CircleProgressRing from './atoms/CircleProgressRing.vue';

const Flickity = require('flickity');

export default defineComponent({
    components: { CircleProgressRing },
    props: {
        slideDuration: Number,
        editMode: { default: false, type: Boolean }
    },
    data() {
        return {
            index: -1,
            progress: 0,
            tabs: [],
            startTime: 0,
            flickity: null,
            animate: true // for dev purposes only
        };
    },
    provide() {
        return {
            childTabbedRegistration: this.register
        };
    },

    mounted() {
        if (location.search === '?noanimate') {
            this.animate = false;
        }
        this.$nextTick(() => {
            this.initFlickity();
        });
    },

    methods: {
        animationLoop(timestamp, duration) {
            if (!this.animate) {
                return;
            }
            const time = timestamp || new Date().getTime();
            const runtime = time - this.startTime;
            this.progress = Math.min(runtime / duration, 1);
            if (runtime < duration) {
                requestAnimationFrame(ts => {
                    this.animationLoop(ts, duration);
                });
            } else {
                this.index = this.index + 1 >= this.tabs.length ? 0 : this.index + 1;
            }
        },

        onItemClick(index) {
            if (window.innerWidth > 768) {
                this.index = index;
            }
        },

        activateItem(index) {
            this.index = index;
        },

        initFlickity() {
            const flktyOptions = {
                prevNextButtons: false,
                cellSelector: '.tabbed-slider__control-item',
                pageDots: false,
                wrapAround: true,
                watchCSS: true,
                cellAlign: 'left'
            };
            this.flickity = new Flickity(this.$refs.sliderControl, flktyOptions);
            this.flickity.on('change', slide => {
                this.index = slide;
            });
            this.$nextTick(() => {
                this.flickity.resize();
            });
            this.index = 0;
        },

        // called by child tabs
        register(tab) {
            this.tabs.push(tab);
        }
    },

    watch: {
        index() {
            if (this.flickity.isActive) {
                this.flickity.select(this.index, true);
            }
            this.tabs.forEach((t, i) => {
                t.active = this.index === i;
            });
            requestAnimationFrame(timestamp => {
                this.startTime = timestamp || new Date().getTime();
                this.animationLoop(timestamp, this.slideDuration);
            });
        }
    }

});
</script>

<template>
    <div class="overlay-navigation">
        <div class="section section--medium">
            <div class="section__container">
                <div class="section__content">
                    <vit-tabs class="overlay-navigation__tabs">
                        <vit-tab
                            v-for="(item, i) in items"
                            :title="item.title"
                            :key="`overlay-item#${i}`"
                            :narrow="items.length > 3">
                            <div class="overlay-navigation__links">
                                <div class="overlay-navigation__content">
                                    <ul class="link-list__items overlay-navigation__links">
                                        <li
                                            class="overlay-navigation__link"
                                            v-for="(subItem, s) in item.items"
                                            :key="`overlay-item#${s}`">
                                            <a
                                                :href="subItem.link"
                                                class="button button--link"
                                                :target="subItem.newWindow ? '_blank' : '_self'">{{ subItem.title }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </vit-tab>
                    </vit-tabs>
                </div>
            </div>
        </div>
        <button class="overlay-navigation__close-btn" @click="$emit('close')" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import VitTabs from '../base/VitTabs.vue';
import VitTab from '../base/VitTab.vue';

export default defineComponent({
    components: { VitTab, VitTabs },
    props: {
        items: Array
    }
});

</script>

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.name)
    ? (_openBlock(), _createElementBlock("svg", _hoisted_1, [
        _createElementVNode("use", {
          "xlink:href": `${_ctx.$resourcePath}img/sprite.svg#${_ctx.name}`
        }, null, 8, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}
import { defineStore } from 'pinia';

export interface State {
    scrollMagicActive: boolean;
    currentBreakpoint: string;
}

export const useMyStore = defineStore('myStore', {
    state: (): State => ({
        scrollMagicActive: false,
        currentBreakpoint: ''
    }),
    actions: {
        setScrollMagic(value: boolean) {
            this.scrollMagicActive = value;
        },
        setBreakpoint(value: string) {
            this.currentBreakpoint = value;
        }
    },
    getters: {
        getScrollMagic(): boolean {
            return this.scrollMagicActive;
        },
        getCurrentBreakpoint(): string {
            return this.currentBreakpoint;
        },
        isMobile(): boolean {
            return this.currentBreakpoint !== 'large' && this.currentBreakpoint !== 'xLarge';
        }
    }
});

import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "leadership" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "leadership__department-row"
}
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "leadership__member-info" }
const _hoisted_6 = { class: "leadership__member-info-text" }
const _hoisted_7 = { class: "leadership-department__member-name" }
const _hoisted_8 = { class: "leadership-department__member-text" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_leadership_detail_view = _resolveComponent("leadership-detail-view")!
  const _component_icon = _resolveComponent("icon")!
  const _component_vit_tab = _resolveComponent("vit-tab")!
  const _component_vit_tabs = _resolveComponent("vit-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vit_tabs, {
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_vit_tab, {
          title: _ctx.tab1Label,
          class: "leadership__tab"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _renderSlot(_ctx.$slots, "board")
            ]),
            (_ctx.activeMember)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_leadership_detail_view, {
                    member: _ctx.activeMember,
                    members: _ctx.members,
                    "menu-title": _ctx.tab1Label,
                    "appointed-label": _ctx.appointedLabel,
                    "experience-label": _ctx.experienceLabel,
                    onMember: _ctx.openProfile
                  }, null, 8, ["member", "members", "menu-title", "appointed-label", "experience-label", "onMember"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (member) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: member.uuid,
                      class: "leadership-department__member leadership__member--image"
                    }, [
                      (member.smallImage)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            class: "leadership__member-image",
                            src: member.smallImage,
                            alt: member.name
                          }, null, 8, _hoisted_4))
                        : (_openBlock(), _createBlock(_component_icon, {
                            key: 1,
                            class: "leadership__member-placeholder",
                            name: "icn-placeholder"
                          })),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, _toDisplayString(member.name), 1),
                          _createElementVNode("div", _hoisted_8, _toDisplayString(member.job), 1)
                        ]),
                        _createElementVNode("button", {
                          class: "button",
                          onClick: ($event: any) => (_ctx.openProfile(member))
                        }, _toDisplayString(_ctx.viewProfileLabel), 9, _hoisted_9)
                      ])
                    ]))
                  }), 128))
                ]))
          ]),
          _: 3
        }, 8, ["title"]),
        _createVNode(_component_vit_tab, {
          title: _ctx.tab2Label,
          class: "leadership__tab"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _renderSlot(_ctx.$slots, "management")
            ])
          ]),
          _: 3
        }, 8, ["title"])
      ]),
      _: 3
    }, 8, ["modelValue"])
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "cash-pricing__filter" }
const _hoisted_2 = { class: "cash-pricing__filter-date" }
const _hoisted_3 = { class: "cash-pricing__filters" }
const _hoisted_4 = { class: "cash-pricing__filter-item cash-pricing__filter-item--search" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "cash-pricing__filter-item cash-pricing__filter-item--search" }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { class: "cash-pricing__filters--mobile" }
const _hoisted_9 = { class: "cash-pricing__filter-item cash-pricing__filter-item--search" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "cash-pricing__filter-item cash-pricing__filter-item--search" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { key: 4 }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  key: 0,
  class: "mt-4 justify-content-between d-flex"
}
const _hoisted_18 = {
  key: 1,
  class: "justify-content-between d-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_dropdown_item = _resolveComponent("dropdown-item")!
  const _component_vit_dropdown = _resolveComponent("vit-dropdown")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _component_vit_accordion = _resolveComponent("vit-accordion")!
  const _component_vit_accordion_group = _resolveComponent("vit-accordion-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translations.lastRefresh) + ": " + _toDisplayString(_ctx.refreshDate), 1),
    _createVNode(_component_icon, {
      class: _normalizeClass([{ 'cash-pricing__refresh-loading': _ctx.loading }, "cash-pricing__refresh"]),
      name: "icn-refresh",
      onClick: _ctx.fetchPrices
    }, null, 8, ["class", "onClick"]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.sites.length)
        ? (_openBlock(), _createBlock(_component_vit_dropdown, {
            key: 0,
            label: _ctx.translations.site,
            "dark-menu": "",
            inline: "",
            light: "",
            multiple: "",
            rounded: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_dropdown_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_icon, {
                      class: "icon",
                      name: "icn-search"
                    }),
                    _createElementVNode("input", {
                      class: "cash-pricing__filter-search",
                      type: "text",
                      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSearchSites && _ctx.onSearchSites(...args)))
                    }, null, 32)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_dropdown_item, { onClick: _ctx.selectAllSites }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass([{ 'cash-pricing__filter-item--active': _ctx.allSitesSelected }, "cash-pricing__filter-item"])
                  }, _toDisplayString(_ctx.translations.allSites), 3)
                ]),
                _: 1
              }, 8, ["onClick"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectableSites, (site) => {
                return (_openBlock(), _createBlock(_component_dropdown_item, {
                  key: 'filter-' + site.sId,
                  onClick: ($event: any) => (_ctx.selectSite(site.sId))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass([{ 'cash-pricing__filter-item--active': _ctx.selectedSites.find(x => x.sId === site.sId) }, "cash-pricing__filter-item"])
                    }, _toDisplayString(site.sName), 3)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["label"]))
        : (_ctx.status.sites)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.status.sites), 1))
          : (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_loading_spinner, { key: 2 }))
            : _createCommentVNode("", true),
      (_ctx.commodities.length)
        ? (_openBlock(), _createBlock(_component_vit_dropdown, {
            key: 3,
            label: _ctx.translations.commodityGrade,
            "dark-menu": "",
            inline: "",
            light: "",
            multiple: "",
            rounded: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_dropdown_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_icon, {
                      class: "icon",
                      name: "icn-search"
                    }),
                    _createElementVNode("input", {
                      class: "cash-pricing__filter-search",
                      type: "text",
                      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSearchCommodities && _ctx.onSearchCommodities(...args)))
                    }, null, 32)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_dropdown_item, { onClick: _ctx.selectAllCommodities }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass([{ 'cash-pricing__filter-item--active': _ctx.allCommoditiesSelected }, "cash-pricing__filter-item"])
                  }, _toDisplayString(_ctx.translations.allCommodities), 3)
                ]),
                _: 1
              }, 8, ["onClick"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectableCommodities, (commodity) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: 'filtersss-' + commodity.cId
                }, [
                  (commodity.grades.length)
                    ? (_openBlock(), _createBlock(_component_dropdown_item, {
                        key: 'filter-' + commodity.cId,
                        onClick: ($event: any) => (_ctx.selectCommodity(commodity.cId))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            class: _normalizeClass([{ 'cash-pricing__filter-item--active': _ctx.isCommoditySelected(commodity.cId) }, "cash-pricing__filter-item"])
                          }, _toDisplayString(commodity.cName), 3)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(commodity.grades, (grade) => {
                    return (_openBlock(), _createBlock(_component_dropdown_item, {
                      key: 'filter-' + grade.gId,
                      onClick: ($event: any) => (_ctx.selectGrade(grade.gId))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass([{ 'cash-pricing__filter-item--active': _ctx.selectedGrades.find(x => x.gId === grade.gId) }, "cash-pricing__filter-item cash-pricing__filter-item--sub"])
                        }, _toDisplayString(grade.gName), 3)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128))
                ]))
              }), 128))
            ]),
            _: 1
          }, 8, ["label"]))
        : (_ctx.status.commodities)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.status.commodities), 1))
          : (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_loading_spinner, { key: 5 }))
            : _createCommentVNode("", true),
      _createVNode(_component_vit_dropdown, {
        disabled: !(_ctx.selectedSites.length && _ctx.selectedGrades.length),
        label: _ctx.translations.group,
        "dark-menu": "",
        inline: "",
        light: "",
        rounded: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupOptions, (groupOption) => {
            return (_openBlock(), _createBlock(_component_dropdown_item, {
              key: groupOption.key,
              onClick: ($event: any) => (_ctx.groupBy(groupOption.key))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass([{ 'cash-pricing__filter-item--active': _ctx.selectedGroupOption === groupOption.key }, "cash-pricing__filter-item"])
                }, _toDisplayString(groupOption.label), 3)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["disabled", "label"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_vit_accordion_group, { group: "cashpricing" }, {
        default: _withCtx(() => [
          (_ctx.sites.length)
            ? (_openBlock(), _createBlock(_component_vit_accordion, {
                key: 0,
                group: "cashpricing",
                title: _ctx.translations.site,
                narrow: ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_icon, {
                      class: "icon",
                      name: "icn-search"
                    }),
                    _createElementVNode("input", {
                      class: "cash-pricing__filter-search",
                      type: "text",
                      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSearchSites && _ctx.onSearchSites(...args)))
                    }, null, 32)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass([{ 'cash-pricing__filter-item--active': _ctx.allSitesSelected }, "cash-pricing__filter-item"]),
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.selectAllSites && _ctx.selectAllSites(...args)))
                  }, _toDisplayString(_ctx.translations.allSites), 3),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectableSites, (site) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: 'filter-' + site.sId,
                      class: _normalizeClass([{ 'cash-pricing__filter-item--active': _ctx.selectedSites.find(x => x.sId === site.sId) }, "cash-pricing__filter-item"]),
                      onClick: ($event: any) => (_ctx.selectSite(site.sId))
                    }, _toDisplayString(site.sName), 11, _hoisted_10))
                  }), 128))
                ]),
                _: 1
              }, 8, ["title"]))
            : (_ctx.status.sites)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.status.sites), 1))
              : (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_loading_spinner, { key: 2 }))
                : _createCommentVNode("", true),
          (_ctx.commodities.length)
            ? (_openBlock(), _createBlock(_component_vit_accordion, {
                key: 3,
                group: "cashpricing",
                title: _ctx.translations.commodityGrade,
                narrow: ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_icon, {
                      class: "icon",
                      name: "icn-search"
                    }),
                    _createElementVNode("input", {
                      class: "cash-pricing__filter-search",
                      type: "text",
                      onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onSearchCommodities && _ctx.onSearchCommodities(...args)))
                    }, null, 32)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass([{ 'cash-pricing__filter-item--active': _ctx.allCommoditiesSelected }, "cash-pricing__filter-item"]),
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.selectAllCommodities && _ctx.selectAllCommodities(...args)))
                  }, _toDisplayString(_ctx.translations.allCommodities), 3),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectableCommodities, (commodity) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: 'filterss-' + commodity.cId
                    }, [
                      (_openBlock(), _createElementBlock("div", {
                        key: 'filter-' + commodity.cId,
                        class: _normalizeClass([{ 'cash-pricing__filter-item--active': _ctx.isCommoditySelected(commodity.cId) }, "cash-pricing__filter-item"]),
                        onClick: ($event: any) => (_ctx.selectCommodity(commodity.cId))
                      }, _toDisplayString(commodity.cName), 11, _hoisted_13)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(commodity.grades, (grade) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: 'filter-' + grade.gId,
                          class: _normalizeClass([{ 'cash-pricing__filter-item--active': _ctx.selectedGrades.find(x => x.gId === grade.gId) }, "cash-pricing__filter-item cash-pricing__filter-item--sub"]),
                          onClick: ($event: any) => (_ctx.selectGrade(grade.gId))
                        }, _toDisplayString(grade.gName), 11, _hoisted_14))
                      }), 128))
                    ]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["title"]))
            : (_ctx.status.commodities)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.status.commodities), 1))
              : (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_loading_spinner, { key: 5 }))
                : _createCommentVNode("", true),
          _createVNode(_component_vit_accordion, {
            group: "cashpricing",
            disabled: !(_ctx.selectedSites.length && _ctx.selectedGrades.length),
            title: _ctx.translations.group,
            narrow: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupOptions, (groupOption) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: groupOption.key,
                  class: _normalizeClass([{ 'cash-pricing__filter-item--active': _ctx.selectedGroupOption === groupOption.key }, "cash-pricing__filter-item"]),
                  onClick: ($event: any) => (_ctx.groupBy(groupOption.key))
                }, _toDisplayString(groupOption.label), 11, _hoisted_16))
              }), 128))
            ]),
            _: 1
          }, 8, ["disabled", "title"])
        ]),
        _: 1
      })
    ]),
    (_ctx.selectedSites.length && _ctx.selectedGrades.length && _ctx.filteredPrices.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("div", {
            class: _normalizeClass([{ 'cash-pricing__pool-filter--active': _ctx.showPoolOptions }, "cash-pricing__pool-filter"]),
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showPoolOptions = !_ctx.showPoolOptions))
          }, _toDisplayString(_ctx.translations.showPool), 3),
          _createElementVNode("button", {
            class: "button d-none d-lg-flex align-self-start",
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.exportXlsx && _ctx.exportXlsx(...args)))
          }, _toDisplayString(_ctx.translations.export), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.selectedSites.length && _ctx.selectedGrades.length && _ctx.filteredPrices.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("div", {
            class: _normalizeClass([{ 'cash-pricing__pool-filter--active': _ctx.showExtendedPaymentTerms }, "cash-pricing__pool-filter"]),
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showExtendedPaymentTerms = !_ctx.showExtendedPaymentTerms))
          }, _toDisplayString(_ctx.translations.showExtendedPaymentTerms), 3)
        ]))
      : _createCommentVNode("", true)
  ]))
}
<template>
    <div class="cash-pricing__table-container" :class="{ 'cash-pricing__table-container--narrow': hideGroupTitle }">
        <div v-if="!hideGroupTitle" class="cash-pricing__price-pool-header">
            <h4 class="cash-pricing__price-pool-header">
                {{ group.title }}
            </h4>
            <button
                class="cash-pricing__deselect-group-btn"
                @click="$emit('remove', group.id)"
                v-if="group.type === 'commodity'">
                <icon name="icn-cross" class="icon" />
            </button>
        </div>
        <template v-if="group.gradePools && group.gradePools.length">
            <div v-if="!hideGroupTitle" class="cash-pricing__accordion-header">
                <div>{{ group.type === 'site' ? translations.grade : translations.site }}</div>
                <div>{{ translations.price }}</div>
            </div>
            <vit-accordion
                v-for="gradePool in group.gradePools"
                :key="gradePool.id"
                @collapse="onCollapseTable(gradePool.id)"
                :expand-text="translations.more"
                :collapse-text="translations.less">
                <template #toggle>
                    <div class="cash-pricing__accordion-toggle">
                        <div>
                            {{ gradePool.title }}
                            <span class="cash-pricing__pool-badge">{{ gradePool.prices.length }}</span>
                        </div>
                        <div>{{ gradePool.max > 0 ? '$' + gradePool.max : translations.noPrice }}</div>
                    </div>
                </template>
                <div class="cash-pricing__table-scroller">
                    <table class="table cash-pricing__table" :class="{ 'table--transparent': transparent }">
                        <thead>
                            <tr>
                                <th
                                    v-for="(th, index) in SortOption"
                                    :key="`${group}-${th}-${index}`"
                                    @click="sortTable(th, gradePool.id)">
                                    {{ translations[th] }}
                                    <icon
                                        name="icn-sort-arrow"
                                        :class="`cash-pricing__sort-arrow cash-pricing__sort-arrow-${getSortDir(th, gradePool.id)}`" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(price, p) in getSortedPrices(gradePool.prices, gradePool.id)"
                                :key="`${gradePool.id}-${p}`">
                                <td>{{ price.owner.oDescription }}</td>
                                <td v-if="price.sellInfo.isPool">
                                    {{ price.price }}
                                </td>
                                <td v-else>
                                    ${{ price.price.toFixed(2) }}
                                </td>
                                <td>{{ price.sellInfo.sOptionDescription }}</td>
                                <td>{{ price.sellInfo.pTermDescription }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="cash-pricing__table-more" v-if="!isExpanded(gradePool.id) && gradePool.prices.length > 5">
                    <button class="button button--down" @click="expand(gradePool.id)">
                        {{ translations.load }}
                    </button>
                </div>
            </vit-accordion>
        </template>
        <div v-else-if="showEmptyGrades">
            <div class="accordion__header">
                <button class="accordion__toggle collapsed" aria-expanded="false" aria-controls="accordion-75">
                    <span class="accordion__toggle-text">
                        <div class="cash-pricing__accordion-toggle">
                            <div>{{ group.title }}</div>
                            <div>{{ translations.noIndivPriceGrade }}</div>
                        </div>
                    </span>
                    <div class="accordion__toggle-icon-wrapper" />
                </button>
            </div>
        </div>
        <slot v-else />
    </div>
</template>

<script lang="ts">
/* eslint-disable no-unused-vars */
import { defineComponent } from 'vue';
import Icon from '../atoms/Icon.vue';
import { PriceInfo, GradePoolInfo, SortOptionBase } from '../../lib/Segregation';
import VitAccordion from '../base/VitAccordion.vue';

enum SortDir { ASC, DESC, DEFAULT }

interface SortInfo {
    id: string;
    sortBy: SortOptionBase;
    sortDir: SortDir;
    expanded: boolean;
}

export default defineComponent({
    components: { VitAccordion, Icon },
    props: {
        translations: { required: true, type: Object as () => Record<string, string> },
        group: { type: Object as () => { id: string; title: string; gradePools: GradePoolInfo[]; type: 'site' | 'commodity'; }, required: false },
        hideGroupTitle: { default: false, type: Boolean },
        transparent: { default: false, type: Boolean },
        showEmptyGrades: { default: false, type: Boolean }
    },
    data() {
        return {
            SortOption: SortOptionBase,
            sortInfo: [] as SortInfo[]
        };
    },

    methods: {
        sortTable(option: SortOptionBase, groupId: string) {
            const sortInfo: SortInfo = this.sortInfo.find(x => x.id === groupId);
            if (sortInfo.sortBy !== option) {
                sortInfo.sortBy = option;
                sortInfo.sortDir = SortDir.ASC;
            } else {
                switch (sortInfo.sortDir) {
                    case SortDir.ASC:
                        sortInfo.sortDir = SortDir.DESC;
                        break;
                    case SortDir.DESC:
                        sortInfo.sortDir = SortDir.DEFAULT;
                        break;
                    case SortDir.DEFAULT:
                        sortInfo.sortDir = SortDir.ASC;
                        break;
                    default:
                        sortInfo.sortDir = SortDir.DEFAULT;
                }
            }
        },

        getSortDir(col: string, groupId: string): string {
            const sortInfo: SortInfo = this.sortInfo.find(x => x.id === groupId);
            if (!sortInfo) {
                return 'default';
            }
            if (sortInfo.sortBy !== col || sortInfo.sortDir === SortDir.DEFAULT) {
                return 'default';
            }
            return sortInfo.sortDir === SortDir.DESC ? 'desc' : 'asc';
        },

        getSortedPrices(prices: PriceInfo[], groupId: string): PriceInfo[] {
            const sortInfo: SortInfo = this.sortInfo.find(x => x.id === groupId);
            let sorted = [];
            if (!sortInfo || sortInfo.sortDir === SortDir.DEFAULT) {
                sorted = prices;
            }
            sorted = prices.map(p => p).sort((a: PriceInfo, b: PriceInfo) => {
                let c = 0;
                switch (sortInfo.sortBy) {
                    case SortOptionBase.BUYER:
                        c = a.owner.oDescription < b.owner.oDescription ? -1 : 1;
                        break;
                    case SortOptionBase.PRICE:
                        c = (a.price as number) - (b.price as number);
                        break;
                    case SortOptionBase.PRICE_TYPE:
                        c = a.sellInfo.sOptionDescription < b.sellInfo.sOptionDescription ? -1 : 1;
                        break;
                    case SortOptionBase.PAYMENT_TERMS:
                        c = a.sellInfo.pTermDescription.localeCompare(b.sellInfo.pTermDescription, undefined, {
                            numeric: true,
                            sensitivity: 'base'
                        });
                        break;
                    default:
                        c = 0;
                }
                return sortInfo.sortDir === SortDir.ASC ? c : -c;
            });
            return sorted.slice(0, sortInfo.expanded ? prices.length : 5);
        },

        expand(groupId: string) {
            const sortInfo: SortInfo = this.sortInfo.find(x => x.id === groupId);
            if (!sortInfo) {
                return;
            }
            sortInfo.expanded = true;
            this.$forceUpdate();
        },

        onCollapseTable(groupId: string) {
            const sortInfo: SortInfo = this.sortInfo.find(x => x.id === groupId);
            if (!sortInfo) {
                return;
            }
            sortInfo.expanded = false;
        },

        isExpanded(groupId: string) {
            const sortInfo: SortInfo = this.sortInfo.find(x => x.id === groupId);
            if (!sortInfo) {
                return true;
            }
            return sortInfo.expanded;
        }
    },

    watch: {
        group: {
            handler() {
                this.sortInfo = this.group.gradePools.map(({ id }) => ({
                    id,
                    sortBy: SortOptionBase.PRICE,
                    sortDir: SortDir.DEFAULT,
                    expanded: false
                }));
            },
            deep: true,
            immediate: true
        }
    }
});

</script>

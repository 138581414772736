<template>
    <div class="filterable-articles">
        <vit-dropdown-bar :title="filtersLabel">
            <vit-dropdown :label="$capitalize(tagsLabel)" multiple v-if="tags.length">
                <dropdown-item
                    v-for="tag in tags"
                    :key="tag.key"
                    :label="tag.label"
                    :selected="selectedTags.includes(tag.key)"
                    @click="toggleTag(tag)" />
            </vit-dropdown>
            <vit-dropdown :label="year > 0 ? year : allYearsLabel">
                <dropdown-item :label="allYearsLabel" @click="selectYear(-1)" />
                <dropdown-item
                    v-for="y in years"
                    :key="y"
                    :selected="y === year"
                    :label="`${y}`"
                    @click="selectYear(`${y}`)" />
            </vit-dropdown>
        </vit-dropdown-bar>
        <button
            v-if="filterQuery.includes('tags') || filterQuery.includes('year')"
            @click="resetFilter"
            class="button button--borderless button--no-arrow filterable-articles__reset-filters">
            {{ resetFiltersLabel }}
        </button>
        <div class="teasers teasers--grid3 teasers--grid3-article">
            <template v-if="loading">
                <article-teaser
                    v-for="i in 9"
                    :key="`loading#${i}`"
                    class="teasers__cell"
                    loading />
            </template>
            <template v-else-if="articles.length">
                <div class="filterable-articles__cover-row" v-if="coverArticles.length">
                    <article-teaser
                        v-for="(article, a) in coverArticles"
                        :key="article.uuid"
                        class="teasers__cell"
                        :date="article.date"
                        :link="article.link"
                        :large="withCover && a === 0"
                        :show-image="showImages"
                        :read-more-label="readMoreLabel"
                        :image-md="article.mdImageUrl"
                        :image-lg="article.lgImageUrl"
                        :description="article.title" />
                </div>
                <article-teaser
                    v-for="article in articles.slice(coverArticles.length)"
                    :key="article.uuid"
                    class="teasers__cell"
                    :date="article.date"
                    :link="article.link"
                    :show-image="showImages"
                    :read-more-label="readMoreLabel"
                    :image-md="article.mdImageUrl"
                    :image-lg="article.lgImageUrl"
                    :description="article.title" />
            </template>
            <div v-else class="filterable-articles__error">
                <div class="filterable-articles__error-message">
                    {{ noResultsLabel }}
                </div>
                <button class="button" @click="resetFilter">
                    {{ resetFiltersLabel }}
                </button>
            </div>
        </div>
        <pagination :num-pages="numPages" v-model="page" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
import ArticleTeaser from './ArticleTeaser.vue';
import { ARTICLES_ENDPOINT } from '../Constants';
import VitDropdownBar from './base/VitDropdownBar.vue';
import DropdownItem from './base/VitDropdownItem.vue';
import Pagination from './atoms/Pagination.vue';
import VitDropdown from './base/VitDropdown.vue';

export default defineComponent({
    components: { VitDropdown, ArticleTeaser, Pagination, VitDropdownBar, DropdownItem },
    props: {
        rootId: { type: String, required: true },
        showImages: { type: Boolean, default: false },
        withCover: { type: Boolean, default: false },
        highlight: { type: Boolean, default: false },
        noResultsLabel: { type: String, required: true },
        resetFiltersLabel: { type: String, required: true },
        pageSize: { type: Number, required: true },
        readMoreLabel: { type: String, required: true },
        allYearsLabel: { type: String, required: true },
        tagsLabel: { type: String, required: true },
        filtersLabel: { type: String, required: true }
    },
    data() {
        return {
            articles: [],
            year: -1,
            tags: [],
            selectedTags: [],
            years: [],
            page: 1,
            total: 0,
            loading: false
        };
    },

    created() {
        const urlParams = new URLSearchParams(window.location.search);
        const tag = urlParams.get('tag');
        if (tag && tag.length) {
            this.selectedTags.push(tag);
        }
        this.getYears();
        this.getTags();
        this.getArticles();
    },

    methods: {
        toggleTag(tag) {
            const index = this.selectedTags.indexOf(tag.key);
            if (index >= 0) {
                this.selectedTags.splice(index, 1);
            } else {
                this.selectedTags.push(tag.key);
            }
            this.page = 1;
        },

        selectYear(year) {
            this.year = year;
            this.page = 1;
        },

        resetFilter() {
            this.year = -1;
            this.selectedTags = [];
            this.page = 1;
        },
        async getYears() {
            this.years = (await axios.get(`${this.$contextPath}${ARTICLES_ENDPOINT.years(this.rootId)}`)).data.sort((a, b) => b - a);
        },

        async getTags() {
            this.tags = (await axios.get(`${this.$contextPath}${ARTICLES_ENDPOINT.tags(this.rootId)}`)).data.sort();
        },

        async getArticles() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            const {
                articles,
                total,
                page
            } = (await axios.get(`${this.$contextPath}${ARTICLES_ENDPOINT.articles(this.rootId)}${this.filterQuery}`)).data;
            this.loading = false;
            this.articles = articles;
            this.total = total;
            this.page = page;
        }
    },
    computed: {
        selectedTagsStr() {
            return this.selectedTags.join(',');
        },

        // build filter query or empty string if no filter is selected
        filterQuery() {
            const query = {
                year: this.year > 0 ? `${this.year}` : '',
                tags: this.selectedTags.join(','),
                limit: `${this.pageSize}`,
                page: `${this.page}`
            };
            const queryStr = `${Object.keys(query).filter(key => query[key].length > 0).map(key => `${key}=${query[key]}`).join('&')}`;
            return queryStr.length ? `?${queryStr}` : '';
        },

        numPages() {
            return Math.ceil(this.total / this.pageSize);
        },

        coverArticles() {
            if (this.withCover && this.articles.length > 3) {
                return this.articles.slice(0, 3);
            }
            return [];
        }
    },

    watch: {
        filterQuery() {
            this.getArticles();
        }
    }
});

</script>

<template>
    <div>
        <div class="contracts-box_title">
            {{ formTranslations.title }}
        </div>
        <div class="contracts-box_selections contracts-results_box-grid-lg2col">
            <div class="contracts-box_selection contracts-box_selection-checkmarks">
                <div class="form__label--rubik">
                    {{ formTranslations.location }}
                </div>
                <vit-dropdown
                    :label="formSelectionValue.location? formSelectionValue.location : formTranslations.select"
                    light
                    rounded>
                    <dropdown-item :prevent-close-dropdown="true">
                        <div class="cash-pricing__filter-item cash-pricing__filter-item--search">
                            <icon class="icon" name="icn-search" />
                            <input class="cash-pricing__filter-search" type="text" @input="onSearchLocation">
                        </div>
                    </dropdown-item>
                    <dropdown-item
                        v-for="location in filteredLocations"
                        :key="location.id"
                        @click="onLocationChange(location)">
                        <span class="contract-dropdown-checkmark" :class="{ 'checkmark-active': formSelectionValue.location === location.description}" />
                        <span class="contract-dropdown-option">{{ location.description }}</span>
                    </dropdown-item>
                </vit-dropdown>
                <div v-if="requiredTest && !formSelectionValue.location" class="contracts-field-required">
                    {{ formTranslations.requiredfield }}
                </div>
                <a
                    v-if="locationsLink && formTranslations.allLocations"
                    class="button button--borderless"
                    :href="locationsLink"
                    target="_blank"
                    rel="noopener noreferrer">
                    {{ formTranslations.allLocations }}</a>
            </div>
            <div class="contracts-box_selection contracts-box_selection-checkmarks">
                <div class="form__label--rubik">
                    {{ formTranslations.commodity }}
                </div>
                <vit-dropdown
                    :label="formSelectionValue.commodity? formSelectionValue.commodity : formTranslations.select"
                    light
                    rounded>
                    <dropdown-item
                        v-for="commodity in commodities"
                        :key="commodity.id"
                        @click="formSelectionValue.commodity=commodity.label">
                        <span class="contract-dropdown-checkmark" :class="{ 'checkmark-active': formSelectionValue.commodity === commodity.label}" />
                        <span class="contract-dropdown-option">{{ commodity.label }}</span>
                    </dropdown-item>
                </vit-dropdown>
                <div v-if="requiredTest && !formSelectionValue.commodity" class="contracts-field-required">
                    {{ formTranslations.requiredfield }}
                </div>
            </div>
            <div class="contracts-box_selection">
                <div class="form__label--rubik">
                    {{ formTranslations.delivery }}
                </div>
                <div class="contracts-box_selection-radio">
                    <label
                        class="form__radio form__radio--nocenter"
                        v-for="(delivery, index) in deliveriesDate"
                        :class="{'form__radio--nospace': index === 0}"
                        :key="delivery.id">
                        <input
                            type="radio"
                            @click="onDeliveryChange(delivery)"
                            v-model="formSelectionValue.delivery"
                            :value="delivery.id"
                            class="form__radio-input">
                        <div class="form__indicator--grey" />
                        <div class="form__label--rubik form__label--thin">{{ delivery.label }}</div>
                    </label>
                </div>
                <div v-if="requiredTest && !formSelectionValue.delivery" class="contracts-field-required">
                    {{ formTranslations.requiredfield }}
                </div>
            </div>
            <div class="contracts-box_selection">
                <div class="form__label--rubik">
                    {{ formTranslations.direction }}
                </div>
                <vit-range-input
                    :min="directionMin"
                    :max="directionMax"
                    :init-value="selectedRange"
                    @value="onRangeChange"
                    :right-desc="priceHeadline.up"
                    :left-desc="priceHeadline.down" />
            </div>
            <div class="contracts-box_selection contracts-box_selection-manage">
                <div class="form__label--rubik">
                    {{ formTranslations.manage }}
                </div>
                <div>
                    <label
                        class="form__radio form__radio--nospace form__radio--nocenter"
                        v-for="manage in involvedClient"
                        :key="manage.id">
                        <input
                            type="radio"
                            @click="onTradingChange(manage)"
                            :value="manage.id"
                            class="form__radio-input">
                        <div class="form__indicator--grey" />
                        <div class="form__label--rubik form__label--thin">{{ manage.label }}</div>
                    </label>
                </div>
                <div v-if="requiredTest && !selectedTrading.label" class="contracts-field-required">
                    {{ formTranslations.requiredfield }}
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import Gavilon from '../../lib/Gavilon';
import DropdownItem from '../base/VitDropdownItem.vue';
import Icon from '../atoms/Icon.vue';
import VitRangeInput from '../atoms/RangeInput.vue';
import VitDropdown from '../base/VitDropdown.vue';

export default defineComponent({
    components: { VitDropdown, DropdownItem, VitRangeInput, Icon },
    props: {
        formTranslations: { required: true, type: Object as () => Record<string, string> },
        commodities: { required: true, type: Array as () => Array<Object> },
        deliveriesDate: { required: true, type: Array as () => Array<Object> },
        priceHeadline: { required: true, type: Object as () => Record<string, string> },
        formSelection: { required: true, type: Object },
        involvedClient: { required: true, type: Array as () => Array<Object> },
        locationsLink: { required: true, type: String },
        requiredTest: { required: true, type: Boolean }
    },
    data() {
        return {
            locations: [] as Array<{ [key: string]: any }>,
            selectedRange: 0,
            selectedTrading: null as { [key: string]: string },
            directionMin: -50,
            directionMax: 50,
            formSelectionValue: this.formSelection,
            // input to filter locations
            locationFilter: ''
        };
    },

    async created() {
        this.locations = await Gavilon.getLocations();
    },

    methods: {
        // update data when selecting
        onTradingChange(trading) {
            this.selectedTrading = trading;
            this.formSelectionValue.trading = trading.id;
            this.formSelectionValue.tradingLabel = trading.resultSentence;
        },
        onLocationChange(location) {
            this.formSelectionValue.location = location.description;
        },

        onDeliveryChange(delivery) {
            this.formSelectionValue.delivery = delivery.id;
            this.formSelectionValue.deliveryLabel = delivery.label;
        },

        // update range when range component emits
        onRangeChange(range) {
            this.selectedRange = range;
            this.formSelectionValue.priceDirection = this.selectedRange < 0 ? this.priceHeadline.down : this.priceHeadline.up;
        },

        // only for watch purpose
        formSelectionStr() {
            return `${this.formSelectionValue.location}.${this.formSelectionValue.commodity}.${this.formSelectionValue.delivery}.${this.formSelectionValue.trading}.${this.formSelectionValue.priceDirection}`;
        },

        onSearchLocation(event: InputEvent) {
            this.locationFilter = (event.target as HTMLInputElement).value.toLowerCase();
        }
    },

    computed: {
        filteredLocations() {
            return this.locations.filter(item => item.description.toLowerCase().includes(this.locationFilter));
        }
    },
    watch: {
        formSelectionStr() {
            this.$emit('formSelection', this.formSelectionValue);
        }
    }

});

</script>

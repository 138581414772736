import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "link-list__items"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.links)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "link-list__item",
              key: link.id
            }, [
              _createElementVNode("a", {
                class: "button button--link",
                href: _ctx.url(link.id),
                target: "_blank"
              }, _toDisplayString(link.name), 9, _hoisted_2)
            ]))
          }), 128))
        ]))
      : (_ctx.error)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            innerHTML: _ctx.errorMessage
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
  ]))
}
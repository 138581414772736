import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cookie-banner"
}
const _hoisted_2 = { class: "cookie-banner__container" }
const _hoisted_3 = { class: "cookie-banner__title" }
const _hoisted_4 = { class: "cookie-banner__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "cookie-banner__collapse" }, {
    default: _withCtx(() => [
      (!_ctx.accepted)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("button", {
                class: "cookie-banner__close",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.accept && _ctx.accept(...args))),
                "aria-label": "close"
              }, [
                _createVNode(_component_icon, {
                  name: "icn-cross",
                  class: "icon cookie-banner__close-icon"
                })
              ]),
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
              _createElementVNode("div", _hoisted_4, [
                _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
                _renderSlot(_ctx.$slots, "default")
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
<template>
    <div>
        <div class="leadership__member-detail">
            <div class="leadership__member-detail-left">
                <div class="leadership-department__member-name">
                    {{ member.name }}
                </div>
                <div class="leadership-department__member-text">
                    {{ member.job }}
                </div>
                <img
                    v-if="member.image"
                    class="leadership__member-image leadership__member-image--detail"
                    :src="member.image"
                    :alt="member.name">
                <icon v-else class="leadership__member-icon" name="icn-placeholder" />
            </div>
            <div class="leadership__member-detail-right">
                <vit-dropdown :label="member.name" light mobile-only>
                    <dropdown-item
                        v-for="memberoption in members"
                        :key="memberoption.uuid"
                        :label="memberoption.name"
                        @click="$emit('member', memberoption)" />
                </vit-dropdown>
                <div class="leadership__member-board">
                    <h4>{{ menuTitle }}</h4>
                    <button
                        v-for="memberoption in members"
                        :key="memberoption.uuid"
                        class="button button--link leadership__member-board-link"
                        @click="$emit('member', memberoption)">
                        {{ memberoption.name }}
                    </button>
                </div>
            </div>
        </div>
        <div class="leadership__member-content">
            <div v-if="member.appointed && member.appointed.length">
                <div>{{ appointedLabel }}</div>
                <div v-html="member.appointed" class="leadership-department__member-text" />
            </div>
            <div v-if="member.experience && member.experience.length">
                <div>{{ experienceLabel }}</div>
                <div v-html="member.experience" class="leadership-department__member-text" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from './atoms/Icon.vue';
import VitDropdown from './base/VitDropdown.vue';
import DropdownItem from './base/VitDropdownItem.vue';

export default defineComponent({
    components: { VitDropdown, DropdownItem, Icon },
    props: {
        member: { type: Object as () => { name: string, job: string, image: string, experience: string, appointed: string } },
        members: { type: Array as () => Array<{ uuid: string, name: string }>, default: () => [] },
        menuTitle: { type: String, default: '' },
        appointedLabel: { type: String, required: true },
        experienceLabel: { type: String, required: true }
    }
});

</script>

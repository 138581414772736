<template>
    <div class="offices-map" v-if="office">
        <div class="offices-map__map">
            <google-map
                :center="office.location"
                :markers="officeLocations"
                :zoom="zoom"
                @map-zoom="zoom = $event"
                :api-key="apiKey" />
        </div>
        <div class="offices-map__overlay">
            <div class="offices-map__overlay-content">
                <div class="offices-map__location">
                    {{ office.location.title }}
                </div>
                <div v-if="office.addressLine1" class="offices-map__address" v-html="office.addressLine1" />
                <div v-if="office.cityZipCode" class="offices-map__address" v-html="office.cityZipCode" />
                <div v-if="office.addressLine2" class="offices-map__address" v-html="office.addressLine2" />
                <div v-if="office.phone || office.fax" class="offices-map__contact">
                    <a v-if="office.phone" :href="`tel:${office.phone.replace(/\s/g, '')}`">T: {{ office.phone }}</a>
                    <div v-if="office.fax">
                        F: {{ office.fax }}
                    </div>
                </div>
                <button v-if="office.email" class="button button--link offices-map__email" @click="decryptEmail(office.email)">
                    Email
                </button>
                <div v-if="office.optional" class="offices-map__contact" v-html="office.optional.replace(/\n/g, '<br>')" />
                <div v-if="office.description" class="offices-map__name">
                    {{ office.description }}
                </div>
                <vit-dropdown
                    v-if="offices.length > 1 && office"
                    :label="office.location.title"
                    light
                    class="offices-map__dropdown"
                    inline>
                    <dropdown-item
                        v-for="(off, o) in offices"
                        :key="off.location.title"
                        :label="off.location.title"
                        @click="index = o" />
                </vit-dropdown>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GoogleMap from './base/GoogleMap.vue';
import DropdownItem from './base/VitDropdownItem.vue';
import VitDropdown from './base/VitDropdown.vue';

export default defineComponent({
    components: { VitDropdown, GoogleMap, DropdownItem },
    props: {
        apiKey: { required: true, type: String },
        offices: { required: true, type: Array }
    },
    data() {
        return {
            index: 0,
            zoom: 15
        };
    },

    methods: {
        decryptEmail(email) {
            window.open(`mailto:${atob(email)}`, '_self');
        }
    },
    computed: {
        office() {
            if (this.offices.length > this.index) {
                const office = this.offices[this.index];
                const { lat, lng, title } = office.location;
                return {
                    ...office,
                    location: {
                        title,
                        lat: parseFloat(lat),
                        lng: parseFloat(lng)
                    }
                };
            }
            return null;
        },
        officeLocations() {
            return this.offices.map(({ location }) => ({ location: { lat: parseFloat(location.lat), lng: parseFloat(location.lng), title: location.title } }));
        }
    }
});
</script>

<template>
    <div class="sub-navigation" :class="{ 'sub-navigation--dark': submenus.length > 0 }">
        <div class="sub-navigation__bg" v-if="submenus.length" @click="closeSubLevel" />
        <div class="sub-navigation__container">
            <div class="sub-navigation__wrapper" v-if="parentItem">
                <div class="sub-navigation__breadcrumb">
                    <button
                        class="sub-navigation__breadcrumb-button button button--borderless button--left"
                        @click="back">
                        {{ parentItem.title }}
                    </button>
                </div>
            </div>
            <div class="sub-navigation__wrapper" @click="closeSubLevel">
                <div class="sub-navigation__content">
                    <a
                        :href="currentItem.link"
                        :target="currentItem.newWindow ? '_blank' : '_self'"
                        :rel="currentItem.newWindow ? 'noopener noreferrer' : null"
                        @click.stop="onNav"
                        class="sub-navigation__parent-title sub-navigation__item">
                        <b>{{ currentItem.title }}</b></a>
                    <template v-for="(item, i) in items">
                        <button
                            v-if="item.items && item.items.length"
                            :key="`subitem#${i}`"
                            @click.stop="openSubLevel(item)"
                            :class="{
                                'sub-navigation__item-active button--expanded': isActive(item),
                                'sub-navigation__root-item': currentItem && currentItem.title === 'Home'
                            }"
                            class="button button--borderless sub-navigation__item">
                            {{ item.title }}
                        </button>
                        <a
                            v-else
                            :key="`subitem#${i}`"
                            :href="item.link"
                            :target="item.newWindow ? '_blank' : '_self'"
                            :rel="item.newWindow ? 'noopener noreferrer' : null"
                            :class="{ 'sub-navigation__item-active': isActive(item) }"
                            @click.stop="onNav"
                            class="sub-navigation__item">
                            {{ item.title }}</a>
                    </template>
                    <div v-if="!parentItem" class="sub-navigation__meta-nav">
                        <slot />
                    </div>
                </div>
                <div class="sub-navigation__sublevel" v-for="(submenu, s) in submenus" :key="`slvl#${s}`">
                    <div class="sub-navigation__sublevel-content-wrapper" @click.stop>
                        <div
                            v-if="s + 1 === submenus.length"
                            class="sub-navigation__sublevel-close"
                            @click.stop="closeSubLevel" />
                        <div class="sub-navigation__sublevel-content">
                            <a
                                :href="submenu.link"
                                :target="submenu.newWindow ? '_blank' : '_self'"
                                :rel="submenu.newWindow ? 'noopener noreferrer' : null"
                                @click.stop="onNav"
                                class="sub-navigation__item">
                                <b>{{ submenu.title }}</b></a>
                            <template v-for="(item, i) in submenu.items">
                                <button
                                    v-if="item.items && item.items.length"
                                    :key="`subsubitem#${i}`"
                                    @click.stop="openSubLevel(item)"
                                    :class="{ 'sub-navigation__item-active button--expanded': isActive(item, true) }"
                                    class="button button--borderless sub-navigation__item">
                                    {{ item.title }}
                                </button>
                                <a
                                    v-else
                                    :key="`subitem#${i}`"
                                    :href="item.link"
                                    :target="item.newWindow ? '_blank' : '_self'"
                                    :rel="item.newWindow ? 'noopener noreferrer' : null"
                                    :class="{ 'sub-navigation__item-active': isActive(item, true) }"
                                    @click.stop="onNav"
                                    class="sub-navigation__item">
                                    {{ item.title }}</a>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useMyStore } from '../../store';

export default defineComponent({
    // @State currentBreakpoint;
    props: {
        currentItem: Object
    },
    // @Prop() currentItem: any;
    data() {
        return {
            submenus: [],
            parentItems: []
        };
    },
    methods: {
        openSubLevel(item) {
            // eslint-disable-next-line dot-notation
            if (this.currentBreakpoint === 'large' || this.currentBreakpoint === 'xLarge') {
                this.submenus.push(item);
            } else {
                this.parentItems.push(this.currentItem);
                this.$emit('submenu', item);
            }
        },

        closeSubLevel() {
            this.submenus.pop();
        },

        back() {
            this.$emit('submenu', this.parentItem);
            this.parentItems.pop();
        },

        isActive(item, sub = false) {
            if (this.submenus.length && !sub) {
                return this.submenus.some(x => x.title === item.title);
            }
            return item.link && item.link.length && location.href.includes(item.link);
        },

        onNav() {
            this.$emit('nav');
        }
    },

    computed: {
        ...mapState(useMyStore, {
            currentBreakpoint: state => state.currentBreakpoint
        }),
        items() {
            return this.currentItem.items;
        },

        parentItem() {
            return this.parentItems.slice(-1).pop();
        }
    },

    watch: {
        items: {
            immediate: true,
            handler() {
                // eslint-disable-next-line dot-notation
                if (!(this.currentBreakpoint === 'large' || this.currentBreakpoint === 'xLarge')) {
                    return;
                }
                this.submenus = [];
                const findActive = items => {
                    const active = items.find(x => location.href.includes(x.link));
                    if (active) {
                        this.submenus.push(active);
                    } else {
                        return;
                    }
                    if (active.items && active.items.length) {
                        findActive(active.items);
                    }
                };
                findActive(this.items);
                // remove current item
                this.submenus.splice(-1, 1);
            }
        }
    }
});
</script>

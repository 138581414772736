<template>
    <div v-html="html" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        html: String,
        css: String,
        js: String,
        libs: { type: Array as () => Array<String> }
    },

    mounted() {
        if (this.libs && this.libs.length > 0) {
            this.libs.forEach(url => {
                const script: HTMLScriptElement = document.createElement('script');
                script.src = url;
                script.type = 'application/javascript';
                this.$el.appendChild(script);
            });
        }

        if (this.js && this.js.length > 0) {
            const script: HTMLScriptElement = document.createElement('script');
            script.innerHTML = this.js;
            this.$el.appendChild(script);
        }

        if (this.css && this.css.length > 0) {
            const style: HTMLStyleElement = document.createElement('style');
            style.innerHTML = this.css;
            this.$el.appendChild(style);
        }
    }
});

</script>

<template>
    <div
        class="accordion"
        :id="anchorId"
        :class="{ 'accordion--inset': inset, 'accordion--narrow': narrow, 'accordion--expanded': opened, 'accordion--mobile': mobileOnly }">
        <div class="accordion__header">
            <button class="accordion__toggle" @click="toggleAccordion">
                <span class="accordion__toggle-text">
                    <slot name="toggle">{{ title }}</slot>
                </span>
                <div class="accordion__toggle-icon-wrapper">
                    <span v-if="expandText && !opened" class="accordion__toggle-icon-text">{{ expandText }}</span>
                    <span v-if="collapseText && opened" class="accordion__toggle-icon-text">{{ collapseText }}</span>
                    <icon :name="opened ? 'icn-upload' : 'icn-download'" class="accordion__toggle-icon" />
                </div>
            </button>
        </div>
        <collapse-transition>
            <div class="accordion__content" v-if="opened">
                <slot />
            </div>
        </collapse-transition>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import { v4 as uuid } from 'uuid';
import Icon from '../atoms/Icon.vue';
import eventBus from '../../lib/event';

export default defineComponent({
    components: { Icon, CollapseTransition },
    props: {
        title: { type: String, default: '' },
        expandText: String,
        collapseText: String,
        open: { type: Boolean, default: false },
        group: { type: String, default: '' },
        mobileOnly: { type: Boolean, default: false },
        inset: { type: Boolean, default: false },
        narrow: { type: Boolean, default: false },
        anchorId: { type: String, default: '' }
    },
    data() {
        return {
            opened: this.open,
            uid: uuid()
        };
    },

    // inject need default, because vit-accordion-group can be not present
    inject: {
        registerAccordion: { default: () => {} },
        unregisterAccordion: { default: () => {} }
    },

    created() {
        if (this.hasHashToAnchorID()) {
            this.opened = true;
        }
    },

    mounted() {
        if (this.hasHashToAnchorID()) {
            this.$el.scrollIntoView();
        }
        // only register if have group
        if (this.group !== '') {
            this.registerAccordion(this);
        }
        window.addEventListener('hashchange', this.handleHashChange);
    },

    beforeUnmount() {
        if (this.group !== '') {
            this.unregisterAccordion(this);
        }
        window.removeEventListener('hashchange', this.handleHashChange);
    },

    methods: {
        // return true if have hash and is the element id
        hasHashToAnchorID() {
            return window.location.hash && window.location.hash === `#${this.anchorId}`;
        },
        // open dropdown
        toggleAccordion() {
            if (this.opened) {
                this.opened = false;
            } else {
                this.opened = true;
                eventBus.emit('accordion-open', { group: this.group, open: this.uid });
            }
        },
        // close accordion if other in group is open
        closeGroup(accordion) {
            if (accordion.group === this.group && accordion.open !== this.uid) {
                this.opened = false;
            }
        },

        handleHashChange() {
            if (this.hasHashToAnchorID()) {
                this.toggleAccordion();
                this.$nextTick(() => {
                    this.$el.scrollIntoView();
                });
            }
        }
    }
});

</script>

<template>
    <div class="form__range">
        <input
            type="range"
            :min="min"
            :max="max"
            v-model="value"
            class="form__range-input">
        <div class="form__range-background" />
        <div class="form__range-progress" :style="{ width: `${percentage}%`} " />
        <div class="form__range-left">
            {{ leftDesc }}
        </div>
        <div class="form__range-right">
            {{ rightDesc }}
        </div>
        <div class="form__range-output" :style="{ left: `calc(${percentage}% - 1.15rem)`}">
            <div class="form__range-output-text">
                {{ value > 0 ? '+' + value : value }} %
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        min: { required: true, type: Number },
        max: { required: true, type: Number },
        initValue: { default: 0, type: Number },
        rightDesc: { type: String },
        leftDesc: { type: String }
    },
    data() {
        return {
            value: 0 as Number
        };
    },

    created() {
        this.value = this.initValue;
    },
    computed: {
        percentage() {
            return ((this.value - this.min) / (this.max - this.min)) * 100;
        }
    },
    watch: {
        value() {
            this.$emit('value', Number(this.value));
        }
    }
});

</script>

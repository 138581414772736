<template>
    <div class="slider" :class="{ 'slider--highlight': highlight }">
        <div class="slider__container">
            <div class="slider__slides" ref="teaserSlider">
                <slot />
            </div>
        </div>

        <div class="slider__controls-wrapper">
            <div class="slider__controls">
                <icon name="icn-bracket" class="slider__controls-bracket" />
                <div
                    class="button button--borderless button--reverse"
                    :class="{ 'button--disabled': activeIndex === 0 && !loop, 'button--white': highlight }"
                    @click="prev" />
                <div class="slider__indicators">
                    <div
                        v-for="(teaser, i) in teasers"
                        :key="teaser._uid"
                        class="slider__indicator"
                        :class="{ 'slider__indicator--active': activeIndex === i }"
                        @click="jumpTo(i)" />
                </div>
                <div
                    class="button button--borderless slider__controls-button-next"
                    @click="next"
                    :class="{ 'button--disabled': activeIndex + 1 >= teasers.length && !loop, 'button--white': highlight }" />
                <icon name="icn-bracket" class="slider__controls-bracket slider__controls-bracket-right" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '../atoms/Icon.vue';

const Flickity = require('flickity');

export default defineComponent({
    components: { Icon },
    props: {
        linkUrl: String,
        linkLabel: String,
        draggable: { default: true, type: Boolean },
        loop: { default: false, type: Boolean },
        highlight: { default: false, type: Boolean }
    },
    provide() {
        return {
            childRegistration: this.register
        };
    },
    data() {
        return {
            teasers: [],
            flickity: null,
            activeIndex: 0
        };
    },

    mounted() {
        this.$nextTick(() => {
            this.initFlickity();
        });
    },

    methods: {
        prev() {
            if (this.activeIndex > 0) {
                this.activeIndex--;
            } else {
                this.activeIndex = this.teasers.length - 1;
            }
        },

        next() {
            if (this.activeIndex + 1 >= this.teasers.length) {
                this.activeIndex = 0;
            } else {
                this.activeIndex++;
            }
        },

        jumpTo(index) {
            this.activeIndex = index;
        },

        initFlickity() {
            const flktyOptions = {
                prevNextButtons: false,
                cellSelector: '.slide',
                pageDots: false,
                contain: true,
                wrapAround: this.loop,
                cellAlign: 'left',
                draggable: this.draggable
            };
            this.flickity = new Flickity(this.$refs.teaserSlider, flktyOptions);
            this.flickity.on('change', slide => {
                this.activeIndex = slide;
            });
        },

        // called by child teasers
        register(teaser) {
            this.teasers.push(teaser);
        }
    },

    watch: {
        activeIndex() {
            if (this.flickity) {
                this.flickity.select(this.activeIndex);
            }
        }
    }

});

</script>

<template>
    <ol class="breadcrumbs__list">
        <li class="breadcrumbs__list-item" v-if="breadcrumbs.length">
            <a class="breadcrumbs__link button button--link" :href="breadcrumbs[0].link">{{ breadcrumbs[0].label }}</a>
        </li>
        <li class="breadcrumbs__list-item" v-if="!showAll && breadcrumbs.length > 3">
            <span class="breadcrumbs__link button button--link" @click="showAll = true">...</span>
        </li>
        <template v-if="!showAll && breadcrumbs.length > 3">
            <li class="breadcrumbs__list-item">
                <a class="breadcrumbs__link button button--link" :href="breadcrumbs[breadcrumbs.length - 2].link">{{ breadcrumbs[breadcrumbs.length - 2].label }}</a>
            </li>
            <li class="breadcrumbs__list-item">
                <span class="breadcrumbs__link button button--link button--disabled">{{ breadcrumbs[breadcrumbs.length - 1].label }}</span>
            </li>
        </template>
        <template v-if="showAll || breadcrumbs.length <= 3">
            <li class="breadcrumbs__list-item" v-for="(breadcrumb, index) in breadcrumbs.slice(1)" :key="index">
                <a class="breadcrumbs__link button button--link" :href="breadcrumb.link">{{ breadcrumb.label }}</a>
            </li>
        </template>
    </ol>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        breadcrumbsString: { type: String, default: '' }
    },
    data() {
        return {
            showAll: false
        };
    },

    methods: {},

    computed: {
        breadcrumbs() {
            return JSON.parse(this.breadcrumbsString);
        }
    }
});

</script>

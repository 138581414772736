<template>
    <div v-if="numPages > 1" class="pagination" :class="{ 'pagination--highlight': highlight }">
        <div
            v-if="rangeSelectionStart >= rangeSelection"
            class="pagination-item"
            :class="{ 'pagination-item-active': numPages === (modelValue + 1)}"
            @click="selectFirst">
            1
        </div>
        <span
            v-if="rangeSelectionStart >= rangeSelection"
            class="pagination-dots"
            @click="rangeBackward">...</span>
        <div
            v-for="i in numPages"
            v-show="i >= rangeSelectionStart && i <= rangeSelectionEnd"
            :key="`page#${i}`"
            class="pagination-item"
            :class="{ 'pagination-item-active' : i === modelValue }"
            @click="$emit('update:modelValue', i)">
            {{ i }}
        </div>
        <span
            v-if="rangeSelectionEnd < numPages && rangeSelectionEnd !== numPages - 1"
            class="pagination-dots"
            @click="rangeForward">...</span>
        <div
            class="pagination-item"
            :class="{ 'pagination-item-active': numPages === modelValue}"
            @click="selectLast">
            {{ numPages }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

const RANGE = 4;

export default defineComponent({
    props: {
        numPages: { type: Number, required: true },
        highlight: { type: Boolean, default: false },
        modelValue: { type: Number, required: true }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            rangeSelection: RANGE,
            rangeSelectionStart: 1,
            rangeSelectionEnd: 0
        };
    },

    created() {
        this.calculateRange();
    },

    methods: {
        rangeForward() {
            this.rangeSelectionStart += this.rangeSelection;
            this.calculateRange();
        },

        selectFirst() {
            this.$emit('update:modelValue', 1);
            this.rangeSelectionStart = 1;
            this.calculateRange();
        },

        selectLast() {
            this.$emit('update:modelValue', this.numPages);
            this.rangeSelectionStart = this.numPages - (this.rangeSelection - 1);
            if (this.rangeSelectionStart < 1) {
                this.rangeSelectionStart = 1;
            }
            this.calculateRange();
        },

        rangeBackward() {
            this.rangeSelectionStart -= this.rangeSelection;
            this.calculateRange();
        },

        handleOffset() {
            if (this.numPages - this.rangeSelection > 1) {
                if (this.rangeSelectionStart === this.numPages) {
                    const numberOffset = this.numPages % this.rangeSelection;
                    this.rangeSelectionStart = this.numPages - numberOffset + 1 - (numberOffset !== 0 ? 0 : this.rangeSelection);
                    this.rangeSelectionEnd = this.numPages - 1;
                }
            }
        },
        calculateRange() {
            // if numPages are less or equal of RANGE*2, show all pages
            this.rangeSelection = this.numPages <= RANGE * 2 ? this.rangeSelection = RANGE * 2 : this.rangeSelection = RANGE;
            const step = Math.floor(this.rangeSelectionStart / this.rangeSelection);
            this.rangeSelectionEnd = step * this.rangeSelection + this.rangeSelection >= this.numPages
                ? this.numPages - 1
                : step * this.rangeSelection + this.rangeSelection;
        }
    },

    watch: {
        numPages: {
            immediate: true,
            handler() {
                this.calculateRange();
            }
        }
    }
});

</script>

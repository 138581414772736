import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "site-information__details" }
const _hoisted_2 = {
  key: 0,
  class: "site-information__title"
}
const _hoisted_3 = { class: "site-information__message" }
const _hoisted_4 = { class: "site-information__message-title" }
const _hoisted_5 = { class: "site-information__message-text" }
const _hoisted_6 = { class: "site-information__details" }
const _hoisted_7 = { class: "site-information__map-container" }
const _hoisted_8 = { class: "site-information__map" }
const _hoisted_9 = { class: "site-information__wrapper" }
const _hoisted_10 = { class: "site-information__container" }
const _hoisted_11 = { class: "site-information__container-bottom" }
const _hoisted_12 = {
  key: 0,
  class: "site-information__address"
}
const _hoisted_13 = ["href", "title"]
const _hoisted_14 = { class: "site-information__manager" }
const _hoisted_15 = ["href"]
const _hoisted_16 = ["href"]
const _hoisted_17 = { class: "site-information__opening-hours-wrapper" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "site-information__today-label" }
const _hoisted_20 = { class: "site-information__opening-hours" }
const _hoisted_21 = { class: "site-information__details" }
const _hoisted_22 = { class: "site-information__title" }
const _hoisted_23 = { class: "site-information__grades" }
const _hoisted_24 = { class: "site-information__pricing-header" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = {
  key: 1,
  class: "my-3 justify-content-between d-flex"
}
const _hoisted_27 = {
  key: 2,
  class: "flex-column flex-lg-row d-flex"
}
const _hoisted_28 = { class: "cash-pricing__pool-filter-popup-text" }
const _hoisted_29 = ["href"]
const _hoisted_30 = { class: "cash-pricing__pool-filter-popup-text" }
const _hoisted_31 = ["href"]
const _hoisted_32 = { class: "segregation-plan__filter-date" }
const _hoisted_33 = { class: "site-information__table-wrapper" }
const _hoisted_34 = { class: "site-information__accordion-header" }
const _hoisted_35 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_google_map = _resolveComponent("google-map")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _component_icon = _resolveComponent("icon")!
  const _component_segregation_cash_table = _resolveComponent("segregation-cash-table")!
  const _component_vit_tab = _resolveComponent("vit-tab")!
  const _component_vit_tabs = _resolveComponent("vit-tabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.siteInfo && _ctx.fetchStatus.siteDetailStatus == 200)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.siteInfo.message)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.translations.siteMessage), 1),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.siteInfo.message), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_google_map, {
                  center: _ctx.mapCenter,
                  markers: _ctx.mapMarkers,
                  zoom: _ctx.zoom,
                  "api-key": _ctx.apiKey
                }, null, 8, ["center", "markers", "zoom", "api-key"])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", null, [
                    (_ctx.site.addressLine1 || _ctx.site.addressLine2 || _ctx.site.addressLine3)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_12, [
                          _createElementVNode("li", null, _toDisplayString(_ctx.site.addressLine1), 1),
                          _createElementVNode("li", null, _toDisplayString(_ctx.site.addressLine2), 1),
                          _createElementVNode("li", null, _toDisplayString(_ctx.site.addressLine3), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("a", {
                      href: _ctx.directionsUrl,
                      title: _ctx.translations.directions,
                      target: "_blank",
                      class: "button site-information__directions-btn"
                    }, _toDisplayString(_ctx.translations.directions), 9, _hoisted_13)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.translations.siteManager), 1),
                    _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.siteInfo.manager), 1),
                    _createElementVNode("p", null, [
                      (_ctx.siteInfo.phone)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: `tel:${_ctx.siteInfo.phone.replace(/\s/g, '')}`
                          }, " T: " + _toDisplayString(_ctx.siteInfo.phone), 9, _hoisted_15))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("p", null, [
                      (_ctx.siteInfo.managerMobile)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: `tel:${_ctx.siteInfo.managerMobile.replace(/\s/g, '')}`
                          }, " M: " + _toDisplayString(_ctx.siteInfo.managerMobile), 9, _hoisted_16))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("p", {
                      class: _normalizeClass(["site-information__status-text", _ctx.statusClass])
                    }, _toDisplayString(_ctx.formatStatus(_ctx.siteInfo.currentStatus)), 3),
                    (_ctx.siteInfo.currentStatus.toLowerCase() === 'open')
                      ? (_openBlock(), _createElementBlock("p", _hoisted_18, [
                          _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.translations.today), 1),
                          _createElementVNode("span", null, _toDisplayString(_ctx.siteInfo.currentTime), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("p", null, _toDisplayString(_ctx.translations.openingHours), 1),
                    _createElementVNode("ul", _hoisted_20, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dayNames, (day, d) => {
                        return (_openBlock(), _createElementBlock("li", { key: day }, [
                          _createElementVNode("span", null, _toDisplayString(day), 1),
                          _createElementVNode("span", null, _toDisplayString(_ctx.getOpeningTime(d)), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ], 64))
      : (!_ctx.loaded)
        ? (_openBlock(), _createBlock(_component_loading_spinner, { key: 1 }))
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, [
          (_ctx.pricesLoaded && _ctx.fetchStatus.sitePricesStatus == 200)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.translations.pricing), 1),
                (!_ctx.prices.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(_ctx.translations.notAvailable), 1))
                  : _createCommentVNode("", true),
                (_ctx.prices.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["cash-pricing__pool-filter", { 'cash-pricing__pool-filter--active': _ctx.showPoolOptions }]),
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPoolOptions = !_ctx.showPoolOptions))
                      }, _toDisplayString(_ctx.translations.showPool), 3)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.prices.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["cash-pricing__pool-filter", { 'cash-pricing__pool-filter--active': _ctx.showExtendedPaymentTerms }]),
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showExtendedPaymentTerms = !_ctx.showExtendedPaymentTerms))
                      }, [
                        _createTextVNode(_toDisplayString(_ctx.translations.showExtendedPaymentTerms) + " ", 1),
                        _createElementVNode("div", {
                          onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
                        }, [
                          _createVNode(_component_icon, {
                            class: "icon",
                            name: "icn-info",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openClosePopUp('showPopUpEPP')))
                          }),
                          _createElementVNode("div", {
                            class: _normalizeClass(["cash-pricing__pool-filter-popup", { 'cash-pricing__pool-filter-popup-active': _ctx.showPopUpEPP }])
                          }, [
                            _createVNode(_component_icon, {
                              name: "icn-cross",
                              class: "cash-pricing__pool-filter-popup-cross",
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openClosePopUp('showPopUpEPP')))
                            }),
                            _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.translations.showEPPricingDesc), 1),
                            _createElementVNode("a", {
                              class: "button button--borderless",
                              href: _ctx.translations.segregationMoreInfoLink,
                              target: "_blank"
                            }, _toDisplayString(_ctx.translations.segregationMoreInfo), 9, _hoisted_29)
                          ], 2)
                        ])
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["cash-pricing__pool-filter", { 'cash-pricing__pool-filter--active': _ctx.showSustainableCash }]),
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showSustainableCash = !_ctx.showSustainableCash))
                      }, [
                        _createTextVNode(_toDisplayString(_ctx.translations.showSustainableCash) + " ", 1),
                        _createElementVNode("div", {
                          onClick: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"]))
                        }, [
                          _createVNode(_component_icon, {
                            class: "icon",
                            name: "icn-info",
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openClosePopUp('showPopUpSCP')))
                          }),
                          _createElementVNode("div", {
                            class: _normalizeClass(["cash-pricing__pool-filter-popup", { 'cash-pricing__pool-filter-popup-active': _ctx.showPopUpSCP }])
                          }, [
                            _createVNode(_component_icon, {
                              name: "icn-cross",
                              class: "cash-pricing__pool-filter-popup-cross",
                              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openClosePopUp('showPopUpSCP')))
                            }),
                            _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.translations.showSCPricingDesc), 1),
                            _createElementVNode("a", {
                              class: "button button--borderless",
                              href: _ctx.translations.segregationMoreInfoLink,
                              target: "_blank"
                            }, _toDisplayString(_ctx.translations.segregationMoreInfo), 9, _hoisted_31)
                          ], 2)
                        ])
                      ], 2)
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_vit_tabs, {
                  mobile: "",
                  sliding: "",
                  "tabs-lenght": _ctx.groups.length,
                  class: _normalizeClass(["my-5 site-information__tabs", { 'site-information__tabs--sliding': _ctx.commodities.length > 5 }]),
                  onInput: _ctx.refreshPrices,
                  "content-class": _ctx.commodities.length > 5 ? 'site-information__tabs-content' : ''
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group) => {
                      return (_openBlock(), _createBlock(_component_vit_tab, {
                        key: `table-${group.commodity.cId}`,
                        title: group.commodity.cName,
                        capitalize: "",
                        narrow: "",
                        "fixed-width": ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.translations.lastRefresh) + ": " + _toDisplayString(_ctx.refreshDate), 1),
                          _createVNode(_component_icon, {
                            name: "icn-refresh",
                            class: _normalizeClass(["segregation-plan__refresh", { 'segregation-plan__refresh-loading': _ctx.loading }]),
                            onClick: _ctx.refreshPrices
                          }, null, 8, ["class", "onClick"]),
                          _createElementVNode("div", _hoisted_33, [
                            _createElementVNode("div", _hoisted_34, [
                              _createElementVNode("div", null, _toDisplayString(_ctx.translations.grade), 1),
                              _createElementVNode("div", null, _toDisplayString(_ctx.translations.price), 1)
                            ]),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.grades, (subgroup) => {
                              return (_openBlock(), _createBlock(_component_segregation_cash_table, {
                                key: `table-${subgroup.id}`,
                                group: subgroup,
                                "hide-group-title": "",
                                "show-empty-grades": "",
                                transparent: "",
                                translations: _ctx.translations
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(), _createElementBlock("div", {
                                    key: `${subgroup.id}-noprice`,
                                    class: "cash-pricing__no-price-grade-error"
                                  }, _toDisplayString(_ctx.translations.noPrices), 1))
                                ]),
                                _: 2
                              }, 1032, ["group", "translations"]))
                            }), 128))
                          ]),
                          _createElementVNode("div", {
                            class: "site-information__other-grades",
                            innerHTML: _ctx.otherGradesLinked
                          }, null, 8, _hoisted_35)
                        ]),
                        _: 2
                      }, 1032, ["title"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["tabs-lenght", "onInput", "class", "content-class"]),
                (_ctx.prices.length)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 3,
                      class: "ml-auto button d-flex",
                      onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.exportXlsx && _ctx.exportXlsx(...args)))
                    }, _toDisplayString(_ctx.translations.export), 1))
                  : _createCommentVNode("", true)
              ], 64))
            : (!_ctx.pricesLoaded)
              ? (_openBlock(), _createBlock(_component_loading_spinner, { key: 1 }))
              : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}
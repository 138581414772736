<template>
    <li class="nav-item main-navigation__item">
        <a
            :href="link"
            :target="target"
            class="nav-link main-navigation__item-link"
            :rel="'_blank' === target ? 'noopener noreferrer' : null"
            :class="{'active': isActive}">
            {{ title }}
        </a>
    </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        title: String,
        link: String,
        target: String,
        items: Array
    },
    inject: ['register'],

    mounted() {
        this.register(this);
    },

    computed: {
        isActive() {
            return window.location.pathname.startsWith(this.link.replace(/\/$/, ''));
        }
    }
});
</script>

<template>
    <div class="leadership">
        <vit-tabs v-model="activeTab">
            <vit-tab :title="tab1Label" class="leadership__tab">
                <div>
                    <slot name="board" />
                </div>

                <div v-if="activeMember">
                    <leadership-detail-view
                        :member="activeMember"
                        :members="members"
                        :menu-title="tab1Label"
                        :appointed-label="appointedLabel"
                        :experience-label="experienceLabel"
                        @member="openProfile" />
                </div>
                <div v-else class="leadership__department-row">
                    <div
                        v-for="member in members"
                        :key="member.uuid"
                        class="leadership-department__member leadership__member--image">
                        <img
                            v-if="member.smallImage"
                            class="leadership__member-image"
                            :src="member.smallImage"
                            :alt="member.name">
                        <icon v-else class="leadership__member-placeholder" name="icn-placeholder" />
                        <div class="leadership__member-info">
                            <div class="leadership__member-info-text">
                                <div class="leadership-department__member-name">
                                    {{ member.name }}
                                </div>
                                <div class="leadership-department__member-text">
                                    {{ member.job }}
                                </div>
                            </div>
                            <button class="button" @click="openProfile(member)">
                                {{ viewProfileLabel }}
                            </button>
                        </div>
                    </div>
                </div>
            </vit-tab>
            <vit-tab :title="tab2Label" class="leadership__tab">
                <div>
                    <slot name="management" />
                </div>
            </vit-tab>
        </vit-tabs>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LeadershipDetailView from './LeadershipDetailView.vue';
import Icon from './atoms/Icon.vue';
import VitTabs from './base/VitTabs.vue';
import VitTab from './base/VitTab.vue';

export default defineComponent({
    components: { VitTab, VitTabs, LeadershipDetailView, Icon },
    props: {
        members: { type: Array as () => Array<{uuid: string, name: string, job: string, smallImage: string}> },
        tab1Label: { type: String },
        tab2Label: { type: String },
        startTab: { type: Number, default: 0 },
        appointedLabel: { type: String, required: true },
        experienceLabel: { type: String, required: true },
        viewProfileLabel: { type: String, required: true }
    },
    data() {
        return {
            activeTab: 0,
            activeMember: null
        };
    },

    created() {
        this.activeTab = this.startTab;
    },

    methods: {
        openProfile(member) {
            this.activeMember = member;
        }
    },
    watch: {
        activeTab() {
            this.activeMember = null;
        }
    }
});

</script>

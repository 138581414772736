<template>
    <div
        class="file-upload"
        :class="{ 'file-upload--highlight': over }"
        @dragenter.prevent.stop="handleHover(true)"
        @dragover.prevent.stop="handleHover(true)"
        @dragleave.prevent.stop="handleHover(false)"
        @drop.prevent.stop="handleDrop">
        <slot :handle-files="onChange" :hide-label="!!file" />
        <div v-if="file" class="file-upload__file-list">
            <span>{{ file.name }}</span>
            <icon name="icn-cross" class="file-upload__delete-file" @click.native="removeFile" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from './Icon.vue';

export default defineComponent({
    components: { Icon },
    data() {
        return {
            over: false,
            file: null as File
        };
    },

    methods: {
        handleFiles(files: FileList) {
            this.file = files.item(0);
            this.input.files = this.fileList();
        },

        handleDrop(e: DragEvent) {
            if (!this.over) {
                return;
            }
            this.over = false;
            const dt = e.dataTransfer;
            const files = dt.files;

            this.handleFiles(files);
        },

        handleHover(hover: boolean) {
            this.over = hover && !this.file;
        },

        removeFile() {
            this.file = null;
            this.input.files = new DataTransfer().files;
        },

        fileList(): FileList {
            const dT = new DataTransfer();
            dT.items.add(this.file);
            return dT.files;
        },

        onChange() {
            this.handleFiles(this.input.files);
        }
    },

    computed: {
        input(): HTMLInputElement {
            return this.$el.querySelector('input');
        }
    }
});

</script>

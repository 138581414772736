import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "search-bar__container" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = { class: "search-bar__results" }
const _hoisted_4 = {
  key: 0,
  class: "search-bar__result-list search-bar__container"
}
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "search__anim" }, {
    default: _withCtx(() => [
      (_ctx.visible || _ctx.static)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["search-bar", { 'search-bar--static': _ctx.static, 'search-bar--container': _ctx.container }])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("form", {
                class: "search-bar__search-field",
                onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
              }, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  placeholder: _ctx.placeholder,
                  class: "search-bar__input",
                  ref: "input",
                  onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args))),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event))
                }, null, 40, _hoisted_2), [
                  [_vModelText, _ctx.value]
                ])
              ], 32),
              _createVNode(_component_icon, {
                name: "icn-search",
                class: "search-bar__icon",
                onClick: _ctx.toggle
              }, null, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.results.length > 0)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: `result#${result.id}`,
                        class: "search-bar__result",
                        onClick: ($event: any) => (_ctx.onResultClicked(result))
                      }, _toDisplayString(result.title), 9, _hoisted_5))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "default")
            ])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
<template>
    <div class="media-item" :class="{ 'article-teaser--loading': loading }">
        <div class="media-item__content">
            <template v-if="!loading">
                <div v-if="item.type === 'image'" class="media-item__image-wrapper">
                    <img
                        class="media-item__image"
                        v-if="item.mdImageUrl"
                        :src="item.mdImageUrl"
                        :alt="item.title">
                </div>
                <div v-else-if="item.type === 'video'" class="media-item__image-wrapper">
                    <img :src="videoImage" class="media-item__image" :alt="item.title">
                </div>
            </template>
            <div class="media-item__text-content">
                <div>
                    <div v-if="loading" class="media-item__loading-date" />
                    <div v-else class="media-item__meta-info">
                        {{ $date(item.date) }}
                        <span v-if="item.country"> | {{ item.country }}</span>
                        <span v-if="item.commodity"> | {{ item.commodity }}</span>
                        <span v-if="item.videoDuration"> | {{ item.videoDuration }}min</span>
                    </div>
                    <template v-if="loading">
                        <div class="article-teaser__text" />
                        <div class="article-teaser__text" />
                        <div class="article-teaser__text" />
                    </template>
                    <template v-else>
                        <b class="media-info__title">{{ item.title }}</b>
                        <div class="media-item__description" v-if="item.description">
                            <div class="media-item__text-long">
                                {{ $truncate(item.description) }}
                            </div>
                            <div class="media-item__text-short">
                                {{ $truncate(item.description, 50) }}
                            </div>
                        </div>
                        <a
                            v-if="downloadLink"
                            class="media-item__download"
                            :href="downloadLink"
                            download>
                            <icon name="icn-download" class="media-info__download-icon" />
                            <button class="button button--link">
                                {{ item.type === 'image' ? downloadLabel : gotoVideoLabel }}
                            </button>
                        </a>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Icon from './atoms/Icon.vue';

export interface IMediaItem {
    uuid: string;
    type: string;
    title: string;
    date: string;
    videoId?: string;
    mdImageUrl?: string;
    lgImageUrl?: string;
    country?: string;
    description?: string;
    commodity?: string;
    videoDuration?: string;
}

export default defineComponent({
    components: { Icon },
    props: {
        item: { type: Object as PropType<IMediaItem> },
        downloadLabel: { required: true, type: String },
        gotoVideoLabel: { required: true, type: String }

    },
    data() {
        return {
            loading: false
        };
    },

    computed: {
        videoImage() {
            if (!this.item.videoId) {
                return '';
            }
            return `https://img.youtube.com/vi/${this.item.videoId.replace(/&.+$/g, '')}/0.jpg`;
        },

        downloadLink() {
            if (this.item.type === 'video') {
                return `https://www.youtube.com/watch?v=${this.item.videoId}&rel=0`;
            }
            if (!this.item.lgImageUrl) {
                return '';
            }
            return this.item.lgImageUrl.replace('lg', 'original');
        }
    }
});

</script>

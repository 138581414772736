<template>
    <div class="spinner">
        <div class="spinner-border" role="status" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});

</script>

<template>
    <vit-slider>
        <vit-slide v-for="(job, index) in jobsList" :key="index" class="job-teaser-slide">
            <div class="job-teasers__fav">
                <label class="job-teasers__fav-label">
                    <input
                        type="checkbox"
                        :id="job.jobId"
                        :value="job.jobId"
                        v-model="favourites"
                        class="job-teasers__fav-input">
                    <icon name="icn-star" class="icon job-teasers__fav-icon" :class="[{'job-teasers__fav-icon-active': favourites.includes(job.jobId)}]" />
                </label>
            </div>
            <div class="job-teasers__content">
                <div class="job-teasers__title">
                    {{ job.title }}
                </div>
                <div class="job-teasers__info">
                    <div>{{ job.businessArea }}</div>
                    <div>{{ getCountry(job) }}</div>
                </div>
                <div class="job-teasers__descr">
                    <div class="job-teasers__descr__title">
                        {{ translations.jobDescription }}
                    </div>
                    <div v-html="job.descriptionAbstract" />
                </div>
                <a class="button job-teasers__button" :href="determineJobDetailsPageUrl(job.jobId)">{{ translations.goToJob }}</a>
            </div>
        </vit-slide>
    </vit-slider>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from './atoms/Icon.vue';
import Helper from '../lib/Helper';
import VitSlider from './base/VitSlider.vue';
import VitSlide from './base/VitSlide.vue';

const COOKIE_NAME = 'jobs_favourite';

export default defineComponent({
    components: { VitSlide, VitSlider, Icon },
    props: {
        jobsList: { type: Array as () => Array<{ jobId: string, title: string, businessArea: string, descriptionAbstract: string }>, required: true },
        detailsPageUrl: { type: String, required: true },
        translations: { type: Object as () => { [key: string]: string }, required: true }

    },
    data() {
        return {
            favourites: []
        };
    },
    created() {
        this.getFavouriteCookie();
    },
    methods: {
        determineJobDetailsPageUrl(jobId: string): string {
            return encodeURI(`${this.jobsList}/${jobId}`);
        },

        getFavouriteCookie() {
            const cookie = Helper.getCookie(COOKIE_NAME);
            if (cookie) {
                this.favourites = cookie.split(',');
            }
        },
        getCountry(job) {
            let location = '';
            if (job.locationCountry) {
                if (location !== '') {
                    location = this.concatenate(location, ', ');
                }
                location = this.concatenate(location, job.locationCountry);
            }
            if (job.locationTitle) {
                if (location !== '') {
                    location = this.concatenate(location, ', ');
                }
                location = this.concatenate(location, job.locationTitle);
            }
            return location;
        },
        concatenate(a, b) {
            return a + b;
        }
    },
    watch: {
        favourites() {
            Helper.setCookie(COOKIE_NAME, this.favourites.join(','));
        }
    }
});

</script>

import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "popover-header" }
const _hoisted_2 = { class: "popover-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (_ctx.visible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            "x-placement": "top",
            class: _normalizeClass(['popover', 'b-popover', 'bs-popover-top', _ctx.customClass]),
            style: _normalizeStyle(_ctx.stylePopover),
            ref: _ctx.target+'popup'
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_1, [
                _renderSlot(_ctx.$slots, "title")
              ]),
              _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "body")
              ])
            ]),
            _createElementVNode("div", {
              class: "arrow",
              style: _normalizeStyle(_ctx.styleArrow)
            }, null, 4)
          ], 6))
        : _createCommentVNode("", true)
    ]))
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "stage_map-map" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_google_map = _resolveComponent("google-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_google_map, {
      center: _ctx.mapCenter,
      markers: _ctx.mapMarkers,
      zoom: _ctx.zoom,
      "api-key": _ctx.apiKey,
      "static-map": ""
    }, null, 8, ["center", "markers", "zoom", "api-key"])
  ]))
}
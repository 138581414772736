<template>
    <div>
        <ul class="link-list__items" v-if="links">
            <li class="link-list__item" v-for="link in links" :key="link.id">
                <a class="button button--link" :href="url(link.id)" target="_blank">{{ link.name }}</a>
            </li>
        </ul>
        <div v-else-if="error" v-html="errorMessage" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
    props: {
        apiUrl: String,
        errorMessage: String
    },
    data() {
        return {
            links: null,
            error: false
        };
    },

    async created() {
        try {
            const { data } = await axios.get(this.apiUrl);
            this.links = data;
        } catch (e) {
            this.error = true;
            console.error(e.response ? e.response.message : e.message);
        }
    },

    methods: {
        url(id) {
            return this.apiUrl ? `${this.apiUrl.replace(/\/$/, '')}/${id}` : '';
        }
    }
});

</script>

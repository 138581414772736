<template>
    <div class="slide">
        <slot />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        date: String,
        text: String,
        link: String,
        linkLabel: String
    },
    inject: ['childRegistration'],

    mounted() {
        this.childRegistration(this);
    }
});

</script>

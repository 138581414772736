<template>
    <div>
        <Teleport to="body">
            <div
                v-if="visible"
                x-placement="top"
                :class="['popover', 'b-popover', 'bs-popover-top', customClass]"
                :style="stylePopover"
                :ref="target+'popup'">
                <div>
                    <h3 class="popover-header">
                        <slot name="title" />
                    </h3>
                    <div class="popover-body">
                        <slot name="body" />
                    </div>
                </div>
                <div class="arrow" :style="styleArrow" />
            </div>
        </Teleport>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useMyStore } from '../../store';

export default defineComponent({
    props: {
        target: { required: true, type: String },
        customClass: { default: '', type: String }
    },
    data() {
        return {
            elementTrigger: null as HTMLElement,
            visible: false,
            stylePopover: '',
            styleArrow: ''
        };
    },
    mounted() {
        this.elementTrigger = document.getElementById(this.target);
        if (this.elementTrigger) {
            this.elementTrigger.addEventListener('mouseenter', this.showPopover);
            this.elementTrigger.addEventListener('mouseleave', this.hidePopover);
            window.addEventListener('resize', this.styleUpdate);
        }
    },
    unmounted() {
        if (this.elementTrigger) {
            this.elementTrigger.removeEventListener('mouseenter', this.showPopover);
            this.elementTrigger.removeEventListener('mouseleave', this.hidePopover);
            window.removeEventListener('resize', this.styleUpdate);
        }
    },
    methods: {
        hidePopover() {
            this.visible = false;
        },
        showPopover() {
            this.styleUpdate();
            this.visible = true;
        },
        styleUpdate() {
            const width = this.elementTrigger.getBoundingClientRect().width / 2;
            const left = this.elementTrigger.getBoundingClientRect().left + width;
            const top = (this.elementTrigger.getBoundingClientRect().top) + (window.scrollY) - 10;
            this.stylePopover = `transform: translate3d(${this.isMobile ? 0 : left}px, ${top}px, 0px) translateY(-100%);`;

            this.styleArrow = `transform: translate3d(${this.isMobile ? left : 0}px, 0px, 0px)`;
        }
    },
    computed: {
        ...mapState(useMyStore, ['isMobile'])
    }
});

</script>

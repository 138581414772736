import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "cash-pricing__content" }
const _hoisted_2 = {
  key: 0,
  class: "cash-pricing__result-error-outer"
}
const _hoisted_3 = { class: "cash-pricing__result-error" }
const _hoisted_4 = { class: "cash-pricing__result-error-inner" }
const _hoisted_5 = {
  key: 0,
  class: "cash-pricing__content"
}
const _hoisted_6 = { class: "cash-pricing__site-heading" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "cash-pricing__no-price-site-error" }
const _hoisted_9 = {
  key: 1,
  class: "cash-pricing__content"
}
const _hoisted_10 = {
  key: 2,
  class: "cash-pricing__sticky-table"
}
const _hoisted_11 = { class: "table cash-pricing__table cash-pricing__table-ungrouped" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = {
  key: 1,
  class: "cash-pricing__result-error-outer"
}
const _hoisted_16 = { class: "cash-pricing__result-error" }
const _hoisted_17 = { class: "cash-pricing__result-error-inner" }
const _hoisted_18 = {
  key: 2,
  class: "cash-pricing__result-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cash_pricing_filter = _resolveComponent("cash-pricing-filter")!
  const _component_icon = _resolveComponent("icon")!
  const _component_segregation_cash_table = _resolveComponent("segregation-cash-table")!
  const _component_vit_tab = _resolveComponent("vit-tab")!
  const _component_vit_tabs = _resolveComponent("vit-tabs")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_cash_pricing_filter, {
        ref: "filter",
        "ws-base-url": _ctx.wsBaseUrl,
        translations: _ctx.translations,
        onCommodities: _ctx.onCommoditiesLoaded,
        onGrades: _ctx.onGradeSelected,
        onGroup: _ctx.onGroup,
        onPrices: _ctx.onPricesChanged,
        onSites: _ctx.onSiteSelected
      }, null, 8, ["ws-base-url", "translations", "onCommodities", "onGrades", "onGroup", "onPrices", "onSites"])
    ]),
    ((!_ctx.sites.length || !_ctx.grades.length) && !_ctx.pricesLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translations.noSelect), 1)
          ])
        ]))
      : (_ctx.pricesLoaded)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.groups.length)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.groupBy === _ctx.GroupOption.SITE)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: `${group.id}-info`
                          }, [
                            (_openBlock(), _createElementBlock("div", {
                              key: `${group.id}-title`
                            }, [
                              _createElementVNode("h4", _hoisted_6, _toDisplayString(group.title), 1),
                              _createElementVNode("button", {
                                class: "cash-pricing__deselect-group-btn",
                                onClick: ($event: any) => (_ctx.removeGroup(group.id))
                              }, [
                                _createVNode(_component_icon, {
                                  class: "icon",
                                  name: "icn-cross"
                                })
                              ], 8, _hoisted_7)
                            ])),
                            (group.commodities.length)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(group.commodities, (commodity) => {
                                  return (_openBlock(), _createBlock(_component_segregation_cash_table, {
                                    key: `${group.id}-${commodity.title}`,
                                    group: commodity,
                                    translations: _ctx.translations,
                                    onRemove: _ctx.removeGroup
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translations.noPriceSite), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["group", "translations", "onRemove"]))
                                }), 128))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]))
                    : (_ctx.groupBy === _ctx.GroupOption.COMMODITY)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createVNode(_component_vit_tabs, {
                            mobile: "",
                            sliding: "",
                            "tabs-lenght": _ctx.groups.length
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group) => {
                                return (_openBlock(), _createBlock(_component_vit_tab, {
                                  key: `table-${group.commodity.cId}`,
                                  title: group.commodity.cName,
                                  "fixed-width": "",
                                  capitalize: "",
                                  narrow: ""
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.grades, (subgroup) => {
                                      return (_openBlock(), _createBlock(_component_segregation_cash_table, {
                                        key: `table-${subgroup.id}`,
                                        group: subgroup,
                                        translations: _ctx.translations,
                                        onRemove: _ctx.removeGroup
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(), _createElementBlock("div", {
                                            key: `${subgroup.id}-noprice`,
                                            class: "cash-pricing__no-price-grade-error"
                                          }, _toDisplayString(_ctx.translations.noPriceGrade), 1))
                                        ]),
                                        _: 2
                                      }, 1032, ["group", "translations", "onRemove"]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1032, ["title"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["tabs-lenght"])
                        ]))
                      : (_ctx.groupBy === _ctx.GroupOption.NONE)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createElementVNode("table", _hoisted_11, [
                              _createElementVNode("thead", null, [
                                _createElementVNode("tr", null, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.SortOptionNoGrouping, (th) => {
                                    return (_openBlock(), _createElementBlock("th", {
                                      key: th,
                                      onClick: ($event: any) => (_ctx.sortTable(th))
                                    }, [
                                      _createTextVNode(_toDisplayString(_ctx.translations[th]) + " ", 1),
                                      _createVNode(_component_icon, {
                                        class: _normalizeClass(`cash-pricing__sort-arrow cash-pricing__sort-arrow-${_ctx.getSortDir(th)}`),
                                        name: "icn-sort-arrow"
                                      }, null, 8, ["class"])
                                    ], 8, _hoisted_12))
                                  }), 128))
                                ])
                              ]),
                              _createElementVNode("tbody", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (price) => {
                                  return (_openBlock(), _createElementBlock("tr", {
                                    key: price.id
                                  }, [
                                    _createElementVNode("td", null, _toDisplayString(price.site.sName), 1),
                                    _createElementVNode("td", null, _toDisplayString(price.commodity.cName), 1),
                                    _createElementVNode("td", null, _toDisplayString(price.grade.gName), 1),
                                    _createElementVNode("td", null, _toDisplayString(price.owner.oDescription), 1),
                                    (price.sellInfo.isPool)
                                      ? (_openBlock(), _createElementBlock("td", _hoisted_13, _toDisplayString(price.price), 1))
                                      : (_openBlock(), _createElementBlock("td", _hoisted_14, " $" + _toDisplayString(price.price.toFixed(2)), 1)),
                                    _createElementVNode("td", null, _toDisplayString(price.sellInfo.sOptionDescription), 1),
                                    _createElementVNode("td", null, _toDisplayString(price.sellInfo.pTermDescription), 1)
                                  ]))
                                }), 128))
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.translations.noResults), 1)
                  ])
                ]))
          ], 64))
        : (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _createVNode(_component_loading_spinner)
          ]))
  ]))
}
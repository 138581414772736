import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.numPages > 1)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["pagination", { 'pagination--highlight': _ctx.highlight }])
      }, [
        (_ctx.rangeSelectionStart >= _ctx.rangeSelection)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["pagination-item", { 'pagination-item-active': _ctx.numPages === (_ctx.modelValue + 1)}]),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectFirst && _ctx.selectFirst(...args)))
            }, " 1 ", 2))
          : _createCommentVNode("", true),
        (_ctx.rangeSelectionStart >= _ctx.rangeSelection)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "pagination-dots",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.rangeBackward && _ctx.rangeBackward(...args)))
            }, "..."))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numPages, (i) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            key: `page#${i}`,
            class: _normalizeClass(["pagination-item", { 'pagination-item-active' : i === _ctx.modelValue }]),
            onClick: ($event: any) => (_ctx.$emit('update:modelValue', i))
          }, _toDisplayString(i), 11, _hoisted_1)), [
            [_vShow, i >= _ctx.rangeSelectionStart && i <= _ctx.rangeSelectionEnd]
          ])
        }), 128)),
        (_ctx.rangeSelectionEnd < _ctx.numPages && _ctx.rangeSelectionEnd !== _ctx.numPages - 1)
          ? (_openBlock(), _createElementBlock("span", {
              key: 2,
              class: "pagination-dots",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.rangeForward && _ctx.rangeForward(...args)))
            }, "..."))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["pagination-item", { 'pagination-item-active': _ctx.numPages === _ctx.modelValue}]),
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.selectLast && _ctx.selectLast(...args)))
        }, _toDisplayString(_ctx.numPages), 3)
      ], 2))
    : _createCommentVNode("", true)
}
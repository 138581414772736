import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "contracts-box_buttons" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "contracts-box-mb-30" }
const _hoisted_6 = { class: "contracts-box_title" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "contracts-box_buttons" }
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gav_form = _resolveComponent("gav-form")!
  const _component_gav_results = _resolveComponent("gav-results")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.resultsPage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_gav_form, {
            commodities: _ctx.commodities,
            "deliveries-date": _ctx.deliveriesDate,
            "price-headline": _ctx.priceHeadline,
            "involved-client": _ctx.involvedClient,
            "form-translations": _ctx.formTranslations,
            "locations-link": _ctx.locationsLink,
            "form-selection": _ctx.formSelection,
            "required-test": _ctx.formChecked,
            onFormSelection: _ctx.onFormSelection
          }, null, 8, ["commodities", "deliveries-date", "price-headline", "involved-client", "form-translations", "locations-link", "form-selection", "required-test", "onFormSelection"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: "button",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.seeResults && _ctx.seeResults(...args)))
            }, _toDisplayString(_ctx.buttonsTranslations.showContracts), 1),
            (_ctx.professionalLink && _ctx.buttonsTranslations.speakProfessional)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "button button--borderless",
                  href: _ctx.professionalLink,
                  target: "_blank",
                  rel: "noopener noreferrer"
                }, _toDisplayString(_ctx.buttonsTranslations.speakProfessional), 9, _hoisted_3))
              : _createCommentVNode("", true)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "button button--borderless button--reverse contracts-backbutton",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.backForm && _ctx.backForm(...args)))
          }, _toDisplayString(_ctx.buttonsTranslations.backNeeds), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.resultsTranslations.title), 1),
            (!_ctx.resultsShowAll)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "contracts-box_subtitle",
                  innerHTML: _ctx.resultsSentence()
                }, null, 8, _hoisted_7))
              : _createCommentVNode("", true),
            _createVNode(_component_gav_results, {
              class: "more-space",
              "results-translations": _ctx.resultsTranslations,
              "results-labels": _ctx.resultsLabels,
              "show-all": _ctx.resultsShowAll,
              "contracts-cash": _ctx.filterContractsCash,
              "contracts-manage": _ctx.filterContractsManage,
              "contracts-structure": _ctx.filterContractsStructure,
              "contracts-link": _ctx.contractsLink
            }, null, 8, ["results-translations", "results-labels", "show-all", "contracts-cash", "contracts-manage", "contracts-structure", "contracts-link"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            (!_ctx.resultsShowAll && _ctx.buttonsTranslations.viewAllContracts)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "button",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showAllContracts && _ctx.showAllContracts(...args)))
                }, _toDisplayString(_ctx.buttonsTranslations.viewAllContracts), 1))
              : _createCommentVNode("", true),
            (_ctx.professionalLink && _ctx.buttonsTranslations.speakProfessional)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  class: "button button--borderless",
                  href: _ctx.professionalLink,
                  target: "_blank",
                  rel: "noopener noreferrer"
                }, _toDisplayString(_ctx.buttonsTranslations.speakProfessional), 9, _hoisted_9))
              : _createCommentVNode("", true)
          ])
        ]))
  ]))
}
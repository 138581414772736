<template>
    <div class="map__container">
        <div ref="map" class="map" :class="{ 'map--large': large }" />
        <div class="map__zoom-controls" v-if="!staticMap">
            <button
                class="map__zoom-btn"
                :disabled="mapZoom >= MAX_ZOOM"
                @click="mapZoom++"
                aria-label="zoom in">
                <icon name="icn-plus" class="map__zoom-btn-icon" />
            </button>
            <button
                class="map__zoom-btn"
                :disabled="mapZoom === MIN_ZOOM"
                @click="mapZoom--"
                aria-label="zoom out">
                <icon name="icn-minus" class="map__zoom-btn-icon" />
            </button>
        </div>
    </div>
</template>

<script lang="ts">
// note: markRaw disable reactivity system
import { defineComponent, markRaw } from 'vue';
import { MarkerClusterer, SuperClusterOptions } from '@googlemaps/markerclusterer';
import { Loader } from '@googlemaps/js-api-loader';
import Icon from '../atoms/Icon.vue';

export default defineComponent({
    components: { Icon },
    props: {
        markers: { default: () => [], type: Array as () => Array<{ id: string, location: { lat: number, lng: number }, icon?: string, [propName: string]: any }> },
        large: { default: false, type: Boolean },
        zoomToBounds: { default: false, type: Boolean },
        showAll: { default: false, type: Boolean },
        apiKey: { required: true, type: String },
        staticMap: { default: false, type: Boolean },
        center: { default: () => ({ lat: 25, lng: 0 }), type: Object as () => { lat: number; lng: number } },
        zoom: { default: 15, type: Number }
    },
    data() {
        return {
            // base of map variables
            loader: null as Loader,
            google: null,
            apiMap: null as typeof google.maps,
            map: null as google.maps.Map,
            mapRef: null,
            // markers
            allMarkers: [],
            markersOnMap: [],
            // clusters
            clusterer: null as object,
            // map aux
            mapZoom: this.zoom,
            mapCenter: this.center,
            MIN_ZOOM: 2,
            MAX_ZOOM: 18,
            // pop up
            popup: null
        };
    },

    created() {
        // load google maps
        this.loadMapsApi();
    },

    async mounted() {
        this.mapRef = this.$refs.map;
        // create map
        this.google = await this.loader.load();
        this.initMap();
    },

    methods: {
        /*
         * Init Map functions
         */
        // load google maps instance
        loadMapsApi() {
            try {
                this.loader = new Loader({
                    apiKey: this.apiKey,
                    version: 'weekly'
                });
            } catch (err) {
                // Loader instantiated again with different options, which isn't allowed by js-api-loader
                console.error(err);
            }
        },
        // inicialize map
        initMap() {
            this.apiMap = markRaw(this.google.maps);
            this.map = markRaw(new this.google.maps.Map(this.mapRef, this.resolveOptions()));
            // see when is inicilized
            this.google.maps.event.addListenerOnce(this.map, 'idle', () => {
                this.$el.getElementsByTagName('iframe')[0].title = 'Google Maps';
            });
            // get zoom from map
            this.map.addListener('zoom_changed', () => {
                this.mapZoom = this.map.getZoom();
            });

            // init clusters - clusters only needed if have more than 1 location
            if (this.markers && this.markers.length > 1) {
                this.setClusterMarkers();
            }
            // get locations at set them to markers
            if (this.markers && this.markers.length && this.markersOnMap.length === 0) {
                this.setMarkers();
            }
        },

        // map design and start options
        resolveOptions(): google.maps.MapOptions {
            const options = {
                center: this.getCenter(),
                zoom: this.getZoom(),
                maxZoom: this.MAX_ZOOM,
                disableDefaultUI: true,
                draggable: !this.staticMap,
                styles: [
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [{ color: '#e9e9e9' }, { lightness: 17 }]
                    }, {
                        featureType: 'landscape',
                        elementType: 'geometry',
                        stylers: [{ color: '#f5f5f5' }, { lightness: 20 }]
                    }, {
                        featureType: 'road.highway',
                        elementType: 'geometry.fill',
                        stylers: [{ color: '#ffffff' }, { lightness: 17 }]
                    }, {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }]
                    }, {
                        featureType: 'road.arterial',
                        elementType: 'geometry',
                        stylers: [{ color: '#ffffff' }, { lightness: 18 }]
                    }, {
                        featureType: 'road.local',
                        elementType: 'geometry',
                        stylers: [{ color: '#ffffff' }, { lightness: 16 }]
                    }, {
                        featureType: 'poi',
                        elementType: 'geometry',
                        stylers: [{ color: '#f5f5f5' }, { lightness: 21 }]
                    }, {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [{ color: '#dedede' }, { lightness: 21 }]
                    }, {
                        elementType: 'labels.text.stroke',
                        stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }]
                    }, {
                        elementType: 'labels.text.fill',
                        stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }]
                    }, { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] }, {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [{ color: '#f2f2f2' }, { lightness: 19 }]
                    }, {
                        featureType: 'administrative',
                        elementType: 'geometry.fill',
                        stylers: [{ color: '#fefefe' }, { lightness: 20 }]
                    }, {
                        featureType: 'administrative',
                        elementType: 'geometry.stroke',
                        stylers: [{ color: '#dddddd' }, { lightness: 17 }, { weight: 1.2 }]
                    }
                ]
            } as google.maps.MapOptions;
            return { ...options };
        },
        /*
         * Map functions
         */
        // get center based on markers or passed one if zoomToBounds
        getCenter(): google.maps.LatLngLiteral {
            if (!this.zoomToBounds) {
                return this.mapCenter || { lat: 25, lng: 0 };
            }
            return {
                lat: this.markers.reduce((a, b) => a + b.location.lat, 0) / this.markers.length,
                lng: this.markers.reduce((a, b) => a + b.location.lng, 0) / this.markers.length
            };
        },

        // get zoom based on markers or passed one if zoomToBounds
        getZoom(): number {
            if (!this.zoomToBounds) {
                return this.mapZoom;
            }
            // get bounds
            const bounds = new this.google.maps.LatLngBounds();
            this.markers.forEach(p => {
                bounds.extend(new this.google.maps.LatLng(p.location.lat, p.location.lng));
            });
            // get zoom based on marker bounds
            this.mapZoom = this.getBoundsZoomLevel(bounds);
            return this.mapZoom;
        },

        // get zoom based on marker bounds
        getBoundsZoomLevel(bounds: google.maps.LatLngBounds) {
            const WORLD_DIM = { height: 256, width: 256 };
            const ZOOM_MAX = 6;

            const latRad = lat => {
                const sin = Math.sin((lat * Math.PI) / 180);
                const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
                return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
            };

            const zoom = (mapPx, worldPx, fraction) => Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);

            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();

            const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

            const lngDiff = ne.lng() - sw.lng();
            const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

            const latZoom = zoom((this.mapRef).clientHeight, WORLD_DIM.height, latFraction);
            const lngZoom = zoom((this.mapRef).clientWidth, WORLD_DIM.width, lngFraction);

            return Math.min(latZoom, lngZoom, ZOOM_MAX);
        },
        positionChanged() {
            if (this.map) {
                this.map.panTo(this.mapCenter);
            }
        },
        /*
         * Cluster functions
         */
        setClusterMarkers() {
            if (!this.map || !this.apiMap || this.clusterer) {
                return;
            }
            this.clusterer = markRaw(new MarkerClusterer({
                map: this.map,
                algorithmOptions: { radius: 120 } as SuperClusterOptions,
                renderer: {
                    render: ({ markers, position }) =>
                        new google.maps.Marker({
                            icon: {
                                size: new this.google.maps.Size(50, 50),
                                anchor: new this.google.maps.Point(30, 30),
                                url: `${this.$resourcePath}img/marker-general.svg`
                            },
                            position: {
                                lat: position.lat(),
                                lng: position.lng()
                            },
                            title: 'Multiple locations',
                            label: {
                                text: String(markers.length),
                                color: 'white'
                            }
                        })
                }
            }));
        },

        /*
         * Markers functions
         */
        // transform all locations in markers, all locations are added to map
        setMarkers() {
            if (!this.map || !this.apiMap) {
                return;
            }
            this.markers.forEach(marker => {
                const { lat, lng } = marker.location;
                const title = marker.location.title ? marker.location.title : marker.description;
                const gMarker = new this.google.maps.Marker({
                    position: { lat, lng },
                    map: this.map,
                    id: marker.id,
                    title: title ? `Location ${title}` : 'Location',
                    icon: {
                        size: new this.google.maps.Size(30, 30),
                        anchor: new this.google.maps.Point(15, 15),
                        url: marker.iconURL || `${this.$resourcePath}img/marker-default.png`
                    }
                });
                if (!this.staticMap) {
                    this.google.maps.event.addListener(gMarker, 'click', () => {
                        this.$emit('marker-click', gMarker.id);
                    });
                }
                this.allMarkers.push(markRaw(gMarker));
                this.markersOnMap.push(markRaw(gMarker));
            });
            // add all locations to clusterer
            if (this.clusterer) {
                this.clusterer.addMarkers(this.markersOnMap);
            }
        },
        // add or remove marker
        setMarkerVisibility(id, visible = true) {
            let marker = null;
            if (visible) {
                marker = this.allMarkers.find(x => x.id === id);
                if (marker && !this.markersOnMap.includes(marker)) {
                    marker.setMap(this.map);
                    this.markersOnMap.push(marker);
                    if (this.clusterer) {
                        this.clusterer.addMarker(marker);
                    }
                }
            } else {
                marker = this.allMarkers.find(x => x.id === id);
                if (marker && this.markersOnMap.includes(marker)) {
                    marker.setMap(null);
                    this.markersOnMap = this.markersOnMap.filter(element => element !== marker);
                    if (this.clusterer) {
                        this.clusterer.removeMarker(marker);
                    }
                }
            }
        },

        hideMarker(id) {
            this.setMarkerVisibility(id, false);
        },

        showMarker(id) {
            this.setMarkerVisibility(id, true);
        },
        /*
         * Pop up functions
         */
        showInfoBox(id, content) {
            const marker = this.markersOnMap.find(x => x.id === id);
            if (!marker) {
                return;
            }
            if (this.popup) {
                this.popup.setMap(null);
                this.popup = null;
            }
            this.popup = this.openMapPopup(this.map, marker, content);
        },
        // obs: {@code google.maps} namespace is asynchronously loaded
        openMapPopup(_map: google.maps.Map, _marker: google.maps.Marker, _content: string) {
            /* eslint max-classes-per-file: ["error", 2] */
            class MapPopup extends google.maps.OverlayView {
                position: google.maps.LatLng;
                containerDiv: HTMLDivElement;
                constructor(marker: google.maps.Marker, content: HTMLElement) {
                    super();
                    this.position = marker.getPosition();

                    content.classList.add('map-popup');

                    // This zero-height div is positioned at the bottom of the bubble.
                    const bubbleAnchor = document.createElement('div');
                    bubbleAnchor.classList.add('map-popup__anchor');
                    bubbleAnchor.appendChild(content);

                    // This zero-height div is positioned at the bottom of the tip.
                    this.containerDiv = document.createElement('div');
                    this.containerDiv.classList.add('map-popup__container');
                    this.containerDiv.appendChild(bubbleAnchor);

                    const closeButton = document.createElement('div');
                    closeButton.classList.add('map-popup__close-btn');
                    content.appendChild(closeButton);
                    closeButton.addEventListener('click', () => {
                        this.setMap(null);
                    });

                    // Optionally stop clicks, etc., from bubbling up to the map.
                    MapPopup.preventMapHitsAndGesturesFrom(this.containerDiv);
                }

                /** Called when the popup is added to the map. */
                onAdd() {
                    this.getPanes().floatPane.appendChild(this.containerDiv);
                }

                /** Called when the popup is removed from the map. */
                onRemove() {
                    if (this.containerDiv.parentElement) {
                        this.containerDiv.parentElement.removeChild(this.containerDiv);
                    }
                }

                /** Called each frame when the popup needs to draw itself. */
                draw() {
                    const divPosition = this.getProjection().fromLatLngToDivPixel(
                        this.position
                    );

                    // Hide the popup when it is far out of view.
                    const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                        ? 'block'
                        : 'none';

                    if (display === 'block') {
                        this.containerDiv.style.left = `${divPosition.x}px`;
                        this.containerDiv.style.top = `${divPosition.y}px`;
                    }

                    if (this.containerDiv.style.display !== display) {
                        this.containerDiv.style.display = display;
                    }
                }
            }
            const div = document.createElement('div');
            div.innerHTML = _content.trim();
            const htmlContent = div.firstChild as HTMLElement;

            const popup = new MapPopup(_marker, htmlContent);
            popup.setMap(_map);
            return popup;
        }
    },

    watch: {
        // update center
        center: {
            handler(newVal) {
                this.mapCenter = newVal;
                this.$emit('update:mapCenter', newVal);
            },
            deep: true
        },
        mapCenter() {
            this.positionChanged();
        },
        // update zoom
        zoom(newVal) {
            this.$emit('update:mapZoom', newVal);
            this.mapZoom = this.zoom;
        },
        mapZoom(newVal) {
            if (this.map) {
                this.map.setZoom(newVal);
            }
        }
    }
});

</script>

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "contracts-results_box-grid-cat" }
const _hoisted_2 = { class: "contracts-results_box-title" }
const _hoisted_3 = { class: "contracts-results_box-category" }
const _hoisted_4 = { class: "contracts-results_box-tagline" }
const _hoisted_5 = { class: "contracts-results_box-grid-lg2col" }
const _hoisted_6 = { class: "contracts-results_box-subtitle" }
const _hoisted_7 = { class: "contracts-results_box-grid-2col" }
const _hoisted_8 = { class: "contracts-results_box-subtitle" }
const _hoisted_9 = { class: "contracts-results_box-tagline" }
const _hoisted_10 = { class: "contracts-results_box-subtitle" }
const _hoisted_11 = { class: "contracts-results_box-subtitle-info" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "contracts-results_box-tagline" }
const _hoisted_14 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_vit_tab = _resolveComponent("vit-tab")!
  const _component_vit_tabs = _resolveComponent("vit-tabs")!

  return (_openBlock(), _createBlock(_component_vit_tabs, {
    mobile: "",
    sliding: "",
    "tabs-lenght": _ctx.completeResults.length
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.completeResults, (result) => {
        return (_openBlock(), _createBlock(_component_vit_tab, {
          key: `table-${result.resultLabel.id}`,
          title: result.resultLabel.label,
          disabled: result.contracts.length < 1,
          "content-width": true,
          narrow: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(result.contracts, (contract) => {
              return (_openBlock(), _createElementBlock("div", {
                key: contract.id,
                class: "contracts-results_box"
              }, [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(contract.name), 1),
                  _createElementVNode("div", _hoisted_3, _toDisplayString(contract.category), 1)
                ]),
                _createElementVNode("div", _hoisted_4, _toDisplayString(contract.tagline), 1),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.resultsTranslations.planFeatures), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(contract.features, (feature) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: feature.substring(0, 4),
                        class: "contracts-results_box-feature"
                      }, [
                        _createVNode(_component_icon, {
                          class: "contracts-results_box-feature-icon",
                          name: "icn-plus"
                        }),
                        _createElementVNode("div", null, _toDisplayString(feature), 1)
                      ]))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.resultsTranslations.cashDelivery), 1),
                      _createElementVNode("div", _hoisted_9, _toDisplayString(contract.delivery), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_10, [
                        _createTextVNode(_toDisplayString(_ctx.resultsTranslations.priceRange) + " ", 1),
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_icon, {
                            class: "contracts-results_box-subtitle-icon",
                            name: "icn-info"
                          }),
                          _createElementVNode("div", {
                            class: "contracts-results_box-subtitle-message",
                            innerHTML: _ctx.resultsTranslations.feeMessage
                          }, null, 8, _hoisted_12)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_13, _toDisplayString(contract.cost), 1)
                    ])
                  ])
                ]),
                (_ctx.contractsLink)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      class: "button button--borderless contracts-results_box-button",
                      href: _ctx.generateAnchorUrl(contract.anchor),
                      target: "_blank",
                      rel: "noopener noreferrer"
                    }, _toDisplayString(_ctx.resultsTranslations.learnMore), 9, _hoisted_14))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["title", "disabled"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["tabs-lenght"]))
}
<template>
    <div>
        <div v-if="showPrivacyPolicy" class="form__privacy">
            <input type="checkbox" v-model="privacyPolicyAccepted">
            <span class="form__privacy-text"><slot /></span>
        </div>
        <div class="form__submit">
            <vue-recaptcha
                v-if="!isDev"
                ref="captcha"
                :sitekey="siteKey"
                load-recaptcha-script
                @verify="onVerified" />
            <button
                type="submit"
                :disabled="!((verified || isDev) && isFilled && (!showPrivacyPolicy || privacyPolicyAccepted))"
                class="button form__submit-btn"
                @submit.prevent="onSubmit">
                {{ label }}
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VueRecaptcha } from 'vue-recaptcha';

export default defineComponent({
    components: { VueRecaptcha },
    props: {
        label: { required: true, type: String },
        siteKey: { required: true, type: String },
        siteName: { required: true, type: String },
        isFilled: { required: false, type: Boolean, default: true },
        showPrivacyPolicy: { required: false, type: Boolean, default: false }
    },
    data() {
        return {
            verified: false,
            form: null as HTMLFormElement,
            privacyPolicyAccepted: false
        };
    },

    created() {
        this.verified = this.isDev;
    },
    mounted() {
        // logic from directive v-prevent-submit
        let parent = this.$el.parentNode;
        while (parent && parent.tagName !== 'FORM') {
            parent = parent.parentNode;
        }
        if (parent) {
            this.form = parent;
            this.form.realSubmit = this.form.submit;
            this.form.submit = () => {
                if ((this.verified || this.isDev) && (!this.showPrivacyPolicy || this.privacyPolicyAccepted)) {
                    this.form.realSubmit();
                }
            };
        }
    },

    methods: {
        /**
         * According to Google, the recaptcha response has to be verified by the backend.
         * https://developers.google.com/recaptcha/docs/verify#api-request
         *
         * @see 'com.viterra.web.form.processors.ReCaptchaV2Validator'
         */
        onVerified() {
            this.verified = true;
        },
        onSubmit(event) {
            if ((this.verified || this.isDev) && (!this.showPrivacyPolicy || this.privacyPolicyAccepted)) {
                event.target.submit();
            }
        }
    },

    computed: {
        isDev() {
            return this.siteKey === '---no-recaptcha---';
        }
    }
});

</script>

<template>
    <div v-if="show" class="redirect-popup">
        <div class="redirect-popup__container">
            <div class="redirect-popup__inner">
                <div class="redirect-popup__bunge-logo" :style="logoStyle" />
                <button class="redirect-popup__close-btn" @click="show = false">
                    <icon name="icn-cross" class="redirect-popup__close-btn-icon" />
                </button>
                <div class="redirect-popup__text redirect-popup__abstract" v-html="headline" />
                <div class="redirect-popup__text" v-html="text" />
                <div class="redirect-popup__buttons">
                    <button v-show="showCurrentWebsiteButton" class="button button-bunge redirect-popup__button" @click="show = false">
                        {{ currentWebsiteButtonLabel }}
                    </button>
                    <a class="button  button-bunge redirect-popup__button" :href="linkUrl" :target="linkTarget">{{ linkLabel }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from './atoms/Icon.vue';

export default defineComponent({
    components: { Icon },
    props: {
        editMode: { default: false, type: Boolean },
        headline: { required: true, type: String },
        text: { required: true, type: String },
        showCurrentWebsiteButton: { default: false, type: Boolean },
        currentWebsiteButtonLabel: { required: true, type: String },
        linkUrl: { required: true, type: String },
        linkTarget: { required: true, default: '_blank', type: String },
        linkLabel: { required: true, type: String },
        logoUrl: String
    },
    data() {
        return {
            show: true
        };
    },

    computed: {
        logoStyle() {
            const styles = {};
            if (this.logoUrl) {
                styles['--logo'] = `url(${this.logoUrl})`;
            }
            return styles;
        }
    }
});

</script>

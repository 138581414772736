import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "file-upload__file-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["file-upload", { 'file-upload--highlight': _ctx.over }]),
    onDragenter: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.handleHover(true)), ["prevent","stop"])),
    onDragover: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.handleHover(true)), ["prevent","stop"])),
    onDragleave: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.handleHover(false)), ["prevent","stop"])),
    onDrop: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args)), ["prevent","stop"]))
  }, [
    _renderSlot(_ctx.$slots, "default", {
      handleFiles: _ctx.onChange,
      hideLabel: !!_ctx.file
    }),
    (_ctx.file)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(_ctx.file.name), 1),
          _createVNode(_component_icon, {
            name: "icn-cross",
            class: "file-upload__delete-file",
            onClick: _ctx.removeFile
          }, null, 8, ["onClick"])
        ]))
      : _createCommentVNode("", true)
  ], 34))
}
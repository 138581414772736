<template>
    <div class="meta-navigation" :class="{ 'meta-navigation--light': light }">
        <template v-for="(item, i) in items.filter(x => x.title)">
            <a
                v-if="item.navigationType === 'META'"
                :key="`metanav#${i}`"
                :href="item.link"
                class="meta-navigation__item">
                {{ item.title }}
            </a>
            <button
                :key="`metanav#${i}`"
                v-if="item.navigationType === 'GLOBAL'"
                @click="$emit('open-overlay-nav');"
                class="meta-navigation__item">
                {{ item.title }}
            </button>
        </template>
        <div class="meta-navigation__langs" v-if="langItems.length > 1">
            <a
                v-for="lang in langItems"
                :key="`lang#${lang.label}`"
                :href="lang.link"
                class="meta-navigation__lang"
                :class="{ 'meta-navigation__lang-active': lang.active }">{{ getLabel(lang) }}</a>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        items: Array,
        langItems: Array,
        light: { default: false, type: Boolean }
    },

    methods: {
        getLabel(langitem) {
            switch (langitem.label) {
                case 'KK':
                    return 'KZ';
                case 'UK':
                    return 'UA';
                default:
                    return langitem.label;
            }
        }
    }
});
</script>

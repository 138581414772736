import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "icon-teasers" }
const _hoisted_2 = { class: "icon-teasers__items" }
const _hoisted_3 = { class: "icon-teasers__active-item" }
const _hoisted_4 = { class: "icon-teasers__active-item-text" }
const _hoisted_5 = {
  key: 0,
  class: "icon-teasers__active-item-text-active"
}
const _hoisted_6 = {
  key: 1,
  class: "icon-teasers__active-item-text-active"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.activeSection)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.activeSection.text), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.placeholderText), 1))
      ])
    ])
  ]))
}
/* eslint-disable no-unused-vars */
// this file have API calls and export file used in diferent segregation components
import axios, { CancelToken, CancelTokenSource } from 'axios';
import flatten from 'lodash/flatten';
import chunk from 'lodash/chunk';
import ExcelExport from './ExcelExport';

const COMMODITIES = '/Commodity';
const GRADES = '/Commodities/%s/Grades?$orderby=GradeRank';
const GRADESPRICES = '/ActivePurchaseOption?$filter=';
const PRICES_SELECT = '&$select=Id,PaymentCategory,PaymentTermCode,PaymentTermDescription,IsSustainable,OwnerId,Owner,OwnerDescription,SiteId,SiteDescription,CommodityId,CommodityDescription,GradeId,Grade,GradeRank,SellingOptionType,SellingOptionDescription,Price';

const SITES = '/Sites';

const SITE_FILTER = '(SiteDescription eq \'%s\')';
const BASIC_FILTERS = ['IsEzigrainBuyer eq true', 'IsGrowerBuyer eq true'];
const EXCLUDE_POOL_PRICE_FILTER = 'PaymentCategory ne \'Pool\'';

const SITE_GRADES = '/SiteGrade?$filter=';
const GRADES_SELECT = '&$select=PrimaryGradeId,PrimaryGrade,GradeRank,SiteId,CommodityId,CommodityCode';

const SITES_ORDER = '/Sites?$orderby=Description';
const SITES_BY_REGION = '/Sites/GeographicalRegion/';

// only to get values
interface SiteDetails {
    message0: null
    status0: string,
    status1: string,
    status2: string,
    time0: string,
    time1: string
}
interface GradePrices {
    commodityCode: string,
    commodityId: string,
    grade: string,
    gradeId: string,
    gradeRank: number,
    paymentCategory: string,
    price: number,
    sellingOptionType: 'DC' | 'SC' | 'EP',
    siteId: string
}

// NEW ONES
interface OwnerInfo {
    oId: string;
    oName: string;
    oDescription: string;
}

export enum GroupOption {
    SITE = 'site',
    COMMODITY = 'commodity',
    NONE = 'none'
}

export interface GradePricesInfo {
    commodityCode: string,
    commodityId: string,
    grade: string,
    gradeId: string,
    gradeRank: number,
    paymentCategory: string,
    price: number,
    sellingOptionType: 'DC' | 'SC' | 'EP',
    siteId: string
}

export enum Region {
    CENTRAL = 'Central',
    EASTERN = 'Eastern',
    WESTERN = 'Western'
}

export interface SiteInfo {
    sId: string;
    sName: string;
    uuid?: string;
    sDescription?: string;
    sRegion?: Region;
    sCode?: string;
    sMessage?: string;
    openingHoursToday?: string;
    openingHoursTomorrow?: string;
    currentStatus?: string,
}

export interface GradeInfo {
    gId: string;
    gName: string;
    gRank: number;
    gPrices?: {
        price: number;
        sellingOptionType: 'DC' | 'SC' | 'EP';
    }[];
}

export interface CommodityInfo {
    cId: string;
    cName: string;
    grades?: GradeInfo[];
    cDescription?: string;
    available?: boolean;
}
export interface PriceLineInfo {
    site: SiteInfo;
    gradePrices: GradeInfo[];
}

export interface CommodityPricesInfo {
    commodity: CommodityInfo;
    allGrades: GradeInfo[];
    priceLines: PriceLineInfo[];
}

export interface PriceInfo {
    pId: string,
    price: number;
    owner: OwnerInfo;
    site: SiteInfo;
    commodity: CommodityInfo;
    grade: GradeInfo;
    gRank: number;
    sellInfo: {
        isPool: boolean;
        isExtendedPaymentTerm: boolean;
        isSustainable: boolean
        pTermDescription: string;
        pCategory: string;
        sOptionDescription: string;
    };
}

export interface SiteDetailInfo {
    sId: string,
    sName: string,
    currentStatus: string,
    currentTime: string,
    tomorrowStatus?: string,
    tomorrowTime?: string,
    days?: [{ status: string, time: string}],
    openingHoursToday?: string,
    openingHoursTomorrow?: string,
    manager?: string,
    phone?: string,
    managerMobile?: string,
    message: string
}

export interface GradePoolInfo {
    id: string;
    title: string;
    max: string;
    prices: PriceInfo[];
}
// used at cashpricing, segregationcashtable
export enum SortOptionBase {
    BUYER = 'buyer',
    PRICE = 'price',
    PRICE_TYPE = 'priceType',
    PAYMENT_TERMS = 'payment',
}

export enum SortOption {
    BUYER = 'buyer',
    PRICE = 'price',
    PRICE_TYPE = 'priceType',
    PAYMENT_TERMS = 'payment',
    SITE = 'site',
    COMMODITY = 'commodity',
    GRADE = 'grade'
}

export enum SortOptionNoGrouping {
    SITE = 'site',
    COMMODITY = 'commodity',
    GRADE = 'grade',
    BUYER = 'buyer',
    PRICE = 'price',
    PRICE_TYPE = 'priceType',
    PAYMENT_TERMS = 'payment'
}

// used at cashpricing, segregationcashtable
export enum SortDir { ASC, DESC, DEFAULT }

// interfaces

// cashpricing only use the first 2
export interface Site {
    id: string;
    name: string;
    uuid?: string;
    description?: string;
    region?: Region;
    code?: string;
    status?: string;
    openingHoursToday?: string;
    openingHoursTomorrow?: string;
    siteMessage?: string;
    // only for seg details
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    lat?: string;
    lng?: string;
}

export default class Segregation {
    public static IGNORE_SITE_NAMES: string[] = [
        'COWELL', 'MANGALO', 'MURDINGA', 'GULNARE', 'MALLALA', 'MELROSE', 'TARLEE', 'TWO WELLS', 'COOMANDOOK', 'MURRAY BRIDGE',
        'PARILLA', 'ORROROO', 'WUNKAR', 'MINNIPA', 'REDHILL', 'ALAWOONA', 'KYANCUTTA', 'BRINKWORTH', 'WALPEUP', 'WADDIKEE', 'ROBERTSTOWN',
        'MILLICENT', 'KIELPA', 'PASKEVILLE', 'WHARMINDA', 'LONG PLAINS', 'STOCKWELL', 'LARA', 'OUTER HARBOR', 'NEWCASTLE'
    ];

    static cancelTokenSourceSiteGrades: CancelTokenSource[] = [];
    static cancelTokenSourceSiteGradesInfo: CancelTokenSource[] = [];
    static cancelTokenSourceSitesPrices: CancelTokenSource[] = [];

    /*
     * Auxiliar funcions
     */

    // remove / at the end of the url if exists
    public static checkBaseUrl(baseurl: string) {
        return baseurl.endsWith('/') ? baseurl.slice(0, -1) : baseurl;
    }

    public static formatStatus(status: string) {
        return status.toLowerCase() === 'open' || status.toLowerCase() === 'closed'
            ? `${status}`
            : status.replace(/([a-z])([A-Z])/g, (_, $0, $1) => `${$0} ${$1.toLowerCase()}`);
    }

    private static getCommodityName(allCommodities, info): string {
        const commodity = allCommodities.find(c => c.cId === info.commodityId);
        if (commodity && commodity.cName) return commodity.cName;
        return info.commodityCode;
    }
    /*
     * Fetch functions
     */
    // get grade by commodity
    private static async fetchGradeByCommodity(baseurl: string, commodityId: string): Promise<{grades: GradeInfo[], gradesStatus: number}> {
        try {
            const cts = axios.CancelToken.source();
            const res = await axios.get(`${this.checkBaseUrl(baseurl)}${GRADES.replace('%s', commodityId)}`, { cancelToken: cts.token });
            if (res.status === 200) {
                const grades = res.data.map(({ grade, gradeId, gradeRank }) => ({
                    gId: gradeId,
                    gName: grade,
                    gRank: gradeRank
                })).sort((a, b) => a.gRank - b.gRank);
                return { grades, gradesStatus: 200 };
            }
            return { grades: [], gradesStatus: res.status };
        } catch (e) {
            if (e.response && e.response.status) {
                return { grades: [], gradesStatus: e.response.status };
            }
            return { grades: [], gradesStatus: 0 };
        }
    }

    // get al commodities
    public static async fetchCommodities(baseurl: string): Promise<{commodities: CommodityInfo[], commoditiesStatus: number}> {
        try {
            const cts = axios.CancelToken.source();
            const res = await axios.get(`${this.checkBaseUrl(baseurl)}${COMMODITIES}`, { cancelToken: cts.token });
            if (res.status === 200) {
                const commodities = res.data.map(({ commodityId, commodity }) => ({
                    cId: commodityId,
                    cName: commodity
                }))
                    // exclude Rye, Chickpeas, Soybean and Oats. FIXME: can be removed after API has been updated
                    .filter(x =>
                        x.cId !== 'f2151f4d-3d82-4736-9bcc-ab87001bb3dc' // Oats
                        && x.cId !== '2ed5cd11-3387-4f79-9214-a913018356be' // Chickpeas
                        && x.cId !== 'dc2a010a-30c2-4520-b348-a9b201250817' // Rye
                        && x.cId !== 'ef251356-fe4a-42f3-ad8c-a913018356be') // Soybean
                    // sort wheat first
                    .sort((a, b) =>
                        (a.cName.toLowerCase() === 'wheat' ? -1 : b.cName.toLowerCase() === 'wheat' ? 1 : (a.cName < b.cName ? -1 : 0)));
                return { commodities, commoditiesStatus: 200 };
            }
            return { commodities: [], commoditiesStatus: res.status };
        } catch (e) {
            if (e.response && e.response.status) {
                return { commodities: [], commoditiesStatus: e.response.status };
            }
            return { commodities: [], commoditiesStatus: 0 };
        }
    }
    // get all sites
    public static async fetchSites(baseurl: string): Promise<{sites: SiteInfo[], sitesStatus: number}> { // previous getAllSites
        try {
            const cts = axios.CancelToken.source();
            const res = await axios.get(`${this.checkBaseUrl(baseurl)}${SITES_ORDER}`, { cancelToken: cts.token });
            if (res.status === 200) {
                const sites = res.data
                    .map(({ id, site }) => ({ sId: id, sName: site }))
                    .filter(site => this.IGNORE_SITE_NAMES.findIndex(s => s.toLowerCase() === site.sName.toLowerCase()) < 0);
                return { sites, sitesStatus: 200 };
            }
            return { sites: [], sitesStatus: 200 };
        } catch (e) {
            if (e.response && e.response.status) {
                return { sites: [], sitesStatus: e.response.status };
            }
            return { sites: [], sitesStatus: 400 };
        }
    }
    // site details
    public static async fetchSiteDetails(baseurl: string, sId: string): Promise<{siteDetail: SiteDetailInfo, siteDetailStatus: number}> {
        try {
            const cts = axios.CancelToken.source();
            const res = await axios.get(`${this.checkBaseUrl(baseurl)}${SITES}/${sId}`, { cancelToken: cts.token });
            if (res.status === 200 && res.data && res.data[0]) {
                const siteDetails = res.data.map(({ id, site, status0, time0, status1, time1, status2, time2, status3, time3, status4, time4, status5, time5, status6, time6, manager, phone, managerMobile, message0 }) => ({
                    sId: id,
                    sName: site,
                    currentStatus: status0,
                    currentTime: time0,
                    days: [
                        { status: status1, time: time1 },
                        { status: status2, time: time2 },
                        { status: status3, time: time3 },
                        { status: status4, time: time4 },
                        { status: status5, time: time5 },
                        { status: status6, time: time6 }
                    ],
                    openingHoursToday: status0.toLowerCase() === 'open' ? time0 : status0,
                    openingHoursTomorrow: status1.toLowerCase() === 'open' ? time1 : status1,
                    manager,
                    phone,
                    managerMobile,
                    message: message0
                }));
                return { siteDetail: siteDetails[0], siteDetailStatus: 200 };
            }
            return { siteDetail: null, siteDetailStatus: 0 };
        } catch (e) {
            if (e.response && e.response.status) {
                return { siteDetail: null, siteDetailStatus: e.response.status };
            }
            return { siteDetail: null, siteDetailStatus: 0 };
        }
    }

    // get grade Prices by sites
    public static async fetchGradePrices(baseurl:string, filters: string[]): Promise<{prices: PriceInfo[], pricesStatus: number}> {
        try {
            const cts = axios.CancelToken.source();
            const res = await axios.get(`${this.checkBaseUrl(baseurl)}${GRADESPRICES}${filters.filter(x => x.length).join(' and ')}${PRICES_SELECT}`, { cancelToken: cts.token });
            if (res.status === 200) {
                const prices = res.data.map(x => ({
                    pId: x.id,
                    price: x.paymentCategory.toLowerCase() === 'pool' ? 0 : parseFloat(x.price),
                    owner: { oId: x.ownerId, oName: x.owner, oDescription: x.ownerDescription } as OwnerInfo,
                    site: { id: x.siteId, name: x.siteDescription } as Site,
                    commodity: { cId: x.commodityId, cName: x.commodityDescription } as CommodityInfo,
                    grade: { gId: x.gradeId, gName: x.grade, gRank: x.gradeRank } as GradeInfo,
                    gRank: x.gradeRank,
                    sellInfo: {
                        isPool: x.paymentCategory.toLowerCase() === 'pool',
                        isExtendedPaymentTerm: x.paymentCategory.toLowerCase() === 'cash' && x.sellingOptionType.toLowerCase() === 'ep',
                        isSustainable: x.isSustainable,
                        pTermDescription: x.paymentTermDescription,
                        pCategory: x.paymentCategory ? x.paymentCategory.toLowerCase() : '',
                        sOptionDescription: x.sellingOptionDescription
                    }
                })).sort((a, b) => {
                    if (a.sellInfo.pCategory !== b.sellInfo.pCategory) {
                        return a.sellInfo.pCategory > b.sellInfo.pCategory ? 1 : -1;
                    }
                    if (a.gRank !== b.gRank) {
                        return a.gRank - b.gRank;
                    }
                    return 0;
                });
                return { prices, pricesStatus: 200 };
            }
        } catch (e) {
            if (e.response && e.response.status) {
                return { prices: [], pricesStatus: e.response.status };
            }
        }
        return { prices: [], pricesStatus: 0 };
    }

    // get site (only one) grades without prices
    static async fetchSiteGrades(baseurl: string, siteName: string, noPrice: string, allCommodities: CommodityInfo[]): Promise<{ siteNoPrices: PriceInfo[], siteNoPricesStatus }> {
        try {
            const cts = axios.CancelToken.source();
            const filters = [SITE_FILTER.replace('%s', siteName.toUpperCase())];
            const url = this.checkBaseUrl(baseurl) + SITE_GRADES + filters + GRADES_SELECT;
            const res = await axios.get(url, { cancelToken: cts.token });
            if (res.status === 200) {
                const siteNoPrices = res.data.map(x => ({
                    pId: null,
                    price: noPrice,
                    owner: { oId: '', oName: '', oDescription: '' },
                    site: { sId: x.siteId, sName: '' },
                    commodity: { cId: x.commodityId, cName: this.getCommodityName(allCommodities, x) },
                    grade: { gId: x.primaryGradeId, gName: x.primaryGrade, gRank: x.gradeRank },
                    gRank: x.gradeRank,
                    sellInfo: {
                        isPool: true,
                        isExtendedPaymentTerm: false,
                        isSustainable: false,
                        pTermDescription: '',
                        sOptionDescription: ''
                    }
                }));
                return { siteNoPrices, siteNoPricesStatus: 200 };
            }
        } catch (e) {
            if (e.response && e.response.status) {
                return { siteNoPrices: [], siteNoPricesStatus: e.response.status };
            }
        }
        return { siteNoPrices: [], siteNoPricesStatus: 0 };
    }

    /*
     * fetch and combine or aux fetch
     */
    public static async getRegionSites(baseurl: string, allSites: SiteInfo[], region: Region): Promise<number> {
        try {
            const res = await axios.get(`${Segregation.checkBaseUrl(baseurl)}${SITES_BY_REGION}${region}`);
            if (res.status === 200 && res.data.length > 0) {
                const sitesRegion = res.data
                    .map(({ id, description, code }) => {
                        if (description) {
                            return { id, name: description.toLowerCase(), code };
                        }
                        return { id, name: '' };
                    })
                    .filter(site => Segregation.IGNORE_SITE_NAMES.findIndex(is => is.toLowerCase() === site.sName) < 0);

                // Associate the region for each site
                sitesRegion.forEach((siteForRegion: Site) => {
                    const site: SiteInfo = allSites.find((s: SiteInfo) => s.sId === siteForRegion.id);
                    if (site) {
                        site.sRegion = region;
                        site.sCode = siteForRegion.code;
                    }
                });
            }
            return 200;
        } catch (e) {
            if (e.response && e.response.status) {
                return e.response.status;
            }
            return 400;
        }
    }

    // get details by site of every site
    public static async getSitesDetails(baseurl: string, sites: SiteInfo[]): Promise<number> {
        const promises: Promise<number>[] = [];

        for (const site of sites) {
            promises.push(
                (async () => {
                    try {
                        const res = await axios.get(`${Segregation.checkBaseUrl(baseurl)}${SITES}/${site.sId}`);
                        if (res.status === 200 && res.data && res.data[0]) {
                            const siteDetails = res.data[0] as SiteDetails;
                            // Update site properties based on siteDetails
                            site.currentStatus = siteDetails.status0;
                            site.openingHoursToday = siteDetails.status0.toLowerCase() === 'open'
                                ? siteDetails.time0
                                : siteDetails.status0;
                            site.openingHoursTomorrow = siteDetails.status1.toLowerCase() === 'open'
                                ? siteDetails.time1
                                : siteDetails.status1;
                            site.sMessage = siteDetails.message0;
                        } else {
                            site.sMessage = 'Error getting opening hours';
                        }
                        return 200;
                    } catch (e) {
                        if (e.response && e.response.status) {
                            return e.response.status;
                        }
                        return 400;
                    }
                })()
            );
        }

        // Wait for all promises to be resolved
        const results = await Promise.all(promises);
        const errorStatus = results.find(status => status !== 200) ? results.find(status => status !== 200) : 200;
        return errorStatus;
    }

    // get sites grades without prices - used for seg plan
    public static async fetchSitesGrades(baseurl: string, searchSites: SiteInfo[]): Promise<CommodityPricesInfo[]> {
        const getSiteGrades = async sites => {
            const filters = `(${sites.map(s => SITE_FILTER.replace('%s', s.sName.toUpperCase())).join(' or ')})`;
            const url = Segregation.checkBaseUrl(baseurl) + SITE_GRADES + filters + GRADES_SELECT;
            const cts = axios.CancelToken.source();
            this.cancelTokenSourceSiteGrades.push(cts);
            try {
                const res = await axios.get(url, { cancelToken: cts.token });
                if (res.status === 200) {
                    return res.data;
                }
            } catch (e) {
                if (e.response && e.response.status) {
                    return [];
                }
            }
            return [];
        };

        if (searchSites.length) {
            // chunk selected sites into arrays of 5
            const chunks = chunk(searchSites, 5);
            // cancel any running requests
            this.cancelTokenSourceSiteGrades.forEach(cts => {
                cts.cancel();
            });
            this.cancelTokenSourceSiteGrades = [];
            // make one request per chunk and wait for all requests
            const siteGrades = await Promise.all(chunks.map(getSiteGrades));
            // combine results
            return this.mapSiteGrades(flatten(siteGrades), searchSites);
        }
        return [];
    }

    // organise sites grades
    static mapSiteGrades(data: any[], searchSites: SiteInfo[]): CommodityPricesInfo[] {
        const prices: CommodityPricesInfo[] = data.reduce((accumulator: CommodityPricesInfo[], current: any) => {
            const grade: GradeInfo = {
                gId: current.primaryGradeId,
                gName: current.primaryGrade,
                gRank: current.gradeRank,
                gPrices: []
            };
            const site: SiteInfo = searchSites.find(x => x.sId === current.siteId);

            // Since all prices are fetched at once, regardless of selected sites, it could be that the site is
            // not found for the current price line: if so, ignore this line and skip to the next one.
            if (!site) { return accumulator; }

            const priceLine: PriceLineInfo = { site, gradePrices: Array.of(grade) };
            const existingCommodityPrices: CommodityPricesInfo = accumulator.find((cp: CommodityPricesInfo) => cp.commodity.cId === current.commodityId);

            if (existingCommodityPrices) {
                // Store (unique!) grade names for table header
                if (!existingCommodityPrices.allGrades.find(gr => gr.gId === grade.gId)) {
                    existingCommodityPrices.allGrades.push(grade);
                }
                const existingSitePriceLine: PriceLineInfo = existingCommodityPrices.priceLines.find((pl: PriceLineInfo) => pl.site.sId === current.siteId);
                if (existingSitePriceLine) {
                    // add/overwrite prices
                    existingSitePriceLine.gradePrices.push(grade);
                } else {
                    existingCommodityPrices.priceLines.push(priceLine);
                }
            } else {
                // add new CommodityPrices for commodityId
                accumulator.push({
                    commodity: {
                        cId: current.commodityId,
                        cName: current.commodityCode
                    },
                    allGrades: Array.of(grade),
                    priceLines: Array.of(priceLine)
                });
            }
            return accumulator;
        }, []);

        prices.forEach((commodityPrices: CommodityPricesInfo) => {
            // sort grades by rank
            commodityPrices.allGrades.sort((gr1: GradeInfo, gr2: GradeInfo) => gr1.gRank - gr2.gRank);
            // sort sites by name
            commodityPrices.priceLines.sort((prl1: PriceLineInfo, prl2: PriceLineInfo) => (prl1.site.sName < prl2.site.sName ? -1 : 1));
        });

        return prices;
    }

    // get sites prices
    public static async fetchSitesPrices(baseurl: string, searchSites: SiteInfo[]): Promise<GradePrices[]> {
        // get prices for an array of sites
        const getSiteGrades = async sites => {
            const filters: string[] = [];
            filters.push(`(${sites.map(s => SITE_FILTER.replace('%s', s.sName.toUpperCase())).join(' or ')})`);
            filters.push(...BASIC_FILTERS);
            const url: string = Segregation.checkBaseUrl(baseurl) + GRADESPRICES + filters.filter(x => x.length).join(' and ') + PRICES_SELECT;
            const cts = axios.CancelToken.source();
            this.cancelTokenSourceSitesPrices.push(cts);
            try {
                const res = await axios.get(url, { cancelToken: cts.token });
                if (res.status === 200) {
                    return res.data;
                }
            } catch (e) {
                if (e.toString() === 'Cancel') {
                    return Promise.reject(e);
                }
                // ignore cancelled requests
                if (e.response && e.response.status) {
                    return e.response.status;
                }
                return 400;
            }
            return 200;
        };
        if (searchSites.length) {
            // chunk selected sites into arrays of 5
            const chunks = chunk(searchSites, 5);
            // cancel any running requests
            this.cancelTokenSourceSitesPrices.forEach(cts => {
                cts.cancel();
            });
            this.cancelTokenSourceSitesPrices = [];
            // make one request per chunk and wait for all requests
            const siteGrades = await Promise.all(chunks.map(getSiteGrades));
            return flatten(siteGrades);
        }
        return [];
    }

    // get sites prices
    public static async fetchSitesPricesInfo(baseurl: string, searchSites: SiteInfo[]): Promise<PriceInfo[]> {
        // get prices for an array of sites
        const getSiteGrades = async sites => {
            const filters: string[] = [];
            filters.push(`(${sites.map(s => SITE_FILTER.replace('%s', s.sName.toUpperCase())).join(' or ')})`);
            filters.push(...BASIC_FILTERS);
            const url: string = Segregation.checkBaseUrl(baseurl) + GRADESPRICES + filters.filter(x => x.length).join(' and ') + PRICES_SELECT;
            const cts = axios.CancelToken.source();
            this.cancelTokenSourceSiteGrades.push(cts);
            try {
                const res = await axios.get(url, { cancelToken: cts.token });
                if (res.status === 200) {
                    return res.data.map(x => ({
                        pId: x.id,
                        price: x.paymentCategory.toLowerCase() === 'pool' ? 0 : parseFloat(x.price),
                        owner: { oId: x.ownerId, oName: x.owner, oDescription: x.ownerDescription },
                        site: { sId: x.siteId, sName: x.siteDescription },
                        commodity: { cId: x.commodityId, cName: x.commodityDescription },
                        grade: { gId: x.gradeId, gName: x.grade, gRank: x.gradeRank },
                        gRank: x.gradeRank,
                        sellInfo: {
                            isPool: x.paymentCategory.toLowerCase() === 'pool',
                            isExtendedPaymentTerm: x.paymentCategory.toLowerCase() === 'cash' && x.sellingOptionType.toLowerCase() === 'ep',
                            isSustainable: x.isSustainable,
                            pTermDescription: x.paymentTermDescription,
                            sOptionDescription: x.sellingOptionDescription,
                            sOptionType: x.sellingOptionType,
                            pCategory: x.paymentCategory ? x.paymentCategory.toLowerCase() : '',
                            pTermCode: x.paymentTermCode
                        }
                    }));
                }
            } catch (e) {
                if (e.toString() === 'Cancel') {
                    return Promise.reject(e);
                }
                // ignore cancelled requests
                if (e.response && e.response.status) {
                    return e.response.status;
                }
                return 400;
            }
            return 200;
        };
        if (searchSites.length) {
            // chunk selected sites into arrays of 5
            const chunks = chunk(searchSites, 5);
            // cancel any running requests
            this.cancelTokenSourceSiteGradesInfo.forEach(cts => {
                cts.cancel();
            });
            this.cancelTokenSourceSiteGradesInfo = [];
            // make one request per chunk and wait for all requests
            const siteGrades = await Promise.all(chunks.map(getSiteGrades));
            return flatten(siteGrades);
        }
        return [];
    }

    /*
     * Combine or filter fetch functions
     */
    // combine commodities with grades - used at segplan
    public static async getCommoditiesWithGrades(baseurl: string): Promise<{commodities: CommodityInfo[], commoditiesStatus: number, allGradeStatus: number}> {
        // get all commodities
        const { commodities, commoditiesStatus } = await this.fetchCommodities(baseurl);
        let allGradeStatus = 200;
        // get grades for each commodity
        const promises = commodities.map(async commodity => {
            const { grades, gradesStatus } = await this.fetchGradeByCommodity(baseurl, commodity.cId);
            commodity.grades = grades;
            if (gradesStatus !== 200) {
                allGradeStatus = gradesStatus;
            }
        });
        // wait to get all data
        await Promise.all(promises);
        return { commodities, commoditiesStatus, allGradeStatus };
    }

    // get filtered prices of site - used at getSiteGradePricesFilter
    public static async getGradePricesFilter(baseurl:string, sName: string, showPoolOptions: boolean): Promise<{sitePrices: PriceInfo[], sitePricesStatus: number}> {
        const filters = [SITE_FILTER.replace('%s', sName.toUpperCase()), ...BASIC_FILTERS];
        if (!showPoolOptions) {
            filters.push(EXCLUDE_POOL_PRICE_FILTER);
        }
        const { prices, pricesStatus } = await this.fetchGradePrices(baseurl, filters);
        return { sitePrices: prices, sitePricesStatus: pricesStatus };
    }

    // get all grades with prices - used at seg-detail
    public static async getSiteGradePricesFilter(baseurl:string, sName: string, showPoolOptions: boolean, noPrice: string, allCommodities: CommodityInfo[]): Promise<{prices: PriceInfo[], sitePricesStatus: number, siteNoPricesStatus: number}> {
        let join = true;
        const { sitePrices, sitePricesStatus } = await this.getGradePricesFilter(baseurl, sName, showPoolOptions);
        const { siteNoPrices, siteNoPricesStatus } = await this.fetchSiteGrades(baseurl, sName, noPrice, allCommodities);

        siteNoPrices.forEach(element => {
            if (sitePrices.find(element2 => element.grade.gId === element2.grade.gId)) {
                join = false;
            }
            if (join) {
                sitePrices.push(element);
            }
            join = true;
        });
        const prices = sitePrices.filter(element => !!siteNoPrices.find(element2 => element.grade.gId === element2.grade.gId))
            .sort((a, b) => (a.gRank - b.grade.gRank));
        return { prices, sitePricesStatus, siteNoPricesStatus };
    }

    // get sites with all info - used at seg-plan
    public static async getSitesComplete(baseurl: string): Promise<{ sites: SiteInfo[], sitesStatus: number, sitesRegionStatus: number, sitesDetailsStatus: number }> {
        const regions: Region[] = [
            Region.CENTRAL,
            Region.EASTERN,
            Region.WESTERN
        ];
        const { sites, sitesStatus } = await this.fetchSites(baseurl);

        // regions
        const siteRegionPromises = regions.map(region => this.getRegionSites(baseurl, sites, region));
        const sitesByRegion = await Promise.all(siteRegionPromises);
        const sitesRegionStatus = sitesByRegion.some(regionError => regionError !== 200) ? sitesByRegion.find(regionError => regionError !== 200) : 200;

        // details
        const sitesDetailsStatus = await this.getSitesDetails(baseurl, sites);
        return { sites, sitesStatus, sitesRegionStatus, sitesDetailsStatus };
    }

    public static async fetchCommoditiesPrices(baseurl: string, searchSites: SiteInfo[]): Promise<CommodityPricesInfo[]> {
        const commoditiesSites = await this.fetchSitesGrades(baseurl, searchSites);
        const gradePrices = await this.fetchSitesPrices(baseurl, searchSites);
        // join prices to all grade commodities
        const commoditiesPrices: CommodityPricesInfo[] = commoditiesSites.map((commodityPrice: CommodityPricesInfo) => ({
            ...commodityPrice,
            priceLines: commodityPrice.priceLines.map((priceLine: PriceLineInfo) => ({
                ...priceLine,
                gradePrices: priceLine.gradePrices.map((gradePrice: GradeInfo) => {
                    // find price for segregation
                    const gPrices = gradePrices.filter(
                        x => x.gradeId === gradePrice.gId
                            && x.siteId === priceLine.site.sId
                            && x.gradeRank === gradePrice.gRank
                    );
                    if (gPrices.length) {
                        return { ...gradePrice, gPrices: gPrices.map(p => ({ price: p.price, sellingOptionType: p.sellingOptionType })) };
                    }
                    return gradePrice;
                })
            }))
        }));
        return commoditiesPrices;
    }

    /*
     * Exports
     */
    public static exportPricesInfoToXlsx(translations: { [key: string]: string }, prices: PriceInfo[], fileName: string) {
        const exportData = [
            [
                translations.site,
                translations.commodity,
                translations.grade,
                translations.buyer,
                translations.price,
                translations.priceType,
                translations.payment
            ].map(s => ({
                v: s,
                t: 's'
            }))
        ];
        prices.forEach((price: PriceInfo) => {
            exportData.push([
                price.site.sName,
                price.commodity.cName,
                price.grade.gName,
                price.owner.oDescription,
                typeof price.price === 'number' ? price.price.toFixed(2) : price.price,
                price.sellInfo.sOptionDescription,
                price.sellInfo.pTermDescription
            ] as any);
        });
        ExcelExport.export(exportData, fileName);
    }

    public static exportCommodityPricesXlsx(translations:{ [key: string]: string }, commodities: CommodityInfo[], commodityPrices: CommodityPricesInfo[]) {
        const sites: { site: SiteInfo, commodities: { commodity: CommodityInfo, prices: GradeInfo[], name: string }[] }[] = [];
        commodityPrices.forEach((cp: CommodityPricesInfo) => {
            cp.priceLines.forEach((pl: PriceLineInfo) => {
                const site = { ...sites.find(s => s.site.sId === pl.site.sId) };
                const priceCommodity = (commodities.find(cd => cd.cId === cp.commodity.cId) as CommodityInfo);
                if (site.commodities) {
                    const commodity: { commodity: CommodityInfo, prices: GradeInfo[] } = site.commodities.find(c => c.commodity.cId === cp.commodity.cId);
                    if (commodity) {
                        commodity.prices.push(...pl.gradePrices);
                    } else if (priceCommodity && priceCommodity.cName) {
                        site.commodities.push({ commodity: cp.commodity, prices: pl.gradePrices, name: priceCommodity.cName });
                    } else {
                        site.commodities.push({ commodity: cp.commodity, prices: pl.gradePrices, name: '' });
                    }
                } else if (priceCommodity && priceCommodity.cName) {
                    sites.push({ site: pl.site, commodities: [{ commodity: cp.commodity, prices: pl.gradePrices, name: priceCommodity.cName }] });
                } else {
                    sites.push({ site: pl.site, commodities: [{ commodity: cp.commodity, prices: pl.gradePrices, name: '' }] });
                }
            });
        });
        sites.sort((a, b) => (a.site.sName < b.site.sName ? -1 : 1));

        const exportData = [
            [
                translations.site,
                translations.siteCode,
                translations.commodityCode,
                translations.grade,
                translations.price
            ].map(s => ({
                v: s || '',
                t: 's'
            }))
        ];
        exportData.push(...sites.reduce(
            (arr, s) =>
                [...arr, ...s.commodities.reduce(
                    (arr2, c) =>
                        [
                            ...arr2,
                            ...c.prices.map(p =>
                                [
                                    s.site.sName.toUpperCase(),
                                    s.site.sCode,
                                    c.name,
                                    p.gName,
                                    p.gPrices.reduce((m, v) => (v.price > (m as unknown as number) ? v.price : m), '')
                                ])
                        ],
                    []
                )],
            []
        ));

        ExcelExport.export(exportData, 'Viterra_Segregation_Plan');
    }
}

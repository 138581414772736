/* eslint-disable dot-notation,no-param-reassign */

// detect Bootstrap breakpoints
class BsBreakpoints {
    constructor() {
        if (typeof window['CustomEvent'] === 'function') return;
        function CustomEvent(event, params) {
            params = params || { bubbles: false, cancelable: false, detail: null };
            const evt = document.createEvent('CustomEvent');
            evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
            return evt;
        }
        // @ts-ignore
        window['CustomEvent'] = CustomEvent;
    }

    breakPoints = {
        xSmall: {
            min: 0,
            max: 575
        },
        small: {
            min: 576,
            max: 767
        },
        medium: {
            min: 768,
            max: 991
        },
        large: {
            min: 992,
            max: 1199
        },
        xLarge: {
            min: 1200,
            max: Infinity
        }
    };
    breakPointsDetected = false;
    currentBreakpoint = null;
    EventName = 'bs.breakpoint';

    listeners = [];

    private getBreakPoints() {
        const documentStyle = window.getComputedStyle(document.documentElement);
        const minSmall = parseInt(documentStyle.getPropertyValue('--breakpoint-sm'));
        const minMedium = parseInt(documentStyle.getPropertyValue('--breakpoint-md'));
        const minLarge = parseInt(documentStyle.getPropertyValue('--breakpoint-lg'));
        const minXlarge = parseInt(documentStyle.getPropertyValue('--breakpoint-xl'));

        this.breakPoints.xSmall.max = minSmall - 1; // update small

        this.breakPoints.small.min = minSmall;
        this.breakPoints.small.max = minMedium - 1; // update medium

        this.breakPoints.medium.min = minMedium;
        this.breakPoints.medium.max = minLarge - 1; // update large

        this.breakPoints.large.min = minLarge;
        this.breakPoints.large.max = minXlarge - 1; // update XL

        this.breakPoints.xLarge.min = minXlarge;

        this.breakPointsDetected = true;
    }

    private detectBreakPoint() {
        const widthWindow = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

        for (const key in this.breakPoints) {
            if (widthWindow <= (this.breakPoints)[key].max && widthWindow >= (this.breakPoints)[key].min) {
                return key;
            }
        }

        return this.currentBreakpoint;
    }

    private dispatchBreakpoint(breakPointKey) {
        if (!this.currentBreakpoint || this.currentBreakpoint !== breakPointKey) {
            this.currentBreakpoint = breakPointKey;
            const event = new CustomEvent(this.EventName, {
                detail: breakPointKey
            });
            window.dispatchEvent(event);
            // no send the breakpoint
            this.notifyListeners(breakPointKey);
        }
    }

    init() {
        this.getBreakPoints();
        this.dispatchBreakpoint(this.detectBreakPoint());
        window.addEventListener('resize', () => {
            this.dispatchBreakpoint(this.detectBreakPoint());
        });
    }

    detectBreakpoint() {
        if (!this.breakPointsDetected) {
            this.getBreakPoints();
        }

        this.currentBreakpoint = this.detectBreakPoint();
        return this.currentBreakpoint;
    }

    getCurrentBreakpoint() {
        return this.currentBreakpoint;
    }

    // listeners functions - to send new info to main and then pinia
    registerListener(listener) {
        this.listeners.push(listener);
    }
    private notifyListeners(breakPointKey) {
        this.listeners.forEach(listener => listener(breakPointKey));
    }
}

export default new BsBreakpoints();

<template>
    <div @scroll.prevent>
        <!-- START NAVBAR JOBS -->
        <div class="job-overview__secondheader">
            <div class="job-overview__secondheader-filter">
                <button :class="filtersOn? 'button button--cross' : 'button button--filter'" @click="filtersOpenClose">
                    {{ filtersOn ? translations.closeFilter : filtersCountUsed ? translations.filter + ' (' + filtersCountUsed + ')' : translations.filter
                    }}
                </button>
                <button v-if="filtersUsed && !filtersOn" class="button button--link" @click="cleanFilters">
                    {{ translations.cleanFilter }}
                </button>
            </div>
            <div>
                <div class="job-overview__secondheader-sortby">
                    <p class="job-overview__secondheader-sortby-text">
                        {{ translations.sortBy }}
                    </p>
                    <!-- START SORT BY -->
                    <vit-dropdown :label="sortBy ? sortBy: 'Relevance' " rounded light>
                        <dropdown-item
                            v-for="(sort, index) in sortByOptions"
                            :key="index"
                            @click="sortBy = sortByOptions[index].text"
                            :class="{ 'dropdown__item-link-active': sort.text === sortBy}">
                            <span class="job-overview__secondheader-sortby-checkmark" />
                            <span class="job-overview__secondheader-sortby-option">{{ sort.text }}</span>
                        </dropdown-item>
                    </vit-dropdown>
                    <!-- END SORT BY -->
                </div>
                <p class="job-overview__secondheader-results" v-if="jobsList">
                    {{ jobsList.length > 300 ? '300+ ' + translations.results : allresults.length + ' ' + translations.results
                    }}
                </p>
            </div>
        </div>
        <!-- END NAVBAR JOBS -->
        <!-- START JOBS CONTENT-->
        <div :class="[{'job-overview__content': filtersOn }]">
            <!-- START JOBS FILTERS-->
            <div :class="[{'job-overview__filters-active': filtersOn}]" class="job-overview__filters">
                <div class="job-overview__filters-cross" @click="filtersOpenClose">
                    <icon name="icn-cross" class="icon job-overview__filters-cross-icon" />
                </div>
                <div class="job-overview__filters__fields">
                    <div class="job-overview__filters__fields-titles">
                        <p>{{ translations.filterBy }}</p>
                        <button v-if="filtersUsed" class="button button--link" @click="cleanFilters">
                            {{ translations.cleanFilter }}
                        </button>
                    </div>
                    <!-- FILTER NAME -->
                    <vit-accordion
                        v-for="(filter, index) in filters "
                        :key="index"
                        :title="filter.checked.length ? filter.filter+' ('+filter.checked.length+')':filter.filter">
                        <div>
                            <!-- SEARCH IN FILTER-->
                            <div v-if="filter.options.length > 4" class="job-overview__filters__search">
                                <div class="job-overview__filters__search-icon">
                                    <icon name="icn-search" class="icon job-overview__filters__search-icon-icon" />
                                </div>
                                <input
                                    class="job-overview__filters__search-input"
                                    v-model="filter.search"
                                    @input="updateFilters(filter)"
                                    placeholder="">
                            </div>
                            <!-- FILTER OPTIONS-->
                            <!-- SEE MORE -->
                            <div
                                v-for="(option, optionindex) in filter.optionsSearch"
                                :key="optionindex"
                                class="job-overview__filters__options"
                                :class="{ 'hidden-job': optionindex >= filter.optionsOpen }">
                                <label :for="option.value">
                                    <input
                                        type="checkbox"
                                        :id="option.value"
                                        :value="option.value"
                                        v-model="filter.checked"
                                        class="job-overview__filters__options-input">
                                    <span class="job-overview__filters__options-checkmark" />
                                    <p>{{ option.value }}</p>
                                </label>
                            </div>
                            <button
                                v-if="filter.optionsOpen < filter.optionsSearch.length"
                                class="button button--link"
                                @click="filter.optionsOpen=filter.optionsOpen+4;">
                                {{ translations.loadMore }}
                            </button>
                        </div>
                    </vit-accordion>
                </div>
                <div class="job-overview__filters__seeresults" v-if="filtersUsed">
                    <div class="job-overview__filters__seeresults-grad" />
                    <div class="job-overview__filters__seeresults-button">
                        <button class="button" @click="filtersOpenClose">
                            {{ allresults.length ? 'Show all ' + allresults.length + ' results' : 'No results' }}
                        </button>
                    </div>
                </div>
            </div>
            <!-- END JOBS FILTERS-->
            <!-- START JOBS ALL-->
            <div class="job-overview__job">
                <!-- Start Accordion -->
                <transition-group name="joblist">
                    <vit-accordion
                        v-for="(job, index) in allresults"
                        :key="index"
                        :class="{ 'hidden-job': showJobs < index + 1 }">
                        <template #toggle>
                            <div class="job-overview__job-fav" @click.stop>
                                <label>
                                    <input
                                        type="checkbox"
                                        :id="job.jobId"
                                        :value="job.jobId"
                                        v-model="favourites"
                                        class="job-overview__job-fav-icon-input">
                                    <icon
                                        name="icn-star"
                                        class="job-overview__job-fav-icon"
                                        :class="[{'job-overview__job-fav-icon-active': favourites.includes(job.jobId)}]" />
                                </label>
                            </div>
                            <div class="job-overview__job__info">
                                <div class="job-overview__job__basicinfo">
                                    <div>
                                        <p class="job-overview__titlenew">
                                            <a
                                                @click.stop
                                                :href="determineJobDetailsPageUrl(job.jobId)"
                                                class="button button--linkover job-overview__job-title">{{ job.title
                                                }}</a>
                                            <span v-if="job.postDateString > jobisnew" class="job-overview__new">
                                                <span> {{ translations.new }}</span>
                                            </span>
                                        </p>
                                        <p class="job-overview__job-text">
                                            {{ job.businessArea }}
                                        </p>
                                        <p class="job-overview__job-texlight">
                                            {{ job.postDateString }}
                                        </p>
                                    </div>
                                </div>
                                <div class="">
                                    <p class="job-overview__job-country job-overview__job-text">
                                        {{ getCountry(job) }}
                                    </p>
                                </div>
                            </div>
                        </template>
                        <!-- More Info -->
                        <div>
                            <div class="job-overview__job__info">
                                <div class="job-overview-details">
                                    <p v-if="job.jobId" class="job-overview__job-textbold">
                                        {{ translations.jobId }}
                                    </p>
                                    <p v-if="job.jobId" class="job-overview__job-text">
                                        {{ job.jobId }}
                                    </p>
                                    <p v-if="job.employmentType" class="job-overview__job-textbold">
                                        {{ translations.type }}
                                    </p>
                                    <p v-if="job.employmentType" class="job-overview__job-text">
                                        {{ job.employmentType }}
                                    </p>
                                    <p
                                        v-if="job.closingDateString"
                                        class="job-overview__job-textbold job-overview__job-text-hidden">
                                        {{ translations.closeDate }}
                                    </p>
                                    <p
                                        v-if="job.closingDateString"
                                        class="job-overview__job-text job-overview__job-text-hidden">
                                        {{ job.closingDateString.substring(0, 10) }}
                                    </p>
                                </div>
                                <div v-if="job.closingDateString" class="job-overview-details">
                                    <p class="job-overview__job-textbold job-overview__job-text-show">
                                        {{ translations.closeDate }}
                                    </p>
                                    <p class="job-overview__job-text job-overview__job-text-show">
                                        {{ job.closingDateString.substring(0, 10) }}
                                    </p>
                                </div>
                            </div>
                            <div class="job-overview__job-description">
                                <p class="job-overview__job-textbold">
                                    {{ translations.jobDescription }}
                                </p>
                                <div class="job-overview__job-text">
                                    {{ job.descriptionAbstract }}
                                </div>
                            </div>
                            <a
                                class="job-overview__job-button button"
                                :href="determineJobDetailsPageUrl(job.jobId)">{{ translations.goToJob }}</a>
                        </div>
                    </vit-accordion>
                </transition-group>
                <!-- End Accordion -->
                <!-- START MORE JOBS BUTTON-->
                <div class="job-overview__more">
                    <button v-if="showJobs < allresults.length" class="button button--down" @click="moreJobs">
                        {{ translations.loadMore }}
                    </button>
                </div>
                <!-- END MORE JOBS BUTTON-->
            </div>
            <!-- END JOBS ALL-->
        </div>
        <!-- END JOBS CONTENT-->
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
import Icon from './atoms/Icon.vue';
import DropdownItem from './base/VitDropdownItem.vue';
import Helper from '../lib/Helper';
import VitDropdown from './base/VitDropdown.vue';
import VitAccordion from './base/VitAccordion.vue';

const COOKIE_NAME = 'jobs_favourite';

export default defineComponent({
    components: { VitAccordion, VitDropdown, DropdownItem, Icon },
    props: {
        jobsList: { type: Array, required: true },
        detailsPageUrl: { type: String, required: true },
        translations: { type: Object as () => { [key: string]: string }, required: true }
    },
    data() {
        return {
            // data
            filtersOn: false, // tab filters
            showJobs: 3, // number of results showing
            // Sort By
            sortBy: '',
            sortByOptions: [],
            favourites: [],
            // Filter
            filters: [
                {
                    filter: '',
                    field: 'businessArea',
                    search: '',
                    optionsOpen: 4,
                    options: [],
                    optionsSearch: [],
                    checked: []
                },
                {
                    filter: '',
                    field: 'locationCountry',
                    search: '',
                    optionsOpen: 4,
                    options: [],
                    optionsSearch: [],
                    checked: []
                },
                {
                    filter: '',
                    field: 'locationTitle',
                    search: '',
                    optionsOpen: 4,
                    options: [],
                    optionsSearch: [],
                    checked: []
                },
                {
                    filter: '',
                    field: 'employmentType',
                    search: '',
                    optionsOpen: 4,
                    options: [],
                    optionsSearch: [],
                    checked: []
                }
            ]
        };
    },

    created() {
        this.filtersValue();
        this.sortByOptionsValue();
        this.getFavouriteCookie();
    },

    methods: {
        determineJobDetailsPageUrl(jobId: string): string {
            return encodeURI(`${this.detailsPageUrl}/${jobId}`);
        },
        getFavouriteCookie() {
            const cookie = Helper.getCookie(COOKIE_NAME);
            if (cookie) {
                this.favourites = cookie.split(',');
            }
        },

        filtersOpenClose() {
            this.filtersOn = !this.filtersOn;
        },

        moreJobs() {
            const initialHeight = window.pageYOffset;
            this.showJobs += 3;
            setTimeout(() => window.scrollTo(0, initialHeight), 0);
        },

        cleanFilters() {
            this.filters.forEach(filter => {
                filter.checked = [];
            });
        },

        updateFilters(filter) {
            const searchResults = [];
            // change to uppercase
            const searchUppercase = filter.search.toUpperCase();
            let itemUppercase = '';
            filter.options.forEach(item => {
                // change item to uppercase and search in uppercase
                itemUppercase = item.value.toUpperCase();
                if (itemUppercase.includes(searchUppercase)) {
                    searchResults.push(item);
                }
            });
            filter.optionsSearch = searchResults;
        },

        filtersValue() {
            this.filters.forEach(filter => {
                filter.filter = this.translations[filter.field];
                this.jobsList.map(x => x[filter.field]).filter((v, i, s) => s.indexOf(v) === i)
                    .forEach(element => {
                        if (element != null && element !== '' && element !== undefined) {
                            filter.options.push({
                                value: element
                            });
                            filter.optionsSearch.push({
                                value: element
                            });
                        }
                    });
            });
        },

        sortByOptionsValue() {
            if (this.translations.sortByRelevance) {
                this.sortByOptions.push({
                    text: this.translations.sortByRelevance
                });
            }
            if (this.translations.sortByDateAsc) {
                this.sortByOptions.push({
                    text: this.translations.sortByDateAsc
                });
            }
            if (this.translations.sortByDateDesc) {
                this.sortByOptions.push({
                    text: this.translations.sortByDateDesc
                });
            }
            if (this.translations.sortByLocalAsc) {
                this.sortByOptions.push({
                    text: this.translations.sortByLocalAsc
                });
            }
            if (this.translations.sortByLocalDesc) {
                this.sortByOptions.push({
                    text: this.translations.sortByLocalDesc
                });
            }
            if (this.translations.sortByFavFirst) {
                this.sortByOptions.push({
                    text: this.translations.sortByFavFirst
                });
            }
            if (this.translations.sortByFavLast) {
                this.sortByOptions.push({
                    text: this.translations.sortByFavLast
                });
            }
            if (this.sortByOptions && this.sortBy === '') {
                this.sortBy = this.sortByOptions[0].text;
            }
        },
        getCountry(job) {
            let location = '';
            if (job.locationCountry) {
                if (location !== '') {
                    location = this.concatenate(location, ', ');
                }
                location = this.concatenate(location, job.locationCountry);
            }
            if (job.locationRegion && !location.includes(job.locationRegion)) {
                if (location !== '') {
                    location = this.concatenate(location, ', ');
                }
                location = this.concatenate(location, job.locationRegion);
            }
            if (job.locationTitle && !location.includes(job.locationTitle)) {
                if (location !== '') {
                    location = this.concatenate(location, ', ');
                }
                location = this.concatenate(location, job.locationTitle);
            }
            return location;
        },

        concatenate(a, b) {
            return a + b;
        }
    },

    computed: {
        allresults() {
            let jobs = this.jobsList;

            // FILTERS
            this.filters.forEach(filter => {
                if (filter.checked.length) {
                    jobs = jobs.filter(item => {
                        let have = false;
                        filter.checked.forEach(element => {
                            if (item[filter.field].includes(element)) {
                                have = true;
                            }
                        });
                        return have;
                    });
                }
            });
            // SORT BY
            jobs = jobs.sort((a, b) => {
                // SORT BY POSTDATE
                if (this.sortBy === this.translations.sortByDateAsc || this.sortBy === this.translations.sortByDateDesc) {
                    const fa = a.postDateString.toLowerCase();
                    const
                        fb = b.postDateString.toLowerCase();
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                }
                // SORT BY LOCAL
                if (this.sortBy === this.translations.sortByLocalAsc || this.sortBy === this.translations.sortByLocalDesc) {
                    const fa = a.locationCountry.toLowerCase();
                    const
                        fb = b.locationCountry.toLowerCase();
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                }
                // SORT BY FAV
                if (this.sortBy === this.translations.sortByFavFirst || this.sortBy === this.translations.sortByFavLast) {
                    if (this.favourites.includes(a.jobId) && !this.favourites.includes(b.jobId)) {
                        return -1;
                    }
                    if (!this.favourites.includes(a.jobId) && this.favourites.includes(b.jobId)) {
                        return 1;
                    }
                    return 0;
                }
                return 0;
            });
            // reverse
            if (this.sortBy === this.translations.sortByDateDesc || this.sortBy === this.translations.sortByLocalDesc || this.sortBy === this.translations.sortByFavLast) {
                jobs.reverse();
            }
            return jobs;
        },

        filtersUsed() {
            return this.filters.some(filter => filter.checked.length);
        },

        filtersCountUsed() {
            let count = 0;
            this.filters.forEach(filter => {
                count += filter.checked.length;
            });
            return count;
        },

        jobisnew() {
            const newdate = new Date();
            const newdatenumber = newdate.setDate(newdate.getDate() - 10);
            return new Date(newdatenumber).toJSON().slice(0, 10).replace(/-/g, '/');
        }
    },

    watch: {
        favourites() {
            Helper.setCookie(COOKIE_NAME, this.favourites.join(','));
        }
    }
});

</script>

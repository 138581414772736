export default {
    mounted(element: HTMLInputElement, binding) {
        // Get original text
        const originalText = String(binding.value);

        // Find every number and no number
        const parts = originalText.split(/(\d+)/);

        // Only the first number found will be animated
        const numberIndex = parts.findIndex(part => /^\d+$/.test(part));
        const end = numberIndex !== -1 ? Number(parts[numberIndex]) : NaN;
        if (isNaN(end)) {
            return;
        }

        const duration = 2000;
        const frameDuration = 1000 / 60;
        const totalFrames = Math.round(duration / frameDuration);
        let currentFrame = 0;

        function animate() {
            currentFrame++;
            const progress = currentFrame / totalFrames;
            const currentNumber = Math.round(progress * end);

            // construct text to update at the correct location
            parts[numberIndex] = currentNumber.toString();
            element.innerText = parts.join('');

            if (currentFrame < totalFrames) {
                requestAnimationFrame(animate);
            } else {
                // insure that the final number and the text is correctly build
                parts[numberIndex] = end.toString();
                element.innerText = parts.join('');
            }
        }
        requestAnimationFrame(animate);
    }
};

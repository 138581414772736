import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "sub-navigation__container" }
const _hoisted_2 = {
  key: 0,
  class: "sub-navigation__wrapper"
}
const _hoisted_3 = { class: "sub-navigation__breadcrumb" }
const _hoisted_4 = { class: "sub-navigation__content" }
const _hoisted_5 = ["href", "target", "rel"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["href", "target", "rel"]
const _hoisted_8 = {
  key: 0,
  class: "sub-navigation__meta-nav"
}
const _hoisted_9 = { class: "sub-navigation__sublevel-content" }
const _hoisted_10 = ["href", "target", "rel"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["href", "target", "rel"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sub-navigation", { 'sub-navigation--dark': _ctx.submenus.length > 0 }])
  }, [
    (_ctx.submenus.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "sub-navigation__bg",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeSubLevel && _ctx.closeSubLevel(...args)))
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.parentItem)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                class: "sub-navigation__breadcrumb-button button button--borderless button--left",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
              }, _toDisplayString(_ctx.parentItem.title), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "sub-navigation__wrapper",
        onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.closeSubLevel && _ctx.closeSubLevel(...args)))
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("a", {
            href: _ctx.currentItem.link,
            target: _ctx.currentItem.newWindow ? '_blank' : '_self',
            rel: _ctx.currentItem.newWindow ? 'noopener noreferrer' : null,
            onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onNav && _ctx.onNav(...args)), ["stop"])),
            class: "sub-navigation__parent-title sub-navigation__item"
          }, [
            _createElementVNode("b", null, _toDisplayString(_ctx.currentItem.title), 1)
          ], 8, _hoisted_5),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (item.items && item.items.length)
                ? (_openBlock(), _createElementBlock("button", {
                    key: `subitem#${i}`,
                    onClick: _withModifiers(($event: any) => (_ctx.openSubLevel(item)), ["stop"]),
                    class: _normalizeClass([{
                                'sub-navigation__item-active button--expanded': _ctx.isActive(item),
                                'sub-navigation__root-item': _ctx.currentItem && _ctx.currentItem.title === 'Home'
                            }, "button button--borderless sub-navigation__item"])
                  }, _toDisplayString(item.title), 11, _hoisted_6))
                : (_openBlock(), _createElementBlock("a", {
                    key: `subitem#${i}`,
                    href: item.link,
                    target: item.newWindow ? '_blank' : '_self',
                    rel: item.newWindow ? 'noopener noreferrer' : null,
                    class: _normalizeClass([{ 'sub-navigation__item-active': _ctx.isActive(item) }, "sub-navigation__item"]),
                    onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onNav && _ctx.onNav(...args)), ["stop"]))
                  }, _toDisplayString(item.title), 11, _hoisted_7))
            ], 64))
          }), 256)),
          (!_ctx.parentItem)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _renderSlot(_ctx.$slots, "default")
              ]))
            : _createCommentVNode("", true)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.submenus, (submenu, s) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "sub-navigation__sublevel",
            key: `slvl#${s}`
          }, [
            _createElementVNode("div", {
              class: "sub-navigation__sublevel-content-wrapper",
              onClick: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"]))
            }, [
              (s + 1 === _ctx.submenus.length)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "sub-navigation__sublevel-close",
                    onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeSubLevel && _ctx.closeSubLevel(...args)), ["stop"]))
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("a", {
                  href: submenu.link,
                  target: submenu.newWindow ? '_blank' : '_self',
                  rel: submenu.newWindow ? 'noopener noreferrer' : null,
                  onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onNav && _ctx.onNav(...args)), ["stop"])),
                  class: "sub-navigation__item"
                }, [
                  _createElementVNode("b", null, _toDisplayString(submenu.title), 1)
                ], 8, _hoisted_10),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(submenu.items, (item, i) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (item.items && item.items.length)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: `subsubitem#${i}`,
                          onClick: _withModifiers(($event: any) => (_ctx.openSubLevel(item)), ["stop"]),
                          class: _normalizeClass([{ 'sub-navigation__item-active button--expanded': _ctx.isActive(item, true) }, "button button--borderless sub-navigation__item"])
                        }, _toDisplayString(item.title), 11, _hoisted_11))
                      : (_openBlock(), _createElementBlock("a", {
                          key: `subitem#${i}`,
                          href: item.link,
                          target: item.newWindow ? '_blank' : '_self',
                          rel: item.newWindow ? 'noopener noreferrer' : null,
                          class: _normalizeClass([{ 'sub-navigation__item-active': _ctx.isActive(item, true) }, "sub-navigation__item"]),
                          onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onNav && _ctx.onNav(...args)), ["stop"]))
                        }, _toDisplayString(item.title), 11, _hoisted_12))
                  ], 64))
                }), 256))
              ])
            ])
          ]))
        }), 128))
      ])
    ])
  ], 2))
}
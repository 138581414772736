import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    class: _normalizeClass(["form__input", {'form-input-required' : _ctx.requiredfield}]),
    type: "text",
    value: _ctx.maskedValue,
    placeholder: _ctx.placeholder,
    ref: 'inputRef',
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
  }, null, 42, _hoisted_1))
}
<template>
    <div class="icon-teasers">
        <div class="icon-teasers__items">
            <slot />
        </div>
        <div class="icon-teasers__active-item">
            <div class="icon-teasers__active-item-text">
                <span v-if="activeSection" class="icon-teasers__active-item-text-active">
                    {{ activeSection.text }}
                </span>
                <span v-else class="icon-teasers__active-item-text-active">
                    {{ placeholderText }}
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue';

export default defineComponent({
    props: {
        placeholderText: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            sections: [],
            activeSection: null
        };
    },
    created() {
        provide('register', this.register);
        provide('onHover', this.onHover);
        provide('onBlur', this.onBlur);
    },

    methods: {
        // called by child icon teasers
        register(section) {
            this.sections.push(section);
        },

        onHover(section) {
            this.activeSection = section;
        },

        onBlur() {
            this.activeSection = null;
        }
    }
});

</script>

<template>
    <div class="stage_map-map">
        <google-map
            :center="mapCenter"
            :markers="mapMarkers"
            :zoom="zoom"
            :api-key="apiKey"
            static-map />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GoogleMap from '../base/GoogleMap.vue';

export default defineComponent({
    components: { GoogleMap },
    props: {
        location: { required: true, type: Object as () => Record<string, string> },
        apiKey: { required: true, type: String }
    },
    data() {
        return {
            zoom: 14
        };
    },

    computed: {
        mapCenter(): any {
            // center map a little bit lower and to the left
            // so the location in map doesn't show under stage's title letters
            return {
                lat: parseFloat(this.location.lat) - 0.002,
                lng: parseFloat(this.location.lng) - 0.005
            };
        },

        mapMarkers(): any[] {
            return [{
                id: 'id',
                location: {
                    lat: parseFloat(this.location.lat),
                    lng: parseFloat(this.location.lng)
                }
            }];
        }
    }
});

</script>

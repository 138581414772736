<template>
    <transition name="cookie-banner__collapse">
        <div v-if="!accepted" class="cookie-banner">
            <div class="cookie-banner__container">
                <button class="cookie-banner__close" @click="accept" aria-label="close">
                    <icon name="icn-cross" class="icon cookie-banner__close-icon" />
                </button>
                <div class="cookie-banner__title">
                    {{ title }}
                </div>
                <div class="cookie-banner__text">
                    {{ text }}
                    <slot />
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from './atoms/Icon.vue';

export default defineComponent({
    components: { Icon },
    props: {
        title: String,
        text: String,
        link: String,
        linkLabel: String
    },
    data() {
        return {
            storageKey: 'viterra-cookies-accepted',
            accepted: false
        };
    },

    created() {
        const storage = window.localStorage.getItem(this.storageKey);
        this.accepted = storage === 'accepted';
    },
    methods: {
        accept() {
            window.localStorage.setItem(this.storageKey, 'accepted');
            this.accepted = true;
            location.reload();
        }
    }
});

</script>

<template>
    <!-- site info-->
    <template v-if="siteInfo && fetchStatus.siteDetailStatus == 200">
        <div class="site-information__details">
            <div class="site-information__title" v-if="siteInfo.message">
                <div class="site-information__message">
                    <h4 class="site-information__message-title">{{ translations.siteMessage }}</h4>
                    <span class="site-information__message-text">{{ siteInfo.message }}</span>
                </div>
            </div>
        </div>
        <div class="site-information__details">
            <div class="site-information__map-container">
                <div class="site-information__map">
                    <google-map
                        :center="mapCenter"
                        :markers="mapMarkers"
                        :zoom="zoom"
                        :api-key="apiKey" />
                </div>
            </div>
            <div class="site-information__wrapper">
                <div class="site-information__container">
                    <div class="site-information__container-bottom">
                        <div>
                            <ul
                                v-if="site.addressLine1 || site.addressLine2 || site.addressLine3"
                                class="site-information__address">
                                <li>{{ site.addressLine1 }}</li>
                                <li>{{ site.addressLine2 }}</li>
                                <li>{{ site.addressLine3 }}</li>
                            </ul>
                            <a
                                :href="directionsUrl"
                                :title="translations.directions"
                                target="_blank"
                                class="button site-information__directions-btn">
                                {{ translations.directions }}
                            </a>
                        </div>
                        <div>
                            <span>{{ translations.siteManager }}</span>
                            <p class="site-information__manager">{{ siteInfo.manager }}</p>
                            <p>
                                <a
                                    v-if="siteInfo.phone"
                                    :href="`tel:${siteInfo.phone.replace(/\s/g, '')}`">
                                    T: {{ siteInfo.phone }}
                                </a>
                            </p>
                            <p>
                                <a
                                    v-if="siteInfo.managerMobile"
                                    :href="`tel:${siteInfo.managerMobile.replace(/\s/g, '')}`">
                                    M: {{ siteInfo.managerMobile }}
                                </a>
                            </p>
                        </div>
                        <div class="site-information__opening-hours-wrapper">
                            <p class="site-information__status-text" :class="statusClass">
                                {{ formatStatus(siteInfo.currentStatus) }}
                            </p>
                            <p v-if="siteInfo.currentStatus.toLowerCase() === 'open'">
                                <span class="site-information__today-label">{{ translations.today }}</span>
                                <span>{{ siteInfo.currentTime }}</span>
                            </p>

                            <p>{{ translations.openingHours }}</p>
                            <ul class="site-information__opening-hours">
                                <li v-for="(day, d) in dayNames" :key="day">
                                    <span>{{ day }}</span>
                                    <span>{{ getOpeningTime(d) }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <loading-spinner v-else-if="!loaded" />
    <!-- site grades -->
    <div class="site-information__details">
        <div class="site-information__title">
            <div class="site-information__grades">
                <template v-if="pricesLoaded && fetchStatus.sitePricesStatus == 200">
                    <div class="site-information__pricing-header">{{ translations.pricing }}</div>
                    <div v-if="!prices.length">{{ translations.notAvailable }}</div>
                    <div class="my-3 justify-content-between d-flex" v-if="prices.length">
                        <div
                            class="cash-pricing__pool-filter"
                            :class="{ 'cash-pricing__pool-filter--active': showPoolOptions }"
                            @click="showPoolOptions = !showPoolOptions">
                            {{ translations.showPool }}
                        </div>
                    </div>
                    <div class="flex-column flex-lg-row d-flex" v-if="prices.length">
                        <div
                            class="cash-pricing__pool-filter"
                            :class="{ 'cash-pricing__pool-filter--active': showExtendedPaymentTerms }"
                            @click="showExtendedPaymentTerms = !showExtendedPaymentTerms">
                            {{ translations.showExtendedPaymentTerms }}
                            <div @click.stop>
                                <icon
                                    class="icon"
                                    name="icn-info"
                                    @click.native="openClosePopUp('showPopUpEPP')" />
                                <div
                                    class="cash-pricing__pool-filter-popup"
                                    :class="{ 'cash-pricing__pool-filter-popup-active': showPopUpEPP }">
                                    <icon
                                        name="icn-cross"
                                        class="cash-pricing__pool-filter-popup-cross"
                                        @click.native="openClosePopUp('showPopUpEPP')" />
                                    <div class="cash-pricing__pool-filter-popup-text">
                                        {{ translations.showEPPricingDesc }}
                                    </div>
                                    <a
                                        class="button button--borderless"
                                        :href="translations.segregationMoreInfoLink"
                                        target="_blank">{{ translations.segregationMoreInfo }}</a>
                                </div>
                            </div>
                        </div>
                        <div
                            class="cash-pricing__pool-filter"
                            :class="{ 'cash-pricing__pool-filter--active': showSustainableCash }"
                            @click="showSustainableCash = !showSustainableCash">
                            {{ translations.showSustainableCash }}
                            <div @click.stop>
                                <icon
                                    class="icon"
                                    name="icn-info"
                                    @click.native="openClosePopUp('showPopUpSCP')" />
                                <div
                                    class="cash-pricing__pool-filter-popup"
                                    :class="{ 'cash-pricing__pool-filter-popup-active': showPopUpSCP }">
                                    <icon
                                        name="icn-cross"
                                        class="cash-pricing__pool-filter-popup-cross"
                                        @click.native="openClosePopUp('showPopUpSCP')" />
                                    <div class="cash-pricing__pool-filter-popup-text">
                                        {{ translations.showSCPricingDesc }}
                                    </div>
                                    <a
                                        class="button button--borderless"
                                        :href="translations.segregationMoreInfoLink"
                                        target="_blank">{{ translations.segregationMoreInfo }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <vit-tabs
                        mobile
                        sliding
                        :tabs-lenght="groups.length"
                        class="my-5 site-information__tabs"
                        @input="refreshPrices"
                        :class="{ 'site-information__tabs--sliding': commodities.length > 5 }"
                        :content-class="commodities.length > 5 ? 'site-information__tabs-content' : ''">
                        <vit-tab
                            v-for="group in groups"
                            :key="`table-${group.commodity.cId}`"
                            :title="group.commodity.cName"
                            capitalize
                            narrow
                            fixed-width>
                            <div class="segregation-plan__filter-date">
                                {{ translations.lastRefresh }}: {{ refreshDate }}
                            </div>
                            <icon
                                name="icn-refresh"
                                class="segregation-plan__refresh"
                                :class="{ 'segregation-plan__refresh-loading': loading }"
                                @click.native="refreshPrices" />
                            <div class="site-information__table-wrapper">
                                <div class="site-information__accordion-header">
                                    <div>{{ translations.grade }}</div>
                                    <div>{{ translations.price }}</div>
                                </div>
                                <segregation-cash-table
                                    v-for="subgroup in group.grades"
                                    :key="`table-${subgroup.id}`"
                                    :group="subgroup"
                                    hide-group-title
                                    show-empty-grades
                                    transparent
                                    :translations="translations">
                                    <div :key="`${subgroup.id}-noprice`" class="cash-pricing__no-price-grade-error">
                                        {{ translations.noPrices }}
                                    </div>
                                </segregation-cash-table>
                            </div>
                            <div class="site-information__other-grades" v-html="otherGradesLinked" />
                        </vit-tab>
                    </vit-tabs>
                    <button class="ml-auto button d-flex" @click="exportXlsx" v-if="prices.length">
                        {{ translations.export }}
                    </button>
                </template>
                <loading-spinner v-else-if="!pricesLoaded" />
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent, PropType } from 'vue';
import Segregation, { CommodityInfo, Site, SiteDetailInfo, PriceInfo, GradeInfo, GradePoolInfo } from '../../lib/Segregation';
import SegregationCashTable from './SegregationCashTable.vue';
import GoogleMap from '../base/GoogleMap.vue';
import Icon from '../atoms/Icon.vue';
import LoadingSpinner from '../atoms/LoadingSpinner.vue';
import Helper from '../../lib/Helper';
import VitTabs from '../base/VitTabs.vue';
import VitTab from '../base/VitTab.vue';

export default defineComponent({
    components: { VitTab, VitTabs, GoogleMap, LoadingSpinner, Icon, SegregationCashTable },
    props: {
        apiKey: { required: true, type: String },
        translations: { required: true, type: Object as () => Record<string, string> },
        wsBaseUrl: { required: true, type: String },
        site: { required: true, type: Object as PropType<Site> }
    },
    data() {
        return {
            // infos
            allCommodities: [] as CommodityInfo[],
            commodities: [] as CommodityInfo[],
            grades: [] as GradeInfo[],
            prices: [] as PriceInfo[],
            siteInfo: null as SiteDetailInfo,
            groups: [] as { title: string, gradePools: GradePoolInfo[], type: 'site' }[],
            refreshDate: '',
            dayNames: [] as string[],

            // filters
            showPoolOptions: false,
            showExtendedPaymentTerms: false,
            showSustainableCash: false,
            // only for cookies
            showOpeningHours: false,
            showPricing: true,

            // popup
            showPopUpSCP: false,
            showPopUpEPP: false,

            // aux
            loading: false,
            loaded: false,
            pricesLoaded: false,
            zoom: 11,
            fetchStatus: {
                siteDetailStatus: 0,
                commoditiesStatus: 0,
                sitePricesStatus: 0,
                siteNoPricesStatus: 0
            }
        };
    },

    async created() {
        this.readCookies();

        const { siteDetail, siteDetailStatus } = await Segregation.fetchSiteDetails(this.wsBaseUrl, this.site.uuid);
        this.siteInfo = siteDetail;
        this.fetchStatus.siteDetailStatus = siteDetailStatus || 0;

        const { commodities, commoditiesStatus } = await Segregation.fetchCommodities(this.wsBaseUrl);
        this.allCommodities = commodities;
        this.fetchStatus.commoditiesStatus = commoditiesStatus || 0;

        for (let i = 1; i < 7; i++) {
            const shortDate: Date = new Date();
            shortDate.setDate(shortDate.getDate() + i);
            this.dayNames.push(this.getDayName(shortDate, 'en'));
        }

        await this.refreshPrices();
    },

    methods: {
        // open and close popup
        openClosePopUp(name: string): void {
            if (name === 'showPopUpEPP') {
                this.showPopUpEPP = !this.showPopUpEPP;
                this.showPopUpSCP = false;
            } else {
                this.showPopUpSCP = !this.showPopUpSCP;
                this.showPopUpEPP = false;
            }
        },

        // export to excel
        exportXlsx() {
            Segregation.exportPricesInfoToXlsx(this.translations, this.filteredPrices, `Viterra_${this.site.name}_Segregations`);
        },

        // open time
        getOpeningTime(day: number) {
            if (this.siteInfo.days && this.siteInfo.days[day] && this.siteInfo.days[day].status) {
                if (this.siteInfo.days[day].status.toLowerCase() === 'open') {
                    return this.siteInfo.days[day].time;
                }
                return this.siteInfo.days[day].status.replace(/([a-z])([A-Z])/g, (_, $0, $1) => `${$0} ${$1.toLowerCase()}`);
            }
            return '';
        },
        formatStatus(status: string) {
            if (status) {
                return Segregation.formatStatus(status);
            }
            return '';
        },
        // get all prices
        async refreshPrices() {
            this.loading = true;
            this.prices = await this.fetchAllSiteGrades();
            this.loading = false;
            this.grades = Object.values(this.prices.reduce((res, price: PriceInfo) => {
                if (price.grade) {
                    if (!Object.prototype.hasOwnProperty.call(res, price.grade.gId)) {
                        res[price.grade.gId] = price.grade;
                    }
                }
                return res;
            }, {}));
            this.commodities = Object.values(this.prices.reduce((res, price: PriceInfo) => {
                if (price.commodity) {
                    if (!Object.prototype.hasOwnProperty.call(res, price.commodity.cId)) {
                        res[price.commodity.cId] = price.commodity;
                        res[price.commodity.cId].grades = {};
                    }
                    res[price.commodity.cId].grades[price.grade.gId] = price.grade;
                }
                return res;
            }, {})).map((x: any) => ({ ...x, grades: Object.values(x.grades) }));
            this.groupPrices();
        },

        async fetchAllSiteGrades(): Promise<PriceInfo[]> {
            const { prices, sitePricesStatus, siteNoPricesStatus } = await Segregation.getSiteGradePricesFilter(this.wsBaseUrl, this.site.name, this.showPoolOptions, this.translations.noIndivPriceGrade || '', this.allCommodities);
            this.fetchStatus.sitePricesStatus = sitePricesStatus || 0;
            this.fetchStatus.siteNoPricesStatus = siteNoPricesStatus || 0;
            // update date
            const date: Date = new Date();
            this.refreshDate = `${this.$date(date, false)} ${date.toLocaleTimeString()} (${Intl.DateTimeFormat().resolvedOptions().timeZone})`;
            this.pricesLoaded = true;
            return prices;
        },

        // return day name
        getDayName(date: Date, locale: string): string {
            return date.toLocaleDateString(locale, { weekday: 'short' });
        },

        // group for table
        groupPrices() {
            /* @ts-ignore */
            this.groups = Object.entries(this.grades.map((grade: GradeInfo) => ({
                // map grades into groups by grade
                id: grade.gId,
                title: grade.gName,
                type: 'site',
                gradePools: this.pricesByGrade(grade)
            }))
                .reduce((res, x) => {
                    // group grades into commodities
                    const commodity = this.commodities.find(c => c.grades.some(g => g.gId === x.id));
                    if (!commodity) {
                        // no commodity found for grade
                        return res;
                    }
                    if (!Object.prototype.hasOwnProperty.call(res, commodity.cId)) {
                        res[commodity.cId] = [];
                    }
                    res[commodity.cId].push(x);
                    return res;
                }, {})).map(x => ({ commodity: this.commodities.find(c => c.cId === x[0]), grades: x[1] }))
                .sort((a, b) => // sort wheat first
                    (a.commodity.cName.toLowerCase() === 'wheat'
                        ? -1 : b.commodity.cName.toLowerCase() === 'wheat'
                            ? 1 : (a.commodity.cName < b.commodity.cName ? -1 : 0)));
        },

        // group prices
        pricesByGrade(grade: GradeInfo): GradePoolInfo[] {
            return Object.values(this.filteredPrices
                .filter(price => price.grade.gId === grade.gId && price.pId)
                .reduce((res, p) => {
                    if (!Object.prototype.hasOwnProperty.call(res, p.site.sName)) {
                        res[p.site.sName] = { site: p.site, prices: [] };
                    }
                    res[p.site.sName].prices.push(p);
                    return res;
                }, {})).map(({ site, prices }) =>
                ({
                    id: `${grade.gId}-${site.id}`,
                    title: grade.gName,
                    prices,
                    max: prices.reduce((max, p) => (p.price !== 'POA' && p.price > max ? p.price : max), 0).toFixed(2)
                }));
        },

        /*************
         * Cookies
         ************/
        saveCookies() {
            const segregationPlan = {
                // use the values already in cookie
                show_opening_hours: this.showOpeningHours,
                show_pricing: this.showPricing,
                // update this values
                show_ep_pricing: this.showExtendedPaymentTerms,
                show_sc_pricing: this.showSustainableCash,
                show_pool_options: this.showPoolOptions
            };
            Helper.setCookie('segregation_plan', JSON.stringify(segregationPlan));
        },

        readCookies() {
            const segregationPlan = JSON.parse(Helper.getCookie('segregation_plan'));
            if (segregationPlan) {
                // save values only for writing cookies
                this.showOpeningHours = segregationPlan.show_opening_hours;
                this.showPricing = segregationPlan.show_pricing;
                // save values for page use
                this.showExtendedPaymentTerms = segregationPlan.show_ep_pricing;
                this.showSustainableCash = segregationPlan.show_sc_pricing;
                this.showPoolOptions = segregationPlan.show_pool_options;
            }
        },
        toggleOptions() {
            if (this.fetchStatus.siteDetailStatus === 200 && this.fetchStatus.commoditiesStatus === 200) {
                this.refreshPrices();
                this.groupPrices();
            }
        },

        loadingState() {
            if (this.fetchStatus.siteDetailStatus && this.fetchStatus.commoditiesStatus) {
                this.loaded = true;
                this.refreshPrices();
                this.$forceUpdate();
            }
        }
    },

    computed: {
        /**
         * Map functions
         */
        // Get coordinates for the center of the map.
        mapCenter() {
            if (this.site) {
                return {
                    lat: parseFloat(this.site.lat),
                    lng: parseFloat(this.site.lng)
                };
            }
            return null;
        },
        // Get an array of markers that are placed on the map.
        mapMarkers(): any[] {
            return [{
                id: this.site.uuid,
                location: {
                    lat: parseFloat(this.site.lat),
                    lng: parseFloat(this.site.lng)
                }
            }];
        },

        /**
         * Get URL for directions using Google Maps.
         * See also https://gearside.com/easily-link-to-locations-and-directions-using-the-new-google-maps/
         */
        directionsUrl() {
            return `https://www.google.com/maps/dir/?api=1&destination=${parseFloat(this.site.lat)},${parseFloat(this.site.lng)}`;
        },

        // return class for current state
        statusClass(): string {
            switch (this.siteInfo.currentStatus) {
                case 'Open':
                    return 'site-information__status-text-open';
                case 'Closed':
                    return 'site-information__status-text-closed';
                case 'ByAppointment':
                    return 'site-information__status-text-appointment';
                default:
                    return 'site-information__status-other';
            }
        },

        // get prices to display
        filteredPrices(): PriceInfo[] {
            this.saveCookies();
            return this.prices
                .filter(p => (this.showPoolOptions ? true : !p.sellInfo.isPool))
                .filter(p => (this.showExtendedPaymentTerms ? true : !p.sellInfo.isExtendedPaymentTerm))
                .filter(p => (this.showSustainableCash ? true : !p.sellInfo.isSustainable));
        },

        // get text at bottom of table
        otherGradesLinked() {
            return this.translations.otherGrades
                .replace('segregationLink', `<a target='_blank' href='${this.translations.segregationLink}'>segregation plan</a>`)
                .replace('cashLink', `<a target='_blank' href='${this.translations.cashLink}'>cash pricing</a>`);
        }
    },

    watch: {
        showPoolOptions() {
            this.toggleOptions();
        },
        showExtendedPaymentTerms() {
            this.toggleOptions();
        },
        showSustainableCash() {
            this.toggleOptions();
        },
        fetchStatus() {
            this.loadingState();
        }
    }
});

</script>

import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "drag_location-box-right" }
const _hoisted_2 = { class: "drag_location-box-left-start" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  class: "drag_location-box-left-list",
  id: "locationselement"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "allelement",
    class: _normalizeClass(["drag_location-box", {'drag_location-box-selected': _ctx.mobileSelected }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "static")
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["drag_location-box-left", {'drag_location-box-left-open': _ctx.openPopUpValue}]),
      onMouseover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.enableStoresScroll && _ctx.enableStoresScroll(...args))),
      onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.disableStoresScroll && _ctx.disableStoresScroll(...args))),
      onMousedown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.mousedown && _ctx.mousedown(...args))),
      onMouseup: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.mouseup && _ctx.mouseup(...args))),
      onMousemove: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.mousemove && _ctx.mousemove(...args))),
      onTouchstart: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.touchdown && _ctx.touchdown(...args))),
      onTouchend: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.mouseup && _ctx.mouseup(...args))),
      onTouchmove: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.touchmove && _ctx.touchmove(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "drag_location-box-left-cross",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closePopUPHandle()))
        }, [
          _createVNode(_component_icon, {
            name: "icn-cross",
            class: "icon"
          })
        ]),
        _renderSlot(_ctx.$slots, "stickytop")
      ]),
      (_ctx.isMobile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "mobileselected")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "scroll")
      ])
    ], 34)
  ], 2))
}
<template>
    <div
        class="teasers__cell article-teaser"
        :class="{ 'article-teaser--loading': loading, 'article-teaser--image': showImage, 'article-teaser--cover': large }">
        <div class="article-teaser__content">
            <div v-if="showImage && !loading" class="article-teaser__image-wrapper">
                <img
                    v-if="imageMd && imageLg"
                    class="article-teaser__image"
                    :src="large ? imageLg : imageMd"
                    :alt="fileName">
                <icon v-else class="article-teaser__placeholder" name="icn-placeholder" />
            </div>
            <div class="article-teaser__text-content">
                <div class="article-teaser__wrap-text">
                    <div v-if="loading" class="article-teaser__date" />
                    <div v-else-if="date" class="slide__date">
                        {{ $date(date) }}
                    </div>
                    <template v-if="loading">
                        <div class="article-teaser__text" />
                        <div class="article-teaser__text" />
                        <div class="article-teaser__text" />
                    </template>
                    <template v-else>
                        <div class="slide__text article__text-long">
                            {{ description }}
                        </div>
                    </template>
                </div>
                <div v-if="loading" class="article-teaser__link" />
                <a
                    v-else-if="link"
                    :href="link"
                    :target="determineTarget()"
                    class="button button--borderless">{{ readMoreLabel }}</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from './atoms/Icon.vue';

export default defineComponent({
    components: { Icon },
    props: {
        date: { type: String },
        description: { type: String },
        link: { type: String },
        imageMd: { type: String },
        imageLg: { type: String },
        readMoreLabel: { type: String },
        showImage: { type: Boolean, default: false },
        large: { type: Boolean, default: false },
        loading: { type: Boolean, default: false }
    },
    methods: {
        determineTarget() {
            return this.link.startsWith('http') ? '_blank' : '_self';
        }
    },
    computed: {
        fileName() {
            return this.imageMd ? this.imageMd.split('/').pop() : '';
        }
    }
});

</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "media-filter" }
const _hoisted_2 = { class: "media-filter__grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_item = _resolveComponent("dropdown-item")!
  const _component_vit_dropdown = _resolveComponent("vit-dropdown")!
  const _component_vit_dropdown_bar = _resolveComponent("vit-dropdown-bar")!
  const _component_media_item = _resolveComponent("media-item")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vit_dropdown_bar, null, {
      default: _withCtx(() => [
        _createVNode(_component_vit_dropdown, {
          label: _ctx.format.length ? _ctx.$capitalize(_ctx.format) : _ctx.allFormatsLabel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_dropdown_item, {
              label: _ctx.allFormatsLabel,
              onClick: _ctx.selectFormat,
              selected: _ctx.format === ''
            }, null, 8, ["label", "onClick", "selected"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formats, (f) => {
              return (_openBlock(), _createBlock(_component_dropdown_item, {
                key: f.key,
                label: f.label,
                selected: f.key === _ctx.format,
                onClick: ($event: any) => (_ctx.selectFormat(f.key))
              }, null, 8, ["label", "selected", "onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_vit_dropdown, {
          label: _ctx.country.length ? _ctx.country : _ctx.allCountriesLabel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_dropdown_item, {
              label: _ctx.allCountriesLabel,
              onClick: _ctx.selectCountry,
              selected: _ctx.country === ''
            }, null, 8, ["label", "onClick", "selected"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (c) => {
              return (_openBlock(), _createBlock(_component_dropdown_item, {
                key: c,
                label: c,
                selected: c === _ctx.country,
                onClick: ($event: any) => (_ctx.selectCountry(c))
              }, null, 8, ["label", "selected", "onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_vit_dropdown, {
          label: _ctx.commodity.length ? _ctx.commodity : _ctx.allCommoditiesLabel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_dropdown_item, {
              label: _ctx.allCommoditiesLabel,
              onClick: _ctx.selectCommodity,
              selected: _ctx.commodity === ''
            }, null, 8, ["label", "onClick", "selected"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commodities, (c) => {
              return (_openBlock(), _createBlock(_component_dropdown_item, {
                key: c,
                selected: c === _ctx.commodity,
                label: c,
                onClick: ($event: any) => (_ctx.selectCommodity(c))
              }, null, 8, ["selected", "label", "onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }),
    (_ctx.filterQuery.includes('country') || _ctx.filterQuery.includes('commodity') || _ctx.filterQuery.includes('format'))
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resetFilter && _ctx.resetFilter(...args))),
          class: "button button--borderless button--no-arrow media-filter__reset-filters"
        }, _toDisplayString(_ctx.resetFiltersLabel), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_media_item, {
          key: item.uuid,
          item: item,
          "goto-video-label": _ctx.gotoVideoLabel,
          "download-label": _ctx.downloadLabel,
          class: "media-filter__item"
        }, null, 8, ["item", "goto-video-label", "download-label"]))
      }), 128))
    ]),
    _createVNode(_component_pagination, {
      "num-pages": _ctx.numPages,
      modelValue: _ctx.page,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.page) = $event))
    }, null, 8, ["num-pages", "modelValue"])
  ]))
}
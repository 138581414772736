// eslint-disable-next-line dot-notation
const LANG = window['lang'];
// eslint-disable-next-line dot-notation
const SITE = window['siteName'];

export const REST_ENDPOINT: string = '/.rest/api/v2/';
export const ARTICLES_ENDPOINT = {
    articles(uuid) { return `${REST_ENDPOINT}articles/${uuid}/lang/${LANG}`; },
    years(uuid) { return `${REST_ENDPOINT}articles/${uuid}/years`; },
    tags(uuid) { return `${REST_ENDPOINT}articles/${uuid}/tags`; }
};
export const LOCATION_CATEGORIES_ENDPOINT: string = `${REST_ENDPOINT}categories/location/${LANG}`;
export const LOCATIONS_ENDPOINT: string = `${REST_ENDPOINT}locations/${SITE}/${LANG}`;
export const REGIONS_ENDPOINT: string = `${LOCATIONS_ENDPOINT}/regions`;
export const MEDIA_ENDPOINT = {
    media(siteName) { return `${REST_ENDPOINT}media/${siteName}/${LANG}`; },
    countries(siteName) { return `${REST_ENDPOINT}media/${siteName}/countries/${LANG}`; },
    commodities(siteName) { return `${REST_ENDPOINT}media/${siteName}/commodities/${LANG}`; }
};
export const SEARCH_ENDPOINT: string = `${REST_ENDPOINT}search`;
export const RECAPTCHA_ENDPOINT = {
    verify(siteName) { return `${REST_ENDPOINT}recaptcha/${siteName}`; }
};
export const MLC_ENDPOINT = {
    states() { return `${REST_ENDPOINT}mlc/states`; },
    currentVtCodes(state) { return `${REST_ENDPOINT}mlc/currentVtCodes/${state}`; },
    convert(state, currentVtCode) { return `${REST_ENDPOINT}mlc/${state}/${currentVtCode}`; },
    requestEmail() { return `${REST_ENDPOINT}mlc/request-email`; }
};

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "overlay-navigation" }
const _hoisted_2 = { class: "section section--medium" }
const _hoisted_3 = { class: "section__container" }
const _hoisted_4 = { class: "section__content" }
const _hoisted_5 = { class: "overlay-navigation__links" }
const _hoisted_6 = { class: "overlay-navigation__content" }
const _hoisted_7 = { class: "link-list__items overlay-navigation__links" }
const _hoisted_8 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vit_tab = _resolveComponent("vit-tab")!
  const _component_vit_tabs = _resolveComponent("vit-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_vit_tabs, { class: "overlay-navigation__tabs" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
                return (_openBlock(), _createBlock(_component_vit_tab, {
                  title: item.title,
                  key: `overlay-item#${i}`,
                  narrow: _ctx.items.length > 3
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("ul", _hoisted_7, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (subItem, s) => {
                            return (_openBlock(), _createElementBlock("li", {
                              class: "overlay-navigation__link",
                              key: `overlay-item#${s}`
                            }, [
                              _createElementVNode("a", {
                                href: subItem.link,
                                class: "button button--link",
                                target: subItem.newWindow ? '_blank' : '_self'
                              }, _toDisplayString(subItem.title), 9, _hoisted_8)
                            ]))
                          }), 128))
                        ])
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["title", "narrow"]))
              }), 128))
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("button", {
      class: "overlay-navigation__close-btn",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    })
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "offices-map"
}
const _hoisted_2 = { class: "offices-map__map" }
const _hoisted_3 = { class: "offices-map__overlay" }
const _hoisted_4 = { class: "offices-map__overlay-content" }
const _hoisted_5 = { class: "offices-map__location" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 3,
  class: "offices-map__contact"
}
const _hoisted_10 = ["href"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 6,
  class: "offices-map__name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_google_map = _resolveComponent("google-map")!
  const _component_dropdown_item = _resolveComponent("dropdown-item")!
  const _component_vit_dropdown = _resolveComponent("vit-dropdown")!

  return (_ctx.office)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_google_map, {
            center: _ctx.office.location,
            markers: _ctx.officeLocations,
            zoom: _ctx.zoom,
            onMapZoom: _cache[0] || (_cache[0] = ($event: any) => (_ctx.zoom = $event)),
            "api-key": _ctx.apiKey
          }, null, 8, ["center", "markers", "zoom", "api-key"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.office.location.title), 1),
            (_ctx.office.addressLine1)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "offices-map__address",
                  innerHTML: _ctx.office.addressLine1
                }, null, 8, _hoisted_6))
              : _createCommentVNode("", true),
            (_ctx.office.cityZipCode)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "offices-map__address",
                  innerHTML: _ctx.office.cityZipCode
                }, null, 8, _hoisted_7))
              : _createCommentVNode("", true),
            (_ctx.office.addressLine2)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "offices-map__address",
                  innerHTML: _ctx.office.addressLine2
                }, null, 8, _hoisted_8))
              : _createCommentVNode("", true),
            (_ctx.office.phone || _ctx.office.fax)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_ctx.office.phone)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: `tel:${_ctx.office.phone.replace(/\s/g, '')}`
                      }, "T: " + _toDisplayString(_ctx.office.phone), 9, _hoisted_10))
                    : _createCommentVNode("", true),
                  (_ctx.office.fax)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, " F: " + _toDisplayString(_ctx.office.fax), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.office.email)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 4,
                  class: "button button--link offices-map__email",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.decryptEmail(_ctx.office.email)))
                }, " Email "))
              : _createCommentVNode("", true),
            (_ctx.office.optional)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 5,
                  class: "offices-map__contact",
                  innerHTML: _ctx.office.optional.replace(/\n/g, '<br>')
                }, null, 8, _hoisted_12))
              : _createCommentVNode("", true),
            (_ctx.office.description)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.office.description), 1))
              : _createCommentVNode("", true),
            (_ctx.offices.length > 1 && _ctx.office)
              ? (_openBlock(), _createBlock(_component_vit_dropdown, {
                  key: 7,
                  label: _ctx.office.location.title,
                  light: "",
                  class: "offices-map__dropdown",
                  inline: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offices, (off, o) => {
                      return (_openBlock(), _createBlock(_component_dropdown_item, {
                        key: off.location.title,
                        label: off.location.title,
                        onClick: ($event: any) => (_ctx.index = o)
                      }, null, 8, ["label", "onClick"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
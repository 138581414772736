import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "redirect-popup"
}
const _hoisted_2 = { class: "redirect-popup__container" }
const _hoisted_3 = { class: "redirect-popup__inner" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "redirect-popup__buttons" }
const _hoisted_7 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "redirect-popup__bunge-logo",
              style: _normalizeStyle(_ctx.logoStyle)
            }, null, 4),
            _createElementVNode("button", {
              class: "redirect-popup__close-btn",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = false))
            }, [
              _createVNode(_component_icon, {
                name: "icn-cross",
                class: "redirect-popup__close-btn-icon"
              })
            ]),
            _createElementVNode("div", {
              class: "redirect-popup__text redirect-popup__abstract",
              innerHTML: _ctx.headline
            }, null, 8, _hoisted_4),
            _createElementVNode("div", {
              class: "redirect-popup__text",
              innerHTML: _ctx.text
            }, null, 8, _hoisted_5),
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("button", {
                class: "button button-bunge redirect-popup__button",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.show = false))
              }, _toDisplayString(_ctx.currentWebsiteButtonLabel), 513), [
                [_vShow, _ctx.showCurrentWebsiteButton]
              ]),
              _createElementVNode("a", {
                class: "button button-bunge redirect-popup__button",
                href: _ctx.linkUrl,
                target: _ctx.linkTarget
              }, _toDisplayString(_ctx.linkLabel), 9, _hoisted_7)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
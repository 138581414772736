<template>
    <div
        class="shape-slide"
        :class="[{ 'shape-slide--right': right, 'shape-slide--revealed': revealed, 'shape-slide--history': isHistory }, `shape-slide--${icon}`]"
        @mouseenter="reveal"
        @mouseleave="conceal">
        <div class="shape-slide__wrapper">
            <div class="shape-slide__shape-container">
                <div class="shape-slide__shape" :style="{ backgroundImage: `url(${image})` }" />
                <icon :name="right ? 'icn-shape-slide-right' : 'icn-shape-slide-left'" class="shape-slide__shape-svg" />
                <div class="shape-slide__logo-title">
                    <icon :name="icon" class="shape-slide__icon" v-if="icon && icon !== 'none'" />
                    <div class="shape-slide__title">
                        {{ title }}
                    </div>
                </div>
            </div>
            <div class="shape-slide__content">
                <div />
                <div class="shape-slide__text">
                    <div class="shape-slide__title">
                        {{ title }}
                    </div>
                    <div class="text">
                        {{ text }}
                    </div>
                    <div v-if="highlightText" class="shape-slide__highlight-text">
                        {{ highlightText }}
                    </div>
                </div>
                <div class="shape-slide__link">
                    <slot />
                </div>
            </div>
            <button v-if="revealed" class="shape-slide__more-btn" @click="revealed = false">
                <icon name="icn-cross" class="shape-slide__more-btn-icon" />
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from './Icon.vue';

export default defineComponent({
    components: { Icon },
    props: {
        icon: String,
        image: String,
        title: String,
        text: String,
        highlightText: String,
        isHistory: { default: false, type: Boolean }
    },
    data() {
        return {
            right: false,
            revealed: false,
            concealTimer: null
        };
    },
    inject: {
        registerShapeSlide: { default: () => false }
    },

    mounted() {
        this.right = this.registerShapeSlide(this);
    },

    methods: {
        reveal() {
            clearTimeout(this.concealTimer);
            this.revealed = true;
        },

        conceal() {
            clearTimeout(this.concealTimer);
            this.concealTimer = setTimeout(() => {
                this.revealed = false;
            }, 3000);
        }
    }
});
</script>

<template>
    <div class="world-map">
        <div class="world-map__filters">
            <div class="world-map__title">
                {{ title }}
            </div>
            <div class="world-map__right">
                <icon
                    name="icn-search"
                    class="world-map__filter-bar-btn"
                    :class="{'world-map__filter-bar-btn--active': searchVisible}"
                    @click.native="toggleSearch" />
                <icon
                    name="icn-button-filter"
                    class="world-map__filter-bar-btn"
                    :class="{'world-map__filter-bar-btn--active': filtersVisible}"
                    @click.native="toggleFilters" />
            </div>
        </div>
        <!-- dropdowns -->
        <transition name="filters__anim">
            <div v-if="filtersVisible" class="world-map__dropdowns" ref="filtersdropdowns">
                <div class="world-map__dropdowns-title">
                    Filter by:
                </div>
                <!-- regions -->
                <vit-dropdown
                    v-if="regions.length > 1"
                    :label="regionLabel"
                    multiple
                    light>
                    <dropdown-item class="world-map__dropdowns-search">
                        <icon name="icn-search" class="world-map__dropdowns-search__icon" />
                        <form class="world-map__dropdowns-search__form">
                            <input class="world-map__dropdowns-search__input" type="text" v-model="regionSearch">
                        </form>
                    </dropdown-item>
                    <dropdown-item v-if="!regionSearch" @click="onSelectRegion(null)" :class="{'world-map__dropdowns-active': allRegions }">
                        <span class="world-map__dropdowns-checkmark" />
                        <span class="world-map__dropdowns-option">{{ allRegionsLabel }}</span>
                    </dropdown-item>
                    <dropdown-item
                        v-for="region in regionSearchResults"
                        :key="region.uuid"
                        :class="{'world-map__dropdowns-active': region.active }"
                        @click="onSelectRegion(region)">
                        <span class="world-map__dropdowns-checkmark" />
                        <span class="world-map__dropdowns-option">{{ region.title }}</span>
                    </dropdown-item>
                </vit-dropdown>
                <!-- categories -->
                <vit-dropdown
                    :label="categoryLabel"
                    multiple
                    light>
                    <dropdown-item @click="onSelectCategory(null)" :class="{'world-map__dropdowns-active': allCategories }">
                        <span class="world-map__dropdowns-checkmark" />
                        <span class="world-map__dropdowns-option">{{ allCategoriesLabel }}</span>
                    </dropdown-item>
                    <dropdown-item
                        v-for="filter in filters"
                        :key="filter.uuid"
                        :label="filter.title"
                        :class="{'world-map__dropdowns-active': filter.active }"
                        @click="onSelectCategory(filter)">
                        <span class="world-map__dropdowns-checkmark" :class="`world-map__dropdowns-checkmark__${filter.icon}`" />
                        <span class="world-map__dropdowns-option">{{ filter.title }}</span>
                    </dropdown-item>
                </vit-dropdown>
            </div>
        </transition>
        <!-- search bar -->
        <search-bar
            :visible="searchVisible"
            @search="onSearch"
            @result-clicked="gotoLocation"
            :results="isMobile ? [] : searchResults"
            :placeholder="searchPlaceholder" />
        <!-- map -->
        <div class="world-map-relative">
            <google-map
                v-if="locations.length > 0"
                :zoom="zoom"
                @map-zoom="zoom = $event"
                :center="position"
                @map-center="position = $event"
                :zoom-to-bounds="true"
                large
                :api-key="apiKey"
                :markers="locations"
                @marker-click="onMarkerClick"
                ref="googlemap" />
            <!-- selected location for mobile -->
            <div v-if="selectedLocation && isMobile">
                <div :class="`world-map__info-box world-map__info-box--${selectedLocation.category.icon}`">
                    <div class="world-map__info-box-name">
                        <div>
                            {{ selectedLocation.location.title }}
                        </div>
                        <icon name="icn-cross" class="world-map__info-box-name-icon" @click.native="selectedLocation = null" />
                    </div>
                    <div class="world-map__info-box-description">
                        <!-- regional map -->
                        <template v-if="detailed">
                            <div class="location-info location-info-bold" v-if="selectedLocation.description">
                                {{ selectedLocation.description }}
                            </div>
                            <div class="location-info" v-if="selectedLocation.optional">
                                {{ selectedLocation.optional }}
                            </div>
                            <div class="location-info" v-if="selectedLocation.addressLine1 || selectedLocation.addressLine2">
                                <div>{{ selectedLocation.addressLine1 }}</div>
                                <div>{{ selectedLocation.addressLine2 }}</div>
                                <div>{{ selectedLocation.cityZipCode }}</div>
                            </div>
                            <div v-if="selectedLocation.phone || selectedLocation.fax" class="location-info">
                                <a v-if="selectedLocation.phone" class="button button--linkover" :href="`tel:${selectedLocation.phone}`">
                                    T: {{ selectedLocation.phone }}
                                </a>
                                <div v-if="selectedLocation.fax">
                                    F: {{ selectedLocation.fax }}
                                </div>
                            </div>
                            <div v-if="selectedLocation.email" class="location-info">
                                <a class="button button--link" :href="`mailto:${selectedLocation.email}`">Email</a>
                            </div>
                            <div class="location-info" v-if="selectedLocation.link && selectedLocation.link.url && selectedLocation.link.title">
                                <a class="button button--link" :href="selectedLocation.link.url" :target="selectedLocation.link.target">{{ selectedLocation.link.title }}</a>
                            </div>
                        </template>
                        <!-- world map -->
                        <template v-else>
                            <div class="location-info">
                                {{ selectedLocation.description }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <!-- accordions at mobile -->
        <div class="world-map__locations">
            <div v-for="location in filteredLocations" :key="location.uuid">
                <div class="world-map__locations-region" :key="location.regionId" v-if="location.isRegionChanged && location.regionTitle">
                    {{ location.regionTitle }}
                </div>
                <vit-accordion
                    :key="location.uuid"
                    :title="location.location.title">
                    <template #toggle>
                        <span
                            class="world-map__category-marker"
                            :class="`world-map__category-marker__${location.icon}`">
                            {{ location.location.title }}
                        </span>
                    </template>
                    <div
                        class="world-map__info-box-description world-map__info-box-description-dropdown"
                        :class="`world-map__info-box-description--${location.icon}`">
                        <!-- regional map -->
                        <template v-if="detailed">
                            <div class="location-info location-info-country">
                                {{ location.country }}
                            </div>
                            <div class="location-info location-info-bold">
                                {{ location.description }}
                            </div>
                            <div class="location-info" v-if="location.optional">
                                {{ location.optional }}
                            </div>
                            <div class="location-info" v-if="location.addressLine1 || location.addressLine2">
                                <div>{{ location.addressLine1 }}</div>
                                <div>{{ location.addressLine2 }}</div>
                                <div>{{ location.cityZipCode }}</div>
                            </div>
                            <div v-if="location.phone || location.fax" class="location-info">
                                <a v-if="location.phone" class="button button--linkover" :href="`tel:${location.phone}`">T: {{ location.phone }}</a>
                                <div v-if="location.fax">
                                    F: {{ location.fax }}
                                </div>
                            </div>
                            <div v-if="location.email" class="location-info">
                                <a class="button button--link" :href="`mailto:${location.email}`">Email</a>
                            </div>
                        </template>
                        <!-- world map -->
                        <template v-else>
                            <div class="location-info location-info-country">
                                {{ location.country }}
                            </div>
                            <div class="location-info">
                                {{ location.description }}
                            </div>
                        </template>
                    </div>
                </vit-accordion>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
// to get isMobile from store
import { mapState } from 'pinia';
import { useMyStore } from '../store';
import GoogleMap from './base/GoogleMap.vue';
import { LOCATION_CATEGORIES_ENDPOINT, LOCATIONS_ENDPOINT, REGIONS_ENDPOINT } from '../Constants';
import Icon from './atoms/Icon.vue';
import DropdownItem from './base/VitDropdownItem.vue';
import SearchBar from './base/SearchBar.vue';
import VitDropdown from './base/VitDropdown.vue';
import VitAccordion from './base/VitAccordion.vue';

export default defineComponent({
    components: { VitAccordion, VitDropdown, GoogleMap, Icon, SearchBar, DropdownItem },
    props: {
        title: { type: String },
        apiKey: { type: String, required: true },
        // Labels
        categoryLabel: { type: String, required: true },
        allCategoriesLabel: { type: String, required: true },
        regionLabel: { type: String, required: true },
        allRegionsLabel: { type: String, required: true },
        searchPlaceholder: { type: String, required: true },
        detailed: { type: Boolean, default: true }

    },
    data() {
        return {
            filters: [],
            locations: [],
            regions: [],
            searchResults: [],
            zoom: 2,
            position: null,
            selectedLocation: null,
            searchVisible: false,
            filtersVisible: false,
            regionSearch: '',
            regionSearchResults: []
        };
    },

    /**
     * LIFECYCLE
     */
    async created() {
        await this.getCategories();
        await this.getRegions();
        await this.getLocations();
    },

    methods: {
        /**
         * GET INFOS / FETCH
         */
        async getCategories() {
            this.filters = (await axios.get(`${this.$contextPath}${LOCATION_CATEGORIES_ENDPOINT}`)).data.map(cat => ({
                ...cat,
                active: false
            }));
        },

        async getLocations() {
            const locations = (await axios.get(`${this.$contextPath}${LOCATIONS_ENDPOINT}`)).data;

            this.locations = locations.map(x => {
                const category = this.filters.find(c => c.uuid === x.categoryId);
                const icon = category ? category.icon : null;
                const iconURL = icon ? `${this.$resourcePath}img/marker-${category.icon}.png` : null;
                const { lat, lng, title } = x.location;
                return {
                    ...x,
                    id: x.uuid,
                    icon,
                    iconURL,
                    category,
                    location: {
                        title,
                        lat: parseFloat(lat),
                        lng: parseFloat(lng)
                    }
                };
            });
            this.regions = this.regions.filter(r => this.locations.some(l => l.regionId === r.uuid));
        },

        async getRegions() {
            this.regions = (await axios.get(`${this.$contextPath}${REGIONS_ENDPOINT}`)).data.map(region => ({
                ...region,
                active: false
            }));
            this.regionsSearchResults();
        },
        /**
         * EVENT HANDLER
         */
        // go to location - used at search location
        gotoLocation(id) {
            this.searchVisible = false;
            const location = this.locations.find(x => x.uuid === id);
            if (location) {
                const { lat, lng } = location.location;
                this.position = { lat, lng };
                this.zoom = 10;
                this.onMarkerClick(location.uuid);
            }
        },

        // open/close search option
        toggleSearch() {
            this.searchVisible = !this.searchVisible;
            if (this.searchVisible) {
                this.filtersVisible = false;
            }
        },
        // open/close filters options
        toggleFilters() {
            this.filtersVisible = !this.filtersVisible;
            if (this.filtersVisible) {
                this.searchVisible = false;
            }
        },

        // show info of location on click
        onMarkerClick(id) {
            if (this.$refs.googlemap) {
                const location = this.locations.find(x => x.uuid === id);
                this.selectedLocation = location;

                let description;
                if (this.detailed) { // used for region map
                    description = location.description ? `<div class="location-info location-info-bold">${location.description}</div>` : '';
                    description += location.optional ? `<div class="location-info">${location.optional}</div>` : '';
                    if (location.addressLine1 || location.addressLine2) {
                        description += '<div class="location-info">';
                        description += location.addressLine1 ? `<div>${location.addressLine1}</div>` : '';
                        description += location.addressLine2 ? `<div>${location.addressLine2}</div>` : '';
                        description += location.cityZipCode ? `<div>${location.cityZipCode}</div>` : '';
                        description += '</div>';
                    }
                    const phone = location.phone ? `<a class="button button--linkover" :href="tel:${location.phone}">T: ${location.phone}</a>` : '';
                    const fax = location.fax ? `<div>F: ${location.fax}</div>` : '';
                    description += (phone || fax) ? `<div class="location-info">${phone}${fax}</div>` : '';
                    description += (location.email) ? `<div class="location-info"><a class="button button--link" href="mailto:${location.email}">Email</a></div>` : '';
                    description += (location.link && location.link.url && location.link.title) ? `<div class="location-info"><a class="button button--link" href="${location.link.url}" target="${location.link.target}">${location.link.title}</a></div>` : '';
                } else { // used for world map
                    description = location.description ? `<div class="location-info">${location.description}</div>` : '';
                }

                if (!this.isMobile) {
                    this.$refs.googlemap.showInfoBox(
                        location.uuid,
                        `
                            <div class="world-map__info-box world-map__info-box--${location.category.icon}">
                                <div class="world-map__info-box-name">${location.location.title}</div>
                                <div class="world-map__info-box-description">${description}</div>
                            </div>
                        `
                    );
                }
            }
        },

        // search location
        onSearch(searchValue) {
            this.searchResults = [];
            if (searchValue.length >= 3) {
                this.searchResults = this.locations
                    .filter(x => x.location.title.toLowerCase().includes(searchValue.toLowerCase()))
                    .map(x => ({ title: x.location.title, id: x.uuid }));
            }
        },

        // select region
        onSelectRegion(region) {
            if (!region) {
                this.regions.forEach(regionS => {
                    regionS.active = false;
                });
            // if category update active
            } else {
                region.active = !region.active;
            }
        },

        // select/deselect categories
        onSelectCategory(category) {
            // if null clean categories
            if (!category) {
                this.filters.forEach(filter => {
                    filter.active = false;
                });
            // if category update active
            } else {
                category.active = !category.active;
            }
        },
        // hide/show locations at map
        visibleLocations() {
            if (this.locations && this.filteredLocations && this.$refs.googlemap) {
                this.locations.forEach(location => {
                    if (this.filteredLocations.find(showLocation => showLocation.uuid === location.uuid)) {
                        this.$refs.googlemap.showMarker(location.uuid);
                    } else {
                        this.$refs.googlemap.hideMarker(location.uuid);
                    }
                });
                this.position = this.getCenter();
                this.zoom = this.getZoom();
            }
        },

        // map functions to center and zoom
        getCenter(): google.maps.LatLngLiteral {
            return this.filteredLocations.length ? {
                lat: this.filteredLocations.reduce((a, b) => a + b.location.lat, 0) / this.filteredLocations.length,
                lng: this.filteredLocations.reduce((a, b) => a + b.location.lng, 0) / this.filteredLocations.length
            } : this.$refs.googlemap.mapCenter;
        },

        getZoom(): number {
            // get bounds
            const bounds = new this.$refs.googlemap.google.maps.LatLngBounds();
            if (this.filteredLocations.length < 1) {
                return this.$refs.googlemap.mapZoom;
            }
            this.filteredLocations.forEach(p => {
                bounds.extend(new this.$refs.googlemap.google.maps.LatLng(p.location.lat, p.location.lng));
            });
            // get zoom based on marker bounds
            return this.$refs.googlemap.getBoundsZoomLevel(bounds);
        },
        regionsSearchResults() {
            if (this.regionSearch.length > 2) {
                const search = this.regionSearch.toLowerCase();
                this.regionSearchResults = this.regions.filter(region => region.title.toLowerCase().includes(search));
            } else {
                this.regionSearchResults = this.regions;
            }
        }
    },

    computed: {
        // need for show filtered name at bottom in mobile
        ...mapState(useMyStore, ['isMobile']),
        // filter locations by regions selected
        filteredLocations() {
            let lastRegionId = null;
            return this.locations
                .filter(l => this.allRegions || this.regions.find(region => l.regionId === region.uuid)?.active)
                .filter(l => this.allCategories || this.filters.find(category => l.categoryId === category.uuid)?.active)
                .filter(l => this.searchResults.length === 0 || this.searchResults.find(x => x.id === l.uuid))
                .map(l => {
                    const region = this.regions.find(r => r.uuid === l.regionId);
                    const regionTitle = region ? region.title : '';
                    const isRegionChanged = l.regionId !== lastRegionId;
                    lastRegionId = l.regionId;
                    return { ...l, isRegionChanged, regionTitle };
                });
        },

        // see if have any category selected
        allCategories() {
            // if all true active the all selected
            if (this.filters.every(filter => filter.active)) {
                this.filters.forEach(filter => {
                    filter.active = false;
                });
            }
            return this.filters.every(category => !category.active);
        },
        // see if have any region selected
        allRegions() {
            // if all true active the all selected
            if (this.regions.every(region => region.active)) {
                this.regions.forEach(region => {
                    region.active = false;
                });
            }
            return this.regions.every(region => !region.active);
        }
    },

    watch: {
        filtersVisible(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    if (this.$refs.filtersdropdowns) {
                        (this.$refs.filtersdropdowns as HTMLElement).focus();
                    }
                });
            }
        },
        filteredLocations() {
            this.visibleLocations();
        },
        regionSearch() {
            this.regionsSearchResults();
        }
    }

});

</script>

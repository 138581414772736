import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "breadcrumbs__list" }
const _hoisted_2 = {
  key: 0,
  class: "breadcrumbs__list-item"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 1,
  class: "breadcrumbs__list-item"
}
const _hoisted_5 = { class: "breadcrumbs__list-item" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "breadcrumbs__list-item" }
const _hoisted_8 = { class: "breadcrumbs__link button button--link button--disabled" }
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ol", _hoisted_1, [
    (_ctx.breadcrumbs.length)
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
          _createElementVNode("a", {
            class: "breadcrumbs__link button button--link",
            href: _ctx.breadcrumbs[0].link
          }, _toDisplayString(_ctx.breadcrumbs[0].label), 9, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.showAll && _ctx.breadcrumbs.length > 3)
      ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
          _createElementVNode("span", {
            class: "breadcrumbs__link button button--link",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAll = true))
          }, "...")
        ]))
      : _createCommentVNode("", true),
    (!_ctx.showAll && _ctx.breadcrumbs.length > 3)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createElementVNode("li", _hoisted_5, [
            _createElementVNode("a", {
              class: "breadcrumbs__link button button--link",
              href: _ctx.breadcrumbs[_ctx.breadcrumbs.length - 2].link
            }, _toDisplayString(_ctx.breadcrumbs[_ctx.breadcrumbs.length - 2].label), 9, _hoisted_6)
          ]),
          _createElementVNode("li", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.breadcrumbs[_ctx.breadcrumbs.length - 1].label), 1)
          ])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.showAll || _ctx.breadcrumbs.length <= 3)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.breadcrumbs.slice(1), (breadcrumb, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "breadcrumbs__list-item",
            key: index
          }, [
            _createElementVNode("a", {
              class: "breadcrumbs__link button button--link",
              href: breadcrumb.link
            }, _toDisplayString(breadcrumb.label), 9, _hoisted_9)
          ]))
        }), 128))
      : _createCommentVNode("", true)
  ]))
}
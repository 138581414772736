<template>
    <div ref="vTab">
        <div v-show="isActive">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        title: { type: String, required: true },
        anchorId: { type: String, default: '' },
        narrow: { type: Boolean, default: false },
        capitalize: { type: Boolean, default: false },
        fixedWidth: { type: Boolean, default: false },
        contentWidth: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false }
    },
    data() {
        return {
            isActive: false
        };
    },

    watch: {
        disabled() {
            this.$parent.updatedContent();
        }
    },

    mounted() {
        this.$parent.registerTab(this);
    },

    methods: {
        // return true if current location has a hash and is the element id - used at VitTabs
        hasHashToAnchorID() {
            return window.location.hash && window.location.hash === `#${this.anchorId}`;
        },

        // for the tabs
        capitalizeFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        },

        containsOnlyUppercase(str) {
            return /^[A-Z0-9\s]+$/.test(str);
        }
    },

    computed: {
        titlePrint() {
            return (this.capitalize || this.containsOnlyUppercase(this.title)) ? this.capitalizeFirstLetter(this.title) : this.title;
        }
    }
});

</script>

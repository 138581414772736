import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "map__container" }
const _hoisted_2 = {
  key: 0,
  class: "map__zoom-controls"
}
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "map",
      class: _normalizeClass(["map", { 'map--large': _ctx.large }])
    }, null, 2),
    (!_ctx.staticMap)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "map__zoom-btn",
            disabled: _ctx.mapZoom >= _ctx.MAX_ZOOM,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mapZoom++)),
            "aria-label": "zoom in"
          }, [
            _createVNode(_component_icon, {
              name: "icn-plus",
              class: "map__zoom-btn-icon"
            })
          ], 8, _hoisted_3),
          _createElementVNode("button", {
            class: "map__zoom-btn",
            disabled: _ctx.mapZoom === _ctx.MIN_ZOOM,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mapZoom--)),
            "aria-label": "zoom out"
          }, [
            _createVNode(_component_icon, {
              name: "icn-minus",
              class: "map__zoom-btn-icon"
            })
          ], 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true)
  ]))
}
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav-item main-navigation__item" }
const _hoisted_2 = ["href", "target", "rel"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("a", {
      href: _ctx.link,
      target: _ctx.target,
      class: _normalizeClass(["nav-link main-navigation__item-link", {'active': _ctx.isActive}]),
      rel: '_blank' === _ctx.target ? 'noopener noreferrer' : null
    }, _toDisplayString(_ctx.title), 11, _hoisted_2)
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "share__flex" }
const _hoisted_2 = { class: "share__text" }
const _hoisted_3 = { class: "share__share" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_collapse_transition = _resolveComponent("collapse-transition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_icon, {
        class: _normalizeClass(["share__share__icon", {'share__share__icon--active': _ctx.isOpen}]),
        name: "icn-share",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
      }, null, 8, ["class"]),
      _createVNode(_component_collapse_transition, { dimension: "width" }, {
        default: _withCtx(() => [
          (_ctx.isOpen)
            ? (_openBlock(), _createElementBlock("ul", {
                key: 0,
                class: "share__share__list",
                onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"]))
              }, [
                _createElementVNode("div", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.share('facebook'))),
                  class: "share__share__list__icon"
                }, [
                  _createVNode(_component_icon, {
                    class: "share__share__list__icon--icon",
                    name: "icn-facebook"
                  })
                ]),
                _createElementVNode("div", {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.share('linkedin'))),
                  class: "share__share__list__icon"
                }, [
                  _createVNode(_component_icon, {
                    class: "share__share__list__icon--icon",
                    name: "icn-linkedin"
                  })
                ]),
                _createElementVNode("div", {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.share('socialX'))),
                  class: "share__share__list__icon"
                }, [
                  _createVNode(_component_icon, {
                    class: "share__share__list__icon--icon",
                    name: "icn-social-x"
                  })
                ]),
                _createElementVNode("div", {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.share('whatsapp'))),
                  class: "share__share__list__icon"
                }, [
                  _createVNode(_component_icon, {
                    class: "share__share__list__icon--icon",
                    name: "icn-whatsapp"
                  })
                ]),
                _createElementVNode("div", {
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.share('email'))),
                  class: "share__share__list__icon"
                }, [
                  _createVNode(_component_icon, {
                    class: "share__share__list__icon--icon",
                    name: "icn-email"
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}
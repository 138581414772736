<template>
    <input
        class="form__input"
        :class="{'form-input-required' : requiredfield}"
        type="text"
        :value="maskedValue"
        :placeholder="placeholder"
        :ref="'inputRef'"
        @input="handleInput">
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        placeholder: { default: '', type: String },
        initialValue: { default: 0, type: Number },
        index: { default: 0, type: Number },
        requiredfield: { default: false, type: Boolean },
        dollar: { default: false, type: Boolean }
    },
    data() {
        return {
            valueNumber: 0,
            valueNumberString: '',
            valueString: ''
        };
    },

    created() {
        if (this.initialValue) {
            this.valueNumber = this.initialValue;
            this.valueString = this.initialValue.toString();
        } else if (this.initialValue === 0) {
            this.valueNumber = this.initialValue;
            this.valueString = '0';
        }
    },

    methods: {
        handleInput(event) {
            // remove everything that is not number or dot
            event.target.value = event.target.value.replace(/[^0-9.]/g, '');

            if (event.target.value.split('.').length - 1 > 1) {
                // if is have more more than a dot, put values was before
                event.target.value = this.valueString;
            } else if (event.target.value.split('.').length - 1 === 1) {
                // verify is there 1 dot
                this.valueString = event.target.value;
                if (event.target.value[event.target.value.length - 1] === '.') {
                    // if the dot is in last position save valueNumberString without the dot
                    this.valueNumberString = this.valueString.substring(0, this.valueString.length - 1);
                } else if (event.target.value.split('.')[1].length >= 3) {
                    // if have more than 3 values after the dot, remove excedent numbers
                    this.valueString = this.valueString.substring(0, event.target.value.split('.')[0].length + 4);
                    this.valueNumberString = this.valueString;
                } else {
                    this.valueNumberString = this.valueString;
                }
            } else {
                this.valueString = event.target.value;
                this.valueNumberString = this.valueString;
            }
            // pass valueStringNumber to valueNumber
            this.valueNumber = Number(this.valueNumberString);
            this.$emit('modelvalue', this.valueNumber, this.index);
        }
    },

    computed: {
        maskedValue() {
            if (this.valueString === '0' || (this.valueString === '' && !this.placeholder)) {
                return this.dollar ? '$0' : '0';
            }
            return this.valueNumber
                ? (this.dollar ? '$' : '')
                    + this.valueNumber.toLocaleString('en-US')
                    + (this.valueString[this.valueString.length - 1] === '.' ? '.' : '')
                : '';
        }
    }
});

</script>

import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "accordion__header" }
const _hoisted_3 = { class: "accordion__toggle-text" }
const _hoisted_4 = { class: "accordion__toggle-icon-wrapper" }
const _hoisted_5 = {
  key: 0,
  class: "accordion__toggle-icon-text"
}
const _hoisted_6 = {
  key: 1,
  class: "accordion__toggle-icon-text"
}
const _hoisted_7 = {
  key: 0,
  class: "accordion__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_collapse_transition = _resolveComponent("collapse-transition")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["accordion", { 'accordion--inset': _ctx.inset, 'accordion--narrow': _ctx.narrow, 'accordion--expanded': _ctx.opened, 'accordion--mobile': _ctx.mobileOnly }]),
    id: _ctx.anchorId
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "accordion__toggle",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleAccordion && _ctx.toggleAccordion(...args)))
      }, [
        _createElementVNode("span", _hoisted_3, [
          _renderSlot(_ctx.$slots, "toggle", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.expandText && !_ctx.opened)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.expandText), 1))
            : _createCommentVNode("", true),
          (_ctx.collapseText && _ctx.opened)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.collapseText), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_icon, {
            name: _ctx.opened ? 'icn-upload' : 'icn-download',
            class: "accordion__toggle-icon"
          }, null, 8, ["name"])
        ])
      ])
    ]),
    _createVNode(_component_collapse_transition, null, {
      default: _withCtx(() => [
        (_ctx.opened)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _renderSlot(_ctx.$slots, "default")
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 10, _hoisted_1))
}
<template>
    <div class="segregation-plan__filter">
        <!-- Desktop -->
        <div class="segregation-plan__filters">
            <!-- regions -->
            <vit-dropdown
                v-if="allSites.length"
                :label="translations.region"
                inline
                light
                dark-menu
                multiple
                rounded>
                <dropdown-item @click="selectAllRegions">
                    <div
                        class="segregation-plan__filter-item"
                        :class="{ 'segregation-plan__filter-item--active': allRegionsSelected }">
                        {{ translations.allRegions }}
                    </div>
                </dropdown-item>
                <dropdown-item v-for="region in regions" :key="'filter-' + region" @click="selectRegion(region)">
                    <div
                        class="segregation-plan__filter-item"
                        :class="{ 'segregation-plan__filter-item--active': selectedRegions.find(x => x === region) }">
                        {{ region }} region
                    </div>
                </dropdown-item>
            </vit-dropdown>
            <div v-else-if="status.sitesRegion">
                {{ status.sitesRegion }}
            </div>
            <loading-spinner v-else />
            <!-- sites -->
            <vit-dropdown
                v-if="allSites.length"
                :label="translations.site"
                inline
                light
                dark-menu
                multiple
                rounded
                @close="onDropdownClose">
                <dropdown-item>
                    <div class="segregation-plan__filter-item segregation-plan__filter-item--search">
                        <icon name="icn-search" class="icon" />
                        <input type="text" class="segregation-plan__filter-search" @input="onSearchSites">
                    </div>
                </dropdown-item>
                <dropdown-item @click="selectAllSites">
                    <div
                        class="segregation-plan__filter-item"
                        :class="{ 'segregation-plan__filter-item--active': allSitesSelected }">
                        {{ translations.allSites }}
                    </div>
                </dropdown-item>
                <dropdown-item v-for="site in filteredSites" :key="'filter-' + site.sId" @click="selectSite(site.sId)">
                    <div
                        class="segregation-plan__filter-item"
                        :class="{ 'segregation-plan__filter-item--active': selectedSites.find(x => x.sId === site.sId) }">
                        {{ site.sName }}
                    </div>
                </dropdown-item>
            </vit-dropdown>
            <div v-else-if="selectedRegions.length === 0 && regionSitesLoaded" class="segregation-plan__select-region">
                {{ translations.noRegion }}
            </div>
            <div v-else-if="status.sites">
                {{ status.sites }}
            </div>
            <loading-spinner v-else />
        </div>
        <!-- Mobile -->
        <div class="segregation-plan__filters--mobile">
            <vit-accordion-group group="segregationplan">
                <!-- regions -->
                <vit-accordion
                    v-if="regions.length"
                    :title="translations.region"
                    narrow
                    group="segregationplan">
                    <div
                        class="segregation-plan__filter-item"
                        :class="{ 'segregation-plan__filter-item--active': allRegionsSelected }"
                        @click="selectAllRegions">
                        {{ translations.allRegions }}
                    </div>
                    <div
                        v-for="region in regions"
                        :key="'filter-' + region"
                        class="segregation-plan__filter-item"
                        :class="{ 'segregation-plan__filter-item--active': selectedRegions.find(x => x === region) }"
                        @click="selectRegion(region)">
                        {{ region }}
                    </div>
                </vit-accordion>
                <div v-else-if="status.sitesRegion">
                    {{ status.sitesRegion }}
                </div>
                <loading-spinner v-else />
                <!-- sites -->
                <vit-accordion
                    v-if="allSites.length"
                    :title="translations.site"
                    narrow
                    group="segregationplan">
                    <div class="segregation-plan__filter-item segregation-plan__filter-item--search">
                        <icon name="icn-search" class="icon" />
                        <input type="text" class="segregation-plan__filter-search" @input="onSearchSites">
                    </div>
                    <div
                        class="segregation-plan__filter-item"
                        :class="{ 'segregation-plan__filter-item--active': allSitesSelected }"
                        @click="selectAllSites">
                        {{ translations.allSites }}
                    </div>
                    <div
                        v-for="site in filteredSites"
                        :key="'filter-' + site.sId"
                        class="segregation-plan__filter-item"
                        :class="{ 'segregation-plan__filter-item--active': selectedSites.find(x => x.sId === site.sId) }"
                        @click="selectSite(site.sId)">
                        {{ site.sName }}
                    </div>
                </vit-accordion>
                <div v-else-if="selectedRegions.length === 0" class="segregation-plan__select-region">
                    {{ translations.noRegion }}
                </div>
                <div v-else-if="status.sites">
                    {{ status.sites }}
                </div>
                <loading-spinner v-else />
            </vit-accordion-group>
        </div>
    </div>
</template>

<script lang="ts">
/* eslint-disable no-unused-vars */
import { defineComponent } from 'vue';
import debounce from 'lodash/debounce';
import Helper from '../../lib/Helper';
import DropdownItem from '../base/VitDropdownItem.vue';

// components
import LoadingSpinner from '../atoms/LoadingSpinner.vue';
import Icon from '../atoms/Icon.vue';

// segregation lib
import Segregation, { SiteInfo, CommodityInfo, GradeInfo, CommodityPricesInfo } from '../../lib/Segregation';
import VitDropdown from '../base/VitDropdown.vue';
import VitAccordionGroup from '../base/VitAccordionGroup.vue';
import VitAccordion from '../base/VitAccordion.vue';

const COOKIE_NAME = 'segregation_filters';

// Regions are hardcoded and cannot be obtained by an API call
enum Region {
    CENTRAL = 'Central',
    EASTERN = 'Eastern',
    WESTERN = 'Western'
}

export default defineComponent({
    components: { VitAccordion, VitAccordionGroup, VitDropdown, LoadingSpinner, Icon, DropdownItem },
    props: {
        baseUrl: { required: true, type: String },
        translations: { required: true, type: Object as () => Record<string, string> }
    },
    emits: [
        'siteSelected',
        'commodities',
        'refresh',
        'commoditiesPrices',
    ],
    data() {
        return {
            // all values
            regions: [
                Region.CENTRAL,
                Region.EASTERN,
                Region.WESTERN
            ] as Region[],
            allSites: [] as SiteInfo[],
            commodities: [] as CommodityInfo[],
            commoditiesPrices: [] as CommodityPricesInfo[],

            // values selected
            selectedSites: [] as SiteInfo[],
            selectedRegions: [] as Region[],

            // aux
            sitesFilter: '',
            onSearchSites: null,

            // fetch aux
            loaded: false,
            regionSitesLoaded: false,
            status: {
                sites: '',
                sitesRegion: '',
                sitesDetails: '',
                commodities: '',
                gradeStatus: ''
            }
        };
    },

    computed: {
        /*************
         * Computed
         ************/
        // if all regions are selected
        allRegionsSelected(): boolean {
            return this.selectedRegions.length === this.regions.length;
        },
        // if all sites are selected
        allSitesSelected(): boolean {
            return this.selectedSites.length === this.allSites.length;
        },
        // sites visible at the dropdown (with or without search input)
        filteredSites(): SiteInfo[] {
            return this.allSites.filter(x => x.sName.toLowerCase().includes(this.sitesFilter))
                .sort((a: SiteInfo, b: SiteInfo) => (a.sName < b.sName ? -1 : 1));
        },
        // query with parameters
        filterQuery(): string {
            if (!this.loaded) {
                return '';
            }

            let regions: string = '';
            let sites: string = '';

            if (this.selectedRegions.length) {
                regions = `regions=${this.selectedRegions.join(',')}`;
            }
            if (this.selectedSites.filter(x => this.selectedRegions.indexOf(x.sRegion) < 0).length) {
                sites = `sites=${this.selectedSites.filter(x => this.selectedRegions.indexOf(x.sRegion) < 0).map(x => x.sName).join(',')}`;
            }

            const query: string = `${[regions, sites].filter(x => x.length).join('&')}`;

            return query.length ? `?${query}` : '';
        }
    },

    watch: {
        selectedSites() {
            this.$emit('siteSelected', this.selectedSites);
            this.refreshPrices();
        },
        filterQuery() {
            if (history.pushState) {
                const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${this.filterQuery}`;
                window.history.pushState({ path: newUrl }, '', newUrl);
            }
            Helper.setCookie(COOKIE_NAME, this.filterQuery);
        }
    },

    created() {
        this.onSearchSites = debounce(this.searchSites, 350);
    },

    async mounted() {
        try {
            // sites
            const { sites, sitesStatus, sitesRegionStatus, sitesDetailsStatus } = await Segregation.getSitesComplete(this.baseUrl);
            this.allSites = sites;
            this.status.sites = this.handleError(sitesStatus, 'sites');
            this.status.sitesRegion = this.handleError(sitesRegionStatus, 'sites regions');
            this.status.sitesDetails = this.handleError(sitesDetailsStatus, 'sites details');
            this.regionSitesLoaded = true;
            // commodities
            const { commodities, commoditiesStatus, allGradeStatus } = await Segregation.getCommoditiesWithGrades(this.baseUrl);
            this.commodities = commodities;
            this.status.commodities = this.handleError(commoditiesStatus, 'commodities');
            this.status.gradeStatus = this.handleError(allGradeStatus, 'commodities');
            this.$emit('commodities', this.commodities);
        } catch (e) {
            //
        }

        // if url doesn't have parameters, check and use cookie ones
        if (!this.updateFilters(window.location.search)) {
            this.checkCookie();
        }

        await this.refreshPrices();
        this.$emit('siteSelected', this.selectedSites);
        this.loaded = true;
        this.loaded = true;
    },

    methods: {
        /**
         * Sites
         */
        onDropdownClose() {
            this.sitesFilter = '';
        },
        searchSites(event: InputEvent) {
            this.sitesFilter = (event.target as HTMLInputElement).value.toLowerCase();
        },
        // selects or deselects all sites
        selectAllSites() {
            if (this.allSitesSelected) {
                // deselect all
                this.selectedRegions = [];
                this.selectedSites = [];
            } else {
                this.selectedRegions = [...this.regions];
                this.selectedSites = [...this.allSites];
            }

            // refreshPrices is triggered by watch

            this.$forceUpdate();
        },
        /**
         * toggle a site from the selected sites list
         * @param siteId the id of the site to toggle
         * @param force force select/deselect instead of toggle (-1/negative = deselect, 0 = toggle, 1/positive = select)
         * @param updateRegions if true, checks if a region needs to be selected/deselected based on on the selected sites
         */
        selectSite(siteId: string, force: number = 0, updateRegions = true) {
            const index: number = this.selectedSites.findIndex(x => x.sId === siteId);

            if (index < 0 && force >= 0) {
                // not yet selected, add to list of selected sites
                const selectedSite: SiteInfo = this.allSites.find(x => x.sId === siteId);
                this.selectedSites.push(selectedSite);
            } else if (force <= 0) {
                // region was selected and is now deselected, remove from list
                this.selectedSites.splice(index, 1);
            }

            if (updateRegions) {
                this.updateRegions();
            }
            // Force reactivity
            this.selectedSites = [...this.selectedSites];
            // refreshPrices is triggered by watch
            this.$forceUpdate();
        },

        /**
         * Regions
         */
        // select or deselect all regions
        selectAllRegions() {
            let select = 0;
            if (this.allRegionsSelected) {
                // deselect all regions
                this.selectedRegions = [];
                select = -1;
            } else {
                // select all regions
                this.selectedRegions = [...this.regions];
                select = 1;
            }

            this.allSites.forEach((site: SiteInfo) => {
                this.selectSite(site.sId, select, false);
            });

            this.$forceUpdate();
        },
        selectRegion(region: Region) {
            const index: number = this.selectedRegions.findIndex(x => x === region);

            if (index < 0) {
                // not yet selected, add to list of selected regions
                const selectedRegion: Region = this.regions.find(x => x === region);
                this.selectedRegions.push(selectedRegion);

                if (this.allRegionsSelected) {
                    this.selectAllSites();
                } else {
                    this.allSites.filter((s: SiteInfo) => s.sRegion === region).forEach((site: SiteInfo) => {
                        this.selectSite(site.sId, 1, false);
                    });
                }
            } else {
                // region was selected and is now deselected, remove from list
                this.selectedRegions.splice(index, 1);
                this.allSites.filter((s: SiteInfo) => s.sRegion === region).forEach((site: SiteInfo) => {
                    this.selectSite(site.sId, 0, false);
                });
            }

            this.$forceUpdate();
        },
        updateRegions() {
            // deselect regions where not all sites are selected
            this.selectedRegions.forEach((region: Region) => {
                if (!this.allSites.filter((site: SiteInfo) => site.sRegion === region).every((site: SiteInfo) => this.selectedSites.findIndex(x => x.sId === site.sId) >= 0)) {
                    this.selectedRegions.splice(this.selectedRegions.findIndex(r => r === region), 1);
                }
            });
            // select region after all its sites have been selected
            this.regions.forEach((region: Region) => {
                if (this.selectedSites.filter((site: SiteInfo) => site.sRegion === region).length === this.allSites.filter((site: SiteInfo) => site.sRegion === region).length) {
                    if (this.selectedRegions.indexOf(region) < 0) {
                        this.selectedRegions.push(region);
                    }
                }
            });
        },

        /**
         * Prices
         */
        async refreshPrices() {
            this.$emit('refresh');
            try {
                this.commoditiesPrices = await Segregation.fetchCommoditiesPrices(this.baseUrl, this.selectedSites);
                this.updateCommodityAvailability();
                this.$emit('commoditiesPrices', this.commoditiesPrices);
            } catch (e) {
                // requests got cancelled -> ignore
            }
        },
        updateCommodityAvailability() {
            // TODO ENHANCEMENT MK: this can probably be improved,... but how?!
            this.commodities.forEach(commodity => {
                let hasAnyGrade: boolean = false;

                if (commodity.grades && commodity.grades.length > 0) {
                    commodity.grades.forEach((grade: GradeInfo) => {
                        this.commoditiesPrices.forEach((commodityPrice: CommodityPricesInfo) => {
                            commodityPrice.priceLines.forEach(priceLine => {
                                priceLine.gradePrices.forEach(gradePrice => {
                                    if (gradePrice.gId === grade.gId) {
                                        hasAnyGrade = true;
                                    }
                                });
                            });
                        });
                    });
                }
                commodity.available = hasAnyGrade;
            });
        },
        /**
         * Filters
         */
        updateFilters(filter: string): boolean {
            let filterSet: boolean = false;
            const filterParams: URLSearchParams = new URLSearchParams(filter);

            if (filterParams.has('regions')) {
                filterSet = true;
                const preselectedRegions: string[] = filterParams.get('regions').split(',');

                this.regions.filter((r: Region) => preselectedRegions.some((pr: string) => pr === r))
                    .forEach(region => {
                        this.selectRegion(region);
                    });
            }
            if (filterParams.has('sites')) {
                const preselectedSites: string[] = filterParams.get('sites').split(',');

                if (preselectedSites.length > 0) {
                    // determine preselected sites
                    const selectedSites: SiteInfo[] = this.allSites
                        .filter((s: SiteInfo) => preselectedSites.some((ps: string) => ps === s.sName));
                    // add to selected sites if not already present
                    selectedSites.forEach((s: SiteInfo) => {
                        if (this.selectedSites.findIndex((s2: SiteInfo) => s2.sId === s.sId) < 0) {
                            this.selectedSites.push(s);
                        }
                    });
                }
            }

            return filterSet;
        },

        checkCookie() {
            const cookieValue: string = Helper.getCookie(COOKIE_NAME);

            if (cookieValue && cookieValue !== this.filterQuery) {
                this.updateFilters(cookieValue);
            }
        },

        handleError(error, commodity): string {
            if (error === 200) {
                return '';
            }
            if (error === 504) {
                return `Timeout while fetching ${commodity}. Please try again.`;
            }
            return `Error while fetching ${commodity}. Please try again later.`;
        }
    }
});
</script>

<template>
    <div ref="allelement" class="drag_location-box" :class="{'drag_location-box-selected': mobileSelected }">
        <!-- map div -->
        <div class="drag_location-box-right">
            <slot name="static" />
        </div>
        <!-- locations div -->
        <div
            class="drag_location-box-left"
            :class="{'drag_location-box-left-open': openPopUpValue}"
            @mouseover="enableStoresScroll"
            @mouseleave="disableStoresScroll"
            @mousedown="mousedown"
            @mouseup="mouseup"
            @mousemove="mousemove"
            @touchstart="touchdown"
            @touchend="mouseup"
            @touchmove="touchmove">
            <!-- locations intro only for mobile -->
            <div class="drag_location-box-left-start">
                <div class="drag_location-box-left-cross" @click="closePopUPHandle()">
                    <icon name="icn-cross" class="icon" />
                </div>
                <!-- search and title-->
                <slot name="stickytop" />
            </div>
            <div v-if="isMobile">
                <slot name="mobileselected" />
            </div>
            <div class="drag_location-box-left-list" id="locationselement">
                <slot name="scroll" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '../atoms/Icon.vue';

export default defineComponent({
    components: { Icon },
    props: {
        openPopUp: { default: false, type: Boolean },
        mobileSelected: { default: false, type: Boolean }
    },
    data() {
        return {
            // for drag scrolling
            isDown: false,
            startY: 0,
            scrollTop: 0,
            isMobile: true,
            changeMobile: 992,
            openPopUpValue: this.openPopUp
        };
    },

    mounted() {
        // get elements after mounted
        this.$refs.allelement.addEventListener('scroll', this.openPopUpHandle);
        window.addEventListener('resize', this.updateSize);
        this.updateSize();
    },

    beforeUnmount() {
        this.$refs.allelement.removeEventListener('scroll', this.openPopUpHandle);
        window.removeEventListener('resize', this.updateSize);
    },

    methods: {
        updateSize() {
            this.isMobile = window.innerWidth <= this.changeMobile;
        },
        // for drag scrolling
        mousedown(event: MouseEvent) {
            if (this.isMobile) {
                this.isDown = true;
                this.$refs.allelement.style.overflowY = 'scroll';
                this.$refs.allelement.classList.add('active');
                this.startY = event.pageY - this.$refs.allelement.offsetTop;
                this.scrollTop = this.$refs.allelement.scrollTop;
            }
        },
        mouseup() {
            if (this.isMobile) {
                this.isDown = false;
                this.$refs.allelement.classList.remove('active');
                this.$refs.allelement.style.overflowY = 'hidden';
            }
        },
        mousemove(event: MouseEvent) {
            if (!this.isDown) return;
            if (this.isMobile) {
                event.preventDefault();
                const x = event.pageY - this.$refs.allelement.offsetTop;
                const walk = (x - this.startY) * 3; // scroll-fast
                this.$refs.allelement.scrollTop = this.scrollTop - walk;
            }
        },
        touchdown(event: TouchEvent) {
            if (this.isMobile) {
                this.isDown = true;
                this.$refs.allelement.style.overflowY = 'scroll';
                this.$refs.allelement.classList.add('active');
                this.startY = event.changedTouches[0].pageY - this.$refs.allelement.offsetTop;
                this.scrollTop = this.$refs.allelement.scrollTop;
            }
        },
        touchmove(event: TouchEvent) {
            if (!this.isDown) return;
            if (this.isMobile) {
                event.preventDefault();
                const x = event.changedTouches[0].pageY - this.$refs.allelement.offsetTop;
                const walk = (x - this.startY) * 3;
                this.$refs.allelement.scrollTop = this.scrollTop - walk;
            }
        },

        // for scroll when hover locations
        enableStoresScroll() {
            if (!this.openPopUpValue && this.isMobile) {
                this.$refs.allelement.style.overflowY = 'scroll';
            }
        },
        disableStoresScroll() {
            if (this.isMobile) {
                this.$refs.allelement.style.overflowY = 'hidden';
            }
        },

        // locations pop up
        openPopUpHandle() {
            if (this.$refs.allelement.scrollTop > 300 && this.isMobile) {
                this.openPopUpValue = true;
                document.body.style.overflow = 'hidden';
            }
        },
        closePopUPHandle() {
            if (this.isMobile) {
                this.openPopUpValue = false;
                document.body.style.removeProperty('overflow');
            }
        }
    },

    watch: {
        openPopUpValue() {
            this.$emit('openPopUp', this.openPopUpValue);
        }
    }
});

</script>

<template>
    <li class="dropdown__item dropdown__item-link button button--link" :class="{ 'dropdown__item--selected': selected }">
        <slot>{{ label }}</slot>
    </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
// to see
// @click="$emit('click', preventCloseDropdown)"
export default defineComponent({
    props: {
        label: { type: String, default: '' },
        /**
         * Prevents closing the dropdown when this item is clicked. Used for search field items when the dropdown is not multiselect.
         */
        preventCloseDropdown: { type: Boolean, default: false },
        selected: { type: Boolean, default: false }
    },
    inject: ['registerItem', 'unregisterItem'],
    mounted() {
        this.registerItem(this);
    },
    beforeUnmount() {
        this.unregisterItem(this);
    }
});

</script>

<template>
    <div>
        <slot />
    </div>
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue';
import eventBus from '../../lib/event';

export default defineComponent({
    props: {
        group: { type: String, default: '' }
    },
    data() {
        return {
            openAccordion: 0,
            items: []
        };
    },
    created() {
        provide('registerAccordion', this.registerAccordion);
        provide('unregisterAccordion', this.unregisterAccordion);
    },
    mounted() {
        eventBus.on('accordion-open', this.closeGroup);
    },
    beforeUnmount() {
        eventBus.off('accordion-open', this.closeGroup);
    },
    methods: {
        // called by child items
        registerAccordion(item) {
            this.items.push(item);
        },
        unregisterAccordion(item: any) {
            const index = this.items.indexOf(item);
            if (index !== -1) {
                this.items.splice(index, 1);
            }
        },
        // close group
        closeGroup(accordion) {
            if (accordion.group === this.group) {
                this.openAccordion = accordion.open;
                this.items.forEach(item => {
                    if (item.uid !== this.openAccordion) {
                        item.opened = false;
                    }
                });
            }
        }
    }
});
</script>

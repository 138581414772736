import eventBus from '../lib/event';

export default {
    mounted(element: HTMLElement, binding) {
        // create event listener
        element.addEventListener('click', e => {
            e.preventDefault();
            eventBus.emit('open-popup', binding.arg);
        });
    }
};

<template>
    <div :class=" currentPage === 3 ? 'calculator-box-results' : 'calculator-box'">
        <div class="calculator-box-content">
            <!-- Interaction on top-->
            <div v-if="currentPage !== 3" class="calculator-interaction">
                <div class="calculator-interaction-lines">
                    <div class="calculator-interaction-line" :class="{'calculator-interaction-line-active': currentPage > 0}" />
                    <div class="calculator-interaction-line" :class="{'calculator-interaction-line-active': currentPage > 1}" />
                </div>
                <div class="calculator-interaction-dots">
                    <div
                        v-for="(pageName, index) in pagesNames"
                        class="calculator-interaction-space"
                        :class="{'calculator-interaction-space-done': currentPage > index, 'calculator-interaction-space-active': currentPage === index}"
                        :key="index"
                        @click="goToPage(index)">
                        <div class="calculator-interaction-whitespace">
                            <div class="calculator-interaction-dot" />
                        </div>
                        <div class="calculator-interaction-info">
                            {{ pageName }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- Prev Page Button -->
            <div
                v-if="currentPage > 0"
                class="button button--reverse button--borderless"
                :class="{'button--white calculator-results-button': currentPage === 3}"
                @click="prevPage">
                {{ translations.backButton }}
            </div>
            <!-- First Page -->
            <div v-if="currentPage === 0">
                <div class="calculator-title calculator-title-first" v-if="pagesNames.length > 0">
                    {{ pagesNames[0] }}
                </div>
                <div>
                    <!-- Choose Commodity -->
                    <div class="form__label--rubik calculator-input_label">
                        {{ translations.chooseCommodity }} *
                    </div>
                    <div class="calculator-lgflex">
                        <label class="form__radio form__radio--nospace" v-for="(commodity,index) in commodities" :key="index">
                            <input
                                class="form__radio-input"
                                v-model="grain"
                                type="radio"
                                :value="commodity">
                            <div class="form__indicator--grey" :class="{'form__indicator--required': testFields && !grain}" />
                            <div class="form__label--rubik form__label--thin">{{ commodity }}</div>
                        </label>
                    </div>
                    <div v-if="testFields && !grain" class="calculator-field-required">
                        {{ translations.requiredfield }}
                    </div>
                </div>
                <div class="calculator-3table">
                    <!-- Inicial Values -->
                    <div>
                        <label class="form__label--rubik calculator-input_label">{{ translations.numbAcres }} *</label>
                        <input-mask
                            :initial-value="nAcres"
                            @modelvalue="onNAcres"
                            placeholder="10,000"
                            :requiredfield="testFields && !nAcres" />
                        <div v-if="testFields && !nAcres" class="calculator-field-required">
                            {{ translations.requiredfield }}
                        </div>
                    </div>
                    <div>
                        <label class="form__label--rubik calculator-input_label">{{ translations.marketPrice }} *</label>
                        <input-mask
                            :initial-value="marketPrice"
                            @modelvalue="onMarketPrice"
                            placeholder="$0"
                            dollar
                            :requiredfield="testFields && !marketPrice" />
                        <div v-if="testFields && !marketPrice" class="calculator-field-required">
                            {{ translations.requiredfield }}
                        </div>
                    </div>
                    <div>
                        <label class="form__label--rubik calculator-input_label">{{ translations.yieldBu }} *</label>
                        <input-mask
                            :initial-value="yieldBu"
                            @modelvalue="onYieldBu"
                            placeholder="0"
                            :requiredfield="testFields && !yieldBu" />
                        <div v-if="testFields && !yieldBu" class="calculator-field-required">
                            {{ translations.requiredfield }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- Second Page -->
            <div v-if="currentPage === 1">
                <div class="calculator-title" v-if="pagesNames.length > 1">
                    {{ pagesNames[1] }} <span class="calculator-title-info">({{ translations.variableCostsOptional }})</span>
                </div>
                <div class="calculator-subtitle" v-if="pagesNames.length > 1">
                    {{ pagesNames[1] }} ({{ translations.variableCostsPerAcre }})
                </div>
                <div class="calculator-3table">
                    <!-- Choose Commodity -->
                    <div v-for="(cost, index) in costsVariables" :key="'chose'+cost">
                        <label class="form__label--rubik calculator-input_label">{{ cost }}</label>
                        <input-mask
                            :initial-value="costsValues[index]"
                            :index="index"
                            dollar
                            @modelvalue="onCostsValues" />
                    </div>
                </div>
            </div>
            <!-- Third Page -->
            <div v-if="currentPage === 2">
                <div class="calculator-title" v-if="pagesNames.length > 2">
                    {{ pagesNames[2] }}
                </div>
                <div class="calculator-subtitle calculator-subtitle-mb-15" v-if="pagesNames.length > 0">
                    {{ pagesNames[0] }}
                </div>
                <div class="calculator-3table">
                    <div class="calculator-table">
                        <div>{{ translations.commodity }}</div>
                        <div class="calculator-value">
                            {{ grain }}
                        </div>
                        <div>{{ translations.numbAcres }}</div>
                        <div class="calculator-value">
                            {{ nAcres.toLocaleString('en-US') }}
                        </div>
                    </div>
                    <div class="calculator-table">
                        <div>{{ translations.marketPrice }}</div>
                        <div class="calculator-value">
                            $<span>{{ marketPrice.toLocaleString('en-US') }}</span>
                        </div>
                        <div>
                            {{ translations.yieldBu }}
                        </div>
                        <div class="calculator-value">
                            {{ yieldBu.toLocaleString('en-US') }}
                        </div>
                    </div>
                </div>
                <div class="calculator-subtitle calculator-subtitle-mb-15" v-if="pagesNames.length > 1">
                    {{ pagesNames[1] }} ({{ translations.variableCostsPerAcre }})
                </div>
                <div class="calculator-3table">
                    <div class="calculator-table" v-for="(cost, index) in costsVariables" :key="'chosen'+cost">
                        <div>{{ cost }}</div>
                        <div class="calculator-value">
                            $<span>{{ costsValues[index].toLocaleString('en-US') }}</span>
                        </div>
                    </div>
                </div>
                <div class="calculator-button-right">
                    <div class="button" @click="nextPage">
                        {{ translations.calculate }}
                    </div>
                </div>
            </div>
            <!-- Results Page -->
            <div v-if="currentPage === 3">
                <!-- Results table -->
                <div class="calculator-result-table">
                    <div class="calculator-result-table-start-col-2 ">
                        <div class="calculator-result-title">
                            {{ translations.marketIncomeAcre }}
                        </div>
                        <div class="calculator-result">
                            $ {{ marketIncomeAcre.toLocaleString('en-US', {minimumFractionDigits: 2}) }}
                        </div>
                    </div>
                    <div class="calculator-result-table-start-col-3">
                        <div class="calculator-result-title">
                            {{ translations.totalCosts }}
                        </div>
                        <div class="calculator-result">
                            $ {{ totalCosts.toLocaleString('en-US', {minimumFractionDigits: 2}) }}
                        </div>
                    </div>
                    <div class="calculator-result-table-start-col-3">
                        <div class="calculator-result-title">
                            {{ translations.netIncome }}
                        </div>
                        <div class="calculator-result">
                            $ {{ netIncome.toLocaleString('en-US', {minimumFractionDigits: 2}) }}
                        </div>
                    </div>
                    <div class="calculator-result-table-start-col-2 calculator-result-table-start-row-2">
                        <div class="calculator-result-title">
                            {{ translations.totalFarmIncome }}
                        </div>
                        <div class="calculator-result">
                            $ {{ totalFarmIncome.toLocaleString('en-US', {minimumFractionDigits: 2}) }}
                        </div>
                    </div>

                    <!-- Line -->
                    <div class="calculator-result-line calculator-result-table-span-4" />
                    <!-- Results buttons -->
                    <div class="calculator-button-center calculator-result-table-start-col-2">
                        <div class="button button--white button--download" @click="generatePDF">
                            {{ translations.saveResults }}
                        </div>
                    </div>
                    <div class="calculator-button-center calculator-result-table-start-col-3">
                        <a
                            class="button button--white button--borderless calculator-last-button"
                            :href="contactLink"
                            target="_blank"
                            rel="noopener noreferrer">{{ translations.contactMerchandiser }}</a>
                    </div>
                </div>
            </div>
            <!-- Next Page Button -->
            <div class="calculator-button-right" v-if="currentPage === 0 || currentPage === 1">
                <div class="button button--round-arrow" @click="nextPage">
                    {{ translations.nextButton }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { jsPDF as PDFjs } from 'jspdf';
import PDF_Fonts = require('../../lib/RubikFontjsPDF');
import InputMask from '../atoms/InputMask.vue';

export default defineComponent({
    components: { InputMask },
    props: {
        contactLink: { required: true, type: String },
        pagesNames: { required: true, type: Array as () => string[] },
        commodities: { required: true, type: Array as () => string[] },
        costsVariables: { required: true, type: Array as () => string[] },
        translations: { required: true, type: Object as () => Record<string, string> }
    },
    data() {
        return {
            grain: '',
            nAcres: null as Number,
            marketPrice: null as Number,
            yieldBu: null as Number,
            costsValues: [] as Number[],
            currentPage: 0,
            testFields: false
        };
    },

    created() {
        // initialise costsValues to 0
        this.costsValues = new Array(this.costsVariables.length).fill(0);
    },

    computed: {
        // Calculate Results
        marketIncomeAcre() {
            return this.marketPrice * this.yieldBu;
        },
        totalCosts() {
            return this.costsValues.reduce((partialSum, a) => partialSum + Number(a), 0);
        },
        netIncome() {
            return this.marketIncomeAcre - this.totalCosts;
        },
        totalFarmIncome() {
            return this.netIncome * this.nAcres;
        }
    },

    methods: {
        // Pages navigation
        nextPage() {
            this.testFields = true;
            if (this.grain && this.nAcres && this.marketPrice && this.yieldBu) {
                this.currentPage++;
                this.testFields = false;
                this.$el.scrollIntoView({ behavior: 'smooth' });
            }
        },
        prevPage() {
            this.currentPage--;
        },

        goToPage(number) {
            if (this.grain && this.nAcres && this.marketPrice && this.yieldBu && (this.currentPage + 1 >= number)) {
                this.currentPage = number;
            }
        },

        // PDF generator
        generatePDF() {
            const nowDate = new Date();
            const date = `${nowDate.getFullYear()}_${nowDate.getMonth()}_${nowDate.getDate()}`;
            const doc = new PDFjs();
            const logo = `${this.$resourcePath}img/logo_green_tagline_white.png`;

            // add logo image
            doc.addImage(logo, 'PNG', 16, 12, 109.6, 11.2, '', 'MEDIUM', 0);

            // eslint-disable-next-line no-unused-expressions
            PDF_Fonts;

            doc.setFont('Rubik', 'normal');
            doc.setTextColor(45, 20, 55);
            let currentLine = 20;
            // Farm Income
            currentLine = this.writeDoc(doc, this.pagesNames[0], 20, currentLine, 30, 22);
            currentLine = this.writeDoc(doc, this.translations.chooseCommodity, 25, currentLine, 10);
            currentLine = this.writeDoc(doc, this.grain, 125, currentLine, 0);
            currentLine = this.writeDoc(doc, this.translations.numbAcres, 25, currentLine, 7);
            currentLine = this.writeDoc(doc, this.nAcres.toLocaleString('en-US'), 125, currentLine, 0);
            currentLine = this.writeDoc(doc, this.translations.marketPrice, 25, currentLine, 7);
            currentLine = this.writeDoc(doc, this.marketPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' }), 125, currentLine, 0);
            currentLine = this.writeDoc(doc, this.translations.yieldBu, 25, currentLine, 7);
            currentLine = this.writeDoc(doc, this.yieldBu.toLocaleString('en-US'), 125, currentLine, 0);

            // VariableCosts
            currentLine = this.writeDoc(doc, this.pagesNames[1], 20, currentLine, 30, 22);
            currentLine = this.writeDoc(doc, this.translations.variableCostsPerAcre, 125, currentLine, 0);
            this.costsVariables.forEach((element, index) => {
                if (index > 0) {
                    currentLine = this.writeDoc(doc, element, 25, currentLine, 7);
                } else {
                    currentLine = this.writeDoc(doc, element, 25, currentLine, 10);
                }
                currentLine = this.writeDoc(doc, this.costsValues[index].toLocaleString('en-US', { style: 'currency', currency: 'USD' }), 125, currentLine, 0);
            });

            // Results
            currentLine = this.writeDoc(doc, 'Results', 20, currentLine, 30, 22);
            currentLine = this.writeDoc(doc, this.translations.marketIncomeAcre, 25, currentLine, 10);
            currentLine = this.writeDoc(doc, this.marketIncomeAcre.toLocaleString('en-US', { style: 'currency', currency: 'USD' }), 125, currentLine, 0);
            currentLine = this.writeDoc(doc, this.translations.totalCosts, 25, currentLine, 7);
            currentLine = this.writeDoc(doc, this.totalCosts.toLocaleString('en-US', { style: 'currency', currency: 'USD' }), 125, currentLine, 0);
            currentLine = this.writeDoc(doc, this.translations.netIncome, 25, currentLine, 7);
            currentLine = this.writeDoc(doc, this.netIncome.toLocaleString('en-US', { style: 'currency', currency: 'USD' }), 125, currentLine, 0);
            currentLine = this.writeDoc(doc, this.translations.totalFarmIncome, 25, currentLine, 7);
            currentLine = this.writeDoc(doc, this.totalFarmIncome.toLocaleString('en-US', { style: 'currency', currency: 'USD' }), 125, currentLine, 0);

            doc.save(`Viterra_Breakeven_Calculator_${date}.pdf`);
        },

        writeDoc(doc: PDFjs, value: string, leftSpace: number, currentLine: number, spaceTop: number, fontSize: number = 16) {
            doc.setFontSize(fontSize);
            currentLine += spaceTop;
            doc.text(value, leftSpace, currentLine);
            return currentLine;
        },

        // get values
        onNAcres(value) {
            this.nAcres = value;
        },

        onMarketPrice(value) {
            this.marketPrice = value;
        },

        onYieldBu(value) {
            this.yieldBu = value;
        },

        onCostsValues(value, index) {
            this.costsValues[index] = value;
        }
    }
});

</script>

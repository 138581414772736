<template>
    <div class="dropdown-bar">
        <div class="dropdown-bar__title">
            {{ title }}
        </div>
        <div class="dropdown-bar__dropdowns">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue';

export default defineComponent({
    props: {
        title: { type: String }
    },
    data() {
        return {
            dropdowns: []
        };
    },
    created() {
        provide('registerDropdown', this.register);
        provide('insideBar', true);
    },
    methods: {
        register(dropdown) {
            this.dropdowns.push(dropdown);
            dropdown.$el.classList.add('dropdown-bar__dropdown');
        }
    }
});

</script>

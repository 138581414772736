<template>
    <svg v-if="name">
        <use :xlink:href="`${$resourcePath}img/sprite.svg#${name}`" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        name: {
            type: String,
            default: ''
        }
    }
});

</script>

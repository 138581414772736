<template>
    <svg class="circle-progress-ring" viewBox="0 0 120 120">
        <circle
            class="circle-progress-ring__bg"
            stroke-width="8"
            fill="transparent"
            r="54"
            cx="60"
            cy="60" />
        <circle
            class="circle-progress-ring__progress"
            stroke-width="8"
            fill="transparent"
            r="54"
            cx="60"
            cy="60"
            transform="rotate(-90, 60, 60)"
            ref="progress"
            :style="progressStyle" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        progress: { default: 0, type: Number }
    },
    data() {
        return {
            c: 54 * 2 * Math.PI
        };
    },
    computed: {
        progressStyle() {
            return {
                strokeDasharray: `${this.c} ${this.c}`,
                strokeDashoffset: this.c - this.progress * this.c
            };
        }
    }
});

</script>

<template>
    <div class="segregation-plan__table-container">
        <button class="my-3 button d-none d-lg-flex" @click="$emit('export')">
            {{ translations.export }}
        </button>
        <!-- filters options -->
        <div
            :class="{ 'segregation-plan__pool-filter--active': showOpeningHours }"
            class="segregation-plan__pool-filter"
            @click="$emit('toggle-opening-hours')">
            {{ translations.showOpeningHours }}
        </div>
        <div class="segregation-plan__table-price-filters">
            <div
                :class="{ 'segregation-plan__table-price-filter--active': showPricing }"
                class="segregation-plan__table-price-filter"
                @click="$emit('toggle-show-pricing')">
                {{ translations.showPricing }}
            </div>
            <template v-if="showPricing">
                <div
                    :class="{ 'segregation-plan__table-price-filter--active': showEPPricing }"
                    class="segregation-plan__table-price-filter"
                    @click="$emit('toggle-show-e-p-pricing')">
                    {{ translations.showEPPricing }}
                    <div @click.stop>
                        <icon class="icon" name="icn-info" @click.native="openClosePopUp('showPopUpEPP')" />
                        <div
                            class="segregation-plan__table-price-filter-popup segregation-plan__table-price-filter-popup-right"
                            :class="{ 'segregation-plan__table-price-filter-popup-active': showPopUpEPP }">
                            <icon
                                name="icn-cross"
                                class="segregation-plan__table-price-filter-popup-cross"
                                @click.native="openClosePopUp('showPopUpEPP')" />
                            <div class="segregation-plan__table-price-filter-popup-text">
                                {{ translations.showEPPricingDesc }}
                            </div>
                            <a
                                class="button button--borderless"
                                :href="translations.segregationMoreInfoLink"
                                target="_blank">{{ translations.segregationMoreInfo }}</a>
                        </div>
                    </div>
                </div>
                <div
                    :class="{ 'segregation-plan__table-price-filter--active': showSCPricing }"
                    class="segregation-plan__table-price-filter"
                    @click="$emit('toggle-show-s-c-pricing')">
                    {{ translations.showSCPricing }}
                    <div @click.stop>
                        <icon class="icon" name="icn-info" @click.native="openClosePopUp('showPopUpSCP')" />
                        <div
                            class="segregation-plan__table-price-filter-popup"
                            :class="{ 'segregation-plan__table-price-filter-popup-active': showPopUpSCP }">
                            <icon
                                name="icn-cross"
                                class="segregation-plan__table-price-filter-popup-cross"
                                @click.native="openClosePopUp('showPopUpSCP')" />
                            <div class="segregation-plan__table-price-filter-popup-text">
                                {{ translations.showSCPricingDesc }}
                            </div>
                            <a
                                class="button button--borderless"
                                :href="translations.segregationMoreInfoLink"
                                target="_blank">{{ translations.segregationMoreInfo }}</a>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <!-- table -->
        <div class="segregation-plan__table-scroller">
            <div class="segregation-plan__table-scroll-fadeout" />
            <table class="table segregation-plan__table segregation-plan__table-ungrouped">
                <!-- header -->
                <thead>
                    <tr>
                        <th>{{ translations.site }}</th>
                        <th
                            v-if="showOpeningHours"
                            class="segregation-plan__table-header segregation-plan__table-site-message">
                            {{ translations.openingHours }}
                        </th>
                        <th
                            v-if="showOpeningHours"
                            class="segregation-plan__table-header segregation-plan__table-site-message">
                            {{ translations.siteMessage }}
                        </th>
                        <th
                            v-for="(grade, index) in commodityPrices.allGrades"
                            :key="`${grade.gName}-${index}`"
                            class="segregation-plan__table-header">
                            {{ grade.gName }}
                        </th>
                    </tr>
                </thead>
                <!-- lines -->
                <tbody>
                    <tr
                        v-for="priceLine in commodityPrices.priceLines"
                        :key="`${priceLine.site.sId}`"
                        class="segregation-plan__table-row"
                        @click="rowClicked(priceLine.site.sName)">
                        <!-- display name and if closed/open -->
                        <template v-if="!showOpeningHours">
                            <td class="segregation-plan__table-site">
                                <div class="segregation-plan__table-site-wrapper">
                                    <icon
                                        class="segregation-plan__table-site-remove"
                                        name="icn-cross"
                                        @click.native.stop="$emit('remove-site', priceLine.site.sId)" />
                                    <a
                                        :id="'site-' + commodityPrices.commodity.cId + '-' + priceLine.site.sName"
                                        :href="determineSiteDetailsPageUrl(priceLine.site.sName)"
                                        class="segregation-plan__table-site-label"
                                        tabindex="0"
                                        target="_blank"
                                        @click.prevent>
                                        <span>{{ priceLine.site.sName }}</span>
                                        <span class="segregation-plan__status-layout" v-if="priceLine.site.currentStatus">
                                            <span class="segregation-plan__today-label">{{ translations.today }}:</span>
                                            <span
                                                class="segregation-plan__status-text"
                                                :class="getStatusClass(priceLine.site, false)">
                                                <span>{{ formatStatus(priceLine.site.openingHoursToday) }}</span>
                                            </span>
                                        </span>
                                    </a>
                                </div>
                                <vit-popover
                                    :target="'site-' + commodityPrices.commodity.cId + '-' + priceLine.site.sName"
                                    class="segregation-plan__tooltip"
                                    custom-class="segregation-plan__popover">
                                    <!-- title that show pop up -->
                                    <template #title>
                                        {{ priceLine.site.sName }}&nbsp;
                                        <span v-if="priceLine.site.currentStatus" :class="getStatusClass(priceLine.site, true)">
                                            {{ formatStatus (priceLine.site.currentStatus) }}
                                        </span>
                                    </template>
                                    <!-- pop up content-->
                                    <template #body>
                                        <ul class="segregation-plan__table-opening-hours">
                                            <li>
                                                <div>
                                                    <span>{{ translations.today }}:</span>
                                                    <span>{{ formatStatus(priceLine.site.openingHoursToday) }}</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <span>{{ translations.tomorrow }}:</span>
                                                    <span>{{ formatStatus(priceLine.site.openingHoursTomorrow) }}</span>
                                                </div>
                                            </li>
                                        </ul>
                                        <p
                                            v-if="priceLine.site.sMessage"
                                            class="segregation-plan__table-popover-site-message">
                                            {{ priceLine.site.sMessage }}
                                        </p>
                                    </template>
                                </vit-popover>
                            </td>
                        </template>
                        <!-- display site name, opening hours, message -->
                        <template v-else>
                            <td class="segregation-plan__table-site segregation-plan__table-site-label">
                                <div class="segregation-plan__table-site-wrapper">
                                    <icon
                                        class="segregation-plan__table-site-remove"
                                        name="icn-cross"
                                        @click.native.stop="$emit('remove-site', priceLine.site.sId)" />
                                    <a
                                        :id="'site-' + commodity.id + '-' + priceLine.site.sName"
                                        :href="determineSiteDetailsPageUrl(priceLine.site.sName)"
                                        class="segregation-plan__table-site-label"
                                        tabindex="0"
                                        target="_blank"
                                        @click.prevent>
                                        {{ priceLine.site.sName }}
                                    </a>
                                </div>
                            </td>
                            <td>
                                <ul class="segregation-plan__table-opening-hours">
                                    <li>
                                        <div>
                                            <span>Today:</span>
                                            <span>{{ formatStatus(priceLine.site.openingHoursToday) }}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <span>Tomorrow:</span>
                                            <span>{{ formatStatus(priceLine.site.openingHoursTomorrow) }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </td>
                            <td>{{ priceLine.site.sMessage }}</td>
                        </template>
                        <!-- grades info -->
                        <td v-for="(grade, index) in commodityPrices.allGrades" :key="`${grade.gName}-${index}`">
                            <div class="segregation-plan__table-column">
                                <icon v-if="hasGrade(priceLine, grade)" class="icon" name="icn-rectangle" />
                                <span v-if="showPricing" class="segregation-plan__table-column-price">
                                    {{ price(determinePrice(priceLine, grade)) }}
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Icon from '../atoms/Icon.vue';
import VitPopover from '../atoms/Popover.vue';
import { SiteInfo, CommodityInfo, CommodityPricesInfo, PriceLineInfo, GradeInfo } from '../../lib/Segregation';

export default defineComponent({
    components: { Icon, VitPopover },
    props: {
        translations: { required: true, type: Object as () => Record<string, string> },
        showOpeningHours: { required: true, type: Boolean },
        showPricing: { required: true, type: Boolean },
        showEPPricing: { required: true, type: Boolean },
        showSCPricing: { required: true, type: Boolean },
        commodityPrices: { required: true, type: Object as PropType<CommodityPricesInfo> },
        commodity: { required: true, type: Object as PropType<CommodityInfo> },
        detailsPageUrl: { required: true, type: String }
    },
    data() {
        return {
            // pop up prices switches
            showPopUpEPP: false,
            showPopUpSCP: false
        };
    },

    methods: {
        // open and close pop up info
        openClosePopUp(name: string): void {
            if (name === 'showPopUpEPP') {
                this.showPopUpEPP = !this.showPopUpEPP;
                this.showPopUpSCP = false;
            } else {
                this.showPopUpSCP = !this.showPopUpSCP;
                this.showPopUpEPP = false;
            }
        },
        // if have the grade
        hasGrade(priceLine: PriceLineInfo, grade: GradeInfo): boolean {
            const gradePrice: GradeInfo = priceLine.gradePrices.find(gp => gp.gId === grade.gId);
            return !!gradePrice;
        },

        // return the price
        determinePrice(priceLine: PriceLineInfo, grade: GradeInfo): string {
            const gradePrice: GradeInfo = priceLine.gradePrices.find(gp => gp.gId === grade.gId);
            if (gradePrice) {
                const price: number = this.findPrice(gradePrice);
                return price === 0 ? '-' : price.toFixed(2);
            }
            return '';
        },

        // return the higher price
        findPrice(gradePrice: GradeInfo): number {
            return gradePrice.gPrices
                .filter(p =>
                    (this.showSCPricing && (p.sellingOptionType === 'SC'))
                    || (this.showEPPricing && p.sellingOptionType === 'EP')
                    || p.sellingOptionType === 'DC')
                .reduce((p, c) => (c.price > p ? c.price : p), 0);
        },

        // go to detail page
        rowClicked(siteName: string) {
            window.open(this.determineSiteDetailsPageUrl(siteName), '_blank');
        },
        // get the detail page url
        determineSiteDetailsPageUrl(siteName: string): string {
            return encodeURI(`${this.detailsPageUrl}/${siteName.toLowerCase()}`);
        },

        // return class for color status
        getStatusClass(site: SiteInfo, tooltip: boolean): string {
            if (tooltip) {
                switch (site.currentStatus) {
                    case 'Open':
                        return 'segregation-plan__tooltip-status-open';
                    case 'Closed':
                        return 'segregation-plan__tooltip-status-closed';
                    case 'ByAppointment':
                        return 'segregation-plan__tooltip-status-appointment';
                    default:
                        return 'segregation-plan__tooltip-status-other';
                }
            } else {
                switch (site.currentStatus) {
                    case 'Open':
                        return 'segregation-plan__status-text-open';
                    case 'Closed':
                        return 'segregation-plan__status-text-closed';
                    case 'ByAppointment':
                        return 'segregation-plan__status-text-appointment';
                    default:
                        return 'segregation-plan__status-text-other';
                }
            }
        },
        // previous filters
        price(value) {
            return value.length > 1 ? `$${value}` : value;
        },
        formatStatus(status) {
            if (!status) return '';
            if (status.toLowerCase() === 'open' || status.toLowerCase() === 'closed') {
                return status;
            }
            return status.replace(/([a-z])([A-Z])/g, (_, $0, $1) => `${$0} ${$1.toLowerCase()}`);
        }
    }
});
</script>

<template>
    <transition name="search__anim">
        <div
            v-if="visible || static"
            class="search-bar"
            :class="{ 'search-bar--static': static, 'search-bar--container': container }">
            <div class="search-bar__container">
                <form class="search-bar__search-field" @submit.prevent="onSubmit">
                    <input
                        type="text"
                        :placeholder="placeholder"
                        class="search-bar__input"
                        ref="input"
                        @input="onSubmit"
                        v-model="value">
                </form>
                <icon name="icn-search" class="search-bar__icon" @click.native="toggle" />
            </div>
            <div class="search-bar__results">
                <ul class="search-bar__result-list search-bar__container" v-if="results.length > 0">
                    <li
                        v-for="result in results"
                        :key="`result#${result.id}`"
                        class="search-bar__result"
                        @click="onResultClicked(result)">
                        {{ result.title }}
                    </li>
                </ul>
                <slot />
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '../atoms/Icon.vue';

export default defineComponent({
    components: { Icon },
    props: {
        placeholder: { type: String },
        visible: { default: true, type: Boolean },
        static: { default: false, type: Boolean },
        container: { default: false, type: Boolean },
        results: { default: () => [], type: Array as () => Array<{ title: string, id: string }> }
    },
    data() {
        return {
            value: ''
        };
    },

    methods: {
        toggle() {
            this.value = '';
            this.$emit('search', this.value);
        },

        onSubmit() {
            this.$emit('search', this.value);
        },

        onResultClicked(result) {
            this.$emit('result-clicked', result.id);
            this.toggle();
        }
    },

    watch: {
        visible(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    if (this.$refs.input) {
                        (this.$refs.input as HTMLElement).focus();
                    }
                });
            }
        }
    }
});

</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "locations_map-search" }
const _hoisted_2 = { class: "locations_map-search-m" }
const _hoisted_3 = { class: "cash-pricing__filter-item cash-pricing__filter-item--search" }
const _hoisted_4 = { class: "locations_locations-search-option" }
const _hoisted_5 = {
  key: 0,
  class: "locations_locations-dot"
}
const _hoisted_6 = {
  key: 1,
  class: "locations_locations-title"
}
const _hoisted_7 = { class: "locations_locations-search" }
const _hoisted_8 = { class: "cash-pricing__filter-item cash-pricing__filter-item--search" }
const _hoisted_9 = { class: "locations_locations-search-option" }
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "locations_location-details" }
const _hoisted_12 = { class: "locations_location-details" }
const _hoisted_13 = {
  key: 0,
  class: "locations_location-open"
}
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "locations_location-name" }
const _hoisted_16 = { class: "locations_location-details" }
const _hoisted_17 = { class: "locations_location-box-contacts" }
const _hoisted_18 = {
  key: 0,
  class: "locations_location-details"
}
const _hoisted_19 = ["href"]
const _hoisted_20 = { class: "locations_location-box-buttons" }
const _hoisted_21 = ["href"]
const _hoisted_22 = ["href"]
const _hoisted_23 = ["id", "onClick"]
const _hoisted_24 = ["href"]
const _hoisted_25 = { class: "locations_location-details" }
const _hoisted_26 = { class: "locations_location-details" }
const _hoisted_27 = {
  key: 0,
  class: "locations_location-open"
}
const _hoisted_28 = ["src"]
const _hoisted_29 = { class: "locations_location-name" }
const _hoisted_30 = { class: "locations_location-details" }
const _hoisted_31 = { class: "locations_location-box-contacts" }
const _hoisted_32 = ["href"]
const _hoisted_33 = { class: "locations_location-box-buttons" }
const _hoisted_34 = ["href"]
const _hoisted_35 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_dropdown_item = _resolveComponent("dropdown-item")!
  const _component_vit_dropdown = _resolveComponent("vit-dropdown")!
  const _component_google_map = _resolveComponent("google-map")!
  const _component_gav_locations_drag = _resolveComponent("gav-locations-drag")!

  return (_openBlock(), _createBlock(_component_gav_locations_drag, {
    onOpenPopUp: _ctx.onPopupOpen,
    "open-pop-up": _ctx.popupOpen,
    "mobile-selected": _ctx.isLocationSelected
  }, {
    static: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_vit_dropdown, {
            label: _ctx.locationSelectedId ? _ctx.locationSelected.description : _ctx.translations.select,
            light: "",
            "dark-menu": "",
            rounded: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_dropdown_item, { "prevent-close-dropdown": true }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_icon, {
                      class: "icon",
                      name: "icn-search"
                    }),
                    _createElementVNode("input", {
                      class: "cash-pricing__filter-search",
                      type: "text",
                      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSearchLocation && _ctx.onSearchLocation(...args)))
                    }, null, 32)
                  ])
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredLocations, (location) => {
                return (_openBlock(), _createBlock(_component_dropdown_item, {
                  key: 'searchmap'+location.id,
                  onClick: ($event: any) => (_ctx.selectLocation(location))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      class: _normalizeClass(["locations_locations-search-checkmark", {'checkmark-active': location.id === _ctx.locationSelectedId}])
                    }, null, 2),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(location.description), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["label"])
        ])
      ]),
      _createVNode(_component_google_map, {
        "api-key": _ctx.apiKey,
        markers: _ctx.locations,
        center: _ctx.position,
        onMapCenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.position = $event)),
        zoom: _ctx.zoom,
        onMapZoom: _cache[2] || (_cache[2] = ($event: any) => (_ctx.zoom = $event)),
        onMarkerClick: _ctx.onMarkerClick
      }, null, 8, ["api-key", "markers", "center", "zoom", "onMarkerClick"])
    ]),
    stickytop: _withCtx(() => [
      (!_ctx.locationSelectedId)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5))
        : _createCommentVNode("", true),
      (!_ctx.locationSelectedId)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.translations.discoverLocation), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_vit_dropdown, {
          label: _ctx.locationSelectedId ? _ctx.locationSelected.description : _ctx.translations.select,
          light: "",
          "dark-menu": "",
          rounded: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_dropdown_item, { "prevent-close-dropdown": true }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_icon, {
                    class: "icon",
                    name: "icn-search"
                  }),
                  _createElementVNode("input", {
                    class: "cash-pricing__filter-search",
                    type: "text",
                    onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onSearchLocation && _ctx.onSearchLocation(...args)))
                  }, null, 32)
                ])
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredLocations, (location) => {
              return (_openBlock(), _createBlock(_component_dropdown_item, {
                key: 'searchlist'+location.id,
                onClick: ($event: any) => (_ctx.selectLocation(location))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    class: _normalizeClass(["locations_locations-search-checkmark", {'checkmark-active': location.id === _ctx.locationSelectedId}])
                  }, null, 2),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(location.description), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["label"])
      ])
    ]),
    mobileselected: _withCtx(() => [
      (_ctx.locationSelectedId && _ctx.locationSelected)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "locations_location-info locations_location-info-selected locations_location-info-selected-mobile-show",
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.selectLocation(_ctx.locationSelected)))
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("a", {
                onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
                href: _ctx.determineLocationUrl(_ctx.locationSelected.description),
                class: "locations_location-title"
              }, _toDisplayString(_ctx.locationSelected.description), 9, _hoisted_10),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.locationSelected.info.address), 1),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.locationSelected.info.city) + ", " + _toDisplayString(_ctx.locationSelected.info.stateProvince) + ", " + _toDisplayString(_ctx.locationSelected.info.postalCode), 1)
              ]),
              (_ctx.locationSelected.info.openHour && _ctx.locationSelected.info.closeHour)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.locationSelected.info.openHour) + " - " + _toDisplayString(_ctx.locationSelected.info.closeHour), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.locationSelected.contact.name ? 'locations_location-box-active' : 'locations_location-box')
            }, [
              _createElementVNode("img", {
                src: _ctx.gavilon.getImage(_ctx.locationSelected.contact.image),
                onError: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.gavilon.removeImage && _ctx.gavilon.removeImage(...args))),
                class: "locations_location-image"
              }, null, 40, _hoisted_14),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.locationSelected.contact.name), 1),
                _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.locationSelected.contact.title), 1)
              ]),
              _createElementVNode("div", _hoisted_17, [
                (_ctx.locationSelected.contact.phone)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, " T: " + _toDisplayString(_ctx.locationSelected.contact.phone), 1))
                  : _createCommentVNode("", true),
                (_ctx.locationSelected.contact.email)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"])),
                      class: "locations_location-message",
                      rel: "noopener noreferrer",
                      href: 'mailto:'+_ctx.locationSelected.contact.email
                    }, _toDisplayString(_ctx.translations.message), 9, _hoisted_19))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_20, [
                (_ctx.locationSelected.contact.phone)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      onClick: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"])),
                      class: "button button--phone",
                      rel: "noopener noreferrer",
                      href: 'tel:+'+_ctx.locationSelected.contact.phone
                    }, _toDisplayString(_ctx.translations.call), 9, _hoisted_21))
                  : _createCommentVNode("", true),
                (_ctx.locationSelected.contact.email)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      onClick: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["stop"])),
                      class: "button button--mail",
                      rel: "noopener noreferrer",
                      href: 'mailto:'+_ctx.locationSelected.contact.email
                    }, _toDisplayString(_ctx.translations.email), 9, _hoisted_22))
                  : _createCommentVNode("", true)
              ])
            ], 2)
          ]))
        : _createCommentVNode("", true)
    ]),
    scroll: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locationsDistance, (location, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'list'+location.id,
          id: 'list'+location.id,
          class: _normalizeClass(["locations_location-info", { 'locations_location-info-selected' : location.id === _ctx.locationSelectedId, 'locations_location-info-first': !index, 'locations_location-info-selected-mobile': _ctx.locationSelectedId && _ctx.locationSelected }]),
          onClick: ($event: any) => (_ctx.selectLocation(location))
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("a", {
              onClick: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["stop"])),
              href: _ctx.determineLocationUrl(location.description),
              class: "locations_location-title"
            }, _toDisplayString(location.description), 9, _hoisted_24),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_25, _toDisplayString(location.info.address), 1),
              _createElementVNode("div", _hoisted_26, _toDisplayString(location.info.city) + ", " + _toDisplayString(location.info.stateProvince) + ", " + _toDisplayString(location.info.postalCode), 1)
            ]),
            (location.info.openHour && location.info.closeHour)
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(location.info.openHour) + " - " + _toDisplayString(location.info.closeHour), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass( location.id === _ctx.locationSelectedId && location.contact.name ? 'locations_location-box-active' : 'locations_location-box')
          }, [
            _createElementVNode("img", {
              src: _ctx.gavilon.getImage(location.contact.image),
              onError: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.gavilon.removeImage && _ctx.gavilon.removeImage(...args))),
              class: "locations_location-image"
            }, null, 40, _hoisted_28),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_29, _toDisplayString(location.contact.name), 1),
              _createElementVNode("div", _hoisted_30, _toDisplayString(location.contact.title), 1)
            ]),
            _createElementVNode("div", _hoisted_31, [
              (location.contact.phone)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[12] || (_cache[12] = _withModifiers(() => {}, ["stop"])),
                    class: "locations_location-details"
                  }, " T: " + _toDisplayString(location.contact.phone), 1))
                : _createCommentVNode("", true),
              (location.contact.email)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    onClick: _cache[13] || (_cache[13] = _withModifiers(() => {}, ["stop"])),
                    class: "locations_location-message",
                    href: 'mailto:'+location.contact.email,
                    rel: "noopener noreferrer"
                  }, _toDisplayString(_ctx.translations.message), 9, _hoisted_32))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_33, [
              (location.contact.phone)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    onClick: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["stop"])),
                    class: "button button--phone",
                    rel: "noopener noreferrer",
                    href: 'tel:+'+location.contact.phone
                  }, _toDisplayString(_ctx.translations.call), 9, _hoisted_34))
                : _createCommentVNode("", true),
              (location.contact.email)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    onClick: _cache[15] || (_cache[15] = _withModifiers(() => {}, ["stop"])),
                    class: "button button--mail",
                    rel: "noopener noreferrer",
                    href: 'mailto:'+location.contact.email
                  }, _toDisplayString(_ctx.translations.email), 9, _hoisted_35))
                : _createCommentVNode("", true)
            ])
          ], 2)
        ], 10, _hoisted_23))
      }), 128))
    ]),
    _: 1
  }, 8, ["onOpenPopUp", "open-pop-up", "mobile-selected"]))
}
<template>
    <div class="country-popup">
        <div class="country-popup__container">
            <div class="country-popup__inner">
                <h3>{{ title }}</h3>
                <p>
                    {{ introText }}
                </p>
                <vit-dropdown
                    :label="$capitalize(selectedCountry.name) || dropdownPlaceholder"
                    v-if="countries.length"
                    class="country-popup__dropdown"
                    light
                    inline>
                    <dropdown-item
                        v-for="country in countries"
                        :key="country.isoCode"
                        :label="country.name"
                        :selected="selectedCountry === country"
                        @click="selectCountry(country)" />
                </vit-dropdown>

                <button class="button country-popup__confirm" @click="confirm" :disabled="selectedCountry.isoCode.length === 0">
                    {{ buttonLabel }}
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Helper from '../lib/Helper';
import DropdownItem from './base/VitDropdownItem.vue';
import VitDropdown from './base/VitDropdown.vue';

export default defineComponent({
    components: { VitDropdown, DropdownItem },
    props: {
        title: String,
        introText: String,
        buttonLabel: String,
        dropdownPlaceholder: String,
        countries: { type: Array as () => Array<{ name: string, isoCode: string, disclaimer: string }> }
    },
    data() {
        return {
            selectedCountry: { name: '', isoCode: '' }
        };
    },

    methods: {
        selectCountry(country) {
            this.selectedCountry = country;
        },

        confirm() {
            Helper.setCookie('country', this.selectedCountry.isoCode);
            location.reload();
        }
    }
});

</script>

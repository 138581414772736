<template>
    <div class="mlc-converter">
        <div class="mlc-converter section__container">
            <div class="section__content section__content--top">
                <div class="mlc-converter__selection">
                    <vit-dropdown
                        :label="(selectedState && selectedState.name) || translations.state"
                        inline
                        light
                        dark-menu
                        rounded
                        v-if="states.length">
                        <dropdown-item
                            v-for="state in filteredStates"
                            :key="'filter-' + state.id"
                            @click="selectState(state.id)">
                            <div
                                class="mlc-converter__selection-item"
                                :class="{ 'mlc-converter__selection-item--active': selectedState && (selectedState.id === state.id) }">
                                {{ state.name }}
                            </div>
                        </dropdown-item>
                    </vit-dropdown>
                    <div v-else-if="statesError">
                        {{ statesError }}
                    </div>
                    <loading-spinner v-else />
                    <vit-dropdown
                        :label="(selectedCurrentVtCode && selectedCurrentVtCode.name) || translations.currentVtCode"
                        inline
                        light
                        dark-menu
                        rounded
                        v-if="!currentVtCodesLoading">
                        <dropdown-item :prevent-close-dropdown="true">
                            <div class="mlc-converter__selection-item mlc-converter__selection-item--search">
                                <icon name="icn-search" class="icon" />
                                <input
                                    type="text"
                                    class="mlc-converter__selection-search"
                                    @input="onSearchCurrentVtCodes">
                            </div>
                        </dropdown-item>
                        <template v-if="currentVtCodes.length">
                            <dropdown-item
                                v-for="currentVtCode in filteredCurrentVtCodes"
                                :key="'selection-' + currentVtCode.id"
                                @click="selectCurrentVtCode(currentVtCode.id)">
                                <div
                                    class="mlc-converter__selection-item"
                                    :class="{ 'mlc-converter__selection-item--active': selectedCurrentVtCode && (selectedCurrentVtCode.id === currentVtCode.id) }">
                                    {{ currentVtCode.name }}
                                </div>
                            </dropdown-item>
                        </template>
                    </vit-dropdown>
                    <loading-spinner v-else />
                    <div v-if="currentVtCodesError">
                        {{ currentVtCodesError }}
                    </div>
                </div>
                <div class="mlc-converter__button-container">
                    <button
                        class="button mlc-converter__button"
                        @click="fetchNewVtCode"
                        :disabled="!selectedState || !selectedCurrentVtCode">
                        {{ translations.fetchCodeButtonLabel }}
                    </button>
                </div>
            </div>
        </div>
        <div v-if="loadingNewVtCode" class="mlc-converter__loading-icon">
            <loading-spinner />
        </div>
        <div v-if="newVtCodeError">
            {{ newVtCodeError }}
        </div>
        <div v-if="newVtCode" id="new-vt-code" class="section section--primary section--edges">
            <div class="section__container">
                <div class="section__content">
                    <h2 class="mlc-converter__code-header">
                        {{ translations.yourNewCodeHeader }}
                    </h2>
                    <div class="mlc-converter__code-container">
                        <div>
                            <hr class="mlc-converter__separator">
                            <h2 class="mlc-converter__code">
                                {{ newVtCode.newVtCode }}
                            </h2>
                            <p class="mlc-converter__code-label">
                                {{ translations.yourNewCode }}
                            </p>
                        </div>
                        <div class="teaser mlc-converter__teaser">
                            <p>{{ translations.receiveByEmail }}</p>
                            <template v-if="!emailSuccess">
                                <label class="mlc-converter__email" for="email">{{ translations.emailAddress }}</label>
                                <input
                                    type="text"
                                    id="email"
                                    placeholder="john.doe@mail.com"
                                    class="form__input"
                                    ref="email"
                                    @input="onEmailChanged()">
                                <div class="mlc-converter__button-container">
                                    <button
                                        class="button mlc-converter__button"
                                        @click="sendEmail"
                                        :disabled="emailValue === '' || sendingEmail">
                                        {{ translations.sendCode }}
                                    </button>
                                </div>
                            </template>
                            <template v-else>
                                <p class="mlc-converter__email-success">
                                    {{ emailSuccess }}
                                </p>
                            </template>
                            <template v-if="emailError">
                                <p class="mlc-converter__email-error">
                                    {{ emailError }}
                                </p>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="newVtCode" class="section__container">
            <div class="section__content">
                <h2>{{ translations.additionalInformation }}</h2>
                <table class="table">
                    <tbody>
                        <tr>
                            <td>{{ translations.description }}</td>
                            <td>{{ $formatNumber(newVtCode.description) }}</td>
                        </tr>
                        <tr>
                            <td>{{ translations.permitRequired }}</td>
                            <td>{{ newVtCode.permitRequired ? translations.yes : translations.no }}</td>
                        </tr>
                        <tr>
                            <td>{{ translations.accreditationRequired }}</td>
                            <td>{{ newVtCode.accreditationRequired ? translations.yes : translations.no }}</td>
                        </tr>
                        <tr>
                            <td>{{ translations.maxWeight }}</td>
                            <td>{{ $formatNumber(newVtCode.maxWeight) }}</td>
                        </tr>
                        <tr>
                            <td>{{ translations.permitMinWeight }}</td>
                            <td>{{ $formatNumber(newVtCode.permitMinWeight) }}</td>
                        </tr>
                        <tr>
                            <td>{{ translations.permitMaxWeight }}</td>
                            <td>{{ $formatNumber(newVtCode.permitMaxWeight) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent, PropType } from 'vue';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { MLC_ENDPOINT } from '../Constants';
import LoadingSpinner from './atoms/LoadingSpinner.vue';
import DropdownItem from './base/VitDropdownItem.vue';
import Icon from './atoms/Icon.vue';
import VitDropdown from './base/VitDropdown.vue';

export interface Item {
    id: string;
    name: string;
}

export interface State extends Item {
}

export interface CurrentVtCode extends Item {
}

export interface NewVtCode {
    newVtCode: string;
    description: string;
    permitRequired: boolean;
    accreditationRequired: boolean;
    maxWeight: string;
    permitMinWeight: string;
    permitMaxWeight: string;
}

export default defineComponent({
    components: { VitDropdown, LoadingSpinner, Icon, DropdownItem },
    props: {
        translations: { type: Object as () => { [key: string]: string }, required: true }
    },
    data() {
        return {
            states: [] as State[],
            currentVtCodes: [] as CurrentVtCode[],
            statesError: '',
            currentVtCodesLoading: false,
            currentVtCodesError: '',
            loadingNewVtCode: false,
            newVtCode: null as PropType<NewVtCode>,
            newVtCodeError: '',
            selectedState: null as PropType<State>,
            selectedCurrentVtCode: null as PropType<CurrentVtCode>,
            statesFilter: '',
            onSearchCurrentVtCodes: null,
            currentVtCodesFilter: '',
            sendingEmail: false,
            emailValue: '',
            emailSuccess: '',
            emailError: ''
        };
    },

    async mounted() {
        this.onSearchCurrentVtCodes = debounce(this.searchCurrentVtCodes, 350);
        this.states = await this.getStates();
    },
    methods: {
        async getStates(): Promise<State[]> {
            // reset error flag
            this.statesError = undefined;

            try {
                const res = await axios.get(`${this.$contextPath}${MLC_ENDPOINT.states()}`);
                if (res.status === 200) {
                    return res.data.map(state => ({ id: state, name: state }));
                }
                return [];
            } catch (e) {
                this.statesError = this.handleError(e, `list of ${this.translations.state.toLowerCase()}s`);
                return [];
            }
        },

        async getCurrentVtCodes(stateId: string): Promise<CurrentVtCode[]> {
            // reset flags
            this.currentVtCodesError = undefined;
            this.currentVtCodesLoading = true;

            try {
                const res = await axios.get(`${this.$contextPath}${MLC_ENDPOINT.currentVtCodes(stateId)}`);
                if (res.status === 200) {
                    return res.data.map(vtCode => ({ id: vtCode, name: vtCode }));
                }
                return [];
            } catch (e) {
                this.currentVtCodesError = this.handleError(e, `list of ${this.translations.currentVtCode.toLowerCase()}s`);
                return [];
            } finally {
                this.currentVtCodesLoading = false;
            }
        },

        async getNewVtCode(): Promise<NewVtCode> {
            // reset flags
            this.loadingNewVtCode = true;
            this.newVtCodeError = undefined;
            this.emailSuccess = '';

            try {
                const res = await axios.get(`${this.$contextPath}${MLC_ENDPOINT.convert(this.selectedState.id, this.selectedCurrentVtCode.id)}`);
                if (res.status === 200) {
                    return {
                        newVtCode: res.data.newVtCode,
                        description: res.data.description,
                        permitRequired: res.data.permitRequired,
                        accreditationRequired: res.data.accreditationRequired,
                        maxWeight: res.data.maxWeight,
                        permitMinWeight: res.data.permitMinWeight,
                        permitMaxWeight: res.data.permitMaxWeight
                    };
                }
                return null;
            } catch (e) {
                this.newVtCodeError = this.handleError(e, this.translations.yourNewCode.toLowerCase());
                return null;
            } finally {
                this.loadingNewVtCode = false;
            }
        },

        async postEmail(email: string) {
            // reset flags
            this.emailError = '';
            this.sendingEmail = true;

            try {
                const res = await axios({
                    method: 'post',
                    url: `${this.$contextPath}${MLC_ENDPOINT.requestEmail()}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        state: this.selectedState.id,
                        currentVtCode: this.selectedCurrentVtCode.id,
                        email
                    }
                });
                if (res.status === 204) {
                    this.emailSuccess = this.translations.emailSent;
                }
            } catch (e) {
                this.emailError = this.handleSendError(e);
            }
        },

        async selectState(stateId: string) {
            this.selectedState = this.states.find(x => x.id === stateId);
            this.currentVtCodes = await this.getCurrentVtCodes(this.selectedState.id);
            this.$forceUpdate();
        },

        selectCurrentVtCode(currentVtCodeId: string) {
            this.selectedCurrentVtCode = this.currentVtCodes.find(x => x.id === currentVtCodeId);
            this.$forceUpdate();
        },

        async fetchNewVtCode() {
            this.newVtCode = await this.getNewVtCode();
            this.$forceUpdate();

            setTimeout(() => {
                const container: Element = this.$el.querySelector('#new-vt-code');
                container.scrollIntoView();
            }, 200);
        },

        async sendEmail() {
            const email: string = this.emailValue;

            if (this.isValidEmail(email)) {
                await this.postEmail(email);
            } else {
                this.emailSuccess = '';
                this.emailError = this.translations.invalidEmail;
            }
        },

        handleError(error, item: string): string {
            if (error.response && error.response.status === 504) {
                return `Timeout while fetching ${item}. Please try again.`;
            }
            return `Error while fetching ${item}. Please try again later.`;
        },

        handleSendError(error): string {
            if (error.response && error.response.status === 504) {
                return 'Timeout while requesting mail. Please try again.';
            }
            return 'Error while requesting mail. Please try again later.';
        },

        searchCurrentVtCodes(event: InputEvent) {
            this.currentVtCodesFilter = (event.target as HTMLInputElement).value.toLowerCase();
        },

        /**
         * Called on any change in the email field.
         */
        onEmailChanged() {
            this.emailValue = (this.$refs.email as HTMLInputElement).value;
        },

        /**
         * Validates an email address.
         */
        isValidEmail(email: string): boolean {
            // eslint-disable-next-line max-len
            const re: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return re.test(email.toLowerCase());
        }
    },

    computed: {
        filteredStates(): State[] {
            return this.states.filter(x => x.name.toLowerCase().includes(this.statesFilter));
        },

        filteredCurrentVtCodes(): CurrentVtCode[] {
            return this.currentVtCodes.filter(x => x.name.toLocaleLowerCase().includes(this.currentVtCodesFilter));
        }
    }
});
</script>

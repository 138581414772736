import { renderSlot as _renderSlot, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "vTab" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default")
    ], 512), [
      [_vShow, _ctx.isActive]
    ])
  ], 512))
}
<template>
    <div>
        <div class="segregation-plan__content">
            <!-- filters -->
            <segregation-plan-filter
                ref="filter"
                :base-url="wsBaseUrl"
                :translations="translations"
                @refresh="loading = true"
                @site-selected="onSiteSelected"
                @commodities="onCommoditiesLoaded"
                @commodities-prices="onPricesChanged" />
            <!-- no sites or regions selected -->
            <div v-if="!siteSelected && !loading" class="segregation-plan__result-error-outer">
                <div class="segregation-plan__result-error">
                    <div class="segregation-plan__result-error-inner">
                        {{ translations.noSite }}
                    </div>
                </div>
            </div>
            <!-- no prices -->
            <div v-else-if="!commodityPrices.length && !loading" class="segregation-plan__result-error-outer">
                <div class="segregation-plan__result-error">
                    <div class="segregation-plan__result-error-inner">
                        {{ translations.noResults }}
                    </div>
                </div>
            </div>
            <!-- table -->
            <template v-else-if="commodities.length > 0 && commodityPrices.length > 0">
                <vit-tabs
                    :tabs-lenght="commodities.length"
                    mobile
                    sliding>
                    <vit-tab
                        v-for="commodity in commodities"
                        :key="`table-${commodity.cName}`"
                        :disabled="!commodity.available"
                        :title="commodity.cName"
                        fixed-width
                        capitalize
                        narrow>
                        <!-- refresh prices -->
                        <div class="segregation-plan__filter-date">
                            {{ translations.lastRefresh }}: {{ refreshDate }}
                        </div>
                        <icon
                            :class="{ 'segregation-plan__refresh-loading': loading }"
                            class="segregation-plan__refresh"
                            name="icn-refresh"
                            @click="refreshPrices" />
                        <segregation-plan-table
                            v-if="pricesForCommodity(commodity.cId)"
                            :translations="translations"
                            :show-pricing="showPricing"
                            :show-opening-hours="showOpeningHours"
                            :show-e-p-pricing="showEPPricing"
                            :show-s-c-pricing="showSCPricing"
                            :commodity="commodity"
                            :commodity-prices="pricesForCommodity(commodity.cId)"
                            :details-page-url="detailsPageUrl"
                            @export="exportXlsx"
                            @toggle-show-pricing="toggleShowPricing"
                            @toggle-opening-hours="toggleOpeningHours"
                            @toggle-show-e-p-pricing="toggleShowEPPricing"
                            @toggle-show-s-c-pricing="toggleShowSCPricing"
                            @remove-site="removeSite" />
                    </vit-tab>
                </vit-tabs>
            </template>
            <div v-else class="segregation-plan__result-error">
                <loading-spinner />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Segregation, { SiteInfo, CommodityInfo, CommodityPricesInfo, PriceLineInfo } from '../../lib/Segregation';
import LoadingSpinner from '../atoms/LoadingSpinner.vue';
import SegregationPlanFilter from './SegregationPlanFilter.vue';
import SegregationPlanTable from './SegregationPlanTable.vue';
import Icon from '../atoms/Icon.vue';
import Helper from '../../lib/Helper';
import VitTabs from '../base/VitTabs.vue';
import VitTab from '../base/VitTab.vue';

export default defineComponent({
    components: { VitTab, VitTabs, LoadingSpinner, Icon, SegregationPlanFilter, SegregationPlanTable },
    props: {
        detailsPageUrl: { required: true, type: String },
        wsBaseUrl: { required: true, type: String },
        translations: { required: true, type: Object as () => Record<string, string> }
    },
    data() {
        return {
            // values
            commodities: [] as CommodityInfo[],
            commodityPrices: [] as CommodityPricesInfo[],
            refreshDate: '',

            // aux
            siteSelected: true,
            pricesLoaded: false,
            loading: true,

            // switches are defined here, to keep the same state across tabs
            showOpeningHours: false,
            showPricing: true,
            showEPPricing: true,
            showSCPricing: true,
            // value in cookie
            showPoolOptions: false
        };
    },
    methods: {
        /*
        * get values from filters
        */
        // see if any site was selected
        onSiteSelected(sites: SiteInfo[]) {
            this.siteSelected = sites.length > 0;
        },
        // get commodities
        onCommoditiesLoaded(commodities: CommodityInfo[]) {
            this.commodities = commodities;
        },

        onPricesChanged(commodityPrices: CommodityPricesInfo[]) {
            this.loading = false;
            this.pricesLoaded = true;
            this.commodityPrices = commodityPrices;
            const date: Date = new Date();
            this.refreshDate = `${this.$date(date, false)} ${date.toLocaleTimeString()} (${Intl.DateTimeFormat().resolvedOptions().timeZone})`;
        },
        /*************
         * Table events
         ************/
        // return all prices of the commodity
        pricesForCommodity(commodityId: string): CommodityPricesInfo {
            return this.commodityPrices.find(cp => cp.commodity.cId === commodityId);
        },

        removeSite(siteId: string) {
            // remove site from the list directly so the user gets immediate feedback
            this.commodityPrices.forEach((cp: CommodityPricesInfo) => {
                cp.priceLines = cp.priceLines.filter((pl: PriceLineInfo) => pl.site.sId !== siteId);
            });
            // remove it properly by deselecting it
            (this.$refs.filter as typeof SegregationPlanFilter).selectSite(siteId, -1);
        },

        // export excel
        exportXlsx() {
            Segregation.exportCommodityPricesXlsx(this.translations, this.commodities, this.commodityPrices);
        },

        /*************
         * Update toggles and refresh prices
         ************/

        toggleOpeningHours() {
            this.showOpeningHours = !this.showOpeningHours;
            this.saveCookies();
            this.refreshPrices();
        },

        toggleShowPricing() {
            this.showPricing = !this.showPricing;
            this.saveCookies();
            this.refreshPrices();
        },

        toggleShowEPPricing() {
            this.showEPPricing = !this.showEPPricing;
            this.saveCookies();
            this.refreshPrices();
        },

        toggleShowSCPricing() {
            this.showSCPricing = !this.showSCPricing;
            this.saveCookies();
            this.refreshPrices();
        },

        refreshPrices() {
            (this.$refs.filter as typeof SegregationPlanFilter).refreshPrices();
        },

        /*************
         * Cookies
         ************/
        saveCookies() {
            const segregationPlan = {
                // update this values
                show_opening_hours: this.showOpeningHours,
                show_pricing: this.showPricing,
                show_ep_pricing: this.showEPPricing,
                show_sc_pricing: this.showSCPricing,
                // use the values already in cookie
                show_pool_options: this.showPoolOptions
            };
            Helper.setCookie('segregation_plan', JSON.stringify(segregationPlan));
        },

        readCookies() {
            const segregationPlan = JSON.parse(Helper.getCookie('segregation_plan'));
            if (segregationPlan) {
                // save values for page use
                this.showOpeningHours = segregationPlan.show_opening_hours;
                this.showPricing = segregationPlan.show_pricing;
                this.showEPPricing = segregationPlan.show_ep_pricing;
                this.showSCPricing = segregationPlan.show_sc_pricing;
                // save value only for writing cookies
                this.showPoolOptions = segregationPlan.show_pool_options;
            }
        }
    }
});

</script>

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "location-grid-lgcol3" }
const _hoisted_2 = { class: "location-grid-staff-image" }
const _hoisted_3 = { class: "location-box-image-box" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "location-box-pb-30 location-grid-staff-info" }
const _hoisted_6 = { class: "location-box-details" }
const _hoisted_7 = { class: "location-box-text-small location-box-thin" }
const _hoisted_8 = { class: "location-box-m-auto location-box-lghidden" }
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "location-grid-staff-lglink" }
const _hoisted_12 = ["href"]
const _hoisted_13 = ["href"]
const _hoisted_14 = {
  key: 0,
  class: "location-box-m-auto location-box-pt-45"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.staffs, (staff, index) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          class: "location-box location-grid-staff",
          key: staff.id
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                src: _ctx.getImage(staff.StaffNetworkId),
                onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeImage && _ctx.removeImage(...args))),
                class: "location-box-image"
              }, null, 40, _hoisted_4)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(staff.DisplayName), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(staff.PositionTitle), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.formatPhone(staff.BusinessPhone))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "button button--phone location-box-mr-15",
                  href: 'tel:'+_ctx.formatPhone(staff.BusinessPhone),
                  rel: "noopener noreferrer"
                }, _toDisplayString(_ctx.translations.call), 9, _hoisted_9))
              : _createCommentVNode("", true),
            _createElementVNode("a", {
              class: "button button--mail",
              href: 'mailto:'+staff.InternalEmail,
              rel: "noopener noreferrer"
            }, _toDisplayString(_ctx.translations.email), 9, _hoisted_10)
          ]),
          _createElementVNode("div", _hoisted_11, [
            (_ctx.formatPhone(staff.BusinessPhone))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "location-box-pb-30 location-box-phone-staff",
                  href: 'tel:'+_ctx.formatPhone(staff.BusinessPhone),
                  rel: "noopener noreferrer"
                }, "Tel. " + _toDisplayString(_ctx.formatPhone(staff.BusinessPhone)), 9, _hoisted_12))
              : _createCommentVNode("", true),
            _createElementVNode("a", {
              class: "button",
              href: 'mailto:'+staff.InternalEmail,
              rel: "noopener noreferrer"
            }, _toDisplayString(_ctx.translations.sendMessage), 9, _hoisted_13)
          ])
        ])), [
          [_vShow, index < _ctx.showNumber && staff.DisplayName]
        ])
      }), 128))
    ]),
    (_ctx.showNumber < _ctx.staffs.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", {
            class: "button button--down",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showNumber+=3))
          }, _toDisplayString(_ctx.translations.showMore), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}
import axios from 'axios';
import Helper from './Helper';

const API_URL = '/.rest/api/v1/gav-locations';

const COOKIE_NAME = 'us_location';

export default class Gavilon {
    /*
     * API Calls Functions
     */

    // locations API calls for contracts
    public static async getLocations(): Promise<Array<Object>> {
        try {
            // eslint-disable-next-line dot-notation
            const results = await axios.get(window['contextPath'] + API_URL);
            if (results.status === 200 && results.data.length > 0) {
                return results.data
                    .map(({ id, name, description, active }) => ({ id, name, description, active }));
            }
            return [];
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    // locations API calls for banner
    static async getBannerLocations(): Promise<Array<{ [key: string]: any }> > {
        try {
            // eslint-disable-next-line dot-notation
            const results = await axios.get(window['contextPath'] + API_URL);
            if (results.status === 200 && results.data.length > 0) {
                return results.data
                    .map(({
                        id,
                        contactDisplayName,
                        contactTitleHr,
                        contactEmail,
                        phone,
                        description,
                        contactNetworkId,
                        latitude,
                        longitude

                    }) => ({
                        id,
                        description,
                        contact: {
                            name: contactDisplayName,
                            title: contactTitleHr,
                            email: contactEmail,
                            phone,
                            image: contactNetworkId
                        },
                        location: {
                            lat: parseFloat(latitude),
                            lng: parseFloat(longitude)
                        }
                    }));
            }
            return [];
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    // locations API calls for locations map
    static async getMapLocations(): Promise<Array<{ [key: string]: any }> > {
        try {
            // eslint-disable-next-line dot-notation
            const results = await axios.get(window['contextPath'] + API_URL);
            if (results.status === 200 && results.data.length > 0) {
                return results.data.filter(element => element.id !== 131)
                    .map(({
                        id,
                        address1,
                        city,
                        stateProvince,
                        postalCode,
                        hoursStandardWeekDayOpen,
                        hoursStandardWeekDayClose,
                        contactDisplayName,
                        contactTitleHr,
                        contactEmail,
                        phone,
                        contactNetworkId,
                        latitude,
                        longitude,
                        description
                    }) => ({
                        id,
                        description,
                        info: {
                            address: address1,
                            stateProvince,
                            city,
                            postalCode,
                            openHour: this.hoursTranslation(hoursStandardWeekDayOpen),
                            closeHour: this.hoursTranslation(hoursStandardWeekDayClose)
                        },
                        contact: {
                            name: contactDisplayName,
                            title: contactTitleHr,
                            email: contactEmail,
                            phone,
                            image: contactNetworkId
                        },
                        location: {
                            lat: parseFloat(latitude),
                            lng: parseFloat(longitude)
                        },
                        // eslint-disable-next-line dot-notation
                        iconURL: `${window['resourcePath']}img/marker-gav.png`
                    }));
            }
            return [];
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    private static hoursTranslation(hourString: string) {
        if (!hourString) {
            return '';
        }
        const newHourNumber: number = (parseInt(hourString) / 100);
        if (newHourNumber > 12) {
            return `${(newHourNumber - 12).toFixed(2).toString()} PM`;
        }
        return `${newHourNumber.toFixed(2).toString()} AM`;
    }

    /*
     * Distance between positions
     */
    // Converts numeric degrees to radians
    private static toRad(value: number) {
        return (value * Math.PI) / 180;
    }
    // calculate distance
    static calcCrow(pos1, pos2) {
        const R = 6371; // km
        const dLat = this.toRad(pos2.lat - pos1.lat);
        const dLon = this.toRad(pos2.lng - pos1.lng);
        const lat1 = this.toRad(pos1.lat);
        const lat2 = this.toRad(pos2.lat);

        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
            + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    }

    /*
     * Staff Image Functions
     */
    static getImage(code: string) {
        if (code) {
            return `https://mygavilonresources.gavilon.com/images/staff/${code}.jpg`;
        }
        return '';
    }
    // default image
    static removeImage(e) {
        // eslint-disable-next-line dot-notation
        e.target.src = `${window['resourcePath']}/samples/person-placeholder-male.jpg`;
    }

    /*
     * Cookie Location Functions
     */
    static getLocationCookie() {
        const cookie = Helper.getCookie(COOKIE_NAME);
        if (cookie) {
            const values = cookie;
            return Number(values);
        }
        return null;
    }
    static setLocationCookie(id: string) {
        Helper.setCookie(COOKIE_NAME, id);
    }
    static eraseLocationCookie() {
        Helper.setCookie(COOKIE_NAME, null);
    }
}

<template>
    <div>
        <div v-if="locationSelected" class="desktop">
            <div v-show="!collapsed || editMode" class="gav-banner-grid" :class="editMode? 'gav-banner-edit' : 'gav-banner'">
                <div v-show="editMode" class="gav-banner-warning gav-banner-text-normal">
                    This component is only displayed if the website visitor already chose a preferred location, or if there is a location nearby.
                </div>
                <div class="gav-banner-start2 align-self-center">
                    <div class="gav-banner-mobile-label">
                        {{ translations.yourClosestLocation }}
                    </div>
                    <div class="gav-banner-flex-row">
                        <a class="button button--link gav-banner-mobile-link" :href="link">
                            <div>{{ locationSelected.description }}</div>
                        </a>
                    </div>
                </div>
                <div class="gav-banner-start5 gav-banner-flex-row align-self-center">
                    <div class="gav-banner-picture">
                        <img
                            :src="gavilon.getImage(locationSelected.contact.image)"
                            @error="gavilon.removeImage"
                            class="location-box-image">
                    </div>
                    <div class="align-self-center">
                        <div class="gav-banner-text-normal">
                            {{ locationSelected.contact.name }}
                        </div>
                        <div>{{ locationSelected.contact.title }}</div>
                    </div>
                    <div class="gav-banner-contact-col2 align-self-center">
                        <div v-if="locationSelected.contact.phone">
                            T: {{ locationSelected.contact.phone }}
                        </div>
                        <a
                            v-if="locationSelected.contact.email"
                            class="button button--link"
                            :href="`mailto:${locationSelected.contact.email}`"
                            rel="noopener noreferrer">
                            {{ translations.sendMessage }}
                        </a>
                    </div>
                </div>
                <div class="gav-banner-start10 gav-banner-text-normal gav-banner-align-self-center">
                    <a
                        :href="`${link}/${locationSelected.description}`"
                        title="See more details"
                        class="button">
                        {{ translations.seeMoreDetails }}
                    </a>
                </div>
                <div class="gav-banner-start12">
                    <a
                        @click="toggleCollapsed"
                        class="gav-banner-flex-row">
                        <div>{{ translations.minimize }}</div>
                        <div class="button button--round-arrow button--borderless" />
                    </a>
                </div>
            </div>
            <div v-show="collapsed && !editMode" class="gav-banner-collapsed">
                <div @click="collapsed = !collapsed; saveCookies()" class="button button--round-arrow button--borderless rotate-icon" />
                <div class="rotate-text">
                    <div>{{ translations.yourLocation }}</div>
                    <div class="gav-banner-text-normal">
                        {{ locationSelected.description }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="locationSelected && open" class="gav-banner-grid mobile" :class="editMode? 'gav-banner-edit' : 'gav-banner'">
            <div class="gav-banner-start2 align-self-center">
                <div class="button button--cross button--borderless right-top" @click="closeBanner(); saveCookies()" />
                <div class="gav-banner-mobile-label">
                    {{ translations.yourClosestLocation }}
                </div>
                <div class="gav-banner-flex-row">
                    <a class="button button--link gav-banner-mobile-link" :href="link">
                        <div>{{ locationSelected.description }}</div>
                    </a>
                    <div class="gav-banner-mobile-buttons">
                        <a class="button button--phone ml-16 mobile" :href="'tel:+'+locationSelected.contact.phone" rel="noopener noreferrer">{{ translations.call }}</a>
                        <a class="button button--mail ml-16 mobile" :href="'mailto:'+locationSelected.contact.email" rel="noopener noreferrer">{{ translations.email }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Gavilon from '../../lib/Gavilon';
import Helper from '../../lib/Helper';

export default defineComponent({
    props: {
        link: { required: true, type: String },
        translations: { required: true, type: Object as () => Record<string, string> },
        editMode: { default: false, type: Boolean }
    },
    data() {
        return {
            // all locations
            locations: [] as Array<{ [key: string]: any }>,
            collapsed: false,
            open: true,
            locationSelectedId: null as number,
            locationSelected: null,
            // to use gavilon functions in template
            gavilon: Gavilon
        };
    },

    async created() {
        // get locations
        this.locations = await Gavilon.getBannerLocations();

        // get selected location in cookies
        this.locationSelectedId = Gavilon.getLocationCookie();
        if (this.locationSelectedId) {
            // if location in cookie get the location info
            this.locationSelected = this.locations.find(element => element.id === this.locationSelectedId);
        } else if (navigator.geolocation) {
            // get position of user
            navigator.geolocation.getCurrentPosition(this.getPosition);
        }
    },

    methods: {
        // get position of user
        getPosition(position) {
            let personLocation = { lat: 0, lng: 0 };
            if (position && position.coords && position.coords.latitude && position.coords.longitude) {
                personLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
            }
            // order locations by distance
            const locationsByDistance = this.locations.sort((a, b) => {
                const fa = Gavilon.calcCrow(a.location, personLocation);
                a.distance = fa;
                const fb = Gavilon.calcCrow(b.location, personLocation);
                b.distance = fb;
                return fa - fb;
            });
            // select the closest location
            if (locationsByDistance.length > 0 && locationsByDistance[0].distance < 5000) {
                this.locationSelected = locationsByDistance[0];
                this.locationSelectedId = Number(this.locationSelected.id);
            }
        },

        closeBanner() {
            this.open = false;
        },

        toggleCollapsed() {
            if (!this.editMode) {
                this.collapsed = !this.collapsed;
                this.saveCookies();
            }
        },

        /*************
         * Cookies
         ************/

        mounted() {
            this.readCookies();
        },

        saveCookies() {
            const gavBanner = { collapsed: this.collapsed };
            Helper.setCookie('gav_banner', JSON.stringify(gavBanner));
        },

        readCookies() {
            const gavBanner = JSON.parse(Helper.getCookie('gav_banner'));
            if (gavBanner) { this.collapsed = gavBanner.collapsed; }
        }
    }

});

</script>

<template>
    <vit-tabs mobile sliding :tabs-lenght="completeResults.length">
        <vit-tab
            v-for="result in completeResults"
            :key="`table-${result.resultLabel.id}`"
            :title="result.resultLabel.label"
            :disabled="result.contracts.length < 1"
            :content-width="true"
            narrow>
            <div v-for="contract in result.contracts" :key="contract.id" class="contracts-results_box">
                <div class="contracts-results_box-grid-cat">
                    <div class="contracts-results_box-title">
                        {{ contract.name }}
                    </div>
                    <div class="contracts-results_box-category">
                        {{ contract.category }}
                    </div>
                </div>
                <div class="contracts-results_box-tagline">
                    {{ contract.tagline }}
                </div>
                <div class="contracts-results_box-grid-lg2col">
                    <div>
                        <div class="contracts-results_box-subtitle">
                            {{ resultsTranslations.planFeatures }}
                        </div>
                        <div
                            v-for="feature in contract.features"
                            :key="feature.substring(0, 4)"
                            class="contracts-results_box-feature">
                            <icon class="contracts-results_box-feature-icon" name="icn-plus" />
                            <div>{{ feature }}</div>
                        </div>
                    </div>
                    <div class="contracts-results_box-grid-2col">
                        <div>
                            <div class="contracts-results_box-subtitle">
                                {{ resultsTranslations.cashDelivery }}
                            </div>
                            <div class="contracts-results_box-tagline">
                                {{ contract.delivery }}
                            </div>
                        </div>
                        <div>
                            <div class="contracts-results_box-subtitle">
                                {{ resultsTranslations.priceRange }}
                                <div class="contracts-results_box-subtitle-info">
                                    <icon class="contracts-results_box-subtitle-icon" name="icn-info" />
                                    <div class="contracts-results_box-subtitle-message" v-html="resultsTranslations.feeMessage" />
                                </div>
                            </div>
                            <div class="contracts-results_box-tagline">
                                {{ contract.cost }}
                            </div>
                        </div>
                    </div>
                </div>
                <a
                    v-if="contractsLink"
                    class="button button--borderless contracts-results_box-button"
                    :href="generateAnchorUrl(contract.anchor)"
                    target="_blank"
                    rel="noopener noreferrer">{{ resultsTranslations.learnMore }}</a>
            </div>
        </vit-tab>
    </vit-tabs>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import Icon from '../atoms/Icon.vue';
import VitTabs from '../base/VitTabs.vue';
import VitTab from '../base/VitTab.vue';

export default defineComponent({
    components: { VitTab, VitTabs, Icon },
    props: {
        resultsTranslations: { required: true, type: Object as () => Record<string, string> },
        resultsLabels: { required: true, type: Array as () => Array<Object> },
        showAll: { default: false, type: Boolean },
        contractsLink: { required: true, type: String },
        contractsCash: { default: () => [], type: Array as () => Array<Object> },
        contractsManage: { default: () => [], type: Array as () => Array<Object> },
        contractsStructure: { default: () => [], type: Array as () => Array<Object> }
    },

    computed: {
        completeResults() {
            return this.resultsLabels.map(resultLabel => ({ resultLabel, contracts: this.getResults(resultLabel) }));
        }
    },
    methods: {
        getResults(label) {
            switch (label.id) {
                case 'cash':
                    return this.contractsCash;
                case 'manage':
                    return this.contractsManage;
                case 'structure':
                    return this.contractsStructure;
                default:
                    return [
                        ...this.contractsCash,
                        ...this.contractsManage,
                        ...this.contractsStructure
                    ];
            }
        },
        generateAnchorUrl(anchorId:string) {
            if (!this.contractsLink) {
                return '';
            }
            if (!anchorId) {
                return this.contractsLink;
            }
            return `${this.contractsLink}#${anchorId}`;
        }
    }
});

</script>

import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "tabbed-slider__carousel" }
const _hoisted_2 = { class: "tabbed-slider__control-wrapper" }
const _hoisted_3 = {
  class: "tabbed-slider__control",
  ref: "sliderControl"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "tabbed-slider__control-item-text" }
const _hoisted_6 = ["href", "target", "aria-label"]
const _hoisted_7 = { class: "tabbed-slider__control-item-button-label" }
const _hoisted_8 = {
  key: 0,
  class: "tabbed-slider__progress-ring"
}
const _hoisted_9 = { class: "tabbed-slider__control-dots" }
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_circle_progress_ring = _resolveComponent("circle-progress-ring")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tabbed-slider", { 'tabbed-slider--edit-mode': _ctx.editMode }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["tabbed-slider__control-item", {' tabbed-slider__control-item--active': _ctx.index === i }]),
            style: _normalizeStyle(`--num-items: ${_ctx.tabs.length}`),
            onClick: ($event: any) => (_ctx.onItemClick(i)),
            key: tab._uid
          }, [
            _createElementVNode("div", {
              ref_for: true,
              ref: "textContainer",
              class: "tabbed-slider__control-item-text-container"
            }, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(tab.text), 1)
            ], 512),
            _createElementVNode("a", {
              href: tab.link,
              class: "button button--borderless tabbed-slider__control-item-button",
              target: tab.linkTarget,
              "aria-label": tab.linkLabel
            }, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(tab.linkLabel), 1)
            ], 8, _hoisted_6),
            (_ctx.index === i)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_circle_progress_ring, { progress: _ctx.progress }, null, 8, ["progress"])
                ]))
              : _createCommentVNode("", true)
          ], 14, _hoisted_4))
        }), 128))
      ], 512),
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, t) => {
          return (_openBlock(), _createElementBlock("div", {
            key: tab._uid + '_dot',
            onClick: ($event: any) => (_ctx.index = t),
            class: _normalizeClass(["tabbed-slider__control-dot", { 'tabbed-slider__control-dot--active': t === _ctx.index }])
          }, null, 10, _hoisted_10))
        }), 128))
      ])
    ])
  ], 2))
}
import { utils, writeFile } from 'xlsx';

export default class ExcelExport {
    static export(exportData, fileName) {
        /* convert from array of arrays to workbook */
        const worksheet = utils.aoa_to_sheet(exportData);
        worksheet['!cols'] = new Array(7).fill({ width: 25 });
        const wb = utils.book_new();
        utils.book_append_sheet(wb, worksheet, 'Sheet');
        const now = new Date();
        const pad = val => `${val}`.padStart(2, '0');
        // generate time string (yyyy-mm-dd-hh-mm) to append to the file name
        const dateStr = `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}-${pad(now.getHours())}-${pad(now.getMinutes())}`;
        /* generate file and send to client */
        writeFile(wb, `${fileName}_${dateStr}.xlsx`);
    }
}

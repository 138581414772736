import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "leadership__member-detail" }
const _hoisted_2 = { class: "leadership__member-detail-left" }
const _hoisted_3 = { class: "leadership-department__member-name" }
const _hoisted_4 = { class: "leadership-department__member-text" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "leadership__member-detail-right" }
const _hoisted_7 = { class: "leadership__member-board" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "leadership__member-content" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_dropdown_item = _resolveComponent("dropdown-item")!
  const _component_vit_dropdown = _resolveComponent("vit-dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.member.name), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.member.job), 1),
        (_ctx.member.image)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "leadership__member-image leadership__member-image--detail",
              src: _ctx.member.image,
              alt: _ctx.member.name
            }, null, 8, _hoisted_5))
          : (_openBlock(), _createBlock(_component_icon, {
              key: 1,
              class: "leadership__member-icon",
              name: "icn-placeholder"
            }))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_vit_dropdown, {
          label: _ctx.member.name,
          light: "",
          "mobile-only": ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (memberoption) => {
              return (_openBlock(), _createBlock(_component_dropdown_item, {
                key: memberoption.uuid,
                label: memberoption.name,
                onClick: ($event: any) => (_ctx.$emit('member', memberoption))
              }, null, 8, ["label", "onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["label"]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.menuTitle), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (memberoption) => {
            return (_openBlock(), _createElementBlock("button", {
              key: memberoption.uuid,
              class: "button button--link leadership__member-board-link",
              onClick: ($event: any) => (_ctx.$emit('member', memberoption))
            }, _toDisplayString(memberoption.name), 9, _hoisted_8))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_ctx.member.appointed && _ctx.member.appointed.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", null, _toDisplayString(_ctx.appointedLabel), 1),
            _createElementVNode("div", {
              innerHTML: _ctx.member.appointed,
              class: "leadership-department__member-text"
            }, null, 8, _hoisted_11)
          ]))
        : _createCommentVNode("", true),
      (_ctx.member.experience && _ctx.member.experience.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", null, _toDisplayString(_ctx.experienceLabel), 1),
            _createElementVNode("div", {
              innerHTML: _ctx.member.experience,
              class: "leadership-department__member-text"
            }, null, 8, _hoisted_13)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
export default class Helper {
    /**
     * Set a new browser cookie. Cookie will be valid for 1 year.
     * @param name The name of the cookie
     * @param value The value of the cookie
     */
    static setCookie(name: string, value: string) {
        const date: Date = new Date();
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000)); // 1 year valid

        const expires: string = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value}; ${expires}; path=/; SameSite=Strict; secure`;
    }

    /**
     * Get a browser cookie by a given name
     * Returns null if not found
     * @param name The cookie name
     */
    static getCookie(name: string): string {
        const nameLenPlus: number = (name.length + 1);

        return document.cookie
            .split(';')
            .map(c => c.trim())
            .filter(cookie => cookie.substring(0, nameLenPlus) === `${name}=`)
            .map(cookie => decodeURIComponent(cookie.substring(nameLenPlus)))[0] || null;
    }

    /**
     * cross browser way to get the current scroll position
     */
    static getScrollTop() {
        if (typeof pageYOffset !== 'undefined') {
            // most browsers except IE before #9
            return pageYOffset;
        }
        const B = document.body; // IE 'quirks'
        let D = document.documentElement; // IE with doctype
        D = (D.clientHeight) ? D : B;
        return D.scrollTop;
    }
}
